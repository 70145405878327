import { VW, VH } from "../../componets/function/sizeAdapter"
function Question_mark(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" style={{ display: 'block' }}>
            <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.8337 15.8337H9.16699V14.167H10.8337V15.8337ZM12.5587 9.37533L11.8087 10.142C11.2087 10.7503 10.8337 11.2503 10.8337 12.5003H9.16699V12.0837C9.16699 11.167 9.54199 10.3337 10.142 9.72533L11.1753 8.67533C11.4837 8.37533 11.667 7.95866 11.667 7.50033C11.667 6.58366 10.917 5.83366 10.0003 5.83366C9.08366 5.83366 8.33366 6.58366 8.33366 7.50033H6.66699C6.66699 5.65866 8.15866 4.16699 10.0003 4.16699C11.842 4.16699 13.3337 5.65866 13.3337 7.50033C13.3337 8.23366 13.0337 8.90033 12.5587 9.37533Z" fill="#2F83FF" />
        </svg>
    )
}

export default Question_mark;