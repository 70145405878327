import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_equipment_second() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width="24" height="24" rx="2" fill="#2F83FF" />
            <path d="M7.6665 10.8416L6.47484 9.65831C6.77484 9.32498 7.4915 8.66665 8.45817 8.66665C9.12484 8.66665 9.6165 8.99165 9.9665 9.22498C10.2248 9.39998 10.3915 9.49998 10.5415 9.49998C10.8498 9.49998 11.2082 9.15831 11.3332 8.99165L12.5165 10.175C12.2165 10.5083 11.4998 11.1666 10.5415 11.1666C9.88317 11.1666 9.39984 10.85 9.04984 10.6166C8.77484 10.4333 8.6165 10.3333 8.45817 10.3333C8.14984 10.3333 7.7915 10.675 7.6665 10.8416ZM8.45817 14.5C8.6165 14.5 8.77484 14.6 9.04984 14.7833C9.39984 15.0166 9.88317 15.3333 10.5415 15.3333C11.5082 15.3333 12.2165 14.675 12.5165 14.3416L11.3332 13.1583C11.2082 13.325 10.8415 13.6666 10.5415 13.6666C10.3915 13.6666 10.2248 13.5666 9.9665 13.3916C9.6165 13.1583 9.12484 12.8333 8.45817 12.8333C7.4915 12.8333 6.77484 13.4916 6.47484 13.825L7.65817 15.0083C7.7915 14.8416 8.14984 14.5 8.45817 14.5ZM20.3332 6.99998V17C20.3332 17.9166 19.5832 18.6666 18.6665 18.6666H5.33317C4.4165 18.6666 3.6665 17.9166 3.6665 17V6.99998C3.6665 6.08331 4.4165 5.33331 5.33317 5.33331H18.6665C19.5832 5.33331 20.3332 6.08331 20.3332 6.99998ZM13.6665 6.99998H5.33317V17H13.6665V6.99998ZM17.8332 15.3333C17.8332 14.875 17.4582 14.5 16.9998 14.5C16.5415 14.5 16.1665 14.875 16.1665 15.3333C16.1665 15.7916 16.5415 16.1666 16.9998 16.1666C17.4582 16.1666 17.8332 15.7916 17.8332 15.3333ZM17.8332 12C17.8332 11.5416 17.4582 11.1666 16.9998 11.1666C16.5415 11.1666 16.1665 11.5416 16.1665 12C16.1665 12.4583 16.5415 12.8333 16.9998 12.8333C17.4582 12.8333 17.8332 12.4583 17.8332 12ZM17.8332 7.83331H16.1665V9.49998H17.8332V7.83331Z" fill="white" />
        </svg>

    )
}
export default PropertyDetail_equipment_second;


