import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter";


const styles = (theme) => ({
    ground: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            fontSize: VW(16),
            paddingLeft: VW(16),
            alignItems: "center",
            whiteSpace: 'pre-line',
            wordBreak: 'break-all'
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            alignItems: 'center',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '80%'
        }
    }
});

function Item_two(props) {
    const {
        classes,
        titleName,
        content,
        height,
        borderBottom
    } = props





    return (
        <div className={classes.ground} style={{ borderBottom: borderBottom ? `1px solid #DDD` : '' }}>
            <div style={{ height: VH(height) }}>{titleName}</div>
            <div style={{ height: VH(height) }}>
                <div style={{ width: '100%', maxHeight: VH(height), overflowY: "scroll" }}>{content}</div>
            </div>
        </div>
    )
}

Item_two.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Item_two);