import React, { useState, useEffect, useRef } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, DatePicker, Select } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import locale from "antd/es/date-picker/locale/ja_JP";
import { errorMessage } from "../../wordsFile/wordsFile";
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CaretDownOutlined } from '@ant-design/icons'
import { ApiSetsubiInfoAdd } from '../../network/api'


dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    prompt: {
        marginTop: VH(13),
        fontSize: VW(16)
    },
    titleBlock: {
        width: '100%',
        height: VH(54),
        marginTop: VH(16),
        borderTop: '1px solid #DDD',
        backgroundColor: '#F8F8F8',
        display: 'flex',
        '& > div': {
            fontSize: VW(16),
            fontWeight: 'bold',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            width: '33.33333%'
        },
    },
    inputBlock: {
        width: '100%',
        height: VH(74),
        borderTop: '1px solid #DDD',
        display: 'flex',
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            width: '33.33333%',
            '& > Input': {
                height: VH(50),
                fontSize: VW(16)
            },
        },
    },
    addBlock: {
        width: '100%',
        height: VH(50),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '1vw',
        '& > div': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.78vw',
            fontSize: VW(16)
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_equipment_list_add(props) {
    const {
        classes,
        open,
        onClose,
        state,
        getList
    } = props


    useEffect(() => {
        if (!open) {
            setRoomInputList([{ rank: '1', room_count: '', miss_num: '' }])
            setRrrorOpen(false)
        }
        log(state)
    }, [open])



    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // room input list 
    const [roomInputList, setRoomInputList] = useState([
        { rank: '1', room_count: '', miss_num: '' }
    ])
    const updateRoomInputList = (item, index, flag) => {
        // if (flag == 'miss_num') {
        //     let noSpace = item.replace(/[\t\r\f\n\s]*/g, '');
        //     roomInputList[index][flag] = noSpace.split('').join(' ')
        // } else {
        roomInputList[index][flag] = item
        // }
        setRoomInputList([...roomInputList])
        log(roomInputList)
    }
    const addRoomInputItem = () => {
        let newRank = Number(roomInputList[roomInputList.length - 1].rank) + 1
        roomInputList.push({ rank: String(newRank), room_count: roomInputList[roomInputList.length - 1].room_count, miss_num: roomInputList[roomInputList.length - 1].miss_num })
        setRoomInputList([...roomInputList])
    }



    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    // add api
    const addItem = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });

        let newRoomInputList = roomInputList
        // newRoomInputList.map(item => {
        //     item.miss_num = item.miss_num.replace(/[\t\r\f\n\s]*/g, '');
        // })

        let erA = false
        let erB = false
        // let erC = false
        let erD = false

        let rankArray = []
        newRoomInputList.map(item => {
            if (item.rank == '' && (item.room_count != '' || (item.miss_num != '' && item.room_count == ''))) {
                erA = true
            }
            if (item.room_count == '' && (item.rank != '' || (item.miss_num != '' && item.rank == ''))) {
                erB = true
            }
            // if (noRepeat2(item.miss_num)) {
            //     erC = true
            // }
            if (item.rank != '') {
                rankArray.push(item.rank)
            }
        })
        if ([...new Set(rankArray)].length != rankArray.length) {
            erD = true
        }

        let errText = ''
        if (erA) {
            errText = errText + '階数が未入力の行があります。\n'
        }
        if (erB) {
            errText = errText + '部屋数が未入力の行があります。\n'
        }
        // if (erC) {
        //     errText = errText + '欠番が重複している行があります。\n'
        // }
        if (erD) {
            errText = errText + '階数が重複しています。\n'
        }

        if (errText != '') {
            setErrorContent(errText)
            setRrrorOpen(true)
        } else {
            let newRoomList = [];
            newRoomInputList.map(v => {
                newRoomList.push(Object.assign(v, { buken_id: state.buken_id }))
            })
            ApiSetsubiInfoAdd(newRoomList).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    getList('add')
                    onClose()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    // 繰り返し文字があるかどうかを判断する
    const noRepeat2 = (str) => {
        if (str.length === new Set(str).size) {
            // no same
            return false
        }
        // same
        return true
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>一括部屋登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.prompt}>
                    ※利用しない部屋番号がある場合、コンマ区切りで欠番に入力してください。（例）1と11を部屋番号で利用しない場合、1,11を入力。
                </div>
                <div className={classes.titleBlock}>
                    <div>階数</div>
                    <div>部屋数</div>
                    <div>欠番</div>
                </div>
                {roomInputList.length > 0 && roomInputList.map((item, index) => {
                    return (
                        <div className={classes.inputBlock} key={index}>
                            <div>
                                <Input
                                    style={{ width: VW(200) }}
                                    value={item.rank}
                                    onChange={(event) => updateRoomInputList(event.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), index, 'rank')}
                                    maxLength={2}
                                />
                            </div>
                            <div>
                                <Input
                                    style={{ width: VW(200) }}
                                    value={item.room_count}
                                    onChange={(event) => updateRoomInputList(event.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), index, 'room_count')}
                                    maxLength={2}
                                />
                            </div>
                            <div>
                                <Input
                                    style={{ width: VW(248) }}
                                    value={item.miss_num}
                                    onChange={(event) => updateRoomInputList(event.target.value.replace(/[^0-9+^\uFF10-\uFF19+^,]+/g, ''), index, 'miss_num')}
                                />
                            </div>
                        </div>
                    )
                })}
                <div className={classes.addBlock}>
                    <div onClick={addRoomInputItem}>
                        <AddIcon />&ensp;階を追加する
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={addItem}>登録</Button>
                </div>
            </div>
        </Modal>
    )
}

PropertyDetail_equipment_list_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_list_add);