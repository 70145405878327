import { CaretDownOutlined } from '@ant-design/icons';
import { withStyles } from "@mui/styles";
import { DatePicker, Input, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import React, { useState } from "react";
import { VH, VW } from "../function/sizeAdapter";


const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    customerBlock: {
        '& > div': {
            height: VH(124),
        },
        '& > div:nth-child(1)': {
            width: VW(146)
        },
        '& > div:nth-child(2)': {
            width: VW(593),
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                '& > Input': {
                    height: VH(50),
                    fontSize: VW(16),
                    borderRadius: "4px"
                },
            }
        }
    },
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(74),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(74)
        },
        '& > div:nth-child(odd)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(even)': {
            width: '26%'
        },
        '& > div > .ant-modal-footer': {
            display: 'none'
        },
        '& > div > .ant-select-single .ant-select-selector': {
            height: VH(50),
            alignItems: 'center',
        },
    },
    groundTwo: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(74),
        },
        '& > div:nth-child(1)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '76%'
        },
        '& > div > Input': {
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > .ant-modal-footer': {
            display: 'none'
        },
        '& > div > .ant-select-single .ant-select-selector': {
            height: VH(50),
            alignItems: 'center',
        },
    }
});

function PropertyDetail_repair_input(props) {
    const {
        classes,
        detailedData,
        changeDetailedData,
        setsubi_idOption,
        business_record_idOption,
        claim_record_idOption,
        priorityValueOption
    } = props

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ステータス
    const [statusValueOption, setStatusValueOption] = useState([
        { value: '0', label: '受注' },
        { value: '1', label: '手配済' },
        { value: '2', label: '実施待ち' },
        { value: '3', label: '実施済' }
    ])



    return (
        <div style={{ marginTop: VH(16) }}>
            <div className={classes.groundTwo}>
                <div>修繕番号</div>
                <div>{detailedData.mending_no}</div>
            </div>
            <div className={classes.groundFour}>
                <div>優先度</div>
                <div>
                    <Select
                        style={{ width: VW(360), }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.priority == undefined ? undefined : String(detailedData.priority)}
                        onChange={(event) => changeDetailedData(event, 'priority')}
                        allowClear
                        onClear={() => changeDetailedData(undefined, 'priority')}
                    >
                        {priorityValueOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>
                                    <span style={{ color: item.color }}>●</span>&nbsp;{item.label}
                                </Option>
                            )
                        })}
                    </Select>
                </div>
                <div>ステータス</div>
                <div>
                    <Select
                        style={{ width: VW(360), }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.status == undefined ? undefined : String(detailedData.status)}
                        onChange={(event) => changeDetailedData(event, 'status')}
                        allowClear
                        onClear={() => changeDetailedData(undefined, 'status')}
                    >
                        {statusValueOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>受注日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.order_date ? dayjs(detailedData.order_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'order_date')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>依頼日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.requested_date ? dayjs(detailedData.requested_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'requested_date')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>予定日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.scheduled_date ? dayjs(detailedData.scheduled_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'scheduled_date')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>完了日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.completion_date ? dayjs(detailedData.completion_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'completion_date')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>修繕スペース</div>
                <div>
                    <Select
                        style={{ width: VW(360), }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.setsubi_id == undefined ? undefined : detailedData.setsubi_id}
                        onChange={(event) => changeDetailedData(event, 'setsubi_id')}
                        allowClear
                        onClear={() => changeDetailedData(undefined, 'setsubi_id')}
                    >
                        {setsubi_idOption.length > 0 && setsubi_idOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.setsubi_id}>{item.room_num}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>件名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                <div>
                    <Input
                        style={{ width: '100%' }}
                        placeholder="件名を記入してください"
                        maxLength={60}
                        value={detailedData.subject}
                        onChange={(event) => changeDetailedData(event.target.value, 'subject')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>修繕箇所</div>
                <div>
                    <Input
                        style={{ width: '100%' }}
                        placeholder="修繕箇所を記入してください"
                        maxLength={60}
                        value={detailedData.mending_location}
                        onChange={(event) => changeDetailedData(event.target.value, 'mending_location')}
                    />
                </div>
            </div>
            <div style={{ height: VH(304) }} className={classes.groundTwo}>
                <div>修繕内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(280) }}
                        placeholder="修繕内容を記入してください"
                        maxLength={500}
                        value={detailedData.mending_content}
                        onChange={(event) => changeDetailedData(event.target.value, 'mending_content')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>実施会社</div>
                <div>
                    <Input
                        style={{ width: '100%' }}
                        placeholder="実施会社を記入してください"
                        maxLength={60}
                        value={detailedData.organizing_company}
                        onChange={(event) => changeDetailedData(event.target.value, 'organizing_company')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>営業番号</div>
                <div>
                    <Select
                        style={{ width: '100%' }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.business_record_id}
                        onChange={(event) => changeDetailedData(event, 'business_record_id')}
                        allowClear
                        onClear={() => { changeDetailedData(undefined, 'business_record_id') }}
                    >
                        {business_record_idOption.length > 0 && business_record_idOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.business_record_id}>{item.business_record_no + '　' + item.subject}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>クレーム番号</div>
                <div>
                    <Select
                        style={{ width: '100%' }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.claim_record_id}
                        onChange={(event) => changeDetailedData(event, 'claim_record_id')}
                        allowClear
                        onClear={() => { changeDetailedData(undefined, 'claim_record_id') }}
                    >
                        {claim_record_idOption.length > 0 && claim_record_idOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.claim_record_id}>{item.claim_record_no + '　' + item.subject}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
        </div>
    )
}

PropertyDetail_repair_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_repair_input);