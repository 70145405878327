import { VW, VH } from "../../componets/function/sizeAdapter"
function MoveIcon(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(20)} height={VH(16)} viewBox="0 0 20 16" style={{ display: 'block' }}>
            <path d="M18 2H10L8 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2ZM12 14V11H8V7H12V4L17 9L12 14Z" fill={color ? color : "#2F83FF"} />
        </svg>
    )
}

export default MoveIcon;