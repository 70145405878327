import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_notificationManagement(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(15)} height={VH(18)} viewBox="0 0 15 18" fill="none" >
            <path
                d="M11.65 4.20817L13.125 5.68317L8.975 9.83317H7.5V8.35817L11.65 4.20817ZM14.375 3.8415L13.4917 2.95817C13.325 2.7915 13.0667 2.7915 12.9 2.95817L12.1917 3.6665L13.6667 5.1415L14.375 4.43317C14.5417 4.2665 14.5417 3.99984 14.375 3.8415ZM12 9.1665V13.1665H13.6667V14.8332H0.333336V13.1665H2V7.33317C2 5.00817 3.59167 3.04984 5.75 2.49984V1.9165C5.75 1.22484 6.30834 0.666504 7 0.666504C7.69167 0.666504 8.25 1.22484 8.25 1.9165V2.49984C8.93334 2.67484 9.55834 2.9915 10.0917 3.40817L5.83334 7.6665V11.4998H9.66667L12 9.1665ZM5.33334 15.6665H8.66667C8.66667 16.5832 7.91667 17.3332 7 17.3332C6.08334 17.3332 5.33334 16.5832 5.33334 15.6665Z"
                fill={color ? color : '#2F83FF'} />
        </svg>


    )
}

export default MenuHeader_notificationManagement;