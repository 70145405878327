import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_equipment_list_string() {
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" fill="none" style={{ display: 'block' }} >
            <path d="M2.5 11.6667H5.83333V8.33342H2.5V11.6667ZM2.5 15.8334H5.83333V12.5001H2.5V15.8334ZM2.5 7.50008H5.83333V4.16675H2.5V7.50008ZM6.66667 11.6667H17.5V8.33342H6.66667V11.6667ZM6.66667 15.8334H17.5V12.5001H6.66667V15.8334ZM6.66667 4.16675V7.50008H17.5V4.16675H6.66667Z" fill="#2F83FF" />
        </svg>
    )
}
export default PropertyDetail_equipment_list_string;


