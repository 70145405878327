import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../../componets/function/sizeAdapter"


const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
        '& > div': {
            height: 'auto',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
        },
        '& > div > div': {
            minHeight: VH(74),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div > div:nth-child(2)': {
            width: VW(752),
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div > div > div': {
            width: VW(224),
            height: VH(30)
        },
    }
});

function Member_item(props) {
    const {
        classes,
        detailedData,
    } = props





    return (
        <div className={classes.contentBlock}>
            <div>
                <div>氏名</div>
                <div>{detailedData.surname + detailedData.name}</div>
            </div>
            <div>
                <div>メールアドレス</div>
                <div>{detailedData.email}</div>
            </div>
            <div>
                <div>所属</div>
                <div>{detailedData.affiliation}</div>
            </div>
            <div>
                <div>権限</div>
                <div>{detailedData.authority_name}</div>
            </div>
        </div>
    )
}

Member_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Member_item);