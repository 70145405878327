import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_reminderManagement(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(19)} height={VH(19)} viewBox="0 0 15 18" fill="none" >
            <path
                d="M2.16667 17.3333C1.70833 17.3333 1.31597 17.1701 0.989583 16.8438C0.663194 16.5174 0.5 16.125 0.5 15.6667V4C0.5 3.54167 0.663194 3.14931 0.989583 2.82292C1.31597 2.49653 1.70833 2.33333 2.16667 2.33333H3V0.666667H4.66667V2.33333H11.3333V0.666667H13V2.33333H13.8333C14.2917 2.33333 14.684 2.49653 15.0104 2.82292C15.3368 3.14931 15.5 3.54167 15.5 4V8.16667H13.8333V7.33333H2.16667V15.6667H8V17.3333H2.16667ZM9.66667 17.3333V14.7708L14.2708 10.1875C14.3958 10.0625 14.5347 9.97222 14.6875 9.91667C14.8403 9.86111 14.9931 9.83333 15.1458 9.83333C15.3125 9.83333 15.4722 9.86458 15.625 9.92708C15.7778 9.98958 15.9167 10.0833 16.0417 10.2083L16.8125 10.9792C16.9236 11.1042 17.0104 11.2431 17.0729 11.3958C17.1354 11.5486 17.1667 11.7014 17.1667 11.8542C17.1667 12.0069 17.1389 12.1632 17.0833 12.3229C17.0278 12.4826 16.9375 12.625 16.8125 12.75L12.2292 17.3333H9.66667ZM15.1458 12.6667L15.9167 11.8542L15.1458 11.0833L14.3542 11.875L15.1458 12.6667Z"
                fill={color ? color : '#2F83FF'} />
        </svg>
    )
}

export default MenuHeader_reminderManagement;