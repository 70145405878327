import React, { useState, useEffect } from "react";
import {
    Select,
    MenuItem,
    Checkbox,
    OutlinedInput
} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ApiCompanyMemberList } from "../../network/api";


dayjs.extend(utc)

const styles = (theme) => ({

});

function Property_select_usage_direction(props) {
    const {
        selectedDirectionName,
        setSelectedDirectionName,
        setSelectedDirectionId,
        selectedDirectionId,
        direction
    } = props

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [customDirectionList, setCustomDirectionList] = useState([])
    const getCustomDirectionList = () => {
        let param = {
            query_content: "",
            pageNum: 1,
            pageLimit: 1000,
            sortKey: "surname",
            sortValue: "asc",
            isSelfIncluded: true
        }
        ApiCompanyMemberList(param).then(res => {
            let resData = res.data
            if (res.status === 200) {
                updateCustomDirectionList(resData.data)
            }
        })
    }
    const updateCustomDirectionList = (directionList) => {
        directionList.map(item => {
            item.all_name = item.surname + item.name
            if (direction !== 2) {
                setSelectedDirectionName([])
                setSelectedDirectionId([])
            }
            if (direction === 2 && selectedDirectionName.length > 0) {
                selectedDirectionName.map(detailItem => {
                    if (item.all_name == detailItem) {
                        item.selected = true
                    }
                })
            } else {
                item.selected = false
            }
        })
        setCustomDirectionList(directionList)
        setIsSelectedAll(directionList.length > 0 && directionList.length === selectedDirectionName.length)
    }
    const handleChange = (item, newValue, index) => {
        customDirectionList[index]['selected'] = newValue
        const indexOfFirst = selectedDirectionId.indexOf(String(item.user_id))
        if (newValue && indexOfFirst === -1) {
            selectedDirectionName.push(item.all_name)
            selectedDirectionId.push(item.user_id.toString())
        } else if (!newValue && indexOfFirst > -1) {
            selectedDirectionName.splice(indexOfFirst, 1)
            selectedDirectionId.splice(indexOfFirst, 1)
        }
        log(customDirectionList)
        log(selectedDirectionName)
        setCustomDirectionList([...customDirectionList])
        setSelectedDirectionName([...selectedDirectionName])
        setSelectedDirectionId([...selectedDirectionId])
        setIsSelectedAll(customDirectionList.length > 0 && customDirectionList.length === selectedDirectionName.length)
    }
    const [isSelectedAll, setIsSelectedAll] = useState(false)
    const handleChangeAll = (newValue) => {
        const selectId = []
        const selectName = []
        customDirectionList.map(directionItem => {
            directionItem.selected = newValue
            if (newValue) {
                selectName.push(directionItem.all_name)
                selectId.push(directionItem.user_id.toString())
            }
        })
        setSelectedDirectionName([...selectName])
        setSelectedDirectionId([...selectId])
        setIsSelectedAll(newValue)
    }

    useEffect(() => {
        getCustomDirectionList()
    }, [direction])

    useEffect(() => {
        updateCustomDirectionList(customDirectionList)
        setIsSelectedAll(customDirectionList.length > 0 && customDirectionList.length === selectedDirectionName.length)
    }, [])

    return (
        <div style={{ marginLeft: VW(16) }}>
            {direction === 2 ?
                <Select
                    style={{ width: VW(416), height: VH(40), display: 'flex', alignItems: 'center', fontSize: VW(16) }}
                    multiple
                    displayEmpty
                    value={selectedDirectionName}
                    input={<OutlinedInput label="" />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <em>配信先を選択してください</em>;
                        }
                        return selected.join(', ');
                    }}
                >
                    <MenuItem style={{ weight: VW(200), height: VH(38), fontSize: VW(16) }} >
                        <Checkbox
                            color="success"
                            checked={isSelectedAll}
                            onChange={(e, newValue) => { handleChangeAll(newValue) }}
                        />
                        <div>一括</div>
                    </MenuItem>
                    {customDirectionList.length > 0 && customDirectionList.map((item, index) => {
                        return (
                            <MenuItem
                                key={index}
                                style={{ weight: VW(200), height: VH(38), fontSize: VW(16) }}
                            >
                                <Checkbox
                                    color="success"
                                    key={item.selected}
                                    checked={item.selected}
                                    onChange={(e, newValue) => { handleChange(item, newValue, index) }}
                                />
                                <div>{item.all_name}</div>
                            </MenuItem>
                        )
                    })}
                </Select>
                :
                <Select
                    style={{ width: VW(416), height: VH(40), display: 'flex', alignItems: 'center', fontSize: VW(16) }}
                    disabled
                    displayEmpty
                    renderValue={() => {
                        return <p>配信先を選択してください</p>
                    }}
                >
                </Select>
            }
        </div>
    )
}

Property_select_usage_direction.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Property_select_usage_direction);