import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_contractor() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width={VW(24)} height={VH(24)} rx="2" fill="#2F83FF" />
            <path d="M9.75001 12.75C11.145 12.75 12.3075 11.79 12.645 10.5H6.85501C7.19251 11.79 8.35501 12.75 9.75001 12.75Z" fill="white" />
            <path d="M6.555 9.75H12.9525C13.155 9.75 13.32 9.585 13.32 9.3825V9.3675C13.32 9.165 13.155 9 12.9525 9H12.75C12.75 7.89 12.1425 6.9375 11.25 6.4125V7.125C11.25 7.335 11.085 7.5 10.875 7.5C10.665 7.5 10.5 7.335 10.5 7.125V6.105C10.26 6.045 10.0125 6 9.75 6C9.4875 6 9.24 6.045 9 6.105V7.125C9 7.335 8.835 7.5 8.625 7.5C8.415 7.5 8.25 7.335 8.25 7.125V6.4125C7.3575 6.9375 6.75 7.89 6.75 9H6.555C6.3525 9 6.1875 9.165 6.1875 9.3675V9.39C6.1875 9.585 6.3525 9.75 6.555 9.75Z" fill="white" />
            <path d="M17.94 11.76L17.565 11.1075C17.5425 11.0625 17.49 11.0475 17.4525 11.0625L16.9875 11.25C16.89 11.175 16.785 11.115 16.6725 11.07L16.605 10.575C16.59 10.53 16.545 10.5 16.5 10.5H15.75C15.705 10.5 15.6675 10.53 15.66 10.5825L15.5925 11.0775C15.48 11.1225 15.375 11.19 15.2775 11.2575L14.8125 11.07C14.7675 11.055 14.7225 11.07 14.7 11.115L14.325 11.7675C14.3025 11.8125 14.31 11.8575 14.3475 11.8875L14.745 12.195C14.7375 12.255 14.73 12.315 14.73 12.375C14.73 12.435 14.7375 12.5025 14.745 12.555L14.3475 12.8625C14.31 12.8925 14.3025 12.945 14.325 12.9825L14.7 13.635C14.7225 13.68 14.775 13.695 14.8125 13.68L15.2775 13.4925C15.375 13.5675 15.48 13.6275 15.5925 13.6725L15.66 14.1675C15.6675 14.22 15.705 14.25 15.75 14.25H16.5C16.545 14.25 16.59 14.22 16.59 14.1675L16.6575 13.6725C16.77 13.6275 16.875 13.56 16.9725 13.4925L17.4375 13.68C17.4825 13.695 17.5275 13.68 17.55 13.635L17.925 12.9825C17.9475 12.9375 17.94 12.8925 17.9025 12.8625L17.5125 12.555C17.52 12.495 17.5275 12.435 17.5275 12.375C17.5275 12.315 17.52 12.2475 17.5125 12.195L17.91 11.8875C17.9475 11.8575 17.955 11.805 17.94 11.76ZM16.125 12.9975C15.78 12.9975 15.5025 12.7125 15.5025 12.375C15.5025 12.03 15.7875 11.7525 16.125 11.7525C16.4625 11.7525 16.7475 12.0375 16.7475 12.375C16.7475 12.72 16.47 12.9975 16.125 12.9975Z" fill="white" />
            <path d="M19.575 8.13C19.5825 8.0475 19.59 7.965 19.59 7.875C19.59 7.785 19.5825 7.7025 19.5675 7.62L20.1225 7.185C20.175 7.1475 20.1825 7.0725 20.1525 7.02L19.6275 6.1125C19.5975 6.0525 19.5225 6.0375 19.47 6.0525L18.825 6.315C18.69 6.21 18.54 6.1275 18.3825 6.06L18.285 5.3625C18.27 5.295 18.2175 5.25 18.15 5.25H17.1C17.0325 5.25 16.98 5.295 16.9725 5.3625L16.875 6.06C16.7175 6.1275 16.5675 6.2175 16.4325 6.315L15.78 6.0525C15.72 6.03 15.6525 6.0525 15.6225 6.1125L15.0975 7.02C15.0675 7.08 15.075 7.1475 15.1275 7.185L15.6825 7.62C15.6675 7.7025 15.66 7.7925 15.66 7.875C15.66 7.9575 15.6675 8.0475 15.6825 8.13L15.1275 8.565C15.075 8.6025 15.0675 8.6775 15.0975 8.73L15.6225 9.6375C15.6525 9.6975 15.7275 9.7125 15.78 9.6975L16.4325 9.435C16.5675 9.54 16.7175 9.6225 16.875 9.69L16.9725 10.3875C16.98 10.455 17.0325 10.5 17.1 10.5H18.15C18.2175 10.5 18.27 10.455 18.2775 10.3875L18.375 9.69C18.5325 9.6225 18.6825 9.5325 18.8175 9.435L19.47 9.6975C19.53 9.72 19.5975 9.6975 19.6275 9.6375L20.1525 8.73C20.1825 8.67 20.175 8.6025 20.1225 8.565L19.575 8.13ZM17.625 8.8125C17.1075 8.8125 16.6875 8.3925 16.6875 7.875C16.6875 7.3575 17.1075 6.9375 17.625 6.9375C18.1425 6.9375 18.5625 7.3575 18.5625 7.875C18.5625 8.3925 18.1425 8.8125 17.625 8.8125Z" fill="white" />
            <path d="M9.75 14.25C7.7475 14.25 3.75 15.255 3.75 17.25V18.75H15.75V17.25C15.75 15.255 11.7525 14.25 9.75 14.25Z" fill="white" />
        </svg>

    )
}

export default Property_detail_contractor;