
import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_house(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(18)} height={VH(16)} viewBox="0 0 18 16" fill="none">
            <path d="M8.99984 3.83333V0.5H0.666504V15.5H17.3332V3.83333H8.99984ZM3.99984 13.8333H2.33317V12.1667H3.99984V13.8333ZM3.99984 10.5H2.33317V8.83333H3.99984V10.5ZM3.99984 7.16667H2.33317V5.5H3.99984V7.16667ZM3.99984 3.83333H2.33317V2.16667H3.99984V3.83333ZM7.33317 13.8333H5.6665V12.1667H7.33317V13.8333ZM7.33317 10.5H5.6665V8.83333H7.33317V10.5ZM7.33317 7.16667H5.6665V5.5H7.33317V7.16667ZM7.33317 3.83333H5.6665V2.16667H7.33317V3.83333ZM15.6665 13.8333H8.99984V12.1667H10.6665V10.5H8.99984V8.83333H10.6665V7.16667H8.99984V5.5H15.6665V13.8333ZM13.9998 7.16667H12.3332V8.83333H13.9998V7.16667ZM13.9998 10.5H12.3332V12.1667H13.9998V10.5Z" fill={color ? color : '#2F83FF'} />
        </svg>
    )
}

export default MenuHeader_house;