import React, { useState, useEffect, useRef } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import AddIcon from "../icon/AddIcon";
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ApiFilemanageCreateFolder } from '../../network/api'

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    InputBlock: {
        display: 'flex',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: VH(74),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(160),
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: VW(848),
            justifyContent: 'center',
            '& > Input': {
                width: '100%',
                height: VH(50),
                fontSize: VW(16)
            }
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_file_folder_add(props) {
    const {
        classes,
        open,
        onClose,
        getList,
        state,
        tabFlag
    } = props

    useEffect(() => {
        if (!open) {
            setFolderName('')
        }
    }, [open]);


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")


    // 新規フォルダ
    const [folderName, setFolderName] = useState('')

    // 作成
    const makeFolder = () => {
        let param = {
            buken_id: state.buken_id,
            is_belong: tabFlag ? 1 : 0,
            file_folder_name: folderName,
        }
        log(param)
        ApiFilemanageCreateFolder(param).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                getList('add')
                onClose()
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>フォルダの作成</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.InputBlock}>
                    <div>フォルダ名</div>
                    <div>
                        <Input
                            placeholder="新規フォルダ"
                            value={folderName}
                            onChange={(event) => setFolderName(event.target.value)}
                        />
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button
                        disabled={folderName == ''}
                        style={{
                            color: folderName == '' ? 'gray' : '#FFF',
                            backgroundColor: folderName == '' ? '' : '#0052CC',
                        }}
                        onClick={makeFolder}>作成</Button>
                </div>
            </div>
        </Modal >
    )
}

PropertyDetail_file_folder_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_file_folder_add);