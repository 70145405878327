import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_file_download() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none">
            <path d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z" fill="#2F83FF" />
        </svg>
    )
}
export default PropertyDetail_file_download;


