import React from 'react';
import { withStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";

const styles = (theme) => ({
    ui: {
        padding: '30px 0',
        justifyContent: 'center'
    },
});

function Common_pagination(props) {
    const {
        classes,
        count,
        rowsPerPage,
        page,
        onChange
    } = props

    const totalPage = Math.ceil(count / rowsPerPage)
    const currentPage = page + 1

    return (
        <div>
            {totalPage === 0 ? (
                ""
            ) : totalPage <= 1 ? (
                <Pagination
                    disabled
                    count={totalPage}
                    color="primary"
                    page={currentPage}
                    onChange={onChange}
                    classes={{
                        ul: classes.ui
                    }}
                />
            ) :
                <Pagination
                    count={totalPage}
                    color="primary"
                    page={currentPage}
                    onChange={onChange}
                    classes={{
                        ul: classes.ui
                    }}
                />
            }
        </div>
    )
}

Common_pagination.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Common_pagination);