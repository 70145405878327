import React, { useEffect, useState, Fragment } from "react";
import {
    Box
} from "@mui/material";
import { withStyles } from "@mui/styles";
import HeaderBar from "../componets/bar/HeaderBar";
import LeftMenu from "../componets/bar/LeftMenu";
import MainRouters from "../routes/MainRouters";
import { useLocation, useNavigate } from 'react-router-dom';
import { VW, VH } from "../componets/function/sizeAdapter"
import qs from 'qs'
import { CompressOutlined } from "@mui/icons-material";

const styles = (theme) => ({
    Box: {
        width: '100%',
        height: '100vh',
    },
    headerBar: {
        width: '100%',
        height: VH(56),
    },
    bodyMain: {
        width: '100%',
        display: 'flex',
    },
    menu: {
        height: 'calc(100vh - 56px)',
        transition: 'all 0.1s',
        overflow: 'hidden'
    },
    content: {
        borderLeft: `1px solid ${theme.palette.themeBorder.main}`,
        transition: 'all 0.1s',
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
});


function Home(props) {
    const {
        classes,
    } = props

    const navigate = useNavigate()

    const [selectFlag, setSelectFlag] = useState('/home/dashBoard')
    const [userObject, setObject] = useState(qs.parse(localStorage.getItem('userObject')))

    // open or close menu
    const [menuFlag, setMenuFlag] = useState(true)
    const changeMenuStatus = () => {
        setMenuFlag(!menuFlag)
    }





    useEffect(() => {
        window.devicePixelRatio = 1
        function handleResize() {
            var zoomLevel = window.devicePixelRatio

            let newWidth = zoomLevel * localStorage.getItem('innerWidth')
            localStorage.setItem('zoomWidth', newWidth)

            let newHeight = zoomLevel * localStorage.getItem('innerHeight')
            localStorage.setItem('zoomHeight', newHeight)
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <Box className={classes.Box} id='father_Block' style={{ overflow: 'scroll', overflow: 'overlay' }}>
            <Box
                style={{
                    minWidth: Number(localStorage.getItem('zoomWidth')) ? Number(localStorage.getItem('zoomWidth')) : Number(localStorage.getItem('innerWidth')),
                    minHeight: Number(localStorage.getItem('zoomHeight')) ? Number(localStorage.getItem('zoomHeight')) : Number(localStorage.getItem('innerHeight')),
                }}>
                <Box className={classes.headerBar}>
                    <HeaderBar
                        changeMenuStatus={changeMenuStatus}
                        menuFlag={menuFlag}
                        userObject={userObject}
                    />
                </Box>
                <Box className={classes.bodyMain}>
                    <Box className={classes.menu} style={{ width: menuFlag ? '18.75%' : '0' }}>
                        <LeftMenu
                            selectFlag={selectFlag}
                        />
                    </Box>
                    <Box className={classes.content} style={{ width: menuFlag ? '81.25%' : '100%' }}>
                        <MainRouters
                            setSelectFlag={setSelectFlag}
                            setObject={setObject}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

Home.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Home);