import React, { useEffect, useState, useRef } from "react";
import {
    MenuItem,
    Select,
    TablePagination
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import MenuHeader_property from "../../componets/icon/MenuHeader_property";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import { useNavigate } from 'react-router-dom'
import { Button, Input } from 'antd'
import { ClearRounded } from "@mui/icons-material"
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import Common_checkBoxTable from "../../componets/common/Common_checkBoxTable";
import PropertyDetail_equipment_list_add from "../../componets/dialog/PropertyDetail_equipment_list_add";
import PropertyDetail_equipment_add from "../../componets/dialog/PropertyDetail_equipment_add";
import PropertyDetail_roomType_update from "../../componets/dialog/PropertyDetail_roomType_update";
import { ApiSetsubiInfoList, ApiSetsubiInfoRoom_topic } from '../../network/api'
import PropertyDetail_equipment_delete from "../../componets/dialog/PropertyDetail_equipment_delete";
import Common_pagination from "../../componets/common/Common_pagination";



dayjs.extend(utc)


const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingBottom: VW(24),
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    objectNameBlock: {
        marginLeft: VW(24),
        marginTop: VH(12),
        minHeight: VH(24),
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
        wordBreak: 'break-all',
        '& > div:nth-child(1)': {
            fontSize: VW(14),
        },
        '& > div:nth-child(2)': {
            fontSize: VW(24),
            fontWeight: 'bold',
            marginLeft: VW(16)
        }
    },
    titleMenu: {
        width: 'auto',
        display: 'flex',
        marginTop: VH(16),
        height: VH(40),
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    menuContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: VW(16)
    },
    retrievalBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        // paddingLeft: VW(16),
        paddingRight: VW(16),
        paddingTop: VH(16),
        '& > Input': {
            width: VW(874),
            height: VH(40),
            fontSize: VW(16)
        },
        '& > Button': {
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
            fontSize: VW(16),
            backgroundColor: theme.palette.primary.main,
        }
    },
    inputTitle: {
        fontSize: VW(14),
        width: VW(100),
        maxWidth: VW(85),
        textAlign: 'right',
        marginRight: VW(16)
    },
    querySelect: {
        width: '75%',
        height: VH(40),
        fontSize: VW(16),
        backgroundColor: '#FFF'
    },
    buttonGroup: {
        marginTop: VH(24),
        display: 'flex',
        justifyContent: 'flex-end',
        '& > Button': {
            height: VH(40),
            fontSize: VW(16),
            borderRadius: '4px'
        },
        '& > Button:nth-child(1)': {
            color: '#E60024',
        },
        '& > Button:not(:nth-child(1))': {
            fontWeight: 'bold',
            marginLeft: VW(24),
            backgroundColor: theme.palette.primary.main,
        },
    },
    tableBlock: {
        marginRight: VW(24),
        paddingTop: '1vh'
    }
});

function PropertyDetail_equipment_list(props) {
    const {
        classes,
        setSelectFlag
    } = props

    const location = useLocation();
    const { state } = location;


    useEffect(() => {
        setSelectFlag(routerNames.Property)
    }, [setSelectFlag])


    useEffect(() => {
        log(state)
    }, [])


    const navigate = useNavigate()


    const authority_id = localStorage.getItem("authority_id")

    // const authority_id = "5";
    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // title menu content
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list
    const [titleMenuList, setTitleMenuList] = useState(routerTitleList)
    function routerTitleList() {
        var items = []
        if (state.buken_shubetsu === 0) {
            items = [
                { name: '物件情報', width: 130, checked: false },
                { name: '部屋情報', width: 120, checked: true },
                { name: 'メンテナンス', width: 130, checked: false },
                { name: '設備記録', width: 120, checked: false },
                { name: '営業記録', width: 120, checked: false },
                { name: 'クレーム記録', width: 130, checked: false },
                { name: '修繕記録', width: 120, checked: false },
                { name: 'ファイル', width: 122, checked: false }
            ]
        } else {
            items = [
                { name: 'コンテナマスタ', width: 142, checked: false },
                { name: 'メンテナンス', width: 142, checked: false },
                { name: '設備記録', width: 142, checked: true },
                { name: '営業記録', width: 142, checked: false },
                { name: 'クレーム記録', width: 142, checked: false },
                { name: '修繕記録', width: 142, checked: false },
                { name: 'ファイル', width: 142, checked: false }
            ]
        }

        return items
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list click
    const selectTitleItem = (content) => {
        log(content)
        if (content.name == "物件情報" || content.name == "コンテナマスタ") {
            navigate(routerNames.PropertyDetail, { state: state })
        } else if (content.name == "営業記録") {
            navigate(routerNames.PropertyDetail_business, { state: state })
        } else if (content.name == "クレーム記録") {
            navigate(routerNames.PropertyDetail_claim, { state: state })
        } else if (content.name == "修繕記録") {
            navigate(routerNames.PropertyDetail_repair, { state: state })
        } else if (content.name == "メンテナンス") {
            navigate(routerNames.PropertyDetail_calendar, { state: state })
        } else if (content.name == "設備記録") {
            navigate(routerNames.PropertyDetail_equipment_records, { state: state })
        } else if (content.name == "ファイル") {
            navigate(routerNames.PropertyDetail_file, { state: state })
        }
    }



    // 検索
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  キーワード input
    const [keywordSearch, setKeywordSearch] = useState(localStorage.getItem('equipmentList_keywordSearch') == null || localStorage.getItem('equipmentList_keywordSearch') == undefined ? '' : localStorage.getItem('equipmentList_keywordSearch'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  招待 select 
    const [statusValueOption, setStatusValueOption] = useState([])
    const [statusValue, setStatusValue] = useState(!localStorage.getItem('equipmentList_loginStatus') ? '-1' : localStorage.getItem('equipmentList_loginStatus'))
    const selectStatusValue = (e) => {
        setStatusValue(e.target.value);
    }


    // Common_checkBoxTable
    const [titleList, setTitleList] = useState([
        { titleIcon: '↓', name: '部屋番号', field: 'room_num', width: '12%', canClick: true },
        { titleIcon: '↓', name: '階数', field: 'rank', width: '10%' },
        { titleIcon: '↓', name: '部屋タイプ', field: 'room_topic', width: '78%' }
    ])
    const [bodyList, setBodyList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  update
    const getItemData = (item) => {
        let newState = Object.assign({}, state, item)
        navigate(routerNames.PropertyDetail_equipment, { state: newState })
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete
    const [deleteData, setDeleteData] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  sort
    const [count, setCount] = useState(0)
    const pagingFlag = useRef('room_num')
    const pagingDirection = useRef('↓')
    const toSort = (item) => {
        log(item)
        pagingFlag.current = item.field
        pagingDirection.current = item.titleIcon
        titleList.map(titleItem => {
            if (titleItem.name === item.name) {
                titleItem.titleIcon = item.titleIcon === '↓' ? '↑' : '↓'
                titleItem.checked = true
            } else {
                titleItem.checked = false
                if (titleItem.field === 'tag_color') {
                    titleItem.titleIcon = ''
                } else {
                    titleItem.titleIcon = '↓'
                }
            }
        })
        setTitleList([...titleList])
        getList()
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('equipmentList_rowsPerPage') ? Number(localStorage.getItem('equipmentList_rowsPerPage')) : 50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setCurrentPage(newPage)
    };
    const handleChangePageForPagination = (event, newPage) => {
        const page = newPage - 1
        setPage(page)
        setCurrentPage(page)
    };
    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('equipmentList_rowsPerPage', String(event.target.value))
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);


    // 一括部屋登録
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }
    // 部屋登録
    const [roomAddOpen, setRoomAddOpen] = useState(false)
    const roomAddOnClose = () => {
        setRoomAddOpen(false)
    }
    const openRoomAddDialog = () => {
        setRoomAddOpen(true)
    }
    // 部屋と設備の紐付け
    const [stringAddOpen, setStringAddOpen] = useState(false)
    const stringAddOnClose = () => {
        setStringAddOpen(false)
    }
    const openStringAddDialog = () => {
        setStringAddOpen(true)
    }
    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }





    //  get list api
    const getList = (successFlag) => {
        if (successFlag == 'useEffect') {
            let param = {
                buken_id: state.buken_id,
            }
            ApiSetsubiInfoRoom_topic(param).then(res => {
                let resDataA = res.data
                if (res.status === 200) {
                    document.getElementById('father_Block').scrollTop = 0;
                    setStatusValueOption(resDataA.data)
                    log(resDataA)
                    let params = {
                        buken_id: state.buken_id,
                        pageNum: page + 1,
                        pageLimit: rowsPerPage,
                        sortKey: pagingFlag.current,
                        sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc',
                        room_topic_type: String(statusValue),
                        query_content: encodeURIComponent(keywordSearch)
                    }
                    log(params)
                    ApiSetsubiInfoList(params).then(res => {
                        let resData = res.data
                        if (res.status === 200) {
                            document.getElementById('father_Block').scrollTop = 0;
                            localStorage.setItem('equipmentList_keywordSearch', keywordSearch)
                            localStorage.setItem('equipmentList_loginStatus', statusValue)
                            log(resData)
                            setCount(resData.count)
                            setBodyList([...resData.data])
                        } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                            log(res)
                            setSuccessOpen(false)
                            setRrrorOpen(true)
                            setErrorContent(res.data.detail.toString())
                        }
                    }).catch(err => {

                    })
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        } else {
            let params = {
                buken_id: state.buken_id,
                pageNum: page + 1,
                pageLimit: rowsPerPage,
                sortKey: pagingFlag.current,
                sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc',
                room_topic_type: String(statusValue),
                query_content: encodeURIComponent(keywordSearch)
            }
            log(params)
            ApiSetsubiInfoList(params).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    document.getElementById('father_Block').scrollTop = 0;
                    localStorage.setItem('equipmentList_keywordSearch', keywordSearch)
                    localStorage.setItem('equipmentList_loginStatus', statusValue)
                    log(resData)
                    setRrrorOpen(false)
                    setCount(resData.count)
                    setBodyList([...resData.data])
                    if (successFlag === 'add') {
                        setSuccessContent('登録が完了しました。')
                        setSuccessOpen(true)
                    }
                    if (successFlag === 'delete') {
                        setSuccessContent('削除が完了しました。')
                        setSuccessOpen(true)
                    }
                    setDeleteData([])
                    setIndeterminate(false)
                    setCheckAll(false)
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }



    useEffect(() => {
        window.addEventListener('keydown', enter);
        return () => {
            window.removeEventListener('keydown', enter);
        };
    }, [keywordSearch])
    const enter = (e) => {
        switch (e.keyCode) {
            case 13:
                getList()
                break;
        }
    }

    // get list
    useEffect(() => {
        getList('useEffect')
    }, [page, rowsPerPage])

    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { navigate(routerNames.Property) }}>物件一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>物件詳細</div>
            <div className={classes.tableTitle}>
                <MenuHeader_property />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件詳細</div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            <div className={classes.objectNameBlock}>
                <div>物件名</div>
                <div>{state.genba_name}</div>
            </div>
            <div className={classes.titleMenu}>
                {titleMenuList.map((item, index) => {
                    return (
                        <div
                            className={classes.menuContent}
                            key={index}
                            style={{ width: VW(item.width), backgroundColor: item.checked ? '#E0ECFF' : '', fontWeight: item.checked ? 'bold' : '' }}
                            onClick={() => { selectTitleItem(item) }}
                        >{item.name}</div>
                    )
                })}
            </div>
            <div style={{ marginTop: VH(12) }}>
                <div className={classes.retrievalBlock}>
                    <div className={classes.inputTitle}>キーワード</div>
                    <Input
                        placeholder='キーワードを入力してください'
                        value={keywordSearch}
                        style={{
                            // width: VW(874),
                            height: VH(40),
                            fontSize: VW(16)
                        }}
                        maxLength={200}
                        onChange={(event) => { setKeywordSearch(event.target.value) }}
                        suffix={
                            (
                                <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                    setKeywordSearch('')
                                }}></ClearRounded>
                            )
                        }
                    />
                </div>
                <div className={classes.retrievalBlock} style={{ paddingBottom: VH(16) }}>
                    <div className={classes.inputTitle}>部屋タイプ</div>
                    <Select
                        className={classes.querySelect}
                        value={statusValue}
                        label=""
                        onChange={selectStatusValue}
                    >
                        {statusValueOption.map((item, index) => {
                            return (
                                <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.room_topic_type}>{item.room_topic}</MenuItem>
                            )
                        })}
                    </Select>
                    <Button onClick={getList} type="primary" icon={<SearchIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} style={{ position: 'absolute', right: VW(16), fontWeight: 'bold' }}>検索</Button>
                </div>
            </div>
            {(authority_id == '3' || authority_id == '4') &&
                <div className={classes.buttonGroup}>
                    <Button
                        className={classes.AB}
                        type="text"
                        danger
                        icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        disabled={deleteData.length === 0}
                        onClick={openDeleteDialog}
                    >削除</Button>
                    <Button
                        type="primary"
                        style={{ width: VW(160) }}
                        icon={<UploadIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={openAddDialog}
                    >一括部屋登録</Button>
                    <Button
                        type="primary"
                        style={{ width: VW(200) }}
                        icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={openStringAddDialog}
                    >部屋と設備の紐付け</Button>
                    <Button
                        type="primary"
                        style={{ width: VW(120) }}
                        icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={openRoomAddDialog}
                    >部屋登録</Button>
                </div>
            }
            <TablePagination
                component="div"
                style={{ paddingRight: VW(24), fontSize: VW(12) }}
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
                labelRowsPerPage='表示件数:'
                className={classes.selectLabel}
                classes={{
                    toolbar: classes.toolbar
                }}
            />
            <div className={classes.tableBlock}>
                <Common_checkBoxTable
                    titleList={titleList}
                    bodyList={bodyList}
                    setBodyList={setBodyList}
                    getItemData={getItemData}
                    setCheckList={setDeleteData}
                    toSort={toSort}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                />
            </div>
            <PropertyDetail_equipment_list_add
                open={addOpen}
                onClose={addOnClose}
                getList={getList}
                state={state}
            />
            <PropertyDetail_equipment_add
                open={roomAddOpen}
                onClose={roomAddOnClose}
                getList={getList}
                buken_id={state.buken_id}
            />
            <PropertyDetail_roomType_update
                open={stringAddOpen}
                onClose={stringAddOnClose}
                getList={() => { getList('useEffect') }}
                state={state}
            />
            <PropertyDetail_equipment_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                setsubi_id={deleteData}
                getList={getList}
            />
            <Common_pagination
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChange={handleChangePageForPagination}
            />
        </div >
    )
}

PropertyDetail_equipment_list.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_list);