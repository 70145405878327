import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../../componets/function/sizeAdapter"


const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
        '& > div': {
            height: 'auto',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
        },
        '& > div > div': {
            minHeight: VH(74),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div > div:nth-child(2)': {
            width: VW(752),
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div > div > div': {
            width: VW(224),
            height: VH(30)
        },
    }
});

function TagDetail_item(props) {
    const {
        classes,
        detailedData,
    } = props





    return (
        <div className={classes.contentBlock}>
            <div>
                <div>タグ名</div>
                <div>{detailedData.tag_name}</div>
            </div>
            <div>
                <div>カラー</div>
                <div>
                    <div
                        style={{
                            backgroundColor:
                                detailedData.color_type === 1 ? '#FFB872' :
                                    detailedData.color_type === 2 ? '#BB99FE' :
                                        detailedData.color_type === 3 ? '#80BFFF' :
                                            detailedData.color_type === 4 ? '#BCFA38' :
                                                detailedData.color_type === 5 ? '#F9CFF9' :
                                                    detailedData.color_type === 6 ? '#ADEAFF' :
                                                        detailedData.color_type === 7 ? '#85F8B3' :
                                                            '#FF8CCF',
                        }}></div>
                </div>
            </div>
        </div>
    )
}

TagDetail_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TagDetail_item);