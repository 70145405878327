import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_install() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width={VW(24)} height={VH(24)} rx="2" fill="#2F83FF" />
            <path d="M7.83366 9.5H3.66699V7.83333H7.83366V9.5ZM7.83366 12H3.66699V13.6667H7.83366V12ZM19.1587 17.8333L15.967 14.6417C15.3003 15.075 14.517 15.3333 13.667 15.3333C11.367 15.3333 9.50033 13.4667 9.50033 11.1667C9.50033 8.86667 11.367 7 13.667 7C15.967 7 17.8337 8.86667 17.8337 11.1667C17.8337 12.0167 17.5753 12.8 17.142 13.4583L20.3337 16.6583L19.1587 17.8333ZM16.167 11.1667C16.167 9.79167 15.042 8.66667 13.667 8.66667C12.292 8.66667 11.167 9.79167 11.167 11.1667C11.167 12.5417 12.292 13.6667 13.667 13.6667C15.042 13.6667 16.167 12.5417 16.167 11.1667ZM3.66699 17.8333H12.0003V16.1667H3.66699V17.8333Z" fill="white" />
        </svg>
    )
}
export default Property_detail_install;






