import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_router_repair() {

    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20">
            <path d="M14.5833 8.33313C16.1917 8.33313 17.5 7.0248 17.5 5.41646C17.5 4.93313 17.3667 4.48313 17.1583 4.08313L14.9083 6.33313L13.6667 5.09146L15.9167 2.84146C15.5167 2.63313 15.0667 2.4998 14.5833 2.4998C12.975 2.4998 11.6667 3.80813 11.6667 5.41646C11.6667 5.75813 11.7333 6.08313 11.8417 6.38313L10.3 7.9248L8.81667 6.44146L9.40833 5.8498L8.23333 4.6748L10 2.90813C9.025 1.93313 7.44167 1.93313 6.46667 2.90813L3.51667 5.85813L4.69167 7.03313H2.34167L1.75 7.6248L4.7 10.5748L5.29167 9.98313V7.6248L6.46667 8.7998L7.05833 8.20813L8.54167 9.69146L2.36667 15.8665L4.13333 17.6331L13.6167 8.15813C13.9167 8.26646 14.2417 8.33313 14.5833 8.33313Z" fill="#2F83FF" />
            <path d="M13.2533 10.8754L11.4856 12.6432L16.4824 17.64L18.2502 15.8723L13.2533 10.8754Z" fill="#2F83FF" />
        </svg>
    )
}

export default Property_detail_router_repair;