import { VW, VH } from "../../componets/function/sizeAdapter"

function TableUploadIcon() {
    return (
        <svg width={VW(20)} height={VH(23)} viewBox="0 0 20 23" fill="none">
            <path d="M6.00008 17.3333H14.0001V9.33333H19.3334L10.0001 0L0.666748 9.33333H6.00008V17.3333ZM0.666748 20H19.3334V22.6667H0.666748V20Z" fill="#5B5B5B" />
        </svg>

    )
}

export default TableUploadIcon;