import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from "react";
import { VH, VW } from "../../componets/function/sizeAdapter";
import { ApiClaimrecordDelete } from '../../network/api';
import Common_errorAlert from "../common/Common_errorAlert";
import log from "../function/log";
import Property_detail_router_claim from "../icon/Property_detail_router_claim";

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    headerBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        marginTop: VH(16),
        backgroundColor: '#F8F8F8',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: '100%',
            fontWeight: 'bold',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw'
        },
        '& > div:nth-child(1)': {
            width: '15%'
        },
        '& > div:nth-child(2)': {
            width: '45%'
        },
        '& > div:nth-child(3)': {
            width: '14%'
        },
        '& > div:nth-child(4)': {
            width: '9%'
        },
        '& > div:nth-child(5)': {
            width: '14%'
        },
        '& > div:nth-child(6)': {
            width: '13%'
        },
    },
    scrollBlock: {
        width: '100%',
        maxHeight: VH(224),
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
    bodyBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDD',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw',
            overflow: 'hidden'
        },
        '& > div:nth-child(1)': {
            width: '15%'
        },
        '& > div:nth-child(2)': {
            width: '45%'
        },
        '& > div:nth-child(3)': {
            width: '14%'
        },
        '& > div:nth-child(4)': {
            width: '9%'
        },
        '& > div:nth-child(5)': {
            width: '14%'
        },
        '& > div:nth-child(6)': {
            width: '13%'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: '#E60024',
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_claim_delete(props) {
    const {
        classes,
        open,
        onClose,
        deleteData,
        getList,
        setDeleteData,
        setIndeterminate,
        setCheckAll,
        historyFlag,
        getPriorityColor
    } = props

    useEffect(() => {
        if (open) {
            log(deleteData)
        } else {
            setTitleFlag(false)
            setRrrorOpen(false)
        }
    }, [open])


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // delete api
    const toDelete = () => {
        if (!titleFlag) {
            setTitleFlag(true)
        } else {
            let deleteList = []
            deleteData.map(item => {
                deleteList.push({ claim_record_id: item.claim_record_id })
            })
            ApiClaimrecordDelete(deleteList).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    if (historyFlag) {
                        onClose()
                        window.history.back()
                        localStorage.setItem('claim_deleteFlag', true)
                    } else {
                        getList('delete')
                        onClose()
                        setDeleteData([])
                        setIndeterminate(false)
                        setCheckAll(false)
                    }
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    // title text flag
    const [titleFlag, setTitleFlag] = useState(false)


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <Property_detail_router_claim />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>クレーム記録の削除</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {titleFlag ? <div style={{ color: '#E60024', fontSize: VW(16) }}>以下のクレーム記録を削除します。<br />削除されたデータは復元できませんが、本当に削除しますか？</div> : <div style={{ fontSize: VW(16) }}>以下のクレーム記録を削除します。</div>}
            <div className={classes.headerBlock}>
                <div>クレーム番号</div>
                <div>件名</div>
                <div>対応期日</div>
                <div>優先度</div>
                <div>お客様</div>
                <div>ステータス</div>
            </div>
            <div className={classes.scrollBlock}>
                {deleteData && deleteData.map((item, index) => {
                    return (
                        <div className={classes.bodyBlock} key={index}>
                            <div>
                                <p>{item.claim_record_no}</p>
                            </div>
                            <div>
                                <p>{item.subject}</p>
                            </div>
                            <div>
                                <p>{item.compatible_date}</p>
                            </div>
                            <div>
                                <p>
                                    <span style={{
                                        display: (item.priority_name != null) ? '' : 'none',
                                        color: getPriorityColor(item.priority_name)
                                    }}>●</span>
                                    &nbsp;{item.priority_name}
                                </p>
                            </div>
                            <div>
                                <p>{item.customer_name}</p>
                            </div>
                            <div>
                                <p>{item.status_name}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>キャンセル</Button>
                <Button danger onClick={toDelete}>削除</Button>
            </div>
        </Modal>
    )
}

PropertyDetail_claim_delete.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_claim_delete);