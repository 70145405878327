import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"
import dic from "../function/utc";


const styles = (theme) => ({
    line: {
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        padding: '8px 12px 16px 16px'
    },
    block: {
        borderRadius: '4px',
        border: '1px solid #DDD',
        padding: '8px 12px 16px 16px',
        marginBottom: VH(8),
        backgroundColor: '#F4F8FF'
    },
    date: {
        color: '#333333',
        fontSize: VW(13),
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: VH(8)
    },
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        fontWeight: 'bold',
        whiteSpace: 'pre-line'
    }
});

function Common_listItem(props) {
    const {
        classes,
        usageStartDate,
        title,
        text,
        type
    } = props


    const linkifyText = () => {
        const urlRegex = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@&#63;^=%&amp;amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/;
        return text.replace(urlRegex, '<a href="$&" target="_blank">$&</a>');
    }

    const getListItemType = () => {
        switch (type) {
            case 'line':
                return classes.line
            case 'block':
                return classes.block
            default:
                return classes.line
        }
    }


    return (
        <div className={getListItemType()}>
            <div className={classes.date}>
                {dic.UTCDateToLocalDateNotice(usageStartDate)
                }</div>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.title} style={{ fontWeight: "normal", fontSize: VW(15), marginTop: VH(8) }}>
                <div dangerouslySetInnerHTML={{ __html: linkifyText() }} />
            </div>
        </div>
    )
}

Common_listItem.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Common_listItem);