import { ArrowForwardIos } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropertyDetail_claim_input from "../../componets/HtmlBody/PropertyDetail_claim_input";
import PropertyDetail_claim_item from "../../componets/HtmlBody/PropertyDetail_claim_item";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import PropertyDetail_claim_delete from "../../componets/dialog/PropertyDetail_claim_delete";
import PropertyDetail_repair_add from "../../componets/dialog/PropertyDetail_repair_add";
import log from "../../componets/function/log";
import { VH, VW } from "../../componets/function/sizeAdapter";
import Property_detail_router_claim from "../../componets/icon/Property_detail_router_claim";
import { ApiClaimrecordDetail, ApiClaimrecordDetailExit, ApiClaimrecordUpdate } from '../../network/api';
import { routerNames } from '../../wordsFile/wordsFile';


dayjs.extend(utc)

const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingRight: VW(24),
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    objectNameBlock: {
        position: 'relative',
        marginTop: VH(19),
        height: VH(24),
        display: 'flex',
        alignItems: 'center',
        '& > div:nth-child(1)': {
            fontSize: VW(14),
        },
        '& > div:nth-child(2)': {
            fontSize: VW(24),
            fontWeight: 'bold',
            marginLeft: VW(16),
            width: VW(730),
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button:nth-child(2)': {
            color: '#E60024',
        },
        '& > Button:nth-child(3)': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main
        }
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_claim_detail(props) {
    const {
        classes,
        setSelectFlag,
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.Property)
        log(state)
        getDetailList()
    }, [setSelectFlag])

    const authority_id = localStorage.getItem("authority_id")


    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate()


    const [updateFlag, setUpdateFlag] = useState(false)


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // detailed list data
    const [detailedData, setDetailedData] = useState({})
    //change titleData
    const changeDetailedData = (value, type) => {
        detailedData[type] = value
        setDetailedData({ ...detailedData })
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  優先度
    const [priorityValueOption, setPriorityValueOption] = useState([
        { value: '-1', label: 'すべて', color: "" },
        { value: '0', label: '低', color: "#91BDFF" },
        { value: '1', label: '中', color: "#FFAB00" },
        { value: '2', label: '高', color: "#CC0000" }
    ])
    const getPriorityColor = (item) => {
        const findPriority = priorityValueOption.find((priority) => priority.label == item)
        return findPriority == undefined ? '' : findPriority.color
    }


    // get detail list api
    const getDetailList = () => {
        document.getElementById('father_Block').scrollTop = 0;
        let param = {
            buken_id: state.buken_id,
            claim_record_id: state.claim_record_id
        }
        ApiClaimrecordDetail(param).then(res => {
            let resData = res.data
            if (res.status == 200) {
                log(resData)
                resData.status = resData.status == -1 ? undefined : resData.status
                resData.customer_name = resData.customer_type == 1 ? resData.customer_name : ''
                resData.priority = resData.priority == -1 ? undefined : resData.priority
                setDetailedData({ ...resData })
                setRrrorOpen(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        })
    }



    // update api
    const toUpdate = () => {
        log(detailedData)
        document.getElementById('father_Block').scrollTop = 0;
        if (detailedData.subject === '') {
            setErrorContent('件名を記入してください')
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else {
            let params = detailedData
            params.status = params.status == undefined ? -1 : Number(params.status)
            params.accrual_date = params.accrual_date ? dayjs(params.accrual_date).format('YYYY/MM/DD') : ''
            params.completion_date = params.completion_date ? dayjs(params.completion_date).format('YYYY/MM/DD') : ''
            params.customer_name = params.customer_type == 1 ? params.customer_name : state.owner_name
            params.buken_id = state.buken_id
            params.priority = params.priority == undefined ? -1 : Number(params.priority)
            params.compatible_date = params.compatible_date ? dayjs(params.compatible_date).format('YYYY/MM/DD') : ''
            delete params.claim_record_no
            delete params.status_name
            delete params.priority_name
            log(params)
            ApiClaimrecordUpdate(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    setSuccessOpen(true)
                    setSuccessContent('更新が完了しました。')
                    setUpdateFlag(false)
                    getDetailList()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })

        }
    }


    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }
    // add repair dialog
    const [addRepairOpen, setAddOpen] = useState(false)
    const addRepairOnClose = (successFlag) => {
        if (successFlag === 'addRepair') {
            setRrrorOpen(false)
            setSuccessOpen(true)
            setSuccessContent('修繕記録の登録が完了しました。')
        }
        setAddOpen(false)
    }
    const openAddRepairDialog = () => {
        setAddOpen(true)
    }


    // 編集
    const changeUpdateFlag = () => {
        let param = {
            buken_id: state.buken_id,
            claim_record_id: state.claim_record_id
        }
        ApiClaimrecordDetailExit(param).then(res => {
            if (res.status === 200) {
                setUpdateFlag(true)
                setRrrorOpen(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    // キャンセル
    const backItem = () => {
        setUpdateFlag(false)
        getDetailList()
    }



    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}>
                <span style={{ cursor: 'pointer' }} onClick={() => { navigate(routerNames.Property) }}>物件一覧</span>
                <ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>
                <span style={{ cursor: 'pointer' }} onClick={() => { state.jumpElsewhere ? navigate(routerNames.PropertyDetail_claim, { state: state }) : window.history.back() }}>物件詳細</span>
                <ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>
                クレーム記録情報
            </div>
            <div className={classes.tableTitle}>
                <Property_detail_router_claim />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{updateFlag ? 'クレーム記録情報の編集' : 'クレーム記録情報'}</div>
            </div>
            <div className={classes.objectNameBlock}>
                <div>物件名</div>
                <div><p>{state.genba_name}</p></div>
                <div className={classes.updateDeleteBlock}>
                    {!updateFlag &&
                        <>
                            <Button style={{ padding: 0, marginRight: VW(24), display: (authority_id == 3 || authority_id == 4) ? '' : 'none' }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={changeUpdateFlag}>&nbsp;編集</Button>
                            <Button style={{ padding: 0, marginRight: VW(24), display: (authority_id == 3 || authority_id == 4) ? '' : 'none' }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openDeleteDialog}>&nbsp;削除</Button>
                            <Button style={{ padding: 12, width: VW(168), height: VH(40), display: (authority_id == 3 || authority_id == 4) ? '' : 'none' }} type="primary" icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openAddRepairDialog}>修繕記録の登録</Button>
                        </>
                    }
                </div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            {!updateFlag ?
                <PropertyDetail_claim_item
                    detailedData={detailedData}
                    state={state}
                    getPriorityColor={getPriorityColor}
                />
                :
                <PropertyDetail_claim_input
                    detailedData={detailedData}
                    changeDetailedData={changeDetailedData}
                    state={state}
                    priorityValueOption={priorityValueOption.filter(item => item.value !== '-1')}
                />
            }
            <div className={classes.bottomButton}>
                {!updateFlag ?
                    <Button onClick={() => { state.jumpElsewhere ? navigate(routerNames.PropertyDetail_claim, { state: state }) : window.history.back() }}>クレーム記録一覧へ戻る</Button>
                    :
                    <>
                        <Button onClick={backItem}>キャンセル</Button>
                        <Button onClick={toUpdate}>更新</Button>
                    </>
                }
            </div>
            <PropertyDetail_claim_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[detailedData]}
                historyFlag={true}
                getPriorityColor={getPriorityColor}
            />
            <PropertyDetail_repair_add
                open={addRepairOpen}
                onClose={addRepairOnClose}
                state={state}
                priorityValueOption={priorityValueOption.filter(item => item.value !== '-1')}
            />
        </div>
    )
}

PropertyDetail_claim_detail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_claim_detail);