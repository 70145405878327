import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_equipment_third() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width="24" height="24" rx="2" fill="#2F83FF" />
            <path d="M19.5002 9.5C18.5835 9.5 17.8335 10.25 17.8335 11.1667V14.5H6.16683V11.1667C6.16683 10.25 5.41683 9.5 4.50016 9.5C3.5835 9.5 2.8335 10.25 2.8335 11.1667V15.3333C2.8335 16.7083 3.9585 17.8333 5.3335 17.8333V18.6667C5.3335 19.125 5.7085 19.5 6.16683 19.5C6.62516 19.5 7.00016 19.125 7.00016 18.6667V17.8333H17.0002V18.6667C17.0002 19.125 17.3752 19.5 17.8335 19.5C18.2918 19.5 18.6668 19.125 18.6668 18.6667V17.8333C20.0418 17.8333 21.1668 16.7083 21.1668 15.3333V11.1667C21.1668 10.25 20.4168 9.5 19.5002 9.5Z" fill="white" />
            <path d="M7.8335 11.1667V12.8333H16.1668V11.1667C16.1668 9.61667 17.2335 8.325 18.6668 7.95V7C18.6668 5.625 17.5418 4.5 16.1668 4.5H7.8335C6.4585 4.5 5.3335 5.625 5.3335 7V7.95C6.76683 8.325 7.8335 9.61667 7.8335 11.1667Z" fill="white" />
        </svg>


    )
}
export default PropertyDetail_equipment_third;


