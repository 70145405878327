import { ArrowForwardIos } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropertyDetail_repair_input from "../../componets/HtmlBody/PropertyDetail_repair_input";
import PropertyDetail_repair_item from "../../componets/HtmlBody/PropertyDetail_repair_item";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import PropertyDetail_repair_delete from "../../componets/dialog/PropertyDetail_repair_delete";
import log from "../../componets/function/log";
import { VH, VW } from "../../componets/function/sizeAdapter";
import Property_detail_router_repair from "../../componets/icon/Property_detail_router_repair";
import { ApiMendingBusinessrecord, ApiMendingClaimrecord, ApiMendingDetail, ApiMendingDetailExit, ApiMendingSetsubi, ApiMendingUpdate } from '../../network/api';
import { routerNames } from '../../wordsFile/wordsFile';


dayjs.extend(utc)

const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingRight: VW(24),
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    objectNameBlock: {
        position: 'relative',
        marginTop: VH(19),
        height: VH(24),
        display: 'flex',
        alignItems: 'center',
        '& > div:nth-child(1)': {
            fontSize: VW(14),
        },
        '& > div:nth-child(2)': {
            fontSize: VW(24),
            fontWeight: 'bold',
            marginLeft: VW(16),
            width: VW(730),
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button:nth-child(2)': {
            color: '#E60024',
        }
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_repair_detail(props) {
    const {
        classes,
        setSelectFlag,
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.Property)
        log(state)
        getDetailList()
    }, [setSelectFlag])

    const authority_id = localStorage.getItem("authority_id")


    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate()


    const [updateFlag, setUpdateFlag] = useState(false)


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // detailed list data
    const [detailedData, setDetailedData] = useState({})
    //change titleData
    const changeDetailedData = (value, type) => {
        detailedData[type] = value
        setDetailedData({ ...detailedData })
    }


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 修繕スペース
    const [setsubi_idOption, setSetsubi_idOption] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 営業番号
    const [business_record_idOption, setBusiness_record_idOption] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> クレーム番号
    const [claim_record_idOption, setClaim_record_idOption] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  優先度
    const [priorityValueOption, setPriorityValueOption] = useState([
        { value: '-1', label: 'すべて', color: "" },
        { value: '0', label: '低', color: "#91BDFF" },
        { value: '1', label: '中', color: "#FFAB00" },
        { value: '2', label: '高', color: "#CC0000" }
    ])
    const getPriorityColor = (item) => {
        const findPriority = priorityValueOption.find((priority) => priority.label == item)
        return findPriority == undefined ? '' : findPriority.color
    }

    // get detail list api
    const getDetailList = (flag) => {
        document.getElementById('father_Block').scrollTop = 0;
        let param = {
            mending_id: state.mending_id
        }
        ApiMendingDetail(param).then(res => {
            let resData = res.data
            if (res.status == 200) {
                log(resData)
                resData.status = resData.status == null ? undefined : resData.status
                resData.setsubi_id = resData.setsubi_id == null ? undefined : resData.setsubi_id
                resData.business_record_id = resData.business_record_id == null ? undefined : resData.business_record_id
                resData.claim_record_id = resData.claim_record_id == null ? undefined : resData.claim_record_id
                resData.repair_spaceAndPlace = resData.room_num + '/' + resData.mending_location
                resData.priority = resData.priority == null ? undefined : resData.priority
                resData.subject = resData.subject == null ? '' : resData.subject
                setDetailedData({ ...resData })
                if (flag == 'update') {
                    setRrrorOpen(false)
                    setSuccessOpen(true)
                    setSuccessContent('更新が完了しました。')
                    setUpdateFlag(false)
                }
                let paramC = {
                    buken_id: state.buken_id
                }
                ApiMendingSetsubi(paramC).then(res => {
                    if (res.status === 200) {
                        setRrrorOpen(false)
                        log(res.data)
                        setSetsubi_idOption([...res.data])
                        ApiMendingBusinessrecord(paramC).then(resS => {
                            if (resS.status === 200) {
                                setRrrorOpen(false)
                                log(resS.data)
                                setBusiness_record_idOption([...resS.data])
                                ApiMendingClaimrecord(paramC).then(resT => {
                                    if (resT.status === 200) {
                                        setRrrorOpen(false)
                                        log(resT.data)
                                        setClaim_record_idOption([...resT.data])
                                    } else if (resT.status >= 300 && resT.status < 500 && resT.status != 401) {
                                        log(resT)
                                        setRrrorOpen(true)
                                        setErrorContent(resT.data.detail.toString())
                                    }
                                }).catch(err => {

                                })
                            } else if (resS.status >= 300 && resS.status < 500 && resS.status != 401) {
                                log(resS)
                                setRrrorOpen(true)
                                setErrorContent(resS.data.detail.toString())
                            }
                        }).catch(err => {

                        })
                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                        log(res)
                        setRrrorOpen(true)
                        setErrorContent(res.data.detail.toString())
                    }
                }).catch(err => {

                })
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        })
    }



    // update api
    const toUpdate = () => {
        log(detailedData)
        document.getElementById('father_Block').scrollTop = 0;
        if (detailedData.subject === '') {
            setErrorContent('件名を記入してください')
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else {
            let params = {}
            params.mending_id = detailedData.mending_id
            params.order_date = detailedData.order_date ? dayjs(detailedData.order_date).format('YYYY/MM/DD') : ''
            params.requested_date = detailedData.requested_date ? dayjs(detailedData.requested_date).format('YYYY/MM/DD') : ''
            params.scheduled_date = detailedData.scheduled_date ? dayjs(detailedData.scheduled_date).format('YYYY/MM/DD') : ''
            params.completion_date = detailedData.completion_date ? dayjs(detailedData.completion_date).format('YYYY/MM/DD') : ''
            params.status = detailedData.status == undefined ? -1 : detailedData.status
            params.setsubi_id = detailedData.setsubi_id == undefined ? -1 : detailedData.setsubi_id
            params.mending_location = detailedData.mending_location
            params.mending_content = detailedData.mending_content
            params.organizing_company = detailedData.organizing_company
            params.business_record_id = detailedData.business_record_id == undefined ? -1 : detailedData.business_record_id
            params.claim_record_id = detailedData.claim_record_id == undefined ? -1 : detailedData.claim_record_id
            params.priority = detailedData.priority == undefined ? -1 : detailedData.priority
            params.subject = detailedData.subject
            log(params)
            ApiMendingUpdate(params).then(res => {
                if (res.status === 200) {
                    getDetailList('update')
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })

        }

    }


    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }


    // 編集
    const changeUpdateFlag = () => {
        let param = {
            mending_id: state.mending_id
        }
        ApiMendingDetailExit(param).then(res => {
            if (res.status === 200) {
                setUpdateFlag(true)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    // キャンセル
    const backItem = () => {
        setUpdateFlag(false)
        getDetailList()
    }


    // to business detail
    const toBusiness = () => {
        state.claim_record_id = detailedData.claim_record_id
        state.business_record_id = detailedData.business_record_id
        state.jumpElsewhere = true
        navigate(routerNames.PropertyDetail_business_detail, { state: state })
    }

    // to claim detail
    const toClaim = () => {
        state.claim_record_id = detailedData.claim_record_id
        state.business_record_id = detailedData.business_record_id
        state.jumpElsewhere = true
        navigate(routerNames.PropertyDetail_claim_detail, { state: state })
    }




    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}>
                <span style={{ cursor: 'pointer' }} onClick={() => { navigate(routerNames.Property) }}>物件一覧</span>
                <ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>
                <span style={{ cursor: 'pointer' }} onClick={() => { window.history.back() }}>物件詳細</span>
                <ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>
                修繕記録
            </div>
            <div className={classes.tableTitle}>
                <Property_detail_router_repair />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{updateFlag ? '修繕記録情報の編集' : '修繕記録情報'}</div>
            </div>
            <div className={classes.objectNameBlock}>
                <div>物件名</div>
                <div><p>{state.genba_name}</p></div>
                <div className={classes.updateDeleteBlock}>
                    {!updateFlag &&
                        <>
                            <Button style={{ padding: 0, marginRight: VW(24), display: (authority_id == 3 || authority_id == 4) ? '' : 'none' }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={changeUpdateFlag}>&nbsp;編集</Button>
                            <Button style={{ padding: 0, display: (authority_id == 3 || authority_id == 4) ? '' : 'none' }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openDeleteDialog}>&nbsp;削除</Button>
                        </>
                    }
                </div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            {!updateFlag ?
                <PropertyDetail_repair_item
                    detailedData={detailedData}
                    toBusiness={toBusiness}
                    toClaim={toClaim}
                    getPriorityColor={getPriorityColor}
                />
                :
                <PropertyDetail_repair_input
                    detailedData={detailedData}
                    changeDetailedData={changeDetailedData}
                    setsubi_idOption={setsubi_idOption}
                    business_record_idOption={business_record_idOption}
                    claim_record_idOption={claim_record_idOption}
                    priorityValueOption={priorityValueOption.filter(item => item.value !== '-1')}
                />
            }
            <div className={classes.bottomButton}>
                {!updateFlag ?
                    <Button onClick={() => { window.history.back() }}>修繕記録一覧へ戻る</Button>
                    :
                    <>
                        <Button onClick={backItem}>キャンセル</Button>
                        <Button onClick={toUpdate}>更新</Button>
                    </>
                }
            </div>
            <PropertyDetail_repair_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[detailedData]}
                historyFlag={true}
                getPriorityColor={getPriorityColor}
            />
        </div>
    )
}

PropertyDetail_repair_detail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_repair_detail);