import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter";
import Property_detail_contractor from "../icon/Property_detail_contractor";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Property_detail_antiTheftLamp from "../icon/Property_detail_antiTheftLamp";
import Property_detail_camera from "../icon/Property_detail_camera";
import Property_detail_gangway from "../icon/Property_detail_gangway";
import Property_detail_install from "../icon/Property_detail_install";
import Property_detail_lampBulb from "../icon/Property_detail_lampBulb";
import Property_detail_parkingLot from "../icon/Property_detail_parkingLot";
import Property_detail_vending from "../icon/Property_detail_vending";


const styles = (theme) => ({
    ground: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& > div:nth-child(1)': {
                marginRight: VW(12)
            },
            '& > div:nth-child(2)': {
                fontSize: VW(16),
                fontWeight: 'bold',
                marginRight: VW(12)
            },
            '& > div:nth-child(3)': {
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }
        }
    }
});

function Item_icon_title(props) {
    const {
        classes,
        titleId,
        titleName,
        height,
        borderBottom,
        flag,
        changeFlag
    } = props





    return (
        <div className={classes.ground} style={{ minHeight: VH(height), borderBottom: borderBottom ? `1px solid #DDD` : '' }}>
            <div onClick={changeFlag}>
                {titleId === 1 && <div><Property_detail_contractor /></div>}
                {titleId === 2 && <div><Property_detail_lampBulb /></div>}
                {titleId === 3 && <div><Property_detail_camera /></div>}
                {titleId === 4 && <div><Property_detail_antiTheftLamp /></div>}
                {titleId === 5 && <div><Property_detail_vending /></div>}
                {titleId === 6 && <div><Property_detail_parkingLot /></div>}
                {titleId === 7 && <div><Property_detail_gangway /></div>}
                {titleId === 8 && <div><Property_detail_install /></div>}
                <div>{titleName}</div>
                <div>{flag ? <ArrowDropUpIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} /> : <ArrowDropDownIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} />}</div>
            </div>
        </div>
    )
}

Item_icon_title.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Item_icon_title);