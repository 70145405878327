import React, { useState, useEffect } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import SelectColor_Tag from "../icon/SelectColor_Tag";
import SearchIcon from '@mui/icons-material/Search';
import { ClearRounded } from "@mui/icons-material"
import { ApiTagListBuken, ApiBukenUpdateTag } from '../../network/api'
import Checkbox from '@mui/material/Checkbox';

dayjs.extend(utc)



const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    searchBlock: {
        width: '100%',
        marginTop: VH(24),
        display: 'flex'
    },
    colorBlock: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        marginTop: VH(24),
        '& > div': {
            width: '50%',
            height: VH(40),
            display: 'flex',
            alignItems: 'center'
        },
        '& > div > div': {
            width: VW(112),
            height: VH(30),
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#333',
            fontSize: VW(14),
            marginLeft: VW(6)
        }
    },
    okButton: {
        width: '100%',
        height: VH(40),
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        fontSize: VW(16),
        fontWeight: 'bold',
        marginTop: VH(40)
    }
});


function Property_select_color(props) {
    const {
        classes,
        open,
        onClose,
        tagList,
        getList
    } = props


    useEffect(() => {
        if (open) {
            log(tagList)
            getTagList()
        } else {
            setKeywordSearch('')
        }
    }, [open])

    const [keywordSearch, setKeywordSearch] = useState('')

    const [colorList, setColorList] = useState([])

    const getTagList = () => {
        let param = {
            // tenant_id: localStorage.getItem('tenant_id'),
            query_content: encodeURIComponent(keywordSearch)
        }
        ApiTagListBuken(param).then(res => {
            let resData = res.data
            if (res.status == 200) {
                log(resData)
                resData.data.map(item => {
                    if (item.color_type === 1) {
                        item.color = '#FFB872'
                    } else if (item.color_type === 2) {
                        item.color = '#BB99FE'
                    } else if (item.color_type === 3) {
                        item.color = '#80BFFF'
                    } else if (item.color_type === 4) {
                        item.color = '#BCFA38'
                    } else if (item.color_type === 5) {
                        item.color = '#F9CFF9'
                    } else if (item.color_type === 6) {
                        item.color = '#ADEAFF'
                    } else if (item.color_type === 7) {
                        item.color = '#85F8B3'
                    } else if (item.color_type === 8) {
                        item.color = '#FF8CCF'
                    }
                    if (tagList.tag.length > 0) {
                        tagList.tag.map(tagItem => {
                            if (item.tag_id == Number(tagItem)) {
                                item.selected = true
                            }
                        })
                    } else {
                        item.selected = false
                    }

                })
                log(resData.data)
                setColorList(resData.data)
            }
        })
    }


    const handleChange = (item) => {
        log(item)
        colorList.map(colorItem => {
            if (item.tag_id === colorItem.tag_id) {
                colorItem.selected = !item.selected
            }
        })
        log(colorList)
        setColorList([...colorList])
    }


    const changeTag = () => {
        let newTagList = []
        colorList.map(item => {
            if (item.selected) {
                newTagList.push(item.tag_id)
            }
        })

        let params = {
            buken_id: tagList.buken_id,
            tag: newTagList.join(",")
        }
        log(params)
        ApiBukenUpdateTag(params).then(res => {
            if (res.status == 200) {
                onClose()
                getList()
            }
        })
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(400)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <SelectColor_Tag />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>タグ</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(10),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(40), height: VW(40) }} />
                </IconButton>
            </div>
            <div className={classes.searchBlock}>
                <Input
                    style={{
                        width: VW(288),
                        height: VH(40),
                        fontSize: VW(16),
                        borderRadius: 0
                    }}
                    placeholder='キーワード'
                    value={keywordSearch}
                    onChange={(event) => { setKeywordSearch(event.target.value) }}
                    suffix={
                        (
                            <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                setKeywordSearch('')
                            }}></ClearRounded>
                        )
                    }
                />
                <Button onClick={getTagList} style={{ width: VW(56), height: VH(40), marginLeft: VW(8), backgroundColor: '#0052CC' }} type="primary" icon={<SearchIcon style={{ width: VW(24), height: VH(24) }} />}></Button>
            </div>
            <div className={classes.colorBlock}>
                {colorList.length > 0 && colorList.map((item, index) => {
                    return (
                        <div key={index}>
                            <Checkbox color="success" key={item.selected} checked={item.selected} onChange={() => { handleChange(item) }} />
                            <div style={{ backgroundColor: item.color }}>{item.tag_name}</div>
                        </div>
                    )
                })}
            </div>
            <Button className={classes.okButton} onClick={changeTag}>OK</Button>
        </Modal >
    )
}

Property_select_color.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Property_select_color);