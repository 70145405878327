import React, { useEffect, useState, useRef } from "react";
import {
    Alert,
    Collapse
} from "@mui/material";
import { withStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { VW, VH } from "../../componets/function/sizeAdapter"

const styles = (theme) => ({

});

function Common_successAlert(props) {
    const {
        classes,
        open,
        setSuccessOpen,
        successContent,
        marginTop,
        marginBottom,
    } = props



    useEffect(() => {
        if (open) {
            setTimeout(function () { setSuccessOpen(false) }, 2000)
        }
    }, [open])


    return (
        <div>
            <Collapse in={open}>
                <Alert
                    severity="success"
                    icon={<CheckIcon fontSize="inherit" />}
                    // onClose={() => { setSuccessOpen(false); }}
                    action={
                        //     <IconButton
                        //         style={{ padding: 0, marginRight: VW(10) }}
                        //         aria-label="close"
                        //         disableRipple={true}
                        //         color="inherit"
                        //         size="small"
                        //         onClick={() => {
                        //             setSuccessOpen(false);
                        //         }}
                        //     >
                        //         <CloseIcon style={{ color: "#323232", width: VW(20), height: VH(20) }} />
                        //     </IconButton>
                        // }
                        <div
                            style={{ display: 'flex', alignItems: "center" }}
                        >
                            <CloseIcon
                                onClick={() => {
                                    setSuccessOpen(false);
                                }}
                                style={{ color: "#323232", width: VW(20), height: VH(20), marginRight: VW(10) }} />
                        </div>
                    }
                    style={{
                        fontSize: VW(16),
                        marginTop: marginTop ? marginTop : 0,
                        marginBottom: marginBottom ? marginBottom : 0,
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                        backgroundColor: '#EAFFEC',
                        border: '1px solid #169873',
                        color: '#323232',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >{successContent}
                </Alert>
            </Collapse>
        </div >
    )
}

Common_successAlert.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Common_successAlert);