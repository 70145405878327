import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import log from "../../componets/function/log";
import MenuHeader_notification from "../../componets/icon/MenuHeader_notification";
import MenuHeader_reminder from "../../componets/icon/MenuHeader_reminder";
import Common_listItem from "../../componets/common/Common_listItem";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ApiNoticeList, ApiReminderList } from "../../network/api";

const styles = (theme) => ({
    ground: {
        width: '100%',
        height: '100%',
        wordBreak: 'break-all',
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8)
    },
    contentBlock: {
        display: 'flex',
        '& > div': {
            width: '48.5%'
        },
        '& > div:nth-child(1)': {
            marginRight: VW(24)
        }
    },
    tableTitle: {
        marginTop: VH(16),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    }
});

function Notification(props) {
    const {
        classes,
        setSelectFlag
    } = props


    const [data, setData] = useState([])
    const [reminderData, setReminderData] = useState([])
    useEffect(() => {
        setSelectFlag(routerNames.Notification)
        getList()
        getReminderList()
    }, [setSelectFlag])

    // list api
    const getList = () => {
        // var data = []
        // for (var i = 0; i < 5; i++) {
        //     data.push({
        //         id: i,
        //         usage_start_date: "2023.05.13",
        //         title: "お知らせのタイトル。お知らせのタイトル。お知らせのタイトル。お知らせのタイトル。お知らせのタイトル。お知らせのタイトル。お知らせのタイトル。お知らせのタイトル。お知らせのタイトル。",
        //         text: "ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。ここにお知らせの内容を記載します。"
        //     })
        // }
        // setData(data)
        ApiNoticeList().then(res => {
            let resData = res.data
            if (res.status === 200) {
                setData(resData.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)

            }
        }).catch(err => {

        })
    }

    const getReminderList = () => {
        ApiReminderList().then(res => {
            let resData = res.data
            if (res.status === 200) {
                resData.data.map(item => {
                    item.all_name = item.create_surname + item.create_name
                })
                setReminderData(resData.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(resData)
            }
        }).catch(err => {

        })
    }


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}>通知</div>
            <div className={classes.contentBlock}>
                <div>
                    <div className={classes.tableTitle}>
                        <MenuHeader_notification />
                        <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>お知らせ</div>
                    </div>
                    <div style={{
                        marginTop: VH(24),
                        height: '77vh',
                        overflowY: 'scroll',
                        overflowY: 'overlay',
                    }}>
                        {data &&
                            data.map((item, index) => (
                                <Common_listItem
                                    key={item.notice_id}
                                    usageStartDate={item.usage_start_date}
                                    title={item.title}
                                    text={item.text}
                                    type="line"
                                />
                            ))}
                    </div>
                </div>
                <div>
                    <div className={classes.tableTitle}>
                        <MenuHeader_reminder />
                        <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>リマインダー</div>
                    </div>
                    <div style={{
                        marginTop: VH(24),
                        height: '77vh',
                        overflowY: 'scroll',
                        overflowY: 'overlay',
                    }}>
                        {reminderData &&
                            reminderData.map((item, index) => (
                                <Common_listItem
                                    key={item.notice_id}
                                    usageStartDate={item.usage_start_date}
                                    title={item.title}
                                    text={item.text + '\n' + '（' + item.all_name + '）'}
                                    type="block"
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

Notification.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Notification);