import { VW, VH } from "../../componets/function/sizeAdapter"
function Backward(props) {
    const {
        flag
    } = props
    if (flag) {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" style={{ display: 'block' }}>
                <circle cx="15.5" cy="15.5" r="12.5" fill="#0052CC" />
                <mask id="mask0_11996_8559" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                    <rect width="32" height="32" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_11996_8559)">
                    <path d="M16.9013 16.0003L12.8013 20.1003L14.668 22.0003L20.668 16.0003L14.668 10.0003L12.8013 11.9003L16.9013 16.0003ZM16.0013 29.3337C14.1569 29.3337 12.4235 28.9837 10.8013 28.2837C9.17908 27.5837 7.76797 26.6337 6.56797 25.4337C5.36797 24.2337 4.41797 22.8225 3.71797 21.2003C3.01797 19.5781 2.66797 17.8448 2.66797 16.0003C2.66797 14.1559 3.01797 12.4225 3.71797 10.8003C4.41797 9.1781 5.36797 7.76699 6.56797 6.56699C7.76797 5.36699 9.17908 4.41699 10.8013 3.71699C12.4235 3.01699 14.1569 2.66699 16.0013 2.66699C17.8457 2.66699 19.5791 3.01699 21.2013 3.71699C22.8235 4.41699 24.2346 5.36699 25.4346 6.56699C26.6346 7.76699 27.5846 9.1781 28.2846 10.8003C28.9846 12.4225 29.3346 14.1559 29.3346 16.0003C29.3346 17.8448 28.9846 19.5781 28.2846 21.2003C27.5846 22.8225 26.6346 24.2337 25.4346 25.4337C24.2346 26.6337 22.8235 27.5837 21.2013 28.2837C19.5791 28.9837 17.8457 29.3337 16.0013 29.3337Z" fill="white" />
                </g>
            </svg>
        )
    } else {
        return (
            <svg width={VW(28)} height={VH(28)} viewBox="0 0 28 28" style={{ display: 'block' }}>
                <path
                    d="M14.9003 14.0003L10.8003 18.1003L12.667 20.0003L18.667 14.0003L12.667 8.00033L10.8003 9.90033L14.9003 14.0003ZM14.0003 27.3337C12.1559 27.3337 10.4225 26.9837 8.80032 26.2837C7.1781 25.5837 5.76699 24.6337 4.56699 23.4337C3.36699 22.2337 2.41699 20.8225 1.71699 19.2003C1.01699 17.5781 0.666991 15.8448 0.666991 14.0003C0.666991 12.1559 1.01699 10.4225 1.71699 8.80032C2.41699 7.1781 3.36699 5.76699 4.56699 4.56699C5.76699 3.36699 7.1781 2.41699 8.80032 1.71699C10.4225 1.01699 12.1559 0.666993 14.0003 0.666993C15.8448 0.666994 17.5781 1.01699 19.2003 1.71699C20.8225 2.41699 22.2337 3.36699 23.4337 4.56699C24.6337 5.76699 25.5837 7.17811 26.2837 8.80033C26.9837 10.4225 27.3337 12.1559 27.3337 14.0003C27.3337 15.8448 26.9837 17.5781 26.2837 19.2003C25.5837 20.8226 24.6337 22.2337 23.4337 23.4337C22.2337 24.6337 20.8225 25.5837 19.2003 26.2837C17.5781 26.9837 15.8448 27.3337 14.0003 27.3337Z"
                    fill='#D9D9D9'
                />
            </svg>
        )
    }
}
export default Backward;