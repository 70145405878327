import { CaretDownOutlined } from '@ant-design/icons';
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { DatePicker, Input, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import React from "react";
import { VH, VW } from "../../componets/function/sizeAdapter";
import AddIcon from "../icon/AddIcon";

const { Option } = Select;
const styles = (theme) => ({
    updateInput: {
        width: '100%',
        '& > div:not(:last-child)': {
            width: '100%',
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div:not(:last-child) > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div > Input': {
            width: '100%',
            height: '80%',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2) > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
        '& > div:last-child': {
            width: '100%',
            height: VH(50),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: VW(16),
            Left: VW(19),
            '& > div': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
            }
        }
    },
    InputCustomer: {
        '& > div': {
            width: '82%',
            height: '100%',
            alignItems: 'center',
            height: VH(74)
        },
        '& > div > div > div': {
            height: '100% !important',
            padding: '10px 11px !important'
        },
        '& > div > div > div > span > input': {
            height: '100% !important'
        }
    },
    itemAdministrator: {
        '& > div:nth-child(1)': {
            width: VW(160)
        },
        '& > div:nth-child(2)': {
            width: VW(256)
        },
        '& > div:nth-child(3)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(4)': {
            width: VW(416),
        },
        '& > div > Input': {
            width: '100%'
        }
    },
    inputAdministrator: {
        width: '100%',
        height: VH(74),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div:nth-child(1)': {
            width: VW(160)
        },
        '& > div:nth-child(2)': {
            width: VW(256),
            paddingRight: '1%'
        },
        '& > div:nth-child(3)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(4)': {
            width: VW(416),
        },
        '& > div > Input': {
            width: '100%'
        }
    },
    itemCreateData: {
        width: '100%',
        height: VH(74),
        borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
    }
});

function RealEstateDetail_input(props) {
    const {
        classes,
        falseData,
        tenantName,
        setTenantName,
        endDay,
        changeEndDay,
        administratorList,
        updateAdministratorList,
        addAdministratorListItem,
        customerOption,
        customerList,
        addCustomerListItem,
        updateCustomerList,
        deleteCustomer,
        isDisabledOption
    } = props

    return (
        <div>
            <div className={classes.updateInput} style={{ marginTop: VH(20) }}>
                <div>
                    <div>管理会社CD</div>
                    <div>{falseData.tenant_id}</div>
                </div>
                <div>
                    <div>会社名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                    <div>
                        <Input
                            value={tenantName}
                            onChange={(event) => { setTenantName(event.target.value) }}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div style={{ height: 0, borderBottom: 0, borderLeft: 0, borderRight: 0 }}></div>
                {/* {customerList.map((item, index) => {
                    return (
                        <div className={classes.InputCustomer} key={index}>
                            <div>得意先CD{index + 1}</div>
                            <div>
                                <Select
                                    style={{ width: VW(820), height: VH(50) }}
                                    placeholder='得意先CDを選択してください'
                                    suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                                    value={customerList[index]}
                                    onChange={(event) => updateCustomerList(event, index)}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.join(',').includes(input)
                                    }
                                >
                                    {customerOption.map((optionItem, optionIndex) => {
                                        return (
                                            <Option
                                                tyle={{ fontSize: VW(16) }}
                                                key={optionIndex} value={optionItem.code}
                                                disabled={isDisabledOption(optionItem)}
                                            >
                                                {optionItem.code}({optionItem.name})
                                            </Option>
                                        )
                                    })}
                                </Select>
                                <IconButton
                                    onClick={() => deleteCustomer(index)}
                                    sx={{
                                        marginLeft: '16px',
                                        padding: 0
                                    }}
                                >
                                    <Close style={{ color: '#333333', width: VW(23.33), height: VW(23.33) }} />
                                </IconButton>
                            </div>
                        </div>
                    )
                })}
                <div>
                    <div onClick={addCustomerListItem} style={{ fontSize: VW(16) }}>
                        <AddIcon />&ensp;得意先CDを追加する
                    </div>
                </div> */}
            </div>
            <div className={classes.updateInput}>
                <div style={{ display: 'none' }}>
                    {/* <div>カルテ契約開始日</div>
                    <div>
                        <DatePicker
                            style={{width:'27%', height:'80%'}}
                            placeholder=""
                            value = { startDay }
                            format="YYYY/MM/DD"
                            locale = { locale }
                            onChange={ changeStartDay }
                        />
                    </div> */}
                </div>
                <div style={{ borderTop: 'none' }}>
                    <div>カルテ契約終了日</div>
                    <div>
                        <DatePicker
                            style={{ width: '27%', height: '80%', fontSize: VW(16) }}
                            placeholder=""
                            value={endDay}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeEndDay}
                        />
                    </div>
                </div>
                {falseData.user_master.map((item, index) => {
                    return (
                        <div key={index} className={classes.itemAdministrator}>
                            <div>管理者</div>
                            <div>{item.surname + item.name}</div>
                            <div>メールアドレス</div>
                            <div>{item.email}</div>
                        </div>
                    )
                })}
                {administratorList.length > 0 && administratorList.map((item, index) => {
                    return (
                        <div key={index} className={classes.inputAdministrator}>
                            <div>管理者</div>
                            <div>
                                <Input
                                    placeholder="姓"
                                    value={item.surname}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'surname')}
                                    maxLength={30}
                                    style={{ marginRight: VW(8) }}
                                />
                                <Input
                                    placeholder="名"
                                    value={item.name}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'name')}
                                    maxLength={30}
                                />
                            </div>
                            <div>メールアドレス</div>
                            <div>
                                <Input
                                    value={item.email}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'email')}
                                    maxLength={200}
                                />
                            </div>
                        </div>
                    )
                })}
                <div>
                    <div onClick={addAdministratorListItem} style={{ fontSize: VW(16) }}>
                        <AddIcon />&ensp;管理者を追加する
                    </div>
                </div>
            </div>
            <div className={classes.itemCreateData}>
                <div>登録日</div>
                <div>{falseData.create_date}</div>
            </div>
        </div>
    )
}

RealEstateDetail_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(RealEstateDetail_input);