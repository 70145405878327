import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_member(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" fill="none" >
            <path d="M9.99154 1.66675C5.39154 1.66675 1.6582 5.40008 1.6582 10.0001C1.6582 14.6001 5.39154 18.3334 9.99154 18.3334C14.5915 18.3334 18.3249 14.6001 18.3249 10.0001C18.3249 5.40008 14.5915 1.66675 9.99154 1.66675ZM12.9999 6.95008C13.8915 6.95008 14.6082 7.66675 14.6082 8.55842C14.6082 9.45008 13.8915 10.1667 12.9999 10.1667C12.1082 10.1667 11.3915 9.45008 11.3915 8.55842C11.3832 7.66675 12.1082 6.95008 12.9999 6.95008ZM7.99987 5.63341C9.0832 5.63341 9.96654 6.51675 9.96654 7.60008C9.96654 8.68341 9.0832 9.56675 7.99987 9.56675C6.91654 9.56675 6.0332 8.68341 6.0332 7.60008C6.0332 6.50841 6.9082 5.63341 7.99987 5.63341ZM7.99987 13.2417V16.3667C5.99987 15.7417 4.41654 14.2001 3.71654 12.2334C4.59154 11.3001 6.77487 10.8251 7.99987 10.8251C8.44154 10.8251 8.99987 10.8917 9.5832 11.0084C8.21654 11.7334 7.99987 12.6917 7.99987 13.2417ZM9.99154 16.6667C9.76654 16.6667 9.54987 16.6584 9.3332 16.6334V13.2417C9.3332 12.0584 11.7832 11.4667 12.9999 11.4667C13.8915 11.4667 15.4332 11.7917 16.1999 12.4251C15.2249 14.9001 12.8165 16.6667 9.99154 16.6667Z" fill={color ? color : '#2F83FF'} />
        </svg>

    )
}

export default MenuHeader_member;