import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { Select, Input, DatePicker } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { VW, VH } from "../../componets/function/sizeAdapter"
const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    updateInput: {
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
        width: '100%',
        marginTop: VH(20),
        '& > div': {
            width: '100%',
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
        },
        '& > div > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16)
        },
        '& > div > div:nth-child(1)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div > div:nth-child(2)': {
            width: '82%',
            height: '100%',
        },
        '& > div:nth-last-child(2) > div > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        '& > div:nth-last-child(3) > div > div:nth-child(1)': {
            fontSize: VW(16),
            width: '3%',
        },
        '& > div:nth-last-child(3) > div > div:nth-child(2)': {
            width: "22%",
            minHeight: VH(50)
        },
        '& > div:nth-last-child(3) > div > div:nth-child(3)': {
            fontSize: VW(16),
            color: '#AAA',
            marginLeft: VW(16)
        },
        '& > div:nth-last-child(3) > div > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
            height: VH(120)

        },
        '& > div:last-child > div:nth-child(2)': {
            minHeight: VH(96),

        },
    },
});

function OwnersListDetail_input(props) {
    const {
        classes,
        number,
        setNumber,
        characterFlag,
        setCharacterFlag,
        ownerName,
        setOwnerName,
        phone,
        setPhone,
        email,
        setEmail,
        zipCode,
        setZipCode,
        address,
        setAddress,
        remarks,
        setRemarks
    } = props





    return (
        <div className={classes.updateInput}>
            <div>
                <div>管理番号など</div>
                <div>
                    <Input
                        placeholder="管理番号などを入力してください"
                        value={number}
                        onChange={(event) => setNumber(event.target.value)}
                        maxLength={50}
                    />
                </div>
            </div>
            <div>
                <div>個人 / 法人</div>
                <div>
                    <Select
                        style={{ width: '100%', fontSize: VW(16) }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={characterFlag}
                        onChange={(event) => setCharacterFlag(event)}
                        allowClear
                        onClear={() => { setCharacterFlag(undefined) }}
                    >
                        <Option style={{ fontSize: VW(16) }} value='1'>個人</Option>
                        <Option style={{ fontSize: VW(16) }} value='2'>法人</Option>
                    </Select>
                </div>
            </div>
            <div>
                <div>オーナー名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                <div>
                    <Input
                        placeholder="オーナー名を入力してください"
                        value={ownerName}
                        onChange={(event) => setOwnerName(event.target.value)}
                        maxLength={50}
                    />
                </div>
            </div>
            <div>
                <div>電話番号</div>
                <div>
                    <Input
                        placeholder="電話番号を入力してください"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        maxLength={15}
                    />
                </div>
            </div>
            <div>
                <div>メールアドレス</div>
                <div>
                    <Input
                        placeholder="メールアドレスを入力してください"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        maxLength={200}
                    />
                </div>
            </div>
            <div>
                <div>郵便番号</div>
                <div>
                    <div>〒</div>
                    <div>
                        <Input
                            placeholder="郵便番号"
                            value={zipCode}
                            onChange={(event) => setZipCode(event.target.value)}
                            maxLength={8}
                        />
                    </div>
                    <div>※半角７桁ハイフンあり</div>
                </div>
            </div>
            <div>
                <div>所在地</div>
                <div>
                    <Input
                        placeholder="所在地を入力してください"
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        maxLength={50}
                    />
                </div>
            </div>
            <div>
                <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（200文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(96), width: "100%" }}
                        placeholder="備考を記入してください。"
                        maxLength={200}
                        value={remarks}
                        onChange={(event) => setRemarks(event.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}

OwnersListDetail_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(OwnersListDetail_input);