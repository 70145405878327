import { VW, VH } from "../../componets/function/sizeAdapter"
function ReduceIcon_small() {
    return (
        <svg width={VW(16)} height={VH(16)} viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8" fill="#2F83FF" />
            <path d="M5.21185 8.69812C4.82626 8.69812 4.51367 8.38553 4.51367 7.99994C4.51367 7.61434 4.82626 7.30176 5.21185 7.30176H10.7973C11.1829 7.30176 11.4955 7.61434 11.4955 7.99994C11.4955 8.38553 11.1829 8.69812 10.7973 8.69812H5.21185Z" fill="white" />
        </svg>
    )
}

export default ReduceIcon_small;