import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_router_business() {

    return (
        <svg width={VW(18)} height={VH(14)} viewBox="0 0 18 14" fill="none">
            <path d="M15.6667 3.66665H13.1667V1.99998C13.1667 1.08331 12.4167 0.333313 11.5 0.333313H6.50002C5.58335 0.333313 4.83335 1.08331 4.83335 1.99998V3.66665H2.33335C1.41669 3.66665 0.666687 4.41665 0.666687 5.33331V8.66665H4.00002V6.99998H5.66669V8.66665H12.3334V6.99998H14V8.66665H17.3334V5.33331C17.3334 4.41665 16.5834 3.66665 15.6667 3.66665ZM11.5 3.66665H6.50002V1.99998H11.5V3.66665Z" fill="#2F83FF" />
            <path d="M14 10.3333H12.3334V9.49998H5.66669V10.3333H4.00002V9.49998H0.666687V13.6666H17.3334V9.49998H14V10.3333Z" fill="#2F83FF" />
        </svg>
    )
}

export default Property_detail_router_business;