
import React, { Suspense, Fragment } from 'react';
import Routes from './routes/index.js';
import { CssBaseline } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from "./GlobalStyles"
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <Suspense fallback={<Fragment />}>
        <StyledEngineProvider injectFirst>
          <Routes />
        </StyledEngineProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;