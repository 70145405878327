import React, { useEffect, useState, useRef } from "react";
import {
    Select,
    MenuItem,
    TablePagination
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import MenuHeader_property from "../../componets/icon/MenuHeader_property";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos } from "@mui/icons-material";
import { Button, Input, DatePicker } from 'antd'
import locale from "antd/es/date-picker/locale/ja_JP";
import { useLocation } from "react-router-dom";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import Common_pagenation from '../../componets/common/Common_pagination';
import { useNavigate } from 'react-router-dom'
import { ClearRounded } from "@mui/icons-material"
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import Common_checkBoxTable from "../../componets/common/Common_checkBoxTable";
import { ApiSetubirecordList } from '../../network/api'
import { errorMessage } from "../../wordsFile/wordsFile";
import PropertyDetail_equipment_records_add from "../../componets/dialog/PropertyDetail_equipment_records_add";
import PropertyDetail_equipment_records_delete from "../../componets/dialog/PropertyDetail_equipment_records_delete";


dayjs.extend(utc)

const styles = (theme) => ({
    ground: {
        width: '100%',
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    objectNameBlock: {
        marginLeft: VW(24),
        marginTop: VH(12),
        minHeight: VH(24),
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
        wordBreak: 'break-all',
        '& > div:nth-child(1)': {
            fontSize: VW(14),
        },
        '& > div:nth-child(2)': {
            fontSize: VW(24),
            fontWeight: 'bold',
            marginLeft: VW(16)
        }
    },
    inputTitle: {
        fontSize: VW(14),
        width: VW(100),
        maxWidth: VW(85),
        textAlign: 'right',
        marginRight: VW(16)
    },
    iconContainer: {
        color: '#999',
    },
    querySelect: {
        width: VW(160),
        height: VH(40),
        fontSize: VW(16),
        backgroundColor: '#FFF'
    },
    retrievalBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        // paddingLeft: VW(16),
        paddingRight: VW(16),
        paddingTop: VH(16),
        '& > Input': {
            width: VW(874),
            height: VH(40),
            fontSize: VW(16)
        },
        '& > Button': {
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
            fontSize: VW(16),
            backgroundColor: theme.palette.primary.main,
        }
    },
    titleMenu: {
        width: 'auto',
        display: 'flex',
        marginTop: VH(16),
        height: VH(40),
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    menuContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: VW(16)
    },
    AddDeleteBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: VH(24),
        '& > Button': {
            marginLeft: VW(8),
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
        },
        '& > Button:nth-of-type(1)': {
            color: '#E60024',
        },
        '& > Button:nth-of-type(2)': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
        }
    },
    tableBlock: {
        marginRight: VW(24),
        paddingTop: '1vh'
    }
});

function PropertyDetail_equipment_records(props) {
    const {
        classes,
        setSelectFlag
    } = props

    const location = useLocation();
    const { state } = location;


    useEffect(() => {
        setSelectFlag(routerNames.Property)
    }, [setSelectFlag])


    useEffect(() => {
        log(state)
    }, [])


    const navigate = useNavigate()


    const authority_id = localStorage.getItem("authority_id")


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // list api
    const getList = (successFlag) => {
        if (localStorage.getItem('equipment_records_deleteFlag')) {
            setSuccessContent('削除が完了しました。')
            setSuccessOpen(true)
            localStorage.removeItem('equipment_records_deleteFlag')
        }
        if (startDate > endDate) {
            setErrorContent(errorMessage.startEndDayNull)
            setRrrorOpen(true)
        } else {
            let params = {
                query_content: encodeURIComponent(searchParam),
                pageNum: page + 1,
                pageLimit: rowsPerPage,
                sortKey: pagingFlag.current,
                sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc',
                period_start_date: searchStartValue,
                period_end_date: searchEndValue,
                buken_id: state.buken_id
            }
            log(params)
            ApiSetubirecordList(params).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    document.getElementById('father_Block').scrollTop = 0;
                    localStorage.setItem('equipment_records_keywordSearch', keywordSearch)
                    localStorage.setItem('equipment_searchParam', searchParam)
                    localStorage.setItem('equipment_records_startData', startValue)
                    localStorage.setItem('equipment_records_endData', endValue)
                    log(resData)
                    setRrrorOpen(false)
                    setCount(resData.count)
                    resData.data.map(item => {
                        if (!item.room_num) {
                            item.room_num = '未入力'
                        }
                        item.room_num_sort = item.room_num + '/' + item.setsubi
                    })
                    setBodyList([...resData.data])
                    if (successFlag === 'add') {
                        setSuccessContent('登録が完了しました。')
                        setSuccessOpen(true)
                    }
                    if (successFlag === 'delete') {
                        setSuccessContent('削除が完了しました。')
                        setSuccessOpen(true)
                    }
                    setDeleteData([])
                    setIndeterminate(false)
                    setCheckAll(false)
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }



    // title menu content
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list
    const [titleMenuList, setTitleMenuList] = useState(routerTitleList)
    function routerTitleList() {
        var items = []
        if (state.buken_shubetsu === 0) {
            items = [
                { name: '物件情報', width: 130, checked: false },
                { name: '部屋情報', width: 120, checked: false },
                { name: 'メンテナンス', width: 130, checked: false },
                { name: '設備記録', width: 120, checked: true },
                { name: '営業記録', width: 120, checked: false },
                { name: 'クレーム記録', width: 130, checked: false },
                { name: '修繕記録', width: 120, checked: false },
                { name: 'ファイル', width: 122, checked: false }
            ]
        } else {
            items = [
                { name: 'コンテナマスタ', width: 142, checked: false },
                { name: 'メンテナンス', width: 142, checked: false },
                { name: '設備記録', width: 142, checked: true },
                { name: '営業記録', width: 142, checked: false },
                { name: 'クレーム記録', width: 142, checked: false },
                { name: '修繕記録', width: 142, checked: false },
                { name: 'ファイル', width: 142, checked: false }
            ]
        }

        return items
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list click
    const selectTitleItem = (content) => {
        log(content)
        if (content.name == "物件情報" || content.name == "コンテナマスタ") {
            navigate(routerNames.PropertyDetail, { state: state })
        } else if (content.name == "部屋情報") {
            navigate(routerNames.PropertyDetail_equipment_list, { state: state })
        } else if (content.name == "営業記録") {
            navigate(routerNames.PropertyDetail_business, { state: state })
        } else if (content.name == "修繕記録") {
            navigate(routerNames.PropertyDetail_repair, { state: state })
        } else if (content.name == "クレーム記録") {
            navigate(routerNames.PropertyDetail_claim, { state: state })
        } else if (content.name == "メンテナンス") {
            navigate(routerNames.PropertyDetail_calendar, { state: state })
        } else if (content.name == "ファイル") {
            navigate(routerNames.PropertyDetail_file, { state: state })
        }
    }


    // 検索
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  キーワード input
    const [keywordSearch, setKeywordSearch] = useState(localStorage.getItem('equipment_records_keywordSearch') == null || localStorage.getItem('equipment_records_keywordSearch') == undefined ? '' : localStorage.getItem('equipment_records_keywordSearch'))
    const [searchParam, setSearchParam] = useState(localStorage.getItem('equipment_searchParam') == null || localStorage.getItem('equipment_searchParam') == undefined ? '' : localStorage.getItem('equipment_searchParam'))
    const [searchStartValue, setSearchStartValue] = useState(localStorage.getItem('equipment_searchStartValue') == null || localStorage.getItem('equipment_searchStartValue') == undefined ? '' : localStorage.getItem('equipment_searchStartValue'))
    const [searchEndValue, setSearchEndValue] = useState(localStorage.getItem('equipment_searchEndValue') ? localStorage.getItem('equipment_searchEndValue') : '');
    const search = () => {
        setSearchParam(keywordSearch)
        setSearchStartValue(startValue)
        setSearchEndValue(endValue)
        setPage(0)
        setCurrentPage(0)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  開始日
    const [startDate, setStartDate] = useState(localStorage.getItem('equipment_records_startData') ? dayjs(localStorage.getItem('equipment_records_startData')) : '')
    const [startValue, setStartValue] = useState(localStorage.getItem('equipment_records_startData') ? localStorage.getItem('equipment_records_startData') : '');
    const changeStartDate = (value, dateString) => {
        setStartDate(value)
        setStartValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  終了日
    const [endDate, setEndDate] = useState(localStorage.getItem('equipment_records_endData') ? dayjs(localStorage.getItem('equipment_records_endData')) : '')
    const [endValue, setEndValue] = useState(localStorage.getItem('equipment_records_endData') ? localStorage.getItem('equipment_records_endData') : '');
    const changeEndDate = (value, dateString) => {
        setEndDate(value)
        setEndValue(dateString);
        log(dateString)
    };




    // Common_checkBoxTable
    const [titleList, setTitleList] = useState([
        { titleIcon: '↓', name: '実施日', field: 'implementation_date', width: '13%' },
        { titleIcon: '↓', name: 'スペース / 対象設備', field: 'room_num_sort', width: '22%', canClick: true },
        { titleIcon: '↓', name: '実施内容', field: 'implementation_content', width: '48%' },
        { titleIcon: '↓', name: '次回実施日', field: 'next_implementation_date', width: '12%' },
    ])
    const [bodyList, setBodyList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  update
    const getItemData = (item) => {
        let newState = Object.assign({}, state, item)
        navigate(routerNames.PropertyDetail_equipment_records_detail, { state: newState })
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete
    const [deleteData, setDeleteData] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  sort
    const [count, setCount] = useState(0)
    const pagingFlag = useRef('implementation_date')
    const pagingDirection = useRef('↑')
    const toSort = (item) => {
        log(item)
        pagingFlag.current = item.field
        pagingDirection.current = item.titleIcon
        titleList.map(titleItem => {
            if (titleItem.name === item.name) {
                titleItem.titleIcon = item.titleIcon === '↓' ? '↑' : '↓'
                titleItem.checked = true
            } else {
                titleItem.checked = false
            }
        })
        setTitleList([...titleList])
        getList()
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('equipment_records_rowsPerPage') ? Number(localStorage.getItem('equipment_records_rowsPerPage')) : 50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setCurrentPage(newPage)
    };
    const handleChangePageForPagination = (event, newPage) => {
        const page = newPage - 1
        setPage(page)
        setCurrentPage(page)
    };
    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('equipment_records_rowsPerPage', String(event.target.value))
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);



    // dialog
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }


    // get list
    useEffect(() => {
        getList()
    }, [page, rowsPerPage, searchParam, searchStartValue, searchEndValue])

    useEffect(() => {
        window.addEventListener('keydown', enter);
        return () => {
            window.removeEventListener('keydown', enter);
        };
    }, [keywordSearch])
    const enter = (e) => {
        switch (e.keyCode) {
            case 13:
                search()
                break;
        }
    }


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { navigate(routerNames.Property) }}>物件一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>物件詳細</div>
            <div className={classes.tableTitle}>
                <MenuHeader_property />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件詳細</div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            <div className={classes.objectNameBlock}>
                <div>物件名</div>
                <div>{state.genba_name}</div>
            </div>
            <div className={classes.titleMenu}>
                {titleMenuList.map((item, index) => {
                    return (
                        <div
                            className={classes.menuContent}
                            key={index}
                            style={{ width: VW(item.width), backgroundColor: item.checked ? '#E0ECFF' : '', fontWeight: item.checked ? 'bold' : '' }}
                            onClick={() => { selectTitleItem(item) }}
                        >{item.name}</div>
                    )
                })}
            </div>
            <div style={{ marginTop: VH(12) }}>
                <div className={classes.retrievalBlock}>
                    <div className={classes.inputTitle}>キーワード</div>
                    <Input
                        placeholder='キーワードを入力してください'
                        value={keywordSearch}
                        style={{
                            // width: VW(874),
                            height: VH(40),
                            fontSize: VW(16)
                        }}
                        maxLength={200}
                        onChange={(event) => { setKeywordSearch(event.target.value) }}
                        suffix={
                            (
                                <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                    setKeywordSearch('')
                                }}></ClearRounded>
                            )
                        }
                    />
                </div>
                <div className={classes.retrievalBlock} style={{ paddingBottom: VH(16) }}>
                    <div className={classes.inputTitle}>&emsp;実施日</div>
                    <DatePicker
                        style={{ width: VW(176), height: VH(40), fontSize: '16px', borderRadius: '4px' }}
                        value={startDate}
                        format="YYYY/MM/DD"
                        placeholder="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeStartDate}
                    />
                    <div style={{ marginLeft: VW(16), marginRight: VW(16) }}>〜</div>
                    <DatePicker
                        style={{ width: VW(176), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        value={endDate}
                        format="YYYY/MM/DD"
                        placeholder="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeEndDate}
                    />
                    <Button type="primary" icon={<SearchIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={search} style={{ position: 'absolute', right: VW(16), fontWeight: 'bold' }}>検索</Button>
                </div>
            </div>
            <div className={classes.AddDeleteBlock}>
                <Button style={{ padding: 0, display: (authority_id == 3 || authority_id == 4) ? '' : 'none' }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} disabled={deleteData.length === 0} onClick={openDeleteDialog}>削除</Button>
                <Button style={{ padding: 0, display: (authority_id == 3 || authority_id == 4) ? '' : 'none' }} type="primary" icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openAddDialog}>登録</Button>
            </div>
            <TablePagination
                style={{ fontSize: VW(12) }}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
                labelRowsPerPage='表示件数:'
                className={classes.selectLabel}
                classes={{
                    toolbar: classes.toolbar
                }}
            />
            <div className={classes.tableBlock}>
                <Common_checkBoxTable
                    titleList={titleList}
                    bodyList={bodyList}
                    setBodyList={setBodyList}
                    getItemData={getItemData}
                    setCheckList={setDeleteData}
                    toSort={toSort}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                />
            </div>
            <PropertyDetail_equipment_records_add
                open={addOpen}
                onClose={addOnClose}
                state={state}
                getList={getList}
            />
            <PropertyDetail_equipment_records_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={deleteData}
                setDeleteData={setDeleteData}
                getList={getList}
                setIndeterminate={setIndeterminate}
                setCheckAll={setCheckAll}
            />
            <Common_pagenation
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChange={handleChangePageForPagination}
            />
        </div >
    )
}

PropertyDetail_equipment_records.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_records);