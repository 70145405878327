import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_file_icon() {
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20">
            <path d="M8.33317 3.33325H3.33317C2.4165 3.33325 1.67484 4.08325 1.67484 4.99992L1.6665 14.9999C1.6665 15.9166 2.4165 16.6666 3.33317 16.6666H16.6665C17.5832 16.6666 18.3332 15.9166 18.3332 14.9999V6.66659C18.3332 5.74992 17.5832 4.99992 16.6665 4.99992H9.99984L8.33317 3.33325Z" fill="#2F83FF" />
        </svg>
    )
}
export default PropertyDetail_file_icon;


