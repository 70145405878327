import React, { useState, useEffect } from "react";
import {
    List,
    ListItemButton,
    Collapse
} from "@mui/material";
import { withStyles } from "@mui/styles";
import HeaderBarIcon from "../icon/HeaderBarIcon";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from 'antd'
import { upload } from "@testing-library/user-event/dist/upload";
import { useNavigate } from 'react-router-dom'
import { VW, VH } from "../function/sizeAdapter"
import { routerNames } from '../../wordsFile/wordsFile'
import qs from 'qs'

const styles = (theme) => ({
    headerBar: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    iconBlock: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.1s',
        overflow: 'hidden',
        fontWeight: 'bold',
        fontSize: VW(16)
    },
    menuBlock: {
        height: '100%',
        borderLeft: `1px solid ${theme.palette.themeBorder.main}`,
        borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        transition: 'all 0.1s'
    },
    rightSelect: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: '2%',
        height: '50%',
        display: 'flex',
        alignItems: 'center',
    },
    tenantBlock: {
        fontSize: VW(15),
        borderRight: '1px solid #DDD',
        paddingRight: VW(16),
        marginRight: VW(16)
    },
    selectIocn: {
        width: VH(40),
        height: VH(40),
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#D9D9D9',
        color: '#FFF',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    menuButton: {
        cursor: 'pointer',
        width: VW(22),
        height: VH(22),
        borderRadius: '50%',
        margin: VW(17),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#DDD'
        }
    },
    profileButton: {
        position: 'absolute',
        right: '0',
        marginTop: VH(10),
        padding: VW(13),
        marginRight: VW(-15),
        width: VW(286),
        height: VH(47),
        fontSize: VW(14),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#FFF',
        color: 'black',
        boxShadow: '0px 0 0.85vh 0.12vw #DDD',
        borderRadius: "4px 4px 0 0"
    },
    logoutButton: {
        position: 'absolute',
        right: '0',
        marginTop: VH(57),
        // marginTop: VH(10),
        padding: VW(13),
        marginRight: VW(-15),
        width: VW(286),
        height: VH(47),
        fontSize: VW(14),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#FFF',
        color: 'black',
        boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD',
        borderRadius: "0 0 4px 4px"
    }
});

function HeaderBar(props) {
    const {
        classes,
        changeMenuStatus,
        menuFlag,
        userObject
    } = props

    const navigate = useNavigate()

    // username  
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    }


    // to login
    const goToLogin = () => {
        navigate('/')
    }

    // to personalData
    const toPersonalData = () => {
        navigate(routerNames.PersonalData)
        setOpen(false)
    }




    return (
        <div className={classes.headerBar}>
            <div className={classes.iconBlock} style={{ width: menuFlag ? '18.75%' : '0', paddingLeft: menuFlag ? VW(16) : '0' }}>物件カルテ</div>
            <div className={classes.menuBlock} style={{ width: menuFlag ? '81.25%' : '100%' }}>
                <div className={classes.menuButton} onClick={changeMenuStatus}><HeaderBarIcon /></div>
                <div className={classes.rightSelect}>
                    <div className={classes.tenantBlock}>{userObject.tenant_name}</div>
                    <div className={classes.selectIocn}>
                        {userObject.head_sculpture &&
                            <img
                                src={userObject.head_sculpture}
                                width="100%"
                                height="100%"
                            />
                        }
                    </div>
                    <div>
                        <List
                        >
                            <ListItemButton style={{ display: "flex", justifyContent: 'center', position: 'relative' }} onClick={handleClick}>
                                <span style={{ fontSize: VW(15), color: "#333333", fontWeight: 400 }}>{userObject.surname + ' ' + userObject.name}</span>
                                <div style={{ display: "flex", justifyContent: 'center', position: 'relative', marginLeft: VW(40) }}>
                                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </div>
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit style={{ position: 'absolute', width: '100%', zIndex: '9999' }}>
                                <List component="div" disablePadding>
                                    <Button
                                        // type="primary"
                                        className={classes.profileButton}
                                        onClick={toPersonalData}
                                    >プロフィール</Button>
                                    <Button
                                        // type="primary"
                                        className={classes.logoutButton}
                                        onClick={goToLogin}
                                    >ログアウト</Button>
                                </List>
                            </Collapse>
                        </List>
                    </div>
                </div>
            </div>
        </div>
    )
}

HeaderBar.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(HeaderBar);