// import { message, Upload } from 'antd';
import { withStyles } from "@mui/styles";
import { useDropzone } from 'react-dropzone';
import TableUploadIcon from '../icon/TableUploadIcon';
import React, { useState, useEffect } from "react";
import { VW, VH } from "../../componets/function/sizeAdapter"
import Papaneici from "../icon/Papaneici";

// const { Dragger } = Upload;

const styles = (theme) => ({
    uploadButton: {
        width: VW(360),
        height: VH(50),
        borderRadius: '4px',
        border: '1px solid #DDD',
        backgroundColor: '#FFF',
        fontSize: VW(16),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#5B5B5B'
    },
    sectionBlock: {
        width: '100%',
        minHeight: VH(280),
        cursor: 'pointer',
    },
    warnText: {
        width: '100%',
        marginTop: VH(10),
        color: '#E60024',
        fontSize: VW(16),
        textAlign: 'center'
    }
});
function UploadFiles(props) {
    const {
        classes,
        acceptedFiles,
        getRootProps,
        getInputProps,
        type,
        tabFlag
    } = props



    if (acceptedFiles.length > 0) {
        var files = acceptedFiles.map(file => (
            <div key={file.path} style={{ height: '25px', fontSize: '16px' }}>
                {file.path}
            </div>
        ));
    }


    return (
        <>
            <section className={classes.sectionBlock} style={{ backgroundColor: !tabFlag ? '#F8F8F8' : '', border: !tabFlag ? '1px solid #DDD' : 0 }}>
                <div style={{ width: '100%', minHeight: VH(280), textAlign: 'center', paddingTop: tabFlag ? 0 : VH(75), paddingBottom: tabFlag ? 0 : VH(75) }} {...getRootProps({ className: 'dropzone disabled' })}>
                    <input {...getInputProps()} />
                    {!tabFlag && <TableUploadIcon />}
                    {!tabFlag && <p style={{ fontSize: VW(16) }}>{type ? `ここに${type}ファイルをドラッグ＆ドロップ` : 'ここにファイルをドラッグ＆ドロップ'}</p>}
                    {!tabFlag &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className={classes.uploadButton}>ファイルを選択する</div>
                        </div>
                    }
                    {tabFlag && <Papaneici />}
                </div>
            </section>
            {tabFlag && <div className={classes.warnText}>ここで選択したファイルデータは（株）パパネッツに共有されます。</div>}
            <div>{files}</div>
        </>
    )
}

UploadFiles.defaultProps = {

}


export default withStyles(styles, { withTheme: true })(UploadFiles);