import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_calendar_second() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ display: 'block' }} >
            <rect width="24" height="24" rx="2" fill="#2F83FF" />
            <path d="M9.49969 12.8333C11.0497 12.8333 12.3414 11.7667 12.7164 10.3333H6.28302C6.65802 11.7667 7.94969 12.8333 9.49969 12.8333Z" fill="white" />
            <path d="M5.94967 9.5H13.058C13.283 9.5 13.4663 9.31667 13.4663 9.09167V9.075C13.4663 8.85 13.283 8.66667 13.058 8.66667H12.833C12.833 7.43333 12.158 6.375 11.1663 5.79167V6.58333C11.1663 6.81667 10.983 7 10.7497 7C10.5163 7 10.333 6.81667 10.333 6.58333V5.45C10.0663 5.38333 9.79134 5.33333 9.49967 5.33333C9.20801 5.33333 8.93301 5.38333 8.66634 5.45V6.58333C8.66634 6.81667 8.48301 7 8.24967 7C8.01634 7 7.83301 6.81667 7.83301 6.58333V5.79167C6.84134 6.375 6.16634 7.43333 6.16634 8.66667H5.94967C5.72467 8.66667 5.54134 8.85 5.54134 9.075V9.1C5.54134 9.31667 5.72467 9.5 5.94967 9.5Z" fill="white" />
            <path d="M18.5997 11.7333L18.183 11.0083C18.158 10.9583 18.0997 10.9417 18.058 10.9583L17.5413 11.1667C17.433 11.0833 17.3163 11.0167 17.1913 10.9667L17.1163 10.4167C17.0997 10.3667 17.0497 10.3333 16.9997 10.3333H16.1663C16.1163 10.3333 16.0747 10.3667 16.0663 10.425L15.9913 10.975C15.8663 11.025 15.7497 11.1 15.6413 11.175L15.1247 10.9667C15.0747 10.95 15.0247 10.9667 14.9997 11.0167L14.583 11.7417C14.558 11.7917 14.5663 11.8417 14.608 11.875L15.0497 12.2167C15.0413 12.2833 15.033 12.35 15.033 12.4167C15.033 12.4833 15.0413 12.5583 15.0497 12.6167L14.608 12.9583C14.5663 12.9917 14.558 13.05 14.583 13.0917L14.9997 13.8167C15.0247 13.8667 15.083 13.8833 15.1247 13.8667L15.6413 13.6583C15.7497 13.7417 15.8663 13.8083 15.9913 13.8583L16.0663 14.4083C16.0747 14.4667 16.1163 14.5 16.1663 14.5H16.9997C17.0497 14.5 17.0997 14.4667 17.0997 14.4083L17.1747 13.8583C17.2997 13.8083 17.4163 13.7333 17.5247 13.6583L18.0413 13.8667C18.0913 13.8833 18.1413 13.8667 18.1663 13.8167L18.583 13.0917C18.608 13.0417 18.5997 12.9917 18.558 12.9583L18.1247 12.6167C18.133 12.55 18.1413 12.4833 18.1413 12.4167C18.1413 12.35 18.133 12.275 18.1247 12.2167L18.5663 11.875C18.608 11.8417 18.6163 11.7833 18.5997 11.7333ZM16.583 13.1083C16.1997 13.1083 15.8913 12.7917 15.8913 12.4167C15.8913 12.0333 16.208 11.725 16.583 11.725C16.958 11.725 17.2747 12.0417 17.2747 12.4167C17.2747 12.8 16.9663 13.1083 16.583 13.1083Z" fill="white" />
            <path d="M20.4163 7.7C20.4247 7.60833 20.433 7.51667 20.433 7.41667C20.433 7.31667 20.4247 7.225 20.408 7.13333L21.0247 6.65C21.083 6.60833 21.0913 6.525 21.058 6.46667L20.4747 5.45833C20.4413 5.39167 20.358 5.375 20.2997 5.39167L19.583 5.68333C19.433 5.56667 19.2663 5.475 19.0913 5.4L18.983 4.625C18.9663 4.55 18.908 4.5 18.833 4.5H17.6663C17.5913 4.5 17.533 4.55 17.5247 4.625L17.4163 5.4C17.2413 5.475 17.0747 5.575 16.9247 5.68333L16.1997 5.39167C16.133 5.36667 16.058 5.39167 16.0247 5.45833L15.4413 6.46667C15.408 6.53333 15.4163 6.60833 15.4747 6.65L16.0913 7.13333C16.0747 7.225 16.0663 7.325 16.0663 7.41667C16.0663 7.50833 16.0747 7.60833 16.0913 7.7L15.4747 8.18333C15.4163 8.225 15.408 8.30833 15.4413 8.36667L16.0247 9.375C16.058 9.44167 16.1413 9.45833 16.1997 9.44167L16.9247 9.15C17.0747 9.26667 17.2413 9.35833 17.4163 9.43333L17.5247 10.2083C17.533 10.2833 17.5913 10.3333 17.6663 10.3333H18.833C18.908 10.3333 18.9663 10.2833 18.9747 10.2083L19.083 9.43333C19.258 9.35833 19.4247 9.25833 19.5747 9.15L20.2997 9.44167C20.3663 9.46667 20.4413 9.44167 20.4747 9.375L21.058 8.36667C21.0913 8.3 21.083 8.225 21.0247 8.18333L20.4163 7.7ZM18.2497 8.45833C17.6747 8.45833 17.208 7.99167 17.208 7.41667C17.208 6.84167 17.6747 6.375 18.2497 6.375C18.8247 6.375 19.2913 6.84167 19.2913 7.41667C19.2913 7.99167 18.8247 8.45833 18.2497 8.45833Z" fill="white" />
            <path d="M9.49967 14.5C7.27467 14.5 2.83301 15.6167 2.83301 17.8333V19.5H16.1663V17.8333C16.1663 15.6167 11.7247 14.5 9.49967 14.5Z" fill="white" />
        </svg>

    )
}
export default PropertyDetail_calendar_second;


