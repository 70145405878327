import React, { useEffect, useState, useRef } from "react";
import {
    TablePagination
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { Input, Button } from 'antd'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ClearRounded } from "@mui/icons-material"
import log from "../../componets/function/log";
import { useNavigate } from 'react-router-dom'
import Common_checkBoxTable from "../../componets/common/Common_checkBoxTable";
import Common_errorAlert from '../../componets/common/Common_errorAlert'
import Common_successAlert from "../../componets/common/Common_successAlert";
import Common_pagenation from '../../componets/common/Common_pagination';
import { VW, VH } from "../../componets/function/sizeAdapter"
import AddIcon from '@mui/icons-material/Add';
import MenuHeader_member from "../../componets/icon/MenuHeader_member";
import Member_delete from "../../componets/dialog/Member_delete";
import Member_add from "../../componets/dialog/Member_add";
import { ApiCompanyMemberList, ApiCompanyMemberReinvite } from '../../network/api'
import Member_useState from "../../componets/dialog/Member_useState";

const styles = (theme) => ({
    ground: {
        width: '100%',
        height: '100%',
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8)
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    retrievalBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        marginTop: VH(12),
        paddingLeft: VW(16),
        paddingRight: VW(16),
        height: VH(72),
        justifyContent: 'space-between',
        '& > Input': {
            width: VW(832),
            height: VH(40),
            fontSize: VW(16)
        },
        '& > Button': {
            width: VW(112),
            height: VH(40),
            fontSize: VW(16),
            marginLeft: VW(16),
            backgroundColor: theme.palette.primary.main,
        }
    },
    AddDeleteBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: VH(24),
        '& > Button': {
            marginLeft: VW(8),
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
        },
        '& > Button:nth-of-type(1)': {
            color: '#E60024',
        },
        '& > Button:nth-of-type(2)': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
        }
    },
    iconContainer: {
        color: '#999',
    },
    tableBlock: {
        marginRight: VW(24),
        paddingTop: '1vh'
    },
    toolbar: {
        "& > p:nth-of-type(2)": {
            fontSize: VW(12),
            color: "#333333",
            fontWeight: 400
        },

    },
    selectLabel: {
        "& .MuiTablePagination-selectLabel": {
            fontSize: VW(12),
            color: "#333333",
            fontWeight: 400
        }
    }
});

function Member(props) {
    const {
        classes,
        setSelectFlag,
    } = props

    const authority_id = localStorage.getItem("authority_id")

    useEffect(() => {
        setSelectFlag(routerNames.Member)
    }, [setSelectFlag])


    const navigate = useNavigate()


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // list api
    const getList = (successFlag) => {
        if (localStorage.getItem('member_deleteFlag')) {
            setSuccessContent('削除が完了しました。')
            setSuccessOpen(true)
            localStorage.removeItem('member_deleteFlag')
        }
        let params = {
            query_content: encodeURIComponent(searchParam),
            pageNum: page + 1,
            pageLimit: rowsPerPage,
            sortKey: pagingFlag.current == 'allName' ? 'surname' : pagingFlag.current,
            sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc',
            isSelfIncluded: false
        }
        ApiCompanyMemberList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                document.getElementById('father_Block').scrollTop = 0;
                localStorage.setItem('member_keywordSearch', keywordSearch)
                localStorage.setItem('member_searchParam', searchParam)
                log(resData)
                setRrrorOpen(false)
                setCount(resData.count)
                resData.data.map(item => {
                    item.allName = item.surname + item.name
                    item.sendMessage_button = item.status_name == '招待中' ? true : false
                })
                setBodyList([...resData.data])
                if (successFlag === 'add') {
                    setSuccessContent('登録が完了しました。')
                    setSuccessOpen(true)
                }
                if (successFlag === 'delete') {
                    setSuccessContent('削除が完了しました。')
                    setSuccessOpen(true)
                }
                if (successFlag === 'update') {
                    setSuccessContent('更新が完了しました。')
                    setSuccessOpen(true)
                }
                setDeleteData([])
                setIndeterminate(false)
                setCheckAll(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }




    // 検索
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  キーワード input
    const [keywordSearch, setKeywordSearch] = useState(localStorage.getItem('member_keywordSearch') == null || localStorage.getItem('member_keywordSearch') == undefined ? '' : localStorage.getItem('member_keywordSearch'))
    const [searchParam, setSearchParam] = useState(localStorage.getItem('member_searchParam') == null || localStorage.getItem('member_searchParam') == undefined ? '' : localStorage.getItem('member_searchParam'))
    const search = () => {
        setSearchParam(keywordSearch)
        setPage(0)
        setCurrentPage(0)
    }

    // Common_checkBoxTable
    const [titleList, setTitleList] = useState(
        (authority_id == 2 || authority_id == 4)
            ?
            [
                { titleIcon: '↓', name: '氏名', field: 'allName', width: '16.5%', canClick: true },
                { titleIcon: '↓', name: 'メールアドレス', field: 'email', width: '32.5%' },
                { titleIcon: '↓', name: '所属', field: 'affiliation', width: '17%' },
                { titleIcon: '↓', name: '権限', field: 'authority_name', width: '14%' },
                { titleIcon: '↓', name: 'ステータス', field: 'status_name', width: '10%' },
                { titleIcon: '', name: '', field: 'sendMessage_button', width: '9%' },
            ]
            :
            [
                { titleIcon: '↓', name: '氏名', field: 'allName', width: '12.5%', canClick: true },
                { titleIcon: '↓', name: 'メールアドレス', field: 'email', width: '27.5%' },
                { titleIcon: '↓', name: '所属', field: 'affiliation', width: '15%' },
                { titleIcon: '↓', name: '権限', field: 'authority_name', width: '14%' },
                { titleIcon: '↓', name: 'ステータス', field: 'status_name', width: '10%', center: 'center' },
                { titleIcon: '', name: '', field: 'sendMessage_button', width: '9%' },
                { titleIcon: '', name: 'アカウント\n停止 / 利用', field: 'is_active', width: '11%' },
            ]
    )
    const [bodyList, setBodyList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  update
    const getItemData = (item) => {
        log(item)
        navigate(routerNames.MemberDetail, { state: item })
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete
    const [deleteData, setDeleteData] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  sort
    const [count, setCount] = useState(0)
    const pagingFlag = useRef('user_id')
    const pagingDirection = useRef('↑')
    const toSort = (item) => {
        pagingFlag.current = item.field
        pagingDirection.current = item.titleIcon
        titleList.map(titleItem => {
            if (titleItem.name === item.name) {
                titleItem.titleIcon = item.titleIcon === '↓' ? '↑' : '↓'
                titleItem.checked = true
            } else {
                titleItem.checked = false
            }
        })
        setTitleList([...titleList])
        getList()
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [page, setPage] = useState(localStorage.getItem('member_page') ? Number(localStorage.getItem('member_page')) : 0);
    const [currentPage, setCurrentPage] = useState(localStorage.getItem('member_page') ? Number(localStorage.getItem('member_page')) : 0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('member_rowsPerPage') ? Number(localStorage.getItem('member_rowsPerPage')) : 50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setCurrentPage(newPage)
        localStorage.setItem('member_page', String(newPage))
    };
    const handleChangePageForPagination = (event, newPage) => {
        const page = newPage - 1
        setPage(page)
        setCurrentPage(page)
        localStorage.setItem('member_page', String(page))
    };
    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('member_rowsPerPage', String(event.target.value))
        setRowsPerPage(event.target.value);
        setPage(0);
        localStorage.setItem('member_page', '0')
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);


    // dialog
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  use state dialog
    const [useItem, setUseItem] = useState({})
    const [useOpen, setUseOpen] = useState(false)
    const useOnClose = () => {
        setUseOpen(false)
    }
    const changeUseState = (item) => {
        setUseItem(item)
        setUseOpen(true)
    }


    // get list
    useEffect(() => {
        getList()
    }, [page, rowsPerPage, searchParam])


    useEffect(() => {
        window.addEventListener('keydown', enter);
        return () => {
            window.removeEventListener('keydown', enter);
        };
    }, [keywordSearch])
    const enter = (e) => {
        switch (e.keyCode) {
            case 13:
                search()
                break;
        }
    }



    const sendMessageAgain = (item) => {
        let param = {
            email: item
        }
        ApiCompanyMemberReinvite(param).then(res => {
            if (res.status === 200) {
                log(res.data)
                setRrrorOpen(false)
                setSuccessOpen(true)
                setSuccessContent(res.data.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}>メンバー管理</div>
            <div className={classes.tableTitle} style={{ marginBottom: VH(12) }}>
                <MenuHeader_member />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>メンバー管理</div>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
            />
            <div className={classes.retrievalBlock}>
                <Input
                    placeholder='キーワードを入力してください'
                    value={keywordSearch}
                    style={{
                        // width: VW(874),
                        height: VH(40),
                        fontSize: VW(16)
                    }}
                    onChange={(event) => { setKeywordSearch(event.target.value) }}
                    suffix={
                        (
                            <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                setKeywordSearch('')
                            }}></ClearRounded>
                        )
                    }
                />
                <Button type="primary" icon={<SearchOutlined />} style={{ fontWeight: 'bold' }} onClick={search}>検索</Button>
            </div>
            <div className={classes.AddDeleteBlock}>
                <Button type="text" style={{ display: authority_id == 2 || authority_id == 4 ? 'none' : '' }} danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} disabled={deleteData.length === 0} onClick={openDeleteDialog}>削除</Button>
                <Button type="primary" icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openAddDialog}>登録</Button>
            </div>
            <TablePagination
                style={{ fontSize: VW(12) }}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
                labelRowsPerPage='表示件数:'
                className={classes.selectLabel}
                classes={{
                    toolbar: classes.toolbar
                }}
            />
            <div className={classes.tableBlock}>
                <Common_checkBoxTable
                    titleList={titleList}
                    bodyList={bodyList}
                    setBodyList={setBodyList}
                    getItemData={getItemData}
                    setCheckList={setDeleteData}
                    toSort={toSort}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                    sendMessageAgain={sendMessageAgain}
                    changeUseState={changeUseState}
                />
            </div>
            <Member_add
                open={addOpen}
                onClose={addOnClose}
                getList={getList}
            />
            <Member_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={deleteData}
                setDeleteData={setDeleteData}
                getList={getList}
                setIndeterminate={setIndeterminate}
                setCheckAll={setCheckAll}
            />
            <Common_pagenation
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChange={handleChangePageForPagination}
            />
            <Member_useState
                open={useOpen}
                onClose={useOnClose}
                useItem={useItem}
                getList={getList}
            />
        </div>
    )
}

Member.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Member);