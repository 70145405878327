import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_file_folder_close() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ float: 'left', marginRight: VW(8) }}>
            <path d="M10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z" fill="#0052CC" />
        </svg>
    )
}
export default PropertyDetail_file_folder_close;


