import { VW, VH } from "../../componets/function/sizeAdapter"
function PersonalData_icon_large() {
    return (
        <svg width={VW(200)} height={VH(200)} viewBox="0 0 200 200" fill="none">
            <rect width="200" height="200" fill="white" />
            <path d="M100 16.6667C54.0003 16.6667 16.667 54.0001 16.667 100C16.667 146 54.0003 183.333 100 183.333C146 183.333 183.334 146 183.334 100C183.334 54.0001 146 16.6667 100 16.6667ZM100 41.6667C113.834 41.6667 125 52.8334 125 66.6667C125 80.5001 113.834 91.6667 100 91.6667C86.167 91.6667 75.0003 80.5001 75.0003 66.6667C75.0003 52.8334 86.167 41.6667 100 41.6667ZM100 160C79.167 160 60.7503 149.333 50.0003 133.167C50.2503 116.583 83.3337 107.5 100 107.5C116.584 107.5 149.75 116.583 150 133.167C139.25 149.333 120.834 160 100 160Z" fill="#DDDDDD" />
        </svg>

    )
}

export default PersonalData_icon_large;