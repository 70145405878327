import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_camera() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width={VW(24)} height={VH(24)} rx="2" fill="#2F83FF" />
            <path d="M16.5 10.86V7.5C16.5 6.675 15.825 6 15 6H6C5.175 6 4.5 6.675 4.5 7.5V16.5C4.5 17.325 5.175 18 6 18H15C15.825 18 16.5 17.325 16.5 16.5V13.14L19.5 16.125V7.875L16.5 10.86ZM10.5 9C11.325 9 12 9.675 12 10.5C12 11.325 11.325 12 10.5 12C9.675 12 9 11.325 9 10.5C9 9.675 9.675 9 10.5 9ZM13.5 15H7.5V14.5725C7.5 13.965 7.86 13.425 8.415 13.185C9.0525 12.9075 9.7575 12.75 10.5 12.75C11.2425 12.75 11.9475 12.9075 12.585 13.185C13.14 13.425 13.5 13.965 13.5 14.5725V15Z" fill="white" />
        </svg>
    )
}

export default Property_detail_camera;
