import React, { useState, useEffect, useRef } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Select, message } from 'antd';
import AddIcon from "../icon/AddIcon";
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import UploadFiles from "../common/UploadFiles";
import { useDropzone } from 'react-dropzone';
import { CaretDownOutlined } from '@ant-design/icons'
import { ApiFilemanageAdd, ApiFilemanageSetsubi, ApiFilemanageAddCheck } from '../../network/api'
import { FileUploadS3 } from "../function/UploadS3";
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';

const { Option } = Select;

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
        }
    },
    uploadBlock: {
        width: '100%',
        minHeight: VH(280),
        marginTop: VH(13)
    },
    selectBlock: {
        display: 'flex',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: VH(74),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(240),
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
    }
});

function PropertyDetail_file_upload(props) {
    const {
        classes,
        open,
        onClose,
        getList,
        state,
        tabFlag,
        uploadFile_manage_id,
        setUploadFile_manage_id,
        uploadFile_Folder_name,
        setUploadFile_Folder_name
    } = props

    useEffect(() => {
        if (!open) {
            setAcceptedFilesStandIn([])
            setRrrorOpen(false)
            setGoodsRoom(0)
            successTimes.current = 0
            setUploadFile_manage_id(0)
            setUploadFile_Folder_name('')
        } else {
            getRoomsOption()
        }
    }, [open]);


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")

    // file list
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({

    });
    const [acceptedFilesStandIn, setAcceptedFilesStandIn] = useState([])
    useEffect(() => {
        log(acceptedFiles)
        setAcceptedFilesStandIn([...acceptedFiles])
    }, [acceptedFiles]);




    // 显示loading
    function showLoading() {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.createRoot(dom).render(
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
                <div style={{ fontWeight: "bold", color: '#DDD' }}>処理中です</div>
                <div style={{ fontWeight: "bold", color: '#DDD' }}>しばらくお待ちください</div>
            </div>
        )
    }
    // 隐藏loading
    function hideLoading() {
        document.body.removeChild(document.getElementById('loading'))
    }

    const addArray = useRef([])
    // upload
    const toAdd = () => {
        log(acceptedFilesStandIn)
        let maxSize = false
        acceptedFilesStandIn.map(item => {
            if (item.size > 209715200) {
                maxSize = true
            }
        })
        log(maxSize)
        if (acceptedFilesStandIn.length == 0) {
            setErrorContent('ファイルをアップロードして下さい')
            setRrrorOpen(true)
            return
        } else if (maxSize) {
            setErrorContent('登録できるファイルサイズは1ファイル200MBまでです')
            setRrrorOpen(true)
            return
        } else {
            let newArray = []
            acceptedFilesStandIn.map(item => {
                newArray.push({
                    "file_name": item.path,
                    "file_size": item.size
                })
            })
            let params = {
                buken_id: state.buken_id,
                file_info_list: newArray,
                setsubi_flag: goodsRoom == -1 ? 0 : 1,
                setsubi_id: goodsRoom,
                is_belong: tabFlag ? 1 : 0,
                file_manage_id: uploadFile_manage_id == 0 ? null : uploadFile_manage_id
            }
            log(params)
            ApiFilemanageAddCheck(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    acceptedFilesStandIn.map(item => {
                        FileUploadS3(state.buken_id, item, item.type, callBack, uploadFile_Folder_name)
                    })
                    addArray.current = newArray
                    showLoading()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }



    const successTimes = useRef(0)
    const callBack = (response) => {
        if (response.error) {
            hideLoading()
            setErrorContent('アップロードに失敗しました')
            setRrrorOpen(true)
        }
        if (response.success) {
            successTimes.current = successTimes.current + 1
            if (successTimes.current == acceptedFilesStandIn.length) {
                hideLoading()
                // add api 
                let params = {
                    buken_id: state.buken_id,
                    file_info_list: addArray.current,
                    setsubi_flag: goodsRoom == -1 ? 0 : 1,
                    setsubi_id: goodsRoom,
                    is_belong: tabFlag ? 1 : 0,
                    file_manage_id: uploadFile_manage_id == 0 ? null : uploadFile_manage_id
                }
                log(params)
                ApiFilemanageAdd(params).then(res => {
                    if (res.status === 200) {
                        successTimes.current = 0
                        setRrrorOpen(false)
                        onClose()
                        getList('add')
                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                        log(res)
                        setRrrorOpen(true)
                        setErrorContent(res.data.detail.toString())
                    }
                }).catch(err => {

                })
            }
        }
    }

    // 物件 / 部屋
    const [goodsRoom, setGoodsRoom] = useState('')
    const [roomOption, setRoomOption] = useState([])

    // rooms api
    const getRoomsOption = () => {
        let param = {
            buken_id: state.buken_id
        }
        ApiFilemanageSetsubi(param).then(res => {
            let resData = res.data
            if (res.status == 200) {
                log(resData)
                setRrrorOpen(false)
                setRoomOption(resData)
                setGoodsRoom(-1)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>ファイルの登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />

                <div className={classes.uploadBlock}>
                    <div className={classes.selectBlock}>
                        <div>物件 / 部屋</div>
                        <div>
                            <Select
                                style={{ width: VW(360), height: VH(50) }}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                                value={goodsRoom}
                                onChange={(event) => setGoodsRoom(event)}
                            >
                                {roomOption.map((item, index) => {
                                    return (
                                        <Option style={{ fontSize: VW(16) }} key={index} value={item.setsubi_id}>{item.room_num}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <div style={{ marginTop: VH(24) }}>
                        <UploadFiles
                            acceptedFiles={acceptedFilesStandIn}
                            getRootProps={getRootProps}
                            getInputProps={getInputProps}
                            tabFlag={tabFlag}
                        />
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={toAdd}>データを読み込む</Button>
                </div>
            </div>
        </Modal >
    )
}

PropertyDetail_file_upload.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_file_upload);