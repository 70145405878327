import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../../componets/function/sizeAdapter"
import Forward from "../icon/Forward";
import Backward from "../icon/Backward";


const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
    },
    titleBlock: {
        height: 'auto',
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            minHeight: VH(74),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(200),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: VW(792),
        },
    },
    commonItemTitle: {
        marginTop: VH(40),
        width: '100%',
        height: VH(24),
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        fontSize: VW(16),
        fontWeight: 'bold',
        paddingLeft: VW(12),
        display: 'flex',
        alignItems: 'center'
    },
    commonItemDataT: {
        width: '100%',
        height: 'auto',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderBottom: 0,
        marginTop: VH(16),
        '& > div': {
            width: '100%',
            display: 'flex',
        },
        '& > div > div': {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& > div:nth-child(1)': {
            height: VH(48),
            backgroundColor: "#F8F8F8",
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:not(:nth-child(1))': {
            height: VH(38),
        }
    },
    commonItemDataB: {
        width: '100%',
        height: 'auto',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        '& > div': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& > div:nth-child(1)': {
            height: VH(48),
            backgroundColor: "#F8F8F8",
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:not(:nth-child(1))': {
            height: VH(38),
        },
    },
    scrollBlock: {
        width: '100%',
        position: 'relative',
        '& > div:nth-child(2)': {
            position: 'absolute',
            cursor: 'pointer',
        },
        '& > div:nth-child(3)': {
            position: 'absolute',
            cursor: 'pointer',
        }
    },
    tabTitle: {
        width: '100%',
        display: 'flex',
        marginTop: VH(16),
        overflowX: 'auto',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        userSelect: 'none',
        '& > div': {
            width: VW(160),
            height: VH(64),
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: '#E0ECFF',
            border: `1px solid ${theme.palette.primary.main}`,
            borderBottom: 0,
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: VW(16),
            fontWeight: 'bold',
            cursor: 'pointer',
            flexShrink: 0
        },
    },
    tabHeader: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#F8F8F8',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        '& > div': {
            height: VH(48),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16),
            fontWeight: 'bold',
        },
        '& > div:nth-child(1)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(2)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '48.4%'
        },
    },
    tabBody: {
        display: 'flex',
        width: '100%',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        minHeight: VH(48),
        '& > div': {
            minHeight: VH(48),
            paddingTop: VH(12),
            padding: VW(16),
            fontSize: VW(16),
            wordBreak: 'break-all'
        },
        '& > div:nth-child(1)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(2)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '48.4%',
            paddingBottom: VH(4),
            '& > div': {
                marginBottom: VH(8)
            }
        },
    }
});

function InspectionListDetail_item(props) {
    const {
        classes,
        detailedData,
        setDetailedData,
        displayData,
        setDisplayData
    } = props


    // scroll button flag
    const [leftFlag, setLeftFlag] = useState(false)
    const [rightFlag, setRightFlag] = useState(false)
    // left move
    const leftMove = () => {
        let parent = document.getElementById('inspectionParent')
        parent.scrollLeft = parent.scrollLeft - 480
    }
    // right move
    const rightMove = () => {
        let parent = document.getElementById('inspectionParent')
        parent.scrollLeft = parent.scrollLeft + 480
    }


    useEffect(() => {
        let parent = document.getElementById('inspectionParent')
        if (parent.scrollLeft + parent.clientWidth != parent.scrollWidth) {
            setRightFlag(true)
        }
        parent.addEventListener('scroll', function () {
            if (parent.scrollLeft > 0) {
                setLeftFlag(true)
            } else {
                setLeftFlag(false)
            }
            if (parent.scrollLeft + parent.clientWidth == parent.scrollWidth) {
                setRightFlag(false)
            } else {
                setRightFlag(true)
            }
        });
    }, [detailedData])

    const backFc = () => {
        return
    }

    const selectTab = (item, index) => {
        detailedData.sagyo_shubetsu_data.map(listitem => {
            listitem.checked = false
        })
        detailedData.sagyo_shubetsu_data[index].checked = true
        setDisplayData([...detailedData.sagyo_shubetsu_data[index].sagyo_komoku])
        setDetailedData({ ...detailedData })
    }

    return (
        <div className={classes.contentBlock}>
            <div className={classes.titleBlock}>
                <div>点検表種類</div>
                <div>{detailedData.category == 0 ? '定期巡回' : 'マンスリー'}</div>
            </div>
            <div className={classes.titleBlock} style={{ borderBottom: '1px solid #DDD' }}>
                <div>点検表名</div>
                <div>{detailedData.name}</div>
            </div>
            <div className={classes.commonItemTitle}>結果項目設定</div>
            <div className={classes.commonItemDataT}>
                <div>
                    <div>点検結果</div>
                    <div> 結果（表示）</div>
                </div>
                {detailedData.m_keka_data && detailedData.m_keka_data.length > 0 && detailedData.m_keka_data.map((item, index) => {
                    return (
                        <div key={index}>
                            <div>
                                <p>{(index + 1) + '. ' + item.name}</p>
                            </div>
                            <div>
                                <p>{item.sname}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.commonItemDataB}>
                <div>問題程度</div>
                {detailedData.m_keka_hosoku_data && detailedData.m_keka_hosoku_data.length > 0 && detailedData.m_keka_hosoku_data.map((item, index) => {
                    return (
                        <div key={index}>
                            <p>{(index + 1) + '. ' + item.name}</p>
                        </div>
                    )
                })}
            </div>
            <div className={classes.commonItemTitle}>点検項目設定</div>
            <div className={classes.scrollBlock}>
                <div className={classes.tabTitle} id='inspectionParent'>
                    {detailedData.sagyo_shubetsu_data && detailedData.sagyo_shubetsu_data.length > 0 && detailedData.sagyo_shubetsu_data.map((item, index) => (
                        <div
                            key={index}
                            style={{ backgroundColor: item.checked ? '#0052CC' : '', color: item.checked ? '#FFF' : '' }}
                            onClick={() => { selectTab(item, index) }}
                        >
                            {item.sagyo_shubetsu_name}
                        </div>
                    ))}
                </div>
                <div onClick={leftFlag ? leftMove : backFc} style={{ top: leftFlag ? VH(16) : VH(18), left: leftFlag ? VW(0) : VW(2) }}>
                    <Forward flag={leftFlag} />
                </div>
                <div onClick={rightFlag ? rightMove : backFc} style={{ top: rightFlag ? VH(16) : VH(18), right: rightFlag ? VW(0) : VW(2) }}>
                    <Backward flag={rightFlag} />
                </div>
            </div>
            <div className={classes.tabHeader}>
                <div>点検項目名</div>
                <div>点検方法</div>
                <div>報告事項</div>
            </div>
            {displayData.length > 0 && displayData.map((item, index) => {
                return (
                    <div className={classes.tabBody} key={index}>
                        <div>{item.sagyo_naiyo}</div>
                        <div>{item.sagyo_naiyo_hosoku}</div>
                        <div>
                            {item.hokoku_select && item.hokoku_select.length > 0 && item.hokoku_select.map((TItem, TIndex) => {
                                return (
                                    <div key={TIndex}>{TIndex + 1 + '. ' + TItem.hokoku_select}</div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

InspectionListDetail_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(InspectionListDetail_item);