import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_calendar_third() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ display: 'block' }} >
            <rect width="24" height="24" rx="2" fill="#2F83FF" />
            <g clipPath="url(#clip0_5615_42534)">
                <path d="M18.1457 14.5858C18.6864 13.301 18.8109 11.8791 18.5016 10.52C18.1923 9.16082 17.4649 7.93277 16.4215 7.00849C15.3781 6.08421 14.0713 5.51017 12.6847 5.36708C11.2982 5.224 9.90169 5.51906 8.69154 6.21078L7.86487 4.76328C9.12956 4.04046 10.5617 3.66192 12.0184 3.66543C13.475 3.66894 14.9053 4.05437 16.1665 4.78328C19.9082 6.94328 21.3415 11.5683 19.5974 15.4249L20.7157 16.0699L17.2449 17.9149L17.1074 13.9866L18.1457 14.5858ZM5.85404 9.41411C5.31332 10.6989 5.18886 12.1208 5.49813 13.4799C5.80741 14.8391 6.53488 16.0671 7.57826 16.9914C8.62165 17.9157 9.92847 18.4897 11.315 18.6328C12.7015 18.7759 14.0981 18.4808 15.3082 17.7891L16.1349 19.2366C14.8702 19.9594 13.438 20.338 11.9814 20.3345C10.5247 20.331 9.0944 19.9455 7.8332 19.2166C4.09154 17.0566 2.6582 12.4316 4.40237 8.57495L3.2832 7.93078L6.75404 6.08578L6.89154 10.0141L5.8532 9.41495L5.85404 9.41411ZM12.8332 13.2799H15.3332V14.9466H12.8332V16.6133H11.1665V14.9466H8.66654V13.2799H11.1665V12.4466H8.66654V10.7799H10.8215L9.0532 9.01161L10.2332 7.83328L11.9999 9.60078L13.7674 7.83328L14.9465 9.01161L13.1782 10.7799H15.3332V12.4466H12.8332V13.2799Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_5615_42534">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default PropertyDetail_calendar_third;


