import React, { useState, useEffect, useRef } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, DatePicker, Select } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import locale from "antd/es/date-picker/locale/ja_JP";
import { errorMessage } from "../../wordsFile/wordsFile";
import Common_errorAlert from "../common/Common_errorAlert";
import Common_successAlert from "../common/Common_successAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CaretDownOutlined } from '@ant-design/icons'
import PropertyDetail_equipment_list_string from "../icon/PropertyDetail_equipment_list_string";
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropertyDetail_roomType_delete from "./PropertyDetail_roomType_delete";
import PropertyDetail_roomType_link_roomNumber from "./PropertyDetail_roomType_link_roomNumber";
import { ApiSetsubiTypeList, ApiSetsubiCategoryList, ApiSetsubiTypeAdd } from '../../network/api'
import PropertyDetail_roomType_update_item from "./PropertyDetail_roomType_update_item";



dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    prompt: {
        marginTop: VH(13),
        marginBottom: VH(24),
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(18),
    },
    contentBlock: {
        width: '100%',
        height: VH(74),
        display: 'flex',
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: '15.2%',
            justifyContent: 'center',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '67%',
            paddingLeft: VW(16)
        }
    },
    addBlock: {
        width: '100%',
        height: VH(50),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '1vw',
        '& > div': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.78vw',
            fontSize: VW(16)
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(280),
            height: VH(40),
            fontSize: VW(16),
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    }
});

function PropertyDetail_roomType_update(props) {
    const {
        classes,
        open,
        onClose,
        state,
        getList
    } = props


    useEffect(() => {
        if (!open) {
            getList()
        } else {
            log(state)
            getTypeList()
        }
    }, [open])




    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    const [typeList, setTypeList] = useState([])



    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    const getTypeList = (flag) => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        if (flag == 'delete') {
            setSuccessContent('削除が完了しました。')
            setSuccessOpen(true)
        }
        if (flag == 'add') {
            setSuccessContent('登録が完了しました。')
            setSuccessOpen(true)
        }
        if (flag == 'update') {
            setSuccessContent('編集が完了しました。')
            setSuccessOpen(true)
        }
        if (flag == 'save') {
            setSuccessContent('保存が完了しました。')
            setSuccessOpen(true)
        }
        let params = {
            buken_id: state.buken_id,
        }
        log(params)
        ApiSetsubiTypeList(params).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                log(res.data)
                setTypeList(res.data.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteList, setDeleteList] = useState([])
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = (item) => {
        let newDeleteList = []
        newDeleteList.push(item)
        setDeleteList(newDeleteList)
        setDeleteOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [updateSubi_id, setUpdateSubi_id] = useState()
    const [updateOpen, setUpdateOpen] = useState(false)
    const updateOnClose = () => {
        setUpdateOpen(false)
    }
    const openUpdateDialog = (item) => {
        setUpdateSubi_id(item.setsubi_id)
        setUpdateOpen(true)
    }
    // type link room dialog
    const [linkOpen, setLinkOpen] = useState(false)
    const linkOnClose = () => {
        setLinkOpen(false)
    }
    const openLinkDialog = (item) => {
        setLinkOpen(true)
    }



    // add api
    const addType = () => {
        ApiSetsubiCategoryList().then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                let param = {}
                param.buken_id = state.buken_id
                param.data = res.data.data
                ApiSetsubiTypeAdd(param).then(res => {
                    if (res.status === 200) {
                        setRrrorOpen(false)
                        getTypeList('add')
                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                        log(res)
                        setRrrorOpen(true)
                        setErrorContent(res.data.detail.toString())
                    }
                }).catch(err => {

                })
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>部屋と設備の紐付け</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_successAlert
                    open={successOpen}
                    setSuccessOpen={setSuccessOpen}
                    successContent={successContent}
                    marginTop={VH(12)}
                />
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.prompt}>
                    <PropertyDetail_equipment_list_string />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>部屋タイプ一覧</div>
                </div>
                {typeList.length > 0 && typeList.map((item, index) => {
                    return (
                        <div className={classes.contentBlock} key={index}>
                            <div>{item.title}</div>
                            <div>{item.room_topic}</div>
                            <div>
                                <Button
                                    style={{ padding: 0, marginRight: VW(24), fontSize: VW(16) }}
                                    type="text"
                                    onClick={() => { openUpdateDialog(item) }}
                                    icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}>&nbsp;編集</Button>
                                <Button
                                    style={{ padding: 0, fontSize: VW(16) }}
                                    type="text"
                                    danger
                                    onClick={() => { openDeleteDialog(item) }}
                                    icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}>&nbsp;削除</Button>
                            </div>
                        </div>
                    )
                })}
                <div className={classes.addBlock}>
                    <div onClick={addType}>
                        <AddIcon />&ensp;タイプを追加する
                    </div>
                </div>
                {typeList.length > 0 &&
                    <div className={classes.buttonGroup}>
                        <Button onClick={openLinkDialog} type="primary">部屋と設備の紐付け</Button>
                    </div>
                }
                <PropertyDetail_roomType_delete
                    open={deleteOpen}
                    onClose={deleteOnClose}
                    deleteContent={deleteList}
                    getList={getTypeList}
                />
                <PropertyDetail_roomType_update_item
                    open={updateOpen}
                    onClose={updateOnClose}
                    getList={getTypeList}
                    buken_id={state.buken_id}
                    setsubi_id={updateSubi_id}
                />
                <PropertyDetail_roomType_link_roomNumber
                    open={linkOpen}
                    onClose={linkOnClose}
                    getList={getTypeList}
                    state={state}
                />
            </div>
        </Modal>
    )
}

PropertyDetail_roomType_update.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_roomType_update);