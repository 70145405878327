import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_router_claim() {

    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20">
            <path d="M9.99199 1.66669C5.39199 1.66669 1.66699 5.40002 1.66699 10C1.66699 14.6 5.39199 18.3334 9.99199 18.3334C14.6003 18.3334 18.3337 14.6 18.3337 10C18.3337 5.40002 14.6003 1.66669 9.99199 1.66669ZM10.0003 16.6667C6.31699 16.6667 3.33366 13.6834 3.33366 10C3.33366 6.31669 6.31699 3.33335 10.0003 3.33335C13.6837 3.33335 16.667 6.31669 16.667 10C16.667 13.6834 13.6837 16.6667 10.0003 16.6667ZM10.0003 11.6667C8.05866 11.6667 6.40033 12.875 5.73366 14.5834H7.12533C7.70033 13.5917 8.76699 12.9167 10.0003 12.9167C11.2337 12.9167 12.292 13.5917 12.8753 14.5834H14.267C13.6003 12.875 11.942 11.6667 10.0003 11.6667Z" fill="#2F83FF" />
            <path d="M7.08366 9.16669C7.77401 9.16669 8.33366 8.60704 8.33366 7.91669C8.33366 7.22633 7.77401 6.66669 7.08366 6.66669C6.3933 6.66669 5.83366 7.22633 5.83366 7.91669C5.83366 8.60704 6.3933 9.16669 7.08366 9.16669Z" fill="#2F83FF" />
            <path d="M12.917 9.16669C13.6073 9.16669 14.167 8.60704 14.167 7.91669C14.167 7.22633 13.6073 6.66669 12.917 6.66669C12.2266 6.66669 11.667 7.22633 11.667 7.91669C11.667 8.60704 12.2266 9.16669 12.917 9.16669Z" fill="#2F83FF" />
        </svg>

    )
}

export default Property_detail_router_claim;