import React, { useEffect, useState, useRef } from "react";
import {
    Select,
    MenuItem,
    TablePagination
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import MenuHeader_property from "../../componets/icon/MenuHeader_property";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos } from "@mui/icons-material";
import { Button, Input, DatePicker } from 'antd'
import locale from "antd/es/date-picker/locale/ja_JP";
import { useLocation } from "react-router-dom";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import { useNavigate } from 'react-router-dom'
import { ClearRounded } from "@mui/icons-material"
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import Common_checkBoxTable from "../../componets/common/Common_checkBoxTable";
import { ApiFilemanageList, ApiFilemanageDownload } from '../../network/api'
import { errorMessage } from "../../wordsFile/wordsFile";
import PropertyDetail_file_upload from "../../componets/dialog/PropertyDetail_file_upload";
import PropertyDetail_file_delete from "../../componets/dialog/PropertyDetail_file_delete";
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import MoveIcon from "../../componets/icon/MoveIcon";
import Property_detail_tab_true from "../../componets/icon/Property_detail_tab_true";
import Property_detail_tab_false from "../../componets/icon/Property_detail_tab_false";
import PropertyDetail_file_folder_add from "../../componets/dialog/PropertyDetail_file_folder_add";
import PropertyDetail_file_move from "../../componets/dialog/PropertyDetail_file_move";
import PropertyDetail_file_preview_img from "../../componets/dialog/PropertyDetail_file_preview_img";
import PropertyDetail_file_preview_pdf from "../../componets/dialog/PropertyDetail_file_preview_pdf";
import { ImportExport } from "aws-sdk";
import PropertyDetail_file_folder_update from "../../componets/dialog/PropertyDetail_file_folder_update";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import Common_pagenation from '../../componets/common/Common_pagination';




dayjs.extend(utc)

const styles = (theme) => ({
    ground: {
        width: '100%',
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    objectNameBlock: {
        marginLeft: VW(24),
        marginTop: VH(12),
        minHeight: VH(24),
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
        wordBreak: 'break-all',
        '& > div:nth-child(1)': {
            fontSize: VW(14),
        },
        '& > div:nth-child(2)': {
            fontSize: VW(24),
            fontWeight: 'bold',
            marginLeft: VW(16)
        }
    },
    inputTitle: {
        fontSize: VW(14),
        width: VW(100),
        maxWidth: VW(85),
        textAlign: 'right',
        marginRight: VW(16)
    },
    iconContainer: {
        color: '#999',
    },
    querySelect: {
        width: VW(160),
        height: VH(40),
        fontSize: VW(16),
        backgroundColor: '#FFF'
    },
    retrievalBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        // paddingLeft: VW(16),
        paddingRight: VW(16),
        paddingTop: VH(16),
        '& > Input': {
            width: VW(874),
            height: VH(40),
            fontSize: VW(16)
        },
        '& > Button': {
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
            fontSize: VW(16),
            backgroundColor: theme.palette.primary.main,
        }
    },
    titleMenu: {
        width: 'auto',
        display: 'flex',
        marginTop: VH(16),
        height: VH(40),
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    menuContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: VW(16)
    },
    AddDeleteBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: VH(24),
        paddingRight: VW(24),
        '& > Button': {
            height: VH(40),
            fontSize: VW(16),
            fontWeight: 'bold'
        },
        '& > Button:nth-of-type(1)': {
            width: VW(120),
            color: '#E60024',
        },
        '& > Button:nth-of-type(2)': {
            marginLeft: VW(8),
            width: VW(160),
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`
        },
        '& > Button:nth-of-type(3)': {
            marginLeft: VW(24),
            width: VW(160),
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`
        },
        '& > Button:nth-of-type(4)': {
            marginLeft: VW(24),
            width: VW(160),
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
        },
        '& > Button:nth-of-type(5)': {
            marginLeft: VW(24),
            width: VW(160),
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
        }
    },
    tabButton: {
        display: 'flex',
        float: 'left',
        userSelect: 'none',
        marginTop: VH(7),
        '& > div': {
            width: VW(136),
            height: VH(40),
            fontSize: VW(16),
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        }
    },
    tableBlock: {
        marginRight: VW(24),
        paddingTop: '1vh'
    }
});

function PropertyDetail_file(props) {
    const {
        classes,
        setSelectFlag
    } = props

    const location = useLocation();
    const { state } = location;


    useEffect(() => {
        setSelectFlag(routerNames.Property)
    }, [setSelectFlag])


    useEffect(() => {
        log(state)
    }, [])


    const navigate = useNavigate()


    const authority_id = localStorage.getItem("authority_id")


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // list api
    const getList = (successFlag) => {
        if (startDate > endDate) {
            setErrorContent(errorMessage.startEndDayNull)
            setRrrorOpen(true)
        } else {
            let params = {
                query_content: encodeURIComponent(keywordSearch),
                pageNum: page + 1,
                pageLimit: rowsPerPage,
                sortKey: pagingFlag.current,
                sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc',
                period_start_date: startValue,
                period_end_date: endValue,
                buken_id: state.buken_id,
                is_belong: tabFlag ? 1 : 0
            }
            log(params)
            ApiFilemanageList(params).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    document.getElementById('father_Block').scrollTop = 0;
                    localStorage.setItem('file_keywordSearch', keywordSearch)
                    localStorage.setItem('file_startData', startValue)
                    localStorage.setItem('file_endData', endValue)
                    log(resData)
                    setRrrorOpen(false)
                    setCount(resData.count)
                    resData.data.map(item => {
                        item.goodsAndroom = item.setsubi_name
                        item.downloadIcon = 'downloadIcon'
                        item.uploadIcon = item.file_category == 1 ? 'uploadIcon' : ''
                        item.file_name = item.file_category == 2 ? item.file_name : item.file_name
                        item.file_type = item.file_category == 1 ? '---' : item.file_type
                        item.goodsAndroom = item.file_category == 1 ? '---' : item.goodsAndroom
                        item.file_size = item.file_category == 1 && item.file_size == '1KB' ? '---' : item.file_size
                        item.file_type_name = item.file_category == 1 ? 'file_folder' : item.file_category == 0 ? 'file_file' : 'file_in_folder'
                        item.openFlag = item.file_category == 1 ? 'close' : ''
                        item.file_display_flg = item.file_category == 2 ? 'none' : 'block'
                        item.checked = false
                    })
                    setBodyList([...resData.data])
                    if (successFlag === 'add') {
                        setSuccessContent('登録が完了しました。')
                        setSuccessOpen(true)
                    }
                    if (successFlag === 'delete') {
                        setSuccessContent('削除が完了しました。')
                        setSuccessOpen(true)
                    }
                    if (successFlag === 'update') {
                        setSuccessContent('更新が完了しました。')
                        setSuccessOpen(true)
                    }
                    setDeleteData([])
                    setIndeterminate(false)
                    setCheckAll(false)
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }



    // title menu content
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list
    const [titleMenuList, setTitleMenuList] = useState(routerTitleList)
    function routerTitleList() {
        var items = []
        if (state.buken_shubetsu === 0) {
            items = [
                { name: '物件情報', width: 130, checked: false },
                { name: '部屋情報', width: 120, checked: false },
                { name: 'メンテナンス', width: 130, checked: false },
                { name: '設備記録', width: 120, checked: false },
                { name: '営業記録', width: 120, checked: false },
                { name: 'クレーム記録', width: 130, checked: false },
                { name: '修繕記録', width: 120, checked: false },
                { name: 'ファイル', width: 122, checked: true }
            ]
        } else {
            items = [
                { name: 'コンテナマスタ', width: 142, checked: false },
                { name: 'メンテナンス', width: 142, checked: false },
                { name: '設備記録', width: 142, checked: false },
                { name: '営業記録', width: 142, checked: false },
                { name: 'クレーム記録', width: 142, checked: false },
                { name: '修繕記録', width: 142, checked: false },
                { name: 'ファイル', width: 142, checked: true }
            ]
        }

        return items
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list click
    const selectTitleItem = (content) => {
        log(content)
        if (content.name == "物件情報" || content.name == "コンテナマスタ") {
            navigate(routerNames.PropertyDetail, { state: state })
        } else if (content.name == "部屋情報") {
            navigate(routerNames.PropertyDetail_equipment_list, { state: state })
        } else if (content.name == "営業記録") {
            navigate(routerNames.PropertyDetail_business, { state: state })
        } else if (content.name == "修繕記録") {
            navigate(routerNames.PropertyDetail_repair, { state: state })
        } else if (content.name == "クレーム記録") {
            navigate(routerNames.PropertyDetail_claim, { state: state })
        } else if (content.name == "メンテナンス") {
            navigate(routerNames.PropertyDetail_calendar, { state: state })
        } else if (content.name == "設備記録") {
            navigate(routerNames.PropertyDetail_equipment_records, { state: state })
        }
    }


    // 検索
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  キーワード input
    const [keywordSearch, setKeywordSearch] = useState(localStorage.getItem('file_keywordSearch') == null || localStorage.getItem('file_keywordSearch') == undefined ? '' : localStorage.getItem('file_keywordSearch'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  開始日
    const [startDate, setStartDate] = useState(localStorage.getItem('file_startData') ? dayjs(localStorage.getItem('file_startData')) : '')
    const [startValue, setStartValue] = useState(localStorage.getItem('file_startData') ? localStorage.getItem('file_startData') : '');
    const changeStartDate = (value, dateString) => {
        setStartDate(value)
        setStartValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  終了日
    const [endDate, setEndDate] = useState(localStorage.getItem('file_endData') ? dayjs(localStorage.getItem('file_endData')) : '')
    const [endValue, setEndValue] = useState(localStorage.getItem('file_endData') ? localStorage.getItem('file_endData') : '');
    const changeEndDate = (value, dateString) => {
        setEndDate(value)
        setEndValue(dateString);
        log(dateString)
    };



    // Common_checkBoxTable
    const [titleList, setTitleList] = useState([
        { titleIcon: '↓', name: 'フォルダ名 / ファイル名', field: 'file_name', width: '32%', canClick: true },
        { titleIcon: '↓', name: 'アップロード', field: 'upload_date', width: '12%' },
        { titleIcon: '', name: '物件 / 部屋', field: 'goodsAndroom', width: '13%' },
        { titleIcon: '', name: 'ダウンロード', field: 'downloadIcon', width: '12%', center: 'center' },
        { titleIcon: '↓', name: '形式', field: 'file_type', width: '7%' },
        { titleIcon: '↓', name: 'サイズ', field: 'file_size', width: '10%' },
        { titleIcon: '', name: '\u00A0フォルダへ\nアップロード', field: 'uploadIcon', width: '12%', center: 'center' }
    ])
    const [bodyList, setBodyList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete
    const [deleteData, setDeleteData] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  sort
    const [count, setCount] = useState(100)
    const pagingFlag = useRef('file_name')
    const pagingDirection = useRef('↓')
    const toSort = (item) => {
        log(item)
        pagingFlag.current = item.field
        pagingDirection.current = item.titleIcon
        titleList.map(titleItem => {
            if (titleItem.name === item.name) {
                titleItem.titleIcon = item.titleIcon === '↓' ? '↑' : '↓'
                titleItem.checked = true
            } else {
                titleItem.checked = false
            }
        })
        setTitleList([...titleList])
        getList()
    }
    const [isPreviewImgOpen, setIsPreviewImgOpen] = useState(false);
    const [previewImgUrl, setPreviewImgUrl] = useState(null);
    const [previewPdfUrl, setPreviewPdfUrl] = useState(null);

    const isPreviewImgOnClose = () => {
        setIsPreviewImgOpen(false)
    }
    const [isPreviewPdfOpen, setIsPreviewPdfOpen] = useState(false);
    const isPreviewPdfOnClose = () => {
        setIsPreviewPdfOpen(false)
    }
    const getItemData = (itemData) => {
        if (itemData.file_category !== 1) {
            if (itemData.file_type == "jpg" || itemData.file_type == "png") {
                downlaodFile(itemData, "jpg")
            } else if (itemData.file_type == "pdf") {
                downlaodFile(itemData, "pdf")
            }
        } else {
            bodyList.map(item => {
                if (itemData.file_manage_id == item.file_manage_id) {
                    if (item.openFlag == 'open') {
                        item.openFlag = 'close'
                    } else {
                        item.openFlag = 'open'
                    }
                }
                if (item.parent_file_manage_id == itemData.file_manage_id) {
                    if (item.file_display_flg == 'block') {
                        item.file_display_flg = 'none'
                    } else {
                        item.file_display_flg = 'block'
                    }
                }
            })
            setBodyList([...bodyList])
        }
    }

    const [folderNameItem, setFolderNameItem] = useState()
    const changeFolderName = (item) => {
        if (item.tenant_id == localStorage.getItem('tenant_id')) {
            setFolderNameItem(item)
            openUpdateFolderDialog()
        }
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('file_rowsPerPage') ? Number(localStorage.getItem('file_rowsPerPage')) : 50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setCurrentPage(newPage)
    };
    const handleChangePageForPagination = (event, newPage) => {
        const currentPage = newPage - 1
        setPage(currentPage)
        setCurrentPage(currentPage)
    };
    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('file_rowsPerPage', String(event.target.value))
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);



    // dialog
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const [downloadData, setDownloadData] = useState([])
    const openDeleteDialog = () => {
        let newDeleteData = []
        deleteData.map(item => {
            if (item.tenant_id == localStorage.getItem('tenant_id') || item.file_category == 1) {
                newDeleteData.push(item)
            }
        })
        let deleteFlag = false
        newDeleteData.map(item => {
            if (item.tenant_id == localStorage.getItem('tenant_id')) {
                deleteFlag = true
            }
        })
        if (deleteFlag) {
            setDownloadData([...newDeleteData])
        } else {
            setDownloadData([])
        }
        setDeleteOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  upload dialog
    const [uploadOpen, setUploadOpen] = useState(false)
    const uploadOnClose = () => {
        setUploadOpen(false)
    }
    const openUploadDialog = () => {
        setUploadOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  folder add dialog
    const [addFolderOpen, setAddFolderOpen] = useState(false)
    const addFolderClose = () => {
        setAddFolderOpen(false)
    }
    const openAddFolderDialog = () => {
        setAddFolderOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  folder update dialog
    const [updateFolderOpen, setUpdateFolderOpen] = useState(false)
    const updateFolderClose = () => {
        setUpdateFolderOpen(false)
    }
    const openUpdateFolderDialog = () => {
        setUpdateFolderOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  file move dialog
    const [moveOpen, setMoveOpen] = useState(false)
    const moveClose = () => {
        setMoveOpen(false)
    }
    const openMoveDialog = () => {
        setMoveOpen(true)
    }



    const getUrlname = (url) => {
        url = url.split('?')[0];
        var urlSlashCount = url.split('/').length;
        return url.split('/')[urlSlashCount - 1];
    };
    // download
    const downlaodFile = (item, preview) => {
        log(item)
        let param = {
            file_manage_id_list: String(item.file_manage_id),
            download_type: 1
        }
        log(param)
        ApiFilemanageDownload(param).then(res => {
            let resData = res.data
            if (res.status === 200) {
                log(resData)
                let downloadName = getUrlname(decodeURIComponent(resData.data[0].file_url))
                log(downloadName)

                if (!preview) {
                    fetch(resData.data[0].file_url, {
                        method: 'get',
                        responseType: 'blob'
                    }).then(res => {
                        // 获取blob文件流
                        return res.blob();
                    }).then(blob => {
                        let a = document.createElement('a');
                        // 通过 blob 对象获取对应的 url
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = downloadName;
                        a.click();
                        a.remove();
                    })
                } else {
                    if (preview != 'jpeg' && preview != 'png' && preview != 'pdf') {
                        return
                    } else {
                        if (preview == "png" || preview == "jpeg") {
                            setPreviewImgUrl(resData.data[0].file_url)
                            setIsPreviewImgOpen(true)
                        } else {
                            setPreviewPdfUrl(resData.data[0].file_url)
                            setIsPreviewPdfOpen(true)
                        }
                    }
                }

            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // 显示loading
    function showLoading() {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.createRoot(dom).render(
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
                <div style={{ fontWeight: "bold", color: '#DDD' }}>ダウンロード準備中</div>
            </div>
        )
    }
    // 隐藏loading
    function hideLoading() {
        document.body.removeChild(document.getElementById('loading'))
    }
    // 一括ダウンロード
    const batchDownload = () => {
        log(deleteData)
        let newId = ''
        deleteData.map(item => {
            newId = newId + String(item.file_manage_id) + ','
        })
        let param = {
            file_manage_id_list: newId.slice(0, -1),
            download_type: 0
        }
        log(param)
        ApiFilemanageDownload(param).then(res => {
            let resData = res.data
            if (res.status === 200) {
                log(resData)
                let urlList = []
                resData.data.map(item => {
                    urlList.push(item.file_url)
                })
                if (resData.data.length == 1) {
                    showLoading()
                    let downloadName = getUrlname(decodeURIComponent(resData.data[0].file_url))
                    fetch(resData.data[0].file_url, {
                        method: 'get',
                        responseType: 'blob'
                    }).then(res => {
                        // 获取blob文件流
                        return res.blob();
                    }).then(blob => {
                        hideLoading()
                        let a = document.createElement('a');
                        // 通过 blob 对象获取对应的 url
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = downloadName;
                        a.click();
                        a.remove();
                    })
                } else {
                    showLoading()
                    let index = 0
                    urlList.map(item => {
                        let downloadName = getUrlname(decodeURIComponent(item))
                        fetch(item, {
                            method: 'get',
                            responseType: 'blob'
                        }).then(res => {
                            // 获取blob文件流
                            return res.blob();
                        }).then(blob => {
                            index = index + 1
                            if (index == urlList.length) {
                                hideLoading()
                            }
                            let a = document.createElement('a');
                            // 通过 blob 对象获取对应的 url
                            let url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.download = downloadName;
                            a.click();
                            a.remove();
                        })
                    })
                }
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // tab button
    const [tabFlag, setTabFlag] = useState((authority_id == 3 || authority_id == 4) ? false : true)

    // get list
    useEffect(() => {
        getList()
    }, [page, rowsPerPage, tabFlag])


    useEffect(() => {
        window.addEventListener('keydown', enter);
        return () => {
            window.removeEventListener('keydown', enter);
        };
    }, [keywordSearch])
    const enter = (e) => {
        switch (e.keyCode) {
            case 13:
                getList()
                break;
        }
    }


    const [uploadFile_manage_id, setUploadFile_manage_id] = useState(0)
    const [uploadFile_Folder_name, setUploadFile_Folder_name] = useState('')

    // upload to file
    const uploadToFolder = (item) => {
        log(item)
        setUploadFile_manage_id(item.file_manage_id)
        setUploadFile_Folder_name(item.file_name)
        openUploadDialog()
    }



    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { navigate(routerNames.Property) }}>物件一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>物件詳細</div>
            <div className={classes.tableTitle}>
                <MenuHeader_property />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件詳細</div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            <div className={classes.objectNameBlock} style={{ draggable: true }}>
                <div>物件名</div>
                <div>{state.genba_name}</div>
            </div>
            <div className={classes.titleMenu}>
                {titleMenuList.map((item, index) => {
                    return (
                        <div
                            className={classes.menuContent}
                            key={index}
                            style={{ width: VW(item.width), backgroundColor: item.checked ? '#E0ECFF' : '', fontWeight: item.checked ? 'bold' : '' }}
                            onClick={() => { selectTitleItem(item) }}
                        >{item.name}</div>
                    )
                })}
            </div>
            <div style={{ marginTop: VH(12) }}>
                <div className={classes.retrievalBlock}>
                    <div className={classes.inputTitle}>キーワード</div>
                    <Input
                        placeholder='キーワードを入力してください'
                        value={keywordSearch}
                        style={{
                            // width: VW(874),
                            height: VH(40),
                            fontSize: VW(16)
                        }}
                        maxLength={200}
                        onChange={(event) => { setKeywordSearch(event.target.value) }}
                        suffix={
                            (
                                <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                    setKeywordSearch('')
                                }}></ClearRounded>
                            )
                        }
                    />
                </div>
                <div className={classes.retrievalBlock} style={{ paddingBottom: VH(16) }}>
                    <div className={classes.inputTitle}>&emsp;期間</div>
                    <DatePicker
                        style={{ width: VW(176), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        value={startDate}
                        format="YYYY/MM/DD"
                        placeholder="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeStartDate}
                    />
                    <div style={{ marginLeft: VW(16), marginRight: VW(16) }}>〜</div>
                    <DatePicker
                        style={{ width: VW(176), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        value={endDate}
                        format="YYYY/MM/DD"
                        placeholder="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeEndDate}
                    />
                    <Button type="primary" icon={<SearchIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={getList} style={{ position: 'absolute', right: VW(16), fontWeight: 'bold' }}>検索</Button>
                </div>
            </div>
            <div className={classes.AddDeleteBlock}>
                <Button style={{ padding: 0 }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} disabled={deleteData.length === 0} onClick={openDeleteDialog}>削除</Button>
                <Button style={{ padding: 0 }} icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openAddFolderDialog}>フォルダ作成</Button>
                <Button icon={<MoveIcon color={deleteData.length == 0 ? 'rgba(0, 0, 0, 0.25)' : ''} />} onClick={openMoveDialog} disabled={deleteData.length == 0}
                    style={{
                        padding: 0,
                        color: deleteData.length == 0 ? 'gray' : '',
                        backgroundColor: deleteData.length == 0 ? 'rgb(221, 221, 221)' : '',
                        border: deleteData.length == 0 ? 0 : '',
                        color: deleteData.length == 0 ? 'rgba(0, 0, 0, 0.25)' : ''
                    }}
                ><span style={{ marginLeft: VW(8) }}>フォルダ移動</span></Button>
                <Button style={{ padding: 0 }} type="primary" icon={<UploadIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openUploadDialog}>アップロード</Button>
                <Button style={{ padding: 0, background: deleteData.length == 0 ? '#DDD' : '' }} type="primary" icon={<DownloadIcon style={{ width: VW(24), height: VH(24), marginRight: VW(1) }} />} disabled={deleteData.length == 0} onClick={batchDownload}>一括ダウンロード</Button>
            </div>
            {(authority_id == 3 || authority_id == 4) &&
                <div className={classes.tabButton}>
                    <div
                        style={{
                            backgroundColor: !tabFlag ? '#E0ECFF' : '#E5E5E5',
                            color: !tabFlag ? '#0052CC' : '#AAA',
                            border: !tabFlag ? '1px solid #0052CC' : '1px solid #E5E5E5',
                            borderRadius: '4px 0 0 4px'
                        }} onClick={() => { setTabFlag(false) }}>自 社</div>
                    <div
                        style={{
                            backgroundColor: tabFlag ? '#FFE0E5' : '#E5E5E5',
                            color: tabFlag ? '#E70012' : '#AAA',
                            border: tabFlag ? '1px solid #E70012' : '1px solid #E5E5E5',
                            borderRadius: '0 4px 4px 0'
                        }} onClick={() => { setTabFlag(true) }}>
                        {!tabFlag ? <Property_detail_tab_false /> : <Property_detail_tab_true />}
                        <span style={{ marginLeft: VW(8) }}>パパネッツ</span></div>
                </div>
            }
            <TablePagination
                style={{ fontSize: VW(12) }}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
                labelRowsPerPage='表示件数:'
                className={classes.selectLabel}
                classes={{
                    toolbar: classes.toolbar
                }}
            />
            <div className={classes.tableBlock}>
                <Common_checkBoxTable
                    titleList={titleList}
                    bodyList={bodyList}
                    setBodyList={setBodyList}
                    setCheckList={setDeleteData}
                    toSort={toSort}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                    downlaodFile={downlaodFile}
                    getItemDataAgain={getItemData}
                    getItemData={() => { return }}
                    uploadToFolder={uploadToFolder}
                    pageFlag='property_detail_file'
                    changeFolderName={changeFolderName}
                />
            </div>
            <PropertyDetail_file_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={downloadData}
                setDeleteData={setDownloadData}
                getList={getList}
                setIndeterminate={setIndeterminate}
                setCheckAll={setCheckAll}
            />
            <PropertyDetail_file_upload
                open={uploadOpen}
                onClose={uploadOnClose}
                getList={getList}
                state={state}
                tabFlag={tabFlag}
                uploadFile_manage_id={uploadFile_manage_id}
                setUploadFile_manage_id={setUploadFile_manage_id}
                uploadFile_Folder_name={uploadFile_Folder_name}
                setUploadFile_Folder_name={setUploadFile_Folder_name}
            />
            <PropertyDetail_file_folder_add
                open={addFolderOpen}
                onClose={addFolderClose}
                getList={getList}
                state={state}
                tabFlag={tabFlag}
            />
            <PropertyDetail_file_folder_update
                open={updateFolderOpen}
                onClose={updateFolderClose}
                getList={getList}
                state={state}
                tabFlag={tabFlag}
                folderNameItem={folderNameItem}
            />
            <PropertyDetail_file_move
                open={moveOpen}
                onClose={moveClose}
                getList={getList}
                state={state}
                tabFlag={tabFlag}
                moveList={deleteData}
            />
            <PropertyDetail_file_preview_img
                open={isPreviewImgOpen}
                onClose={isPreviewImgOnClose}
                url={previewImgUrl}
            />
            <PropertyDetail_file_preview_pdf
                open={isPreviewPdfOpen}
                onClose={isPreviewPdfOnClose}
                url={previewPdfUrl}
            />
            <Common_pagenation
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChange={handleChangePageForPagination}
            />
        </div >
    )
}

PropertyDetail_file.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_file);