import React, { useState, useEffect } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, Select, DatePicker } from 'antd';
import { VW, VH } from "../../componets/function/sizeAdapter";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    daysBlock: {
        widht: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            width: VW(68),
            height: VH(68),
            margin: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: VW(18)
        }
    }
});

function Month_Days_dialog(props) {
    const {
        classes,
        open,
        onClose,
        daysArray,
        dayYear,
        dayMonth,
        changeDay
    } = props

    useEffect(() => {
        if (open) {
            let date = dayMonth > 10 ? String(dayYear) + '/' + String(dayMonth) + '/' + '01' : String(dayYear) + '/' + '0' + String(dayMonth) + '/' + '01'
            setNullDays(Number(new Date(date).getDay()))
        }
    }, [open])

    const [nullDays, setNullDays] = useState(0)
    const [week, setWeek] = useState(['日', '月', '火', '水', '木', '金', '土'])


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(600)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{dayYear}年{dayMonth}月</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <div className={classes.daysBlock}>
                {week.map((item, index) => {
                    return (
                        <div key={index}>{item}</div>
                    )
                })}
            </div>
            <div className={classes.daysBlock}>
                {nullDays > 0 && Array.from({ length: nullDays }).map((item, index) => {
                    return (
                        <div key={index}></div>
                    )
                })}
                {daysArray.length > 0 && daysArray.map((item, index) => {
                    return (
                        <div
                            key={index}
                            style={{ cursor: 'pointer', border: item.checked ? '2px solid #0052CC' : '' }}
                            onClick={() => { changeDay(index) }}
                        >{item.name}</div>
                    )
                })}
            </div>
        </Modal >
    )
}

Month_Days_dialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Month_Days_dialog);