import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../../componets/function/sizeAdapter"


const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
        '& > div': {
            height: 'auto',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
        },
        '& > div > div': {
            minHeight: VH(74),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16),
            wordBreak: 'break-all'
        },
        '& > div > div:nth-child(1)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div > div:nth-child(2)': {
            width: '84%'
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
            whiteSpace: 'pre-line',
        },
    }
});

function OwnersListDetail_item(props) {
    const {
        classes,
        detailedData,
    } = props





    return (
        <div className={classes.contentBlock}>
            <div>
                <div>管理番号など</div>
                <div>{detailedData.control_num}</div>
            </div>
            <div>
                <div>個人 / 法人</div>
                <div>{detailedData.corporation}</div>
            </div>
            <div>
                <div>オーナー名</div>
                <div>{detailedData.owner_name}</div>
            </div>
            <div>
                <div>電話番号</div>
                <div>{detailedData.tel}</div>
            </div>
            <div>
                <div>メールアドレス</div>
                <div>{detailedData.email}</div>
            </div>
            <div>
                <div>郵便番号</div>
                <div>〒 {detailedData.postal}</div>
            </div>
            <div>
                <div>所在地</div>
                <div>{detailedData.address}</div>
            </div>
            <div>
                <div>備考</div>
                <div>
                    <div style={{ width: '100%', maxHeight: VH(74), overflowY: "scroll" }}>{detailedData.remarks}</div>
                </div>
            </div>
        </div>
    )
}

OwnersListDetail_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(OwnersListDetail_item);