
import React from "react";
import { Routes, Route } from "react-router-dom"
import {
    Box
} from "@mui/material";
import { withStyles } from "@mui/styles";
import DashBoard from "../home/dashBoard/DashBoard";
import Property from "../home/property/Property";
import RealEstate from "../home/realEstate/RealEstate";
import RealEstateDetail from "../home/realEstate/RealEstateDetail";
import OwnersList from '../home/ownersList/OwnersList';
import OwnersListDetail from "../home/ownersList/OwnersListDetail";
import Notification from "../home/notification/Notification";
import NotificationManagement from "../home/notification_management/NotificationManagement";
import PropertyDetail from "../home/property/PropertyDetail";
import Tag from "../home/tag/Tag";
import TagDetail from "../home/tag/TagDetail";
import NotificationManagementDetail from "../home/notification_management/NotificationManagementDetail";
import PropertyDetail_business from "../home/property/PropertyDetail_business";
import PropertyDetail_business_detail from "../home/property/PropertyDetail_business_detail";
import PropertyDetail_equipment from "../home/property/PropertyDetail_equipment";
import PropertyDetail_claim from "../home/property/PropertyDetail_claim";
import PropertyDetail_claim_detail from "../home/property/PropertyDetail_claim_detail";
import PropertyDetail_repair from "../home/property/PropertyDetail_repair";
import PropertyDetail_repair_detail from "../home/property/PropertyDetail_repair_detail";
import PropertyDetail_calendar from "../home/property/PropertyDetail_calendar";
import Member from "../home/member/Member";
import MemberDetail from "../home/member/MemberDetail";
import PropertyDetail_equipment_records from "../home/property/PropertyDetail_equipment_records";
import PropertyDetail_equipment_records_detail from "../home/property/PropertyDetail_equipment_records_detail";
import PersonalData from "../home/personalData/PersonalData";
import PersonalDataDetail from "../home/personalData/PersonalDataDetail";
import PropertyDetail_file from "../home/property/PropertyDetail_file";
import PropertyDetail_equipment_list from "../home/property/PropertyDetail_equipment_list";
import ReminderManagement from "../home/reminder_management/ReminderManagement";
import ReminderManagementDetail from "../home/reminder_management/ReminderManagementDetail";
import InspectionList from "../home/inspectionList/InspectionList";
import InspectionListDetail from "../home/inspectionList/InspectionListDetail";

const styles = (theme) => ({

});

function MainRouters(props) {
    const {
        classes,
        setSelectFlag,
        setObject
    } = props



    return (
        <Routes>
            <Route path="*" element={<DashBoard setSelectFlag={setSelectFlag} />} />
            <Route path="DashBoard" element={<DashBoard setSelectFlag={setSelectFlag} />} />
            <Route path="personalData" element={<PersonalData setSelectFlag={setSelectFlag} setObject={setObject} />} />
            <Route path="personalData/personalDataDetail" element={<PersonalDataDetail setSelectFlag={setSelectFlag} />} />
            <Route path="Property" element={<Property setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail" element={<PropertyDetail setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_equipment_list" element={<PropertyDetail_equipment_list setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_equipment" element={<PropertyDetail_equipment setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_business" element={<PropertyDetail_business setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_calendar" element={<PropertyDetail_calendar setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_business/PropertyDetail_business_detail" element={<PropertyDetail_business_detail setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_claim" element={<PropertyDetail_claim setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_claim/PropertyDetail_claim_detail" element={<PropertyDetail_claim_detail setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_repair" element={<PropertyDetail_repair setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_repair/PropertyDetail_repair_detail" element={<PropertyDetail_repair_detail setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_equipment_records" element={<PropertyDetail_equipment_records setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_equipment_records/PropertyDetail_equipment_records_detail" element={<PropertyDetail_equipment_records_detail setSelectFlag={setSelectFlag} />} />
            <Route path="Property/PropertyDetail_file" element={<PropertyDetail_file setSelectFlag={setSelectFlag} />} />
            <Route path="RealEstate" element={<RealEstate setSelectFlag={setSelectFlag} />} />
            <Route path="RealEstate/RealEstateDetail" element={<RealEstateDetail setSelectFlag={setSelectFlag} />} />
            <Route path="OwnersList" element={<OwnersList setSelectFlag={setSelectFlag} />} />
            <Route path="OwnersList/OwnersListDetail" element={<OwnersListDetail setSelectFlag={setSelectFlag} />} />
            <Route path="Tag" element={<Tag setSelectFlag={setSelectFlag} />} />
            <Route path="Tag/TagDetail" element={<TagDetail setSelectFlag={setSelectFlag} />} />
            <Route path="Member" element={<Member setSelectFlag={setSelectFlag} />} />
            <Route path="Member/MemberDetail" element={<MemberDetail setSelectFlag={setSelectFlag} />} />
            <Route path="Notification" element={<Notification setSelectFlag={setSelectFlag} />} />
            <Route path="NotificationManagement" element={<NotificationManagement setSelectFlag={setSelectFlag} />} />
            <Route path="NotificationManagement/NotificationManagementDetail" element={<NotificationManagementDetail setSelectFlag={setSelectFlag} />} />
            <Route path="ReminderManagement" element={<ReminderManagement setSelectFlag={setSelectFlag} />} />
            <Route path="ReminderManagement/ReminderManagementDetail" element={<ReminderManagementDetail setSelectFlag={setSelectFlag} />} />
            <Route path="InspectionList" element={<InspectionList setSelectFlag={setSelectFlag} />} />
            <Route path="InspectionList/InspectionListDetail" element={<InspectionListDetail setSelectFlag={setSelectFlag} />} />
        </Routes>
    )
}


MainRouters.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(MainRouters);