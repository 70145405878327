import React, { useState, useEffect, useRef } from "react";
import {
    Select,
    MenuItem,
    Checkbox
} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close, Flag } from "@mui/icons-material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { ApiSetsubiTypeList, ApiSetsubiTypeAssociationList, ApiSetsubiTypeAssociationUpdate } from '../../network/api'


dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    typeSelect: {
        marginTop: VH(13),
        width: '100%',
        height: VH(74),
        borderTop: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        display: 'flex',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: VW(16),
            height: '100%',
        },
        '& > div:nth-child(1)': {
            width: VW(110),
            backgroundColor: '#F8F8F8',
            fontSize: VW(16),
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: VW(1076),
        },
    },
    RoomBlock: {
        width: '100%',
        borderBottom: '1px solid #DDD',
        display: 'flex',
        fontSize: VW(16),
        overflowX: 'scroll',
    },
    rankColumn: {
        width: VW(110),
        backgroundColor: '#F2F7FF',
        '& > div': {
            width: VW(110),
            paddingLeft: VW(16)
        },
        '& > div:nth-child(1)': {
            height: VW(62),
            backgroundColor: '#E0ECFF',
            paddingTop: VH(20)
        },
        '& > div:not(:nth-child(1))': {
            height: VW(48),
            display: 'flex',
            alignItems: 'center',
        }
    },
    roomRow: {
        '& > div:nth-child(1)': {
            height: VW(62),
            display: 'flex',
            backgroundColor: '#F2F7FF',
        },
        '& > div:nth-child(1) > div': {
            flexShrink: 0,
            width: VW(120),
            height: VW(62),
            paddingTop: VH(20),
            paddingLeft: VW(16),
        },
        '& > div:nth-child(2)': {
            width: VW(896),
            position: 'relative',
        },
        '& > div:nth-child(2) > div': {
            width: VW(120),
            height: VW(48),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            position: 'absolute'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(280),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
});

function PropertyDetail_roomType_link_roomNumber(props) {
    const {
        classes,
        open,
        onClose,
        state,
        getList
    } = props


    useEffect(() => {
        if (!open) {
            setAllChecked(false)
            setUnsetCheckd(false)
            setRankTotal([])
            setRoomTotal([])
            setRoomList([])
            setUnset(false)
        } else {
            log(state)
            getRoomList()
        }
    }, [open])





    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')
    // 空白 rank
    const [rankBlank, setRankBlank] = useState(0)
    // 空白 room
    const [roomBlank, setRoomBlank] = useState(9)

    const [startData, setStartData] = useState([])


    // room type list
    const [roomTypeList, setRoomTypeList] = useState([])
    const [roomType, setRoomType] = useState('0')
    const selectRoomType = (e) => {
        setRoomType(e.target.value);
        roomList.map(item => {
            if (item.binding_id == e.target.value) {
                item.checked = true
                item.disabled = false
            } else if (item.binding_id != e.target.value && item.binding_id) {
                item.checked = false
                item.disabled = true
            } else if (item.binding_id != e.target.value && !item.binding_id) {
                item.checked = false
                item.disabled = false
            }
        })
        setRoomList([...roomList])
        setAllChecked(false)
        rankTotal.map(item => {
            item.checked = false
        })
        setRankTotal([...rankTotal])
        roomTotal.map(item => {
            item.checked = false
        })
        setRoomTotal([...roomTotal])
        setUnsetCheckd(false)
    }

    // checkBox
    // 一括
    const [allCheckd, setAllChecked] = useState(false)
    const allChange = (event) => {
        setAllChecked(event.target.checked);
        roomList.map(item => {
            if (!item.disabled) {
                item.checked = event.target.checked
                if (event.target.checked) {
                    item.binding_id = roomType
                } else {
                    item.binding_id = null
                }
            }
        })
        setRoomList([...roomList])
        rankTotal.map(item => {
            item.checked = event.target.checked
        })
        setRankTotal([...rankTotal])
        roomTotal.map(item => {
            item.checked = event.target.checked
        })
        setRoomTotal([...roomTotal])
        setUnsetCheckd(event.target.checked)
    }
    // 階数
    const [rankTotal, setRankTotal] = useState([])
    const changeRank = (event, index) => {
        log(event.target.checked)
        log(index)
        rankTotal[index].checked = event.target.checked
        setRankTotal([...rankTotal])
        roomList.map(item => {
            if (!item.disabled && (item.rank == (index + 1))) {
                item.checked = event.target.checked
                if (event.target.checked) {
                    item.binding_id = roomType
                } else {
                    item.binding_id = null
                }
            }
        })
        setRoomList([...roomList])
    }
    // 号室
    const [roomTotal, setRoomTotal] = useState([])
    const changeRoom = (event, index) => {
        log(event.target.checked)
        log(index)
        roomTotal[index].checked = event.target.checked
        setRoomTotal([...roomTotal])
        roomList.map(item => {
            if (!item.disabled && (item.chamber == (index + 1))) {
                item.checked = event.target.checked
                if (event.target.checked) {
                    item.binding_id = roomType
                } else {
                    item.binding_id = null
                }
            }
        })
        setRoomList([...roomList])
    }
    // 未設定
    const [unset, setUnset] = useState(false)
    const [unsetCheckd, setUnsetCheckd] = useState(false)
    const changeUnsetCheckd = (event) => {
        setUnsetCheckd(event.target.checked)
        roomList.map(item => {
            if (!item.disabled && item.is_set) {
                item.checked = event.target.checked
                if (event.target.checked) {
                    item.binding_id = roomType
                } else {
                    item.binding_id = null
                }
            }
        })
        setRoomList([...roomList])
    }




    // room number
    const [roomList, setRoomList] = useState([])
    const changeRoomItem = (event, index) => {
        log(event.target.checked)
        log(index)
        roomList[index].checked = event.target.checked
        if (event.target.checked) {
            roomList[index].binding_id = roomType
        } else {
            roomList[index].binding_id = null
        }
        setRoomList([...roomList])
    }


    // get list api
    const getRoomList = () => {
        let param = {
            buken_id: state.buken_id,
        }
        log(param)
        ApiSetsubiTypeList(param).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                log(res.data)
                setRoomTypeList(res.data.data)
                setRoomType(res.data.data[0].setsubi_id)
                ApiSetsubiTypeAssociationList(param).then(resRoom => {
                    if (resRoom.status === 200) {
                        setRrrorOpen(false)
                        log(resRoom.data.data)
                        let resData = resRoom.data.data
                        setStartData(JSON.parse(JSON.stringify(resData.result)))
                        // 未設定
                        if (resData.not_set_status > 0) {
                            setUnset(true)
                        } else {
                            setUnset(false)
                        }
                        // rank list
                        if (resData.max_rank > 0) {
                            let newRankTotal = []
                            Array.from({ length: resData.max_rank }).map((item, index) => {
                                newRankTotal.push({ rank: `${index + 1}階`, checked: false })
                            })
                            setRankTotal(newRankTotal)
                        } else {
                            setRankTotal([])
                        }
                        // room list
                        if (resData.max_room > 0) {
                            let newRoomTotal = []
                            Array.from({ length: resData.max_room }).map((item, index) => {
                                newRoomTotal.push({ room: `${index + 1}号室`, checked: false })
                            })
                            setRoomTotal(newRoomTotal)
                        } else {
                            setRoomTotal([])
                        }
                        // room link rank list
                        resData.result.map(item => {
                            if (item.binding_id == res.data.data[0].setsubi_id) {
                                item.checked = true
                                item.disabled = false
                            } else if (item.binding_id != res.data.data[0].setsubi_id && item.binding_id) {
                                item.checked = false
                                item.disabled = true
                            } else if (item.binding_id != res.data.data[0].setsubi_id && !item.binding_id) {
                                item.checked = false
                                item.disabled = false
                            }
                        })
                        log(roomType)
                        log(resData.result)
                        setRoomList([...resData.result])
                        // blank rank
                        let maxRank = 0
                        resData.result.map(item => {
                            if (item.rank > maxRank) {
                                maxRank = item.rank
                            }
                        })
                        log(maxRank)
                        if (maxRank > resData.max_rank && resData.not_set_status == 0) {
                            setRankBlank(maxRank - resData.max_rank)
                        } else if (maxRank > resData.max_rank && resData.not_set_status != 0) {
                            setRankBlank(maxRank - resData.max_rank - 1)
                        }
                        log(rankBlank)
                        // blank room
                        let maxRoom = 0
                        resData.result.map(item => {
                            if (item.chamber > maxRoom) {
                                maxRoom = item.chamber
                            }
                        })
                        log(maxRoom)
                        if (resData.max_room != undefined && resData.max_room != null && maxRoom > resData.max_room) {
                            setRoomBlank(maxRoom - resData.max_room)
                        }
                        log(roomBlank)
                    } else if (resRoom.status >= 300 && resRoom.status < 500 && resRoom.status != 401) {
                        log(resRoom)
                        setRrrorOpen(true)
                        setErrorContent(resRoom.data.detail.toString())
                    }
                }).catch(err => {

                })
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    // 保存 api
    const toUpdate = () => {
        let newData = {}
        newData.buken_id = state.buken_id
        newData.result = startData
        newData.update_result = roomList
        let param = {
            data: newData
        }
        log(param)
        ApiSetsubiTypeAssociationUpdate(param).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                getList('save')
                onClose()
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1236)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>部屋と設備の紐付け</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.typeSelect}>
                    <div>部屋タイプ</div>
                    <div>
                        <Select
                            style={{ width: '100%', height: VH(50), fontSize: VW(16), borderColor: '#DDD' }}
                            value={roomType}
                            onChange={selectRoomType}
                        >
                            {roomTypeList.map((item, index) => {
                                return (
                                    <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.setsubi_id}>{item.room_topic}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className={classes.RoomBlock}>
                    <div className={classes.rankColumn}>
                        <div>
                            <FormControlLabel
                                label="一括"
                                control={<Checkbox style={{ paddingTop: 0, paddingLeft: 8, paddingBottom: 0, paddingRight: 8 }} checked={allCheckd} onChange={allChange} />}
                            />
                        </div>
                        {rankTotal.length > 0 && rankTotal.map((item, index) => {
                            return (
                                <div key={index}>
                                    <FormControlLabel
                                        label={item.rank}
                                        control={<Checkbox style={{ padding: 8 }} checked={item.checked} onChange={(event) => { changeRank(event, index) }} />}
                                    />
                                </div>
                            )
                        })}
                        {unset &&
                            <div>
                                <FormControlLabel
                                    label='未設定'
                                    control={<Checkbox style={{ padding: 8 }} checked={unsetCheckd} onChange={(event) => { changeUnsetCheckd(event) }} />}
                                />
                            </div>
                        }
                        {rankBlank > 0 && Array.from({ length: rankBlank }).map((item, index) => {
                            return (
                                <div key={index}></div>
                            )
                        })}
                    </div>
                    <div className={classes.roomRow}>
                        <div>
                            {roomTotal.length > 0 && roomTotal.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <FormControlLabel
                                            label={item.room}
                                            control={<Checkbox style={{ padding: 2 }} checked={item.checked} onChange={(event) => { changeRoom(event, index) }} />}
                                        />
                                    </div>
                                )
                            })}
                            {roomBlank > 0 && Array.from({ length: roomBlank }).map((item, index) => {
                                return (
                                    <div key={index}></div>
                                )
                            })}
                        </div>
                        <div>
                            {roomList.length > 0 && roomList.map((item, index) => {
                                return (
                                    <div key={index} style={{ top: (item.rank - 1) * 48, left: (item.chamber - 1) * 120 }}>
                                        <FormControlLabel
                                            disabled={item.disabled}
                                            label={item.room_num}
                                            control={<Checkbox style={{ padding: 2 }} checked={item.checked} onChange={(event) => { changeRoomItem(event, index) }} />}
                                        />
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={toUpdate}>保存</Button>
                </div>
            </div>
        </Modal>
    )
}

PropertyDetail_roomType_link_roomNumber.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_roomType_link_roomNumber);