import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { Button, Input } from 'antd'
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useLocation } from "react-router-dom";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import MenuHeader_character from "../../componets/icon/MenuHeader_character";
import OwnersListDetail_item from "../../componets/HtmlBody/OwnersListDetail_item";
import OwnersListDetail_input from "../../componets/HtmlBody/OwnersListDetail_input";
import { ApiOwnerUpdate, ApiOwnerDetail } from '../../network/api'
import OwnersList_delete from "../../componets/dialog/OwnersList_delete";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos } from "@mui/icons-material";

dayjs.extend(utc)

const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingRight: VW(24),
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
            paddingRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        },
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        marginTop: VH(16),
        justifyContent: 'flex-end',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button:nth-child(2)': {
            color: '#E60024',
        }
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function OwnersListDetail(props) {
    const {
        classes,
        setSelectFlag
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.OwnersList)
        log(state)
    }, [setSelectFlag])


    const location = useLocation();
    const { state } = location;


    const [updateFlag, setUpdateFlag] = useState(false)


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // detailed list data
    const [detailedData, setDetailedData] = useState(state)



    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 管理番号など
    const [number, setNumber] = useState(detailedData.control_num)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 個人 / 法人
    const [characterFlag, setCharacterFlag] = useState(detailedData.corporation ? detailedData.corporation : undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> オーナー名
    const [ownerName, setOwnerName] = useState(detailedData.owner_name)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 電話番号
    const [phone, setPhone] = useState(detailedData.tel)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> メールアドレス
    const [email, setEmail] = useState(detailedData.email)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 郵便番号
    const [zipCode, setZipCode] = useState(detailedData.postal)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 住所
    const [address, setAddress] = useState(detailedData.address)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 備考
    const [remarks, setRemarks] = useState(detailedData.remarks)


    // add api
    const toAdd = () => {
        document.getElementById('father_Block').scrollTop = 0;
        if (ownerName === '') {
            setErrorContent(errorMessage.ownerNameNull)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (email != '' && !formatCheck.Email.test(email)) {
            setErrorContent(errorMessage.emailFormatError)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (phone != '' && !formatCheck.Phone.test(phone)) {
            setErrorContent(errorMessage.phoneFormatError)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (zipCode != '' && !formatCheck.ZipCode.test(zipCode)) {
            setErrorContent(errorMessage.zipCodeFormatError)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else {
            let params = {
                owner_id: detailedData.owner_id,
                control_num: number,
                ind_corp_flag: characterFlag ? Number(characterFlag) : null,
                owner_name: ownerName,
                tel: phone,
                email: email,
                postal: zipCode,
                address: address,
                remarks: remarks
            }
            log(params)
            ApiOwnerUpdate(params).then(res => {
                if (res.status === 200) {
                    let detailParam = {
                        owner_id: detailedData.owner_id
                    }
                    ApiOwnerDetail(detailParam).then(resDetail => {
                        let detail = resDetail.data
                        if (resDetail.status == 200) {
                            log(detail)
                            detail.corporation = !detail.ind_corp_flag ? '' : detail.ind_corp_flag === 1 ? '個人' : '法人'
                            setDetailedData(detail)
                            setRrrorOpen(false)
                            setSuccessOpen(true)
                            setSuccessContent('更新が完了しました。')
                            setUpdateFlag(false)
                        } else if (resDetail.status >= 300 && resDetail.status < 500 && resDetail.status != 401) {
                            log(detail)
                            setSuccessOpen(false)
                            setRrrorOpen(true)
                            setErrorContent(resDetail.data.detail.toString())
                        }
                    }).catch(errDetail => {

                    })
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { window.history.back() }}>オーナー一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>オーナー情報</div>
            <div className={classes.tableTitle}>
                <MenuHeader_character />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{updateFlag ? 'オーナー情報の編集' : 'オーナー情報'}</div>
                <div className={classes.updateDeleteBlock}>
                    {!updateFlag &&
                        <>
                            <Button style={{ padding: 0, marginRight: VW(24) }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={() => { setUpdateFlag(true) }}>&nbsp;編集</Button>
                            <Button style={{ padding: 0 }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openDeleteDialog}>&nbsp;削除</Button>
                        </>
                    }
                </div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            {!updateFlag ?
                <OwnersListDetail_item
                    detailedData={detailedData}
                />
                :
                <OwnersListDetail_input
                    number={number}
                    setNumber={setNumber}
                    characterFlag={characterFlag}
                    setCharacterFlag={setCharacterFlag}
                    ownerName={ownerName}
                    setOwnerName={setOwnerName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    zipCode={zipCode}
                    setZipCode={setZipCode}
                    address={address}
                    setAddress={setAddress}
                    remarks={remarks}
                    setRemarks={setRemarks}
                />
            }
            <div className={classes.bottomButton}>
                {!updateFlag ?
                    <Button onClick={() => { window.history.back() }}>オーナー一覧へ戻る</Button>
                    :
                    <>
                        <Button onClick={() => { setUpdateFlag(false) }}>キャンセル</Button>
                        <Button onClick={toAdd}>更新</Button>
                    </>
                }
            </div>
            <OwnersList_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[detailedData]}
                historyFlag={true}
            />
        </div>
    )
}

OwnersListDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(OwnersListDetail);