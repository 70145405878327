import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import log from "../function/log";
import { ApiCompanyMemberActive } from '../../network/api'
import Common_errorAlert from "../common/Common_errorAlert";
import MenuHeader_character from "../icon/MenuHeader_character";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import MenuHeader_member from "../icon/MenuHeader_member";

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    headerBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        marginTop: VH(16),
        backgroundColor: '#F8F8F8',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: '100%',
            fontWeight: 'bold',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw'
        },
        '& > div:nth-child(1)': {
            width: '16.5%'
        },
        '& > div:nth-child(2)': {
            width: '32.5%'
        },
        '& > div:nth-child(3)': {
            width: '17%'
        },
        '& > div:nth-child(4)': {
            width: '14%'
        },
        '& > div:nth-child(5)': {
            width: '16%'
        },
    },
    scrollBlock: {
        width: '100%',
        maxHeight: VH(224),
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
    bodyBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDD',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw',
            overflow: 'hidden'
        },
        '& > div:nth-child(1)': {
            width: '16.5%'
        },
        '& > div:nth-child(2)': {
            width: '32.5%'
        },
        '& > div:nth-child(3)': {
            width: '17%'
        },
        '& > div:nth-child(4)': {
            width: '14%'
        },
        '& > div:nth-child(5)': {
            width: '16%'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: '#E60024',
            marginLeft: VW(16)
        }
    },
    buttonGroupOpen: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function Member_useState(props) {
    const {
        classes,
        open,
        onClose,
        useItem,
        getList,
    } = props


    useEffect(() => {
        if (open) {
            log(useItem)
        } else {
            setRrrorOpen(false)
        }
    }, [open])

    const authority_id = localStorage.getItem("authority_id")


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')




    // update api
    const toUpdate = () => {
        let param = {
            email: useItem.email
        }
        ApiCompanyMemberActive(param).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                getList('update')
                onClose()
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <MenuHeader_member />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>メンバーのアカウント停止 / 利用</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {
                useItem.is_active == 0
                    ?
                    <div style={{ color: '#E60024', fontSize: VW(16) }}>以下のメンバーのアカウントを停止します。</div>
                    :
                    <div style={{ fontSize: VW(16) }}>以下のメンバーのアカウントを再開します。</div>

            }
            <div className={classes.headerBlock}>
                <div>氏名</div>
                <div>メールアドレス</div>
                <div>所属</div>
                <div>権限</div>
                <div>ステータス</div>
            </div>
            <div className={classes.scrollBlock}>
                {(((authority_id == 1 || authority_id == 3) && useItem.authority_id != '5') || authority_id == 5) &&
                    <div className={classes.bodyBlock}>
                        <div>
                            <p>{useItem.allName}</p>
                        </div>
                        <div>
                            <p>{useItem.email}</p>
                        </div>
                        <div>
                            <p>{useItem.affiliation}</p>
                        </div>
                        <div>
                            <p>{useItem.authority_name}</p>
                        </div>
                        <div>
                            <p>{useItem.status_name}</p>
                        </div>
                    </div>
                }
            </div>
            {useItem.is_active == 0
                ?
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button danger onClick={toUpdate} disabled={(authority_id == 1 || authority_id == 3) && useItem.authority_id == '5'}>停止</Button>
                </div>
                :
                <div className={classes.buttonGroupOpen}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={toUpdate}
                        disabled={(authority_id == 1 || authority_id == 3) && useItem.authority_id == '5'}
                        style={{
                            backgroundColor: (authority_id == 1 || authority_id == 3) && useItem.authority_id == '5' ? 'rgba(0, 0, 0, 0.04)' : '',
                            color: (authority_id == 1 || authority_id == 3) && useItem.authority_id == '5' ? 'rgba(0, 0, 0, 0.25)' : ''
                        }}
                    >再開</Button>
                </div>
            }
        </Modal>
    )
}

Member_useState.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Member_useState);