import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_file_folder_open_arrow() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ float: 'left', marginRight: VW(8) }}>
            <path d="M7.41 8.58984L12 13.1698L16.59 8.58984L18 9.99984L12 15.9998L6 9.99984L7.41 8.58984Z" fill="#5B5B5B" />
        </svg>
    )
}
export default PropertyDetail_file_folder_open_arrow;


