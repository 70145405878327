import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_inspectionList(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" fill="none">
            <rect width="20" height="20" fill="white" />
            <path d="M7.49969 10.8333C9.04969 10.8333 10.3414 9.76667 10.7164 8.33333H4.28302C4.65802 9.76667 5.94969 10.8333 7.49969 10.8333Z" fill={color ? color : '#2F83FF'} />
            <path d="M3.94967 7.5H11.058C11.283 7.5 11.4663 7.31667 11.4663 7.09167V7.075C11.4663 6.85 11.283 6.66667 11.058 6.66667H10.833C10.833 5.43333 10.158 4.375 9.16634 3.79167V4.58333C9.16634 4.81667 8.98301 5 8.74967 5C8.51634 5 8.33301 4.81667 8.33301 4.58333V3.45C8.06634 3.38333 7.79134 3.33333 7.49967 3.33333C7.20801 3.33333 6.93301 3.38333 6.66634 3.45V4.58333C6.66634 4.81667 6.48301 5 6.24967 5C6.01634 5 5.83301 4.81667 5.83301 4.58333V3.79167C4.84134 4.375 4.16634 5.43333 4.16634 6.66667H3.94967C3.72467 6.66667 3.54134 6.85 3.54134 7.075V7.1C3.54134 7.31667 3.72467 7.5 3.94967 7.5Z" fill={color ? color : '#2F83FF'} />
            <path d="M16.5997 9.73333L16.183 9.00833C16.158 8.95833 16.0997 8.94167 16.058 8.95833L15.5413 9.16667C15.433 9.08333 15.3163 9.01667 15.1913 8.96667L15.1163 8.41667C15.0997 8.36667 15.0497 8.33333 14.9997 8.33333H14.1663C14.1163 8.33333 14.0747 8.36667 14.0663 8.425L13.9913 8.975C13.8663 9.025 13.7497 9.1 13.6413 9.175L13.1247 8.96667C13.0747 8.95 13.0247 8.96667 12.9997 9.01667L12.583 9.74167C12.558 9.79167 12.5663 9.84167 12.608 9.875L13.0497 10.2167C13.0413 10.2833 13.033 10.35 13.033 10.4167C13.033 10.4833 13.0413 10.5583 13.0497 10.6167L12.608 10.9583C12.5663 10.9917 12.558 11.05 12.583 11.0917L12.9997 11.8167C13.0247 11.8667 13.083 11.8833 13.1247 11.8667L13.6413 11.6583C13.7497 11.7417 13.8663 11.8083 13.9913 11.8583L14.0663 12.4083C14.0747 12.4667 14.1163 12.5 14.1663 12.5H14.9997C15.0497 12.5 15.0997 12.4667 15.0997 12.4083L15.1747 11.8583C15.2997 11.8083 15.4163 11.7333 15.5247 11.6583L16.0413 11.8667C16.0913 11.8833 16.1413 11.8667 16.1663 11.8167L16.583 11.0917C16.608 11.0417 16.5997 10.9917 16.558 10.9583L16.1247 10.6167C16.133 10.55 16.1413 10.4833 16.1413 10.4167C16.1413 10.35 16.133 10.275 16.1247 10.2167L16.5663 9.875C16.608 9.84167 16.6163 9.78333 16.5997 9.73333ZM14.583 11.1083C14.1997 11.1083 13.8913 10.7917 13.8913 10.4167C13.8913 10.0333 14.208 9.725 14.583 9.725C14.958 9.725 15.2747 10.0417 15.2747 10.4167C15.2747 10.8 14.9663 11.1083 14.583 11.1083Z" fill={color ? color : '#2F83FF'} />
            <path d="M18.4163 5.7C18.4247 5.60833 18.433 5.51667 18.433 5.41667C18.433 5.31667 18.4247 5.225 18.408 5.13333L19.0247 4.65C19.083 4.60833 19.0913 4.525 19.058 4.46667L18.4747 3.45833C18.4413 3.39167 18.358 3.375 18.2997 3.39167L17.583 3.68333C17.433 3.56667 17.2663 3.475 17.0913 3.4L16.983 2.625C16.9663 2.55 16.908 2.5 16.833 2.5H15.6663C15.5913 2.5 15.533 2.55 15.5247 2.625L15.4163 3.4C15.2413 3.475 15.0747 3.575 14.9247 3.68333L14.1997 3.39167C14.133 3.36667 14.058 3.39167 14.0247 3.45833L13.4413 4.46667C13.408 4.53333 13.4163 4.60833 13.4747 4.65L14.0913 5.13333C14.0747 5.225 14.0663 5.325 14.0663 5.41667C14.0663 5.50833 14.0747 5.60833 14.0913 5.7L13.4747 6.18333C13.4163 6.225 13.408 6.30833 13.4413 6.36667L14.0247 7.375C14.058 7.44167 14.1413 7.45833 14.1997 7.44167L14.9247 7.15C15.0747 7.26667 15.2413 7.35833 15.4163 7.43333L15.5247 8.20833C15.533 8.28333 15.5913 8.33333 15.6663 8.33333H16.833C16.908 8.33333 16.9663 8.28333 16.9747 8.20833L17.083 7.43333C17.258 7.35833 17.4247 7.25833 17.5747 7.15L18.2997 7.44167C18.3663 7.46667 18.4413 7.44167 18.4747 7.375L19.058 6.36667C19.0913 6.3 19.083 6.225 19.0247 6.18333L18.4163 5.7ZM16.2497 6.45833C15.6747 6.45833 15.208 5.99167 15.208 5.41667C15.208 4.84167 15.6747 4.375 16.2497 4.375C16.8247 4.375 17.2913 4.84167 17.2913 5.41667C17.2913 5.99167 16.8247 6.45833 16.2497 6.45833Z" fill={color ? color : '#2F83FF'} />
            <path d="M7.49967 12.5C5.27467 12.5 0.833008 13.6167 0.833008 15.8333V17.5H14.1663V15.8333C14.1663 13.6167 9.72467 12.5 7.49967 12.5Z" fill={color ? color : '#2F83FF'} />
        </svg>
    )
}

export default MenuHeader_inspectionList;