import React, { useState, useEffect } from "react";
import {
    Box
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuHeader_house from "../icon/MenuHeader_house";
import MenuHeader_character from "../icon/MenuHeader_character";
import MenuHeader_notification from "../icon/MenuHeader_notification";
import MenuHeader_notificationManagement from "../icon/MenuHeader_notificationManagement";
import { VW, VH } from "../function/sizeAdapter"
import MenuHeader_property from "../icon/MenuHeader_property";
import MenuHeader_tag from "../icon/MenuHeader_tag";
import MenuHeader_member from "../icon/MenuHeader_member";
import MenuHeader_reminderManagement from "../icon/MenuHeader_reminderManagement";
import MenuHeader_inspectionList from "../icon/MenuHeader_inspectionList";


const styles = (theme) => ({
    menuLink: {
        textDecoration: "none",
        color: 'black'
    },
    menuItem: {
        width: '100%',
        height: VH(56),
        display: 'flex',
        alignItems: 'center',
    },
    iconItem: {
        width: '100%',
        marginLeft: VW(16),
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(14),
        position: 'relative'
    }
});

function LeftMenu(props) {
    const {
        classes,
        selectFlag
    } = props

    // menu data
    const [menuItems, setMenuItems] = useState(authorityMenuItems)



    // menu change
    const changeMenu = (item, index) => {
        if (item.selectColor === '#F8FAFB') {
            return
        } else {
            menuItems.map(element => {
                element.selectColor = ''
                element.iconColor = '#AAAAAA'
            })
            for (var i = 0; i < menuItems.length; i++) {
                menuItems[index].selectColor = '#F8FAFB'
                menuItems[index].iconColor = '#0081FF'
            }
            setMenuItems([...menuItems])
        }
    }


    useEffect(() => {
        menuItems.map(element => {
            if (element.link === selectFlag) {
                element.selectColor = '#F8FAFB'
                element.iconColor = '#0081FF'
            } else {
                element.selectColor = ''
                element.iconColor = '#AAAAAA'
            }
        })
        setMenuItems([...menuItems])
    }, [selectFlag])

    function authorityMenuItems() {
        var authority_id = localStorage.getItem("authority_id")
        var items = []
        switch (authority_id) {
            case "1":
                items = [
                    // {
                    //     name:'ダッシュボード',
                    //     link:routerNames.DashBoard,
                    //     selectColor:selectFlag === routerNames.DashBoard ? '#F8FAFB' : '',
                    //     iconColor:selectFlag === routerNames.DashBoard ? '#0081FF' : '#AAAAAA',
                    // },
                    {
                        name: '通知',
                        link: routerNames.Notification,
                        selectColor: selectFlag === routerNames.Notification ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Notification ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: '不動産管理会社管理',
                        link: routerNames.RealEstate,
                        selectColor: selectFlag === routerNames.RealEstate ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.RealEstate ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: '物件管理',
                        link: routerNames.Property,
                        selectColor: selectFlag === routerNames.Property ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Property ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'メンバー管理',
                        link: routerNames.Member,
                        selectColor: selectFlag === routerNames.Member ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Member ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'タグ管理',
                        link: routerNames.Tag,
                        selectColor: selectFlag === routerNames.Tag ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Tag ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name: 'オーナー一覧',
                    //     link: routerNames.OwnersList,
                    //     selectColor: selectFlag === routerNames.OwnersList ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.OwnersList ? '#0081FF' : '#AAAAAA',
                    // },
                    // {
                    //     name: 'お知らせ管理',
                    //     link: routerNames.NotificationManagement,
                    //     selectColor: selectFlag === routerNames.NotificationManagement ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.NotificationManagement ? '#0081FF' : '#AAAAAA',
                    // }
                    {
                        name: 'リマインダー管理',
                        link: routerNames.ReminderManagement,
                        selectColor: selectFlag === routerNames.ReminderManagement ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.ReminderManagement ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name: '点検表マスタ管理',
                    //     link: routerNames.InspectionList,
                    //     selectColor: selectFlag === routerNames.InspectionList ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.InspectionList ? '#0081FF' : '#AAAAAA',
                    // },
                ]
                break
            case "2":
                items = [
                    // {
                    //     name:'ダッシュボード',
                    //     link:routerNames.DashBoard,
                    //     selectColor:selectFlag === routerNames.DashBoard ? '#F8FAFB' : '',
                    //     iconColor:selectFlag === routerNames.DashBoard ? '#0081FF' : '#AAAAAA',
                    // },
                    {
                        name: '通知',
                        link: routerNames.Notification,
                        selectColor: selectFlag === routerNames.Notification ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Notification ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: '物件管理',
                        link: routerNames.Property,
                        selectColor: selectFlag === routerNames.Property ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Property ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'メンバー管理',
                        link: routerNames.Member,
                        selectColor: selectFlag === routerNames.Member ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Member ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'タグ管理',
                        link: routerNames.Tag,
                        selectColor: selectFlag === routerNames.Tag ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Tag ? '#0081FF' : '#AAAAAA',
                    },

                    // {
                    //     name: '不動産管理会社一覧',
                    //     link: routerNames.RealEstate,
                    //     selectColor: selectFlag === routerNames.RealEstate ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.RealEstate ? '#0081FF' : '#AAAAAA',
                    // },
                    // {
                    //     name: 'オーナー一覧',
                    //     link: routerNames.OwnersList,
                    //     selectColor: selectFlag === routerNames.OwnersList ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.OwnersList ? '#0081FF' : '#AAAAAA',
                    // },
                    // {
                    //     name: 'お知らせ管理',
                    //     link: routerNames.NotificationManagement,
                    //     selectColor: selectFlag === routerNames.NotificationManagement ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.NotificationManagement ? '#0081FF' : '#AAAAAA',
                    // }
                    {
                        name: 'リマインダー管理',
                        link: routerNames.ReminderManagement,
                        selectColor: selectFlag === routerNames.ReminderManagement ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.ReminderManagement ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name: '点検表マスタ管理',
                    //     link: routerNames.InspectionList,
                    //     selectColor: selectFlag === routerNames.InspectionList ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.InspectionList ? '#0081FF' : '#AAAAAA',
                    // },
                ]
                break
            case "3":
                items = [
                    {
                        name: '通知',
                        link: routerNames.Notification,
                        selectColor: selectFlag === routerNames.Notification ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Notification ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name:'ダッシュボード',
                    //     link:routerNames.DashBoard,
                    //     selectColor:selectFlag === routerNames.DashBoard ? '#F8FAFB' : '',
                    //     iconColor:selectFlag === routerNames.DashBoard ? '#0081FF' : '#AAAAAA',
                    // },
                    {
                        name: '物件管理',
                        link: routerNames.Property,
                        selectColor: selectFlag === routerNames.Property ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Property ? '#0081FF' : '#AAAAAA',
                    },

                    // {
                    //     name: '不動産管理会社一覧',
                    //     link: routerNames.RealEstate,
                    //     selectColor: selectFlag === routerNames.RealEstate ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.RealEstate ? '#0081FF' : '#AAAAAA',
                    // },
                    {
                        name: 'オーナー管理',
                        link: routerNames.OwnersList,
                        selectColor: selectFlag === routerNames.OwnersList ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.OwnersList ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'メンバー管理',
                        link: routerNames.Member,
                        selectColor: selectFlag === routerNames.Member ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Member ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'タグ管理',
                        link: routerNames.Tag,
                        selectColor: selectFlag === routerNames.Tag ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Tag ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name: 'お知らせ管理',
                    //     link: routerNames.NotificationManagement,
                    //     selectColor: selectFlag === routerNames.NotificationManagement ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.NotificationManagement ? '#0081FF' : '#AAAAAA',
                    // }
                    {
                        name: 'リマインダー管理',
                        link: routerNames.ReminderManagement,
                        selectColor: selectFlag === routerNames.ReminderManagement ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.ReminderManagement ? '#0081FF' : '#AAAAAA',
                    },
                ]
                break
            case "4":
                items = [
                    {
                        name: '通知',
                        link: routerNames.Notification,
                        selectColor: selectFlag === routerNames.Notification ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Notification ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name:'ダッシュボード',
                    //     link:routerNames.DashBoard,
                    //     selectColor:selectFlag === routerNames.DashBoard ? '#F8FAFB' : '',
                    //     iconColor:selectFlag === routerNames.DashBoard ? '#0081FF' : '#AAAAAA',
                    // },
                    {
                        name: '物件管理',
                        link: routerNames.Property,
                        selectColor: selectFlag === routerNames.Property ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Property ? '#0081FF' : '#AAAAAA',
                    },

                    // {
                    //     name: '不動産管理会社一覧',
                    //     link: routerNames.RealEstate,
                    //     selectColor: selectFlag === routerNames.RealEstate ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.RealEstate ? '#0081FF' : '#AAAAAA',
                    // },
                    {
                        name: 'オーナー管理',
                        link: routerNames.OwnersList,
                        selectColor: selectFlag === routerNames.OwnersList ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.OwnersList ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'メンバー管理',
                        link: routerNames.Member,
                        selectColor: selectFlag === routerNames.Member ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Member ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'タグ管理',
                        link: routerNames.Tag,
                        selectColor: selectFlag === routerNames.Tag ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Tag ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name: 'お知らせ管理',
                    //     link: routerNames.NotificationManagement,
                    //     selectColor: selectFlag === routerNames.NotificationManagement ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.NotificationManagement ? '#0081FF' : '#AAAAAA',
                    // }
                    {
                        name: 'リマインダー管理',
                        link: routerNames.ReminderManagement,
                        selectColor: selectFlag === routerNames.ReminderManagement ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.ReminderManagement ? '#0081FF' : '#AAAAAA',
                    },
                ]
                break
            case "5":
                items = [
                    // {
                    //     name:'ダッシュボード',
                    //     link:routerNames.DashBoard,
                    //     selectColor:selectFlag === routerNames.DashBoard ? '#F8FAFB' : '',
                    //     iconColor:selectFlag === routerNames.DashBoard ? '#0081FF' : '#AAAAAA',
                    // },
                    {
                        name: '通知',
                        link: routerNames.Notification,
                        selectColor: selectFlag === routerNames.Notification ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Notification ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'お知らせ管理',
                        link: routerNames.NotificationManagement,
                        selectColor: selectFlag === routerNames.NotificationManagement ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.NotificationManagement ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: '不動産管理会社管理',
                        link: routerNames.RealEstate,
                        selectColor: selectFlag === routerNames.RealEstate ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.RealEstate ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: '物件管理',
                        link: routerNames.Property,
                        selectColor: selectFlag === routerNames.Property ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Property ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'メンバー管理',
                        link: routerNames.Member,
                        selectColor: selectFlag === routerNames.Member ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Member ? '#0081FF' : '#AAAAAA',
                    },
                    {
                        name: 'タグ管理',
                        link: routerNames.Tag,
                        selectColor: selectFlag === routerNames.Tag ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.Tag ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name: 'オーナー一覧',
                    //     link: routerNames.OwnersList,
                    //     selectColor: selectFlag === routerNames.OwnersList ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.OwnersList ? '#0081FF' : '#AAAAAA',
                    // },
                    {
                        name: 'リマインダー管理',
                        link: routerNames.ReminderManagement,
                        selectColor: selectFlag === routerNames.ReminderManagement ? '#F8FAFB' : '',
                        iconColor: selectFlag === routerNames.ReminderManagement ? '#0081FF' : '#AAAAAA',
                    },
                    // {
                    //     name: '点検表マスタ管理',
                    //     link: routerNames.InspectionList,
                    //     selectColor: selectFlag === routerNames.InspectionList ? '#F8FAFB' : '',
                    //     iconColor: selectFlag === routerNames.InspectionList ? '#0081FF' : '#AAAAAA',
                    // },

                ]
                break

        }
        return items
        //     [
        //         // {
        //         //     name:'ダッシュボード',
        //         //     link:routerNames.DashBoard,
        //         //     selectColor:selectFlag === routerNames.DashBoard ? '#F8FAFB' : '',
        //         //     iconColor:selectFlag === routerNames.DashBoard ? '#0081FF' : '#AAAAAA',
        //         // },
        //         {
        //             name: '物件一覧',
        //             link: routerNames.Property,
        //             selectColor: selectFlag === routerNames.Property ? '#F8FAFB' : '',
        //             iconColor: selectFlag === routerNames.Property ? '#0081FF' : '#AAAAAA',
        //         },
        //         // {
        //         //     name: '通知',
        //         //     link: routerNames.Notification,
        //         //     selectColor: selectFlag === routerNames.Notification ? '#F8FAFB' : '',
        //         //     iconColor: selectFlag === routerNames.Notification ? '#0081FF' : '#AAAAAA',
        //         // },
        //         {
        //             name: '不動産管理会社一覧',
        //             link: routerNames.RealEstate,
        //             selectColor: selectFlag === routerNames.RealEstate ? '#F8FAFB' : '',
        //             iconColor: selectFlag === routerNames.RealEstate ? '#0081FF' : '#AAAAAA',
        //         },
        //         {
        //             name: 'オーナー一覧',
        //             link: routerNames.OwnersList,
        //             selectColor: selectFlag === routerNames.OwnersList ? '#F8FAFB' : '',
        //             iconColor: selectFlag === routerNames.OwnersList ? '#0081FF' : '#AAAAAA',
        //         },
        //     // {
        //     //     name: 'お知らせ管理',
        //     //     link: routerNames.NotificationManagement,
        //     //     selectColor: selectFlag === routerNames.NotificationManagement ? '#F8FAFB' : '',
        //     //     iconColor: selectFlag === routerNames.NotificationManagement ? '#0081FF' : '#AAAAAA',
        //     // }
        // ]
    }
    return (
        <>
            {
                menuItems.map((item, index) => (
                    <Link
                        to={item.link}
                        key={index}
                        className={classes.menuLink}
                    >
                        <Box
                            sx={{ backgroundColor: item.selectColor }}
                            className={classes.menuItem}
                            key={index}
                            onClick={() => { changeMenu(item, index) }}
                        >
                            <span className={classes.iconItem}>
                                {
                                    item.name === '物件管理' ? <MenuHeader_property color={item.iconColor} />
                                        :
                                        item.name === '通知' ? <MenuHeader_notification color={item.iconColor} />
                                            :
                                            item.name === '不動産管理会社管理' ? <MenuHeader_house color={item.iconColor} />
                                                :
                                                item.name === 'オーナー管理' ? <MenuHeader_character color={item.iconColor} />
                                                    :
                                                    item.name === 'タグ管理' ? <MenuHeader_tag color={item.iconColor} />
                                                        :
                                                        item.name === 'メンバー管理' ? <MenuHeader_member color={item.iconColor} />
                                                            :
                                                            item.name === 'お知らせ管理' ? <MenuHeader_notificationManagement color={item.iconColor} />
                                                                :
                                                                item.name === 'リマインダー管理' ? <MenuHeader_reminderManagement color={item.iconColor} />
                                                                    :
                                                                    item.name === '点検表マスタ管理' ? <MenuHeader_inspectionList color={item.iconColor} /> : ''
                                }
                                <span style={{ marginLeft: VW(8) }}>{item.name}</span>
                                <span style={{ height: '100%', display: 'flex', alignItems: 'center', position: 'absolute', right: VW(16) }}><KeyboardArrowRightIcon /></span>
                            </span>
                        </Box>
                    </Link>
                ))
            }
        </>
    )
}

LeftMenu.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(LeftMenu);