import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_gangway() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width={VW(24)} height={VH(24)} rx="2" fill="#2F83FF" />
            <path d="M17.25 5.25H6.75C5.925 5.25 5.25 5.925 5.25 6.75V17.25C5.25 18.075 5.925 18.75 6.75 18.75H17.25C18.075 18.75 18.75 18.075 18.75 17.25V6.75C18.75 5.925 18.075 5.25 17.25 5.25ZM16.5 9H14.685V11.4975H12.75V13.995H10.815V16.5H7.5V15H9.315V12.5025H11.25V9.9975H13.185V7.5H16.5V9Z" fill="white" />
        </svg>
    )
}
export default Property_detail_gangway;




