import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_vending() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width={VW(24)} height={VH(24)} rx="2" fill="#2F83FF" />
            <path d="M16.5 6V18H7.5V6H16.5ZM16.5 4.5H7.5C6.675 4.5 6 5.175 6 6V18C6 18.825 6.675 19.5 7.5 19.5H16.5C17.325 19.5 18 18.825 18 18V6C18 5.175 17.325 4.5 16.5 4.5ZM8.25 17.25H15.75V12.75H8.25V17.25ZM10.5 10.5H13.5V11.25H15.75V6.75H8.25V11.25H10.5V10.5Z" fill="white" />
        </svg>
    )
}
export default Property_detail_vending;


