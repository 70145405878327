import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { jaJP } from "@mui/material/locale";

// colors
const primary = "#0052CC";
const themeColor = '#E2E6EA';
const themeBorder = '#EBE2E2'
const tableTitleColor = '#44566C'

const gray54 = "#545454"
const gray9E = "#9E9E9E"
const grayEE = "#EEEEEE"
const grayBA = "#BABABA"
const grayF5 = "#F5F5F5"

const blue91 = "#91bdff"
const redEA = "#ea0000"
const redCC = "#CC0000"
const blue00 = "#0052cc"
const blueEE = "#EEF2FF"
const green13 = "#13a221"

const secondary = "#1964FE";
const black = "#333333"// "#545454";
const darkBlack = "rgb(36, 40, 44)";
const background = "#FFFFFF";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// border
const borderWidth = 0;
const borderColor = "#C5C5C5";


const theme = createTheme(
  {
    palette: {
      primary: { main: primary },
      themeColor: { main: themeColor },
      themeBorder: { main: themeBorder },
      tableTitleColor: { main: tableTitleColor }
    },
    typography: {
      fontFamily: ['Noto Sans JP', 'sans-serif'].join(),  // `"Chilanka","Noto Sans JP", "Helvetica", "Arial", sans-serif`,
    },
    MuiInputBase: {
      root: {
        fontFamily: ['Noto Sans JP', 'sans-serif'].join(),
      }
    },
  },
  jaJP
)

export default responsiveFontSizes(theme, "breakpoints");
