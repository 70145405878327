import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import MenuHeader_property from "../../componets/icon/MenuHeader_property";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import { useNavigate } from 'react-router-dom'
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import { Button, Select, Input } from 'antd'
import { ApiSetsubiList, ApiSetsubiDetail, ApiSetsubiUpdate } from '../../network/api'
import PropertyDetail_equipment_item from "../../componets/HtmlBody/PropertyDetail_equipment_item";
import { CaretDownOutlined } from '@ant-design/icons'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropertyDetail_equipment_input from "../../componets/HtmlBody/PropertyDetail_equipment_input";
import PropertyDetail_equipment_delete from "../../componets/dialog/PropertyDetail_equipment_delete";
import PropertyDetail_equipment_add from "../../componets/dialog/PropertyDetail_equipment_add";
import PropertyDetail_roomType_update from "../../componets/dialog/PropertyDetail_roomType_update";

dayjs.extend(utc)
const { Option } = Select;


const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingBottom: VW(24),
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    objectNameBlock: {
        marginLeft: VW(24),
        marginTop: VH(12),
        minHeight: VH(24),
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
        wordBreak: 'break-all',
        '& > div:nth-child(1)': {
            fontSize: VW(14),
        },
        '& > div:nth-child(2)': {
            fontSize: VW(24),
            fontWeight: 'bold',
            marginLeft: VW(16)
        }
    },
    titleMenu: {
        width: 'auto',
        display: 'flex',
        marginTop: VH(16),
        height: VH(40),
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    menuContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: VW(16)
    },
    AddDeleteBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: VH(24),
        height: VH(40),
        '& > Button': {
            marginLeft: VW(8),
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
        },
        '& > Button:nth-of-type(2)': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
        }
    },
    roomSelectBlock: {
        height: VH(74),
        marginTop: VH(16),
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        position: 'relative',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center'
        },
        '& > div:nth-of-type(1)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            paddingLeft: VW(16)
        },
        '& > Button': {
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
            backgroundColor: '#E60024',
            color: '#FFF',
            fontWeight: 'bold',
            position: 'absolute',
            right: 0,
        }
    },
    bottomButton: {
        width: '95%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        float: 'left',
        marginTop: VW(40),
        '& > Button': {
            marginBottom: VW(40),
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_equipment(props) {
    const {
        classes,
        setSelectFlag
    } = props

    const location = useLocation();
    const { state } = location;


    useEffect(() => {
        setSelectFlag(routerNames.Property)
    }, [setSelectFlag])


    useEffect(() => {
        log(state)
        if (localStorage.getItem('eq_first') == null || localStorage.getItem('eq_first') == undefined) {
            localStorage.setItem('eq_first', true)
        }
        if (localStorage.getItem('eq_second') == null || localStorage.getItem('eq_second') == undefined) {
            localStorage.setItem('eq_second', true)
        }
        if (localStorage.getItem('eq_third') == null || localStorage.getItem('eq_third') == undefined) {
            localStorage.setItem('eq_third', true)
        }
        getRoomList()
    }, [])


    const navigate = useNavigate()


    const authority_id = localStorage.getItem("authority_id")

    // const authority_id = "5";
    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // title menu content
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list
    const [titleMenuList, setTitleMenuList] = useState(routerTitleList)
    function routerTitleList() {
        var items = []
        if (state.buken_shubetsu === 0) {
            items = [
                { name: '物件情報', width: 130, checked: false },
                { name: '部屋情報', width: 120, checked: true },
                { name: 'メンテナンス', width: 130, checked: false },
                { name: '設備記録', width: 120, checked: false },
                { name: '営業記録', width: 120, checked: false },
                { name: 'クレーム記録', width: 130, checked: false },
                { name: '修繕記録', width: 120, checked: false },
                { name: 'ファイル', width: 122, checked: false }
            ]
        } else {
            items = [
                { name: 'コンテナマスタ', width: 142, checked: false },
                { name: 'メンテナンス', width: 142, checked: false },
                { name: '設備記録', width: 142, checked: true },
                { name: '営業記録', width: 142, checked: false },
                { name: 'クレーム記録', width: 142, checked: false },
                { name: '修繕記録', width: 142, checked: false },
                { name: 'ファイル', width: 142, checked: false }
            ]
        }

        return items
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list click
    const selectTitleItem = (content) => {
        log(content)
        if (content.name == "物件情報" || content.name == "コンテナマスタ") {
            navigate(routerNames.PropertyDetail, { state: state })
        } else if (content.name == "部屋情報") {
            window.history.back()
        } else if (content.name == "営業記録") {
            navigate(routerNames.PropertyDetail_business, { state: state })
        } else if (content.name == "クレーム記録") {
            navigate(routerNames.PropertyDetail_claim, { state: state })
        } else if (content.name == "修繕記録") {
            navigate(routerNames.PropertyDetail_repair, { state: state })
        } else if (content.name == "メンテナンス") {
            navigate(routerNames.PropertyDetail_calendar, { state: state })
        } else if (content.name == "設備記録") {
            navigate(routerNames.PropertyDetail_equipment_records, { state: state })
        } else if (content.name == "ファイル") {
            navigate(routerNames.PropertyDetail_file, { state: state })
        }
    }



    // get 部屋
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> list
    const [roomList, setRoomList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> api
    const getRoomList = (flag) => {
        document.getElementById('father_Block').scrollTop = 0;
        if (flag == 'delete') {
            setSuccessContent('削除が完了しました。')
            setSuccessOpen(true)
        }
        if (flag == 'add') {
            setSuccessContent('登録が完了しました。')
            setSuccessOpen(true)
        }
        let param = {
            buken_id: state.buken_id
        }
        ApiSetsubiList(param).then(res => {
            let resData = res.data
            if (res.status === 200) {
                log(resData)
                setRoomList(resData.data)
                if (resData.data.length > 0) {
                    if (flag == 'delete') {
                        setRoomNumber(resData.data[0].room_num)
                        setRoomValue(resData.data[0].setsubi_id)
                        changeRoom(resData.data[0].setsubi_id)
                    } else {
                        if (!roomValue) {
                            resData.data.map(item => {
                                if (item.setsubi_id == state.setsubi_id) {
                                    setRoomNumber(item.room_num)
                                    setRoomValue(item.setsubi_id)
                                    changeRoom(item.setsubi_id)
                                }
                            })
                        } else {
                            resData.data.map(item => {
                                if (item.setsubi_id == roomValue) {
                                    setRoomNumber(item.room_num)
                                    changeRoom(roomValue)
                                }
                            })
                        }

                    }
                } else {
                    setRoomNumber('')
                    setRoomValue('')
                    setBodyList([])
                }
                setRrrorOpen(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }

        }).catch(err => {

        })
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> select value
    const [roomValue, setRoomValue] = useState()
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> select change function
    const changeRoom = (item) => {
        roomList.map(itemF => {
            if (itemF.setsubi_id == item) {
                setRoomNumber(itemF.room_num)
            }
        })
        log(item)
        let param = {
            setsubi_id: item
        }
        ApiSetsubiDetail(param).then(res => {
            let resData = res.data.data
            if (res.status === 200) {
                setRank(res.data.rank)
                setRoom_topic(res.data.room_topic)
                setRoomValue(item)
                log(resData)
                resData.map(itemC => {
                    // if (itemC.category_name == '付帯設備') {
                    //     itemC.displayFlag = localStorage.getItem('eq_first') == 'true' ? false : true
                    // } else if (itemC.category_name == '家電') {
                    //     itemC.displayFlag = localStorage.getItem('eq_second') == 'true' ? false : true
                    // } else if (itemC.category_name == '家具') {
                    //     itemC.displayFlag = localStorage.getItem('eq_third') == 'true' ? false : true
                    // }
                    itemC.displayFlag = false
                })
                log(resData)
                setBodyList([...resData])
                setRrrorOpen(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }

        }).catch(err => {

        })
    }


    // bodylist
    const [bodylist, setBodyList] = useState([])
    // change display flag
    const changeDisplayFlag = (index) => {
        // if (index == 0) {
        //     localStorage.setItem('eq_first', !bodylist[index].displayFlag)
        // } else if (index == 1) {
        //     localStorage.setItem('eq_second', !bodylist[index].displayFlag)
        // } else if (index == 2) {
        //     localStorage.setItem('eq_third', !bodylist[index].displayFlag)
        // }
        bodylist[index].displayFlag = !bodylist[index].displayFlag
        setBodyList([...bodylist])
    }


    // update flag
    const [updateFlag, setUpdateFlag] = useState(false)


    // 部屋番号
    const [roomNumber, setRoomNumber] = useState('')
    // rank
    const [rank, setRank] = useState('')
    // タイプ
    const [room_topic, setRoom_topic] = useState('')


    // update api
    const toUpdate = () => {
        document.getElementById('father_Block').scrollTop = 0;
        if (roomNumber == '') {
            setErrorContent('部屋番号は必須です')
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else {
            let params = {}
            params.setsubi_id = roomValue
            params.room_num = roomNumber
            params.rank = rank == null ? '' : rank
            params.buken_id = state.buken_id
            params.data = bodylist
            ApiSetsubiUpdate(params).then(res => {
                if (res.status == 200) {
                    setUpdateFlag(false)
                    setRrrorOpen(false)
                    setSuccessOpen(true)
                    setSuccessContent('更新が完了しました。')
                    roomList.map(item => {
                        if (item.setsubi_id == roomValue) {
                            item.room_num = roomNumber
                            item.rank = rank
                        }
                    })
                    setRoomList([...roomList])
                    changeRoom(roomValue)
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }

    // 部屋と設備の紐付け
    const [stringAddOpen, setStringAddOpen] = useState(false)
    const stringAddOnClose = () => {
        setStringAddOpen(false)
    }
    const openStringAddDialog = () => {
        setStringAddOpen(true)
    }


    // キャンセル
    const backItem = () => {
        document.getElementById('father_Block').scrollTop = 0;
        setUpdateFlag(false)
        changeRoom(roomValue)
    }


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { navigate(routerNames.Property) }}>物件一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>物件詳細</div>
            <div className={classes.tableTitle}>
                <MenuHeader_property />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件詳細</div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            <div className={classes.objectNameBlock}>
                <div>物件名</div>
                <div>{state.genba_name}</div>
            </div>
            <div className={classes.titleMenu}>
                {titleMenuList.map((item, index) => {
                    return (
                        <div
                            className={classes.menuContent}
                            key={index}
                            style={{ width: VW(item.width), backgroundColor: item.checked ? '#E0ECFF' : '', fontWeight: item.checked ? 'bold' : '' }}
                            onClick={() => { selectTitleItem(item) }}
                        >{item.name}</div>
                    )
                })}
            </div>
            {(authority_id == "3" || authority_id == "4") &&
                <div className={classes.AddDeleteBlock}>
                    {!updateFlag && roomList.length > 0 && <Button style={{ padding: 0 }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={() => { setUpdateFlag(true) }}>編集</Button>}
                    {!updateFlag && <Button style={{ padding: 0 }} type="primary" icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openAddDialog}>部屋登録</Button>}
                </div>}
            <div className={classes.roomSelectBlock}>
                <div>部屋番号</div>
                {!updateFlag
                    ?
                    <Select
                        style={{ width: VW(296), marginLeft: VW(16) }}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={roomValue}
                        onChange={(event) => changeRoom(event)}
                    >
                        {roomList.length > 0 && roomList.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.setsubi_id}>{item.room_num}</Option>
                            )
                        })}
                    </Select>
                    :
                    <Input
                        style={{ width: VW(296), marginLeft: VW(16), height: VH(50), fontSize: VW(16) }}
                        placeholder="部屋番号"
                        value={roomNumber}
                        onChange={(event) => setRoomNumber(event.target.value.replace(/[^0-9+^\uFF10-\uFF19+^a-zA-Z]+/g, ''))}
                        maxLength={20}
                    />
                }
                <div style={{ marginLeft: VW(16) }}>号室</div>
                {roomList.length > 0 && (authority_id == "3" || authority_id == "4") && <Button type="primary" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openDeleteDialog}>部屋削除</Button>}
            </div>
            <div className={classes.roomSelectBlock} style={{ marginTop: 0, borderTop: 0 }}>
                <div>階数</div>
                {!updateFlag
                    ?
                    <div style={{ marginLeft: VW(16) }}>{rank ? rank + '階' : ''}</div>
                    :
                    <Input
                        style={{ width: VW(296), marginLeft: VW(16), height: VH(50), fontSize: VW(16) }}
                        placeholder="階数"
                        value={rank}
                        onChange={(event) => setRank(event.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''))}
                        maxLength={2}
                    />
                }
                {updateFlag && <div style={{ marginLeft: VW(16) }}>階</div>}
            </div>
            <div className={classes.roomSelectBlock} style={{ marginTop: 0, borderTop: 0 }}>
                <div>部屋タイプ</div>
                <div style={{ marginLeft: VW(16), width: VW(600), display: 'flex', wordBreak: 'break-all' }}>{room_topic}</div>
                {!updateFlag && authority_id != '5' && authority_id != '1' && authority_id != '2' && <Button onClick={openStringAddDialog} style={{ padding: 0, width: VW(200), backgroundColor: '#0052CC' }} type="primary" icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}>部屋と設備の紐付け</Button>}
            </div>
            {!updateFlag
                ?
                <PropertyDetail_equipment_item
                    bodylist={bodylist}
                    changeDisplayFlag={changeDisplayFlag}
                />
                :
                <PropertyDetail_equipment_input
                    bodylist={bodylist}
                    setBodyList={setBodyList}
                    changeDisplayFlag={changeDisplayFlag}
                />
            }
            {updateFlag &&
                <div className={classes.bottomButton}>
                    <Button onClick={backItem}>キャンセル</Button>
                    <Button onClick={toUpdate}>更新</Button>
                </div>
            }
            <PropertyDetail_equipment_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                setsubi_id={[{ setsubi_id: roomValue, room_num: roomNumber }]}
                getList={getRoomList}
            />
            <PropertyDetail_equipment_add
                open={addOpen}
                onClose={addOnClose}
                getList={getRoomList}
                buken_id={state.buken_id}
            />
            <PropertyDetail_roomType_update
                open={stringAddOpen}
                onClose={stringAddOnClose}
                getList={getRoomList}
                state={state}
            />
        </div >
    )
}

PropertyDetail_equipment.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment);