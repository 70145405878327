import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons'

const { Option } = Select;

const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
        '& .ant-select-selection-item': {
            height: VH(30),
            marginRight: VW(24)
        },
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
            minHeight: VH(74),
        },
        '& > div > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: VW(752),
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
    }
});

function Member_input(props) {
    const {
        classes,
        detailedData,
        authority,
        setAuthority,
        authorityOption
    } = props



    return (
        <div className={classes.contentBlock}>
            <div>
                <div>氏名</div>
                <div>{detailedData.surname + detailedData.name}</div>
            </div>
            <div>
                <div>メールアドレス</div>
                <div>{detailedData.email}</div>
            </div>
            <div>
                <div>所属</div>
                <div>{detailedData.affiliation}</div>
            </div>
            <div>
                <div>権限<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                <div>
                    <Select
                        style={{ width: VW(360), }}
                        placeholder='権限を選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={authority}
                        onChange={(event) => setAuthority(event)}
                    >
                        {authorityOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.authority_id}>{item.authority_name}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
        </div>
    )
}

Member_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Member_input);