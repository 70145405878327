import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_calendar_copy() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ display: 'block' }} >
            <path d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z" fill="#5B5B5B" />
        </svg>
    )
}
export default PropertyDetail_calendar_copy;


