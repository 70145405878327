import { CaretDownOutlined } from '@ant-design/icons';
import { withStyles } from "@mui/styles";
import { DatePicker, Input, Radio, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import React, { useState } from "react";
import { VH, VW } from "../function/sizeAdapter";


const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    customerBlock: {
        '& > div': {
            height: VH(124),
        },
        '& > div:nth-child(1)': {
            width: VW(146)
        },
        '& > div:nth-child(2)': {
            width: VW(593),
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                '& > Input': {
                    height: VH(50),
                    fontSize: VW(16),
                    borderRadius: "4px"
                },
            }
        }
    },
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(74),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(74)
        },
        '& > div:nth-child(odd)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(even)': {
            width: '30%'
        },
        '& > div > .ant-select-single .ant-select-selector': {
            width: '100%',
            height: VH(50),
            alignItems: 'center',
        }
    },
    groundTwo: {
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(74),
        width: '100%',
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(74),
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '80%'
        },
        '& > div > Input': {
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
    }
});

function PropertyDetail_claim_input(props) {
    const {
        classes,
        detailedData,
        changeDetailedData,
        state,
        priorityValueOption
    } = props

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ステータス
    const [statusValueOption, setStatusValueOption] = useState([
        { value: '0', label: '未対応' },
        { value: '1', label: '対応中' },
        { value: '2', label: '対応済' }
    ])



    return (
        <div style={{ marginTop: VH(16) }}>
            <div className={classes.groundTwo}>
                <div>クレーム番号</div>
                <div>{detailedData.claim_record_no}</div>
            </div>
            <div className={classes.groundFour}>
                <div>発生日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(272), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.accrual_date ? dayjs(detailedData.accrual_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'accrual_date')}
                    />
                </div>
                <div>優先度</div>
                <div>
                    <Select
                        style={{ width: VW(360), }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.priority == undefined ? undefined : String(detailedData.priority)}
                        onChange={(event) => changeDetailedData(event, 'priority')}
                        allowClear
                        onClear={() => changeDetailedData(undefined, 'priority')}
                    >
                        {priorityValueOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>
                                    <span style={{ color: item.color }}>●</span>&nbsp;{item.label}
                                </Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className={classes.groundFour}>
                <div>対応期日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(272), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.compatible_date ? dayjs(detailedData.compatible_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'compatible_date')}
                    />
                </div>
                <div>ステータス</div>
                <div>
                    <Select
                        style={{ width: VW(360), }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.status == undefined ? undefined : String(detailedData.status)}
                        onChange={(event) => changeDetailedData(event, 'status')}
                        allowClear
                        onClear={() => changeDetailedData(undefined, 'status')}
                    >
                        {statusValueOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>受付者</div>
                <div>
                    <Input
                        style={{ width: '100%' }}
                        placeholder="受付者"
                        maxLength={60}
                        value={detailedData.pic}
                        onChange={(event) => changeDetailedData(event.target.value, 'pic')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>対応者</div>
                <div>
                    <Input
                        style={{ width: '100%' }}
                        placeholder="対応者"
                        maxLength={60}
                        value={detailedData.manager}
                        onChange={(event) => changeDetailedData(event.target.value, 'manager')}
                    />
                </div>
            </div>
            <div style={{ height: VH(124) }} className={classes.groundTwo}>
                <div>お客様</div>
                <div className={classes.customerBlock}>
                    <div>
                        <Radio.Group onChange={(e) => { changeDetailedData(e.target.value, 'customer_type') }} value={detailedData.customer_type}>
                            <Radio value={0} style={{ fontSize: VW(16), marginTop: VH(25) }} defaultChecked>オーナー</Radio>
                            <Radio value={1} style={{ fontSize: VW(16), marginTop: VH(26) }}>オーナー以外</Radio>
                        </Radio.Group>
                    </div>
                    <div>
                        <div style={{ fontSize: VW(16), marginTop: VH(25) }}>{state.owner_name}</div>
                        <div style={{ fontSize: VW(16), marginTop: VH(13) }}>
                            <Input
                                style={{ width: '100%' }}
                                placeholder="お客様名を記入してください"
                                maxLength={60}
                                value={detailedData.customer_name}
                                onChange={(event) => changeDetailedData(event.target.value, 'customer_name')}
                                disabled={detailedData.customer_type == 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>件名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                <div>
                    <Input
                        style={{ width: '100%' }}
                        placeholder="件名を記入してください"
                        maxLength={60}
                        value={detailedData.subject}
                        onChange={(event) => changeDetailedData(event.target.value, 'subject')}
                    />
                </div>
            </div>
            <div style={{ height: VH(304) }} className={classes.groundTwo}>
                <div>クレーム内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(280) }}
                        placeholder="クレーム内容を記入してください"
                        maxLength={500}
                        value={detailedData.claim_content}
                        onChange={(event) => changeDetailedData(event.target.value, 'claim_content')}
                    />
                </div>
            </div>
            <div style={{ height: VH(304) }} className={classes.groundTwo}>
                <div>対応内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(280) }}
                        placeholder="対応内容を記入してください"
                        maxLength={500}
                        value={detailedData.correspondence_content}
                        onChange={(event) => changeDetailedData(event.target.value, 'correspondence_content')}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>対応完了日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(272), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.completion_date ? dayjs(detailedData.completion_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'completion_date')}
                    />
                </div>
            </div>
            <div style={{ height: VH(304) }} className={classes.groundTwo}>
                <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(280) }}
                        placeholder="備考を記入してください"
                        maxLength={500}
                        value={detailedData.remarks}
                        onChange={(event) => changeDetailedData(event.target.value, 'remarks')}
                    />
                </div>
            </div>
        </div>
    )
}

PropertyDetail_claim_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_claim_input);