import React, { useState, useEffect }  from "react";
import { withStyles } from "@mui/styles";
import { Input, DatePicker, Radio } from 'antd'
import { VW, VH } from "../../componets/function/sizeAdapter"
import locale from "antd/es/date-picker/locale/ja_JP";
import Property_select_usage_direction from "../dialog/Property_select_usage_direction";

const { TextArea } = Input;

const styles = (theme) => ({
    updateInput: {
        width: "100%",
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
        },
        '& > div > div > Input': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: VW(760),
        }
    }
});

function ReminderManagementDetail_input(props) {
    const {
        classes,
        createDate,
        allName,
        title,
        setTitle,
        text,
        setText,
        startDate,
        endDate,
        changeStartDate,
        changeEndDate,
        selectedDirectionId,
        setSelectedDirectionId,
        selectedDirectionName,
        setSelectedDirectionName,
        direction,
        setDirection
    } = props

    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 配信先
    const handleChangeforRadio = (event) => {
        setDirection(event.target.value)
    };

    return (
        <div className={classes.updateInput} style={{ marginTop: VH(20) }}>
            <div style={{ height: VH(74) }}>
                <div>登録日</div>
                <div>{createDate}</div>
            </div>

            <div style={{ height: VH(74) }}>
                <div>作成者</div>
                <div>{allName}</div>
            </div>

            <div style={{ height: VH(74) }}>
                <div>配信先</div>
                <div>
                    <Radio.Group onChange={handleChangeforRadio} value={direction}>
                        <Radio value={0} style={{ fontSize: VW(16), marginTop: VH(12), marginBottom: VH(12), marginRight: VW(35) }} defaultChecked>自分</Radio>
                        <Radio value={1} style={{ fontSize: VW(16), marginTop: VH(12), marginBottom: VH(12), marginRight: VW(35) }}>全体</Radio>
                        <Radio value={2} style={{ fontSize: VW(16), marginTop: VH(12), marginBottom: VH(12) }}>選択する</Radio>
                    </Radio.Group>
                    <Property_select_usage_direction
                        setSelectedDirectionName={setSelectedDirectionName}
                        selectedDirectionName={selectedDirectionName}
                        setSelectedDirectionId={setSelectedDirectionId}
                        selectedDirectionId={selectedDirectionId}
                        direction={direction}
                    />
                </div>
            </div>

            <div style={{ height: VH(74) }}>
                <div>タイトル<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span><p style={{ fontSize: VW(14), fontWeight: '400' }}>（90文字まで）</p></div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                        style={{ height: VH(50), width: "100%" }}
                        placeholder="リマインダーのタイトルを記入してください。"
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                        maxLength={90}
                    />
                </div>
            </div>

            <div style={{ height: VH(304) }}>
                <div>本文<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span><p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div >
                    <TextArea
                        style={{ height: VH(280), width: "100%" }}
                        placeholder="リマインダーの内容を記入してください。"
                        maxLength={500}
                        value={text}
                        onChange={(event) => setText(event.target.value)}
                    />
                </div>
            </div>

            <div style={{ height: VH(74) }}>
                <div>配信期間<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                <div style={{ fontSize: VW(16) }}>{'開始：'}  <div>
                    <DatePicker
                        style={{ width: VW(176), height: VH(50), fontSize: VW(16), borderRadius: '4px', marginLeft: VW(16), marginRight: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        value={startDate}
                        format="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeStartDate}
                    />
                </div>
                    <div style={{ marginRight: VW(16) }}>{'〜'}</div>
                    <div style={{ marginRight: VW(16) }}>{'終了：'}</div>
                    <div>
                        <DatePicker
                            style={{ width: VW(176), height: VH(50), fontSize: VW(16), borderRadius: '4px' }}
                            placeholder="YYYY/MM/DD"
                            value={endDate}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeEndDate}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

ReminderManagementDetail_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(ReminderManagementDetail_input);