import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_calendar_add(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" style={{ display: 'block' }}>
            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15 11H11V15H9V11H5V9H9V5H11V9H15V11Z" fill={color ? color : "#BBBBBB"} />
        </svg>

    )
}
export default PropertyDetail_calendar_add;


