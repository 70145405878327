import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_property(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(18)} height={VH(16)} viewBox="0 0 18 16" fill="none">
            <path d="M12.1667 7.16667V0.5H3.83333V3.83333H0.5V15.5H7.16667V12.1667H8.83333V15.5H15.5V7.16667H12.1667ZM3.83333 13.8333H2.16667V12.1667H3.83333V13.8333ZM3.83333 10.5H2.16667V8.83333H3.83333V10.5ZM3.83333 7.16667H2.16667V5.5H3.83333V7.16667ZM7.16667 10.5H5.5V8.83333H7.16667V10.5ZM7.16667 7.16667H5.5V5.5H7.16667V7.16667ZM7.16667 3.83333H5.5V2.16667H7.16667V3.83333ZM10.5 10.5H8.83333V8.83333H10.5V10.5ZM10.5 7.16667H8.83333V5.5H10.5V7.16667ZM10.5 3.83333H8.83333V2.16667H10.5V3.83333ZM13.8333 13.8333H12.1667V12.1667H13.8333V13.8333ZM13.8333 10.5H12.1667V8.83333H13.8333V10.5Z" fill={color ? color : '#2F83FF'} />
        </svg>
    )
}

export default MenuHeader_property;