import { VW, VH } from "../../componets/function/sizeAdapter"
function Member_tab_true() {
    return (
        <svg width="41" height="24" viewBox="0 0 41 24" style={{ display: 'block' }}>
            <g opacity="0.5">
                <rect x="7.5" y="7" width="26" height="10" rx="5" fill="#91BDFF" />
            </g>
            <g filter="url(#filter0_ddd_12019_6682)">
                <circle cx="28.5" cy="12" r="8" fill="#2F83FF" />
            </g>
            <defs>
                <filter id="filter0_ddd_12019_6682" x="17.5" y="2" width="22" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12019_6682" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_12019_6682" result="effect2_dropShadow_12019_6682" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_12019_6682" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow_12019_6682" result="effect3_dropShadow_12019_6682" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_12019_6682" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default Member_tab_true;