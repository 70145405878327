import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import MenuHeader_house from "../icon/MenuHeader_house";
import CorrectIcon from "../icon/CorrectIcon";
import WrongIcon from "../icon/WrongIcon";
import log from "../function/log";
import { ApiBukenDelete } from '../../network/api'
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import MenuHeader_property from "../icon/MenuHeader_property";
import Checkbox_Table_Block from "../icon/Checkbox_Table_Block";
import { errorMessage } from "../../wordsFile/wordsFile";

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    headerBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        marginTop: VH(16),
        backgroundColor: '#F8F8F8',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        whiteSpace: 'pre-line',
        '& > div': {
            height: '100%',
            fontWeight: 'bold',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw'
        },
        '& > div:nth-child(1)': {
            width: '0.3896103896104%',
        },
        '& > div:nth-child(2)': {
            width: '10.5238095238095%',
        },
        '& > div:nth-child(3)': {
            width: '7.9264069264069%',
            justifyContent: 'center'
        },
        '& > div:nth-child(4)': {
            width: '22.6450216450216%'
        },
        '& > div:nth-child(5)': {
            width: '9.6580086580087%',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            paddingTop: VH(11),
            justifyContent: 'center'
        },
        '& > div:nth-child(6)': {
            width: '6.1948051948052%',
            justifyContent: 'center'
        },
        '& > div:nth-child(7)': {
            width: '14.1212121212121%'
        },
        '& > div:nth-child(8)': {
            width: '27.6753246753247%'
        },
    },
    scrollBlock: {
        width: '100%',
        maxHeight: VH(224),
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
    bodyBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDD',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw',
            overflow: 'hidden'
        },
        '& > div:nth-child(1)': {
            width: '0.3896103896104%',
        },
        '& > div:nth-child(2)': {
            width: '10.5238095238095%',
        },
        '& > div:nth-child(3)': {
            width: '7.9264069264069%',
            justifyContent: 'center'
        },
        '& > div:nth-child(4)': {
            width: '22.6450216450216%'
        },
        '& > div:nth-child(5)': {
            width: '9.6580086580087%',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            paddingTop: VH(11),
            justifyContent: 'center'
        },
        '& > div:nth-child(6)': {
            width: '6.1948051948052%',
            justifyContent: 'center'
        },
        '& > div:nth-child(7)': {
            width: '14.1212121212121%'
        },
        '& > div:nth-child(8)': {
            width: '27.6753246753247%'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: '#E60024',
            marginLeft: VW(16)
        }
    }
});

function Property_delete(props) {
    const {
        classes,
        open,
        onClose,
        deleteData,
        getList,
        setDeleteData,
        setIndeterminate,
        setCheckAll,
        historyFlag
    } = props



    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // delete api
    const toDelete = () => {
        if (!titleFlag) {
            setTitleFlag(true)
        } else {
            let deleteList = []
            newDeleteData.map(item => {
                deleteList.push({ buken_id: item.buken_id })
            })
            log(deleteList)
            ApiBukenDelete(deleteList).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    if (historyFlag) {
                        onClose()
                        window.history.back()
                        localStorage.setItem('property_deleteFlag', true)
                    } else {
                        getList('delete')
                        onClose()
                        setDeleteData([])
                        setIndeterminate(false)
                        setCheckAll(false)
                    }
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    // title text flag
    const [titleFlag, setTitleFlag] = useState(false)
    const [count, setCount] = useState(0)
    const [newDeleteData, setNewDeleteData] = useState([])
    useEffect(() => {
        log(1111)
        setRrrorOpen(false)
        setErrorContent('')
        var countTemp = 0
        var newDeleteDataTemp = []
        deleteData.map(item => {
            if (item.keiyaku_status == 'correct') {
                countTemp++
            }
        })
        if (countTemp > 0) {
            setErrorContent(errorMessage.E0037)
            setRrrorOpen(true)
            deleteData.map(item => {
                if (item.keiyaku_status != 'correct') {
                    newDeleteDataTemp.push(item)
                }
            })
            setNewDeleteData(newDeleteDataTemp)
        } else {
            setNewDeleteData(deleteData)

        }
    }, [open])

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <MenuHeader_property />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件の削除</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {titleFlag ? <div style={{ color: '#E60024', fontSize: VW(16), marginTop: VH(13) }}>以下の物件を削除します。<br />削除されたデータは復元できませんが、本当に削除しますか？</div> : <div style={{ fontSize: VW(16), marginTop: VH(13) }}>以下の物件を削除します。</div>}
            <div className={classes.headerBlock}>
                <div></div>
                <div>物件CD</div>
                <div>
                    <MenuHeader_property color={'#333'} /> / <Checkbox_Table_Block color={'#333'} />
                </div>
                <div>物件名</div>
                <div>タグ</div>
                <div>{'巡回\n契約'}</div>
                <div>オーナー</div>
                <div>所在地</div>
            </div>
            <div className={classes.scrollBlock}>
                {newDeleteData && newDeleteData.map((item, index) => {
                    return (
                        <div className={classes.bodyBlock} key={index}>
                            <div></div>
                            <div>
                                <p>{item.customer_buken_code}</p>
                            </div>
                            <div>
                                {item.buken_shubetsu === 0 ? <MenuHeader_property /> : <Checkbox_Table_Block />}
                            </div>
                            <div>
                                <p>{item.genba_name}</p>
                            </div>
                            <div >
                                {item.tag_color.length > 0 && item.tag_color.map((tagItem, tagIndex) => {
                                    return (
                                        <div
                                            key={tagIndex}
                                            style={{
                                                width: VW(28),
                                                height: VH(6),
                                                backgroundColor:
                                                    tagItem === '1' ? '#FFB872' :
                                                        tagItem === '2' ? '#BB99FE' :
                                                            tagItem === '3' ? '#80BFFF' :
                                                                tagItem === '4' ? '#BCFA38' :
                                                                    tagItem === '5' ? '#F9CFF9' :
                                                                        tagItem === '6' ? '#ADEAFF' :
                                                                            tagItem === '7' ? '#85F8B3' :
                                                                                '#FF8CCF',
                                                borderRadius: '4px',
                                                marginRight: (tagIndex % 2 == 0) ? VW(8) : 0,
                                                marginTop: VH(2)
                                            }}></div>
                                    )
                                })}
                            </div>
                            <div>
                                {item.keiyaku_status === 'correct' ? <CorrectIcon /> : ''}
                            </div>
                            <div>
                                <p>{item.owner_name}</p>
                            </div>
                            <div>
                                <p>{item.address}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>キャンセル</Button>
                <Button danger onClick={toDelete}>削除</Button>
            </div>
        </Modal>
    )
}

Property_delete.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Property_delete);