import React, { useState, useEffect } from "react";
import {
} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, Select } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { CaretDownOutlined } from '@ant-design/icons'
import Common_errorAlert from "../common/Common_errorAlert";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";
import { ApiOwnerAdd } from '../../network/api'
import { VW, VH } from "../../componets/function/sizeAdapter"
import { padding } from "@mui/system";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ApiTagAdd } from '../../network/api'


dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
        '& .ant-select-selection-item': {
            height: VH(30),
            marginRight: VW(24)
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    updateInput: {
        width: '100%',
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
            minHeight: VH(74),
        },
        '& > div > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: VW(760),
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function Tag_add(props) {
    const {
        classes,
        open,
        onClose,
        getList
    } = props

    useEffect(() => {
        if (!open) {
            setColorName('')
            setRrrorOpen(false)
        }
    }, [open])

    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> タグ名
    const [colorName, setColorName] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> カラー
    const [colorType, setColorType] = useState('1')

    // add api
    const toAdd = () => {
        if (colorName === '') {
            setErrorContent('タグ名を入力してください。')
            setRrrorOpen(true)
        } else {
            let params = {
                tag_name: colorName,
                color_type: Number(colorType)
            }
            log(params)
            ApiTagAdd(params).then(res => {
                if (res.status === 200) {
                    setColorName('')
                    setColorType('1')
                    setRrrorOpen(false)
                    log(res.data)
                    onClose()
                    getList('add')
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>タグの登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.updateInput} style={{ marginTop: VH(13) }}>
                    <div>
                        <div>タグ名<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                        <div>
                            <Input
                                placeholder="タグ名を入力してください"
                                value={colorName}
                                onChange={(event) => setColorName(event.target.value)}
                                maxLength={30}
                            />
                        </div>
                    </div>
                    <div>
                        <div>カラー</div>
                        <div>
                            <Select
                                style={{ width: '40.9%', height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16) }}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                suffixIcon={<CaretDownOutlined style={{ color: '#5B5B5B' }} />}
                                value={colorType}
                                onChange={(e) => { setColorType(e) }}
                            >
                                <Option style={{ height: VH(40) }} value='1'>
                                    <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#FFB872', color: '#FFB872' }}></div>
                                </Option>
                                <Option style={{ height: VH(40) }} value='2'>
                                    <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#BB99FE', color: '#FFB872' }}></div>
                                </Option>
                                <Option style={{ height: VH(40) }} value='3'>
                                    <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#80BFFF', color: '#FFB872' }}></div>
                                </Option>
                                <Option style={{ height: VH(40) }} value='4'>
                                    <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#BCFA38', color: '#FFB872' }}></div>
                                </Option>
                                <Option style={{ height: VH(40) }} value='5'>
                                    <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#F9CFF9', color: '#FFB872' }}></div>
                                </Option>
                                <Option style={{ height: VH(40) }} value='6'>
                                    <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#ADEAFF', color: '#FFB872' }}></div>
                                </Option>
                                <Option style={{ height: VH(40) }} value='7'>
                                    <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#85F8B3', color: '#FFB872' }}></div>
                                </Option>
                                <Option style={{ height: VH(40) }} value='8'>
                                    <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#FF8CCF', color: '#FF8CCF' }}></div>
                                </Option>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={toAdd}>登録</Button>
                </div>
            </div>
        </Modal >
    )
}

Tag_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Tag_add);