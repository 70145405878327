import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_calendar_front(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(8)} height={VH(12)} viewBox="0 0 8 12" style={{ display: 'block' }}>
            <path d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z" fill={color ? color : '#5B5B5B'} />
        </svg>
    )
}
export default PropertyDetail_calendar_front;


