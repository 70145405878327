import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_antiTheftLamp() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width={VW(24)} height={VH(24)} rx="2" fill="#2F83FF" />
            <path d="M17.25 9L6.75 9C6.75 11.9025 9.0975 14.25 12 14.25C14.9025 14.25 17.25 11.9025 17.25 9Z" fill="white" />
            <path d="M17.2824 16.1163L18.3431 15.0557L16.7521 13.4647L15.6915 14.5253L17.2824 16.1163Z" fill="white" />
            <path d="M11.25 18H12.75V15.75H11.25V18Z" fill="white" />
            <path d="M4.5 7.5L19.5 7.5V6L4.5 6V7.5Z" fill="white" />
            <path d="M6.69306 16.0951L8.28359 14.5034L7.22249 13.4431L5.63196 15.0347L6.69306 16.0951Z" fill="white" />
        </svg>
    )
}

export default Property_detail_antiTheftLamp;

