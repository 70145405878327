import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_calendar_date(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(16)} height={VH(16)} viewBox="0 0 16 16" style={{ display: 'block' }}>
            <path d="M13.333 2.00002H12.6663V0.666687H11.333V2.00002H4.66634V0.666687H3.33301V2.00002H2.66634C1.93301 2.00002 1.33301 2.60002 1.33301 3.33335V14C1.33301 14.7334 1.93301 15.3334 2.66634 15.3334H13.333C14.0663 15.3334 14.6663 14.7334 14.6663 14V3.33335C14.6663 2.60002 14.0663 2.00002 13.333 2.00002ZM13.333 14H2.66634V5.33335H13.333V14Z" fill="#757575" />
        </svg>
    )
}

export default PropertyDetail_calendar_date;