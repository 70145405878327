import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom"
import { withStyles } from "@mui/styles";
import Login from "../login/Login";
import NewPassWord from '../login/NewPassword'
import SendCode from '../login/SendCode'
import ChangePassword from "../login/ChangePassword";
import ChangeOK from '../login/ChangeOK'

const styles = (theme) => ({

});

let Timer = null

function LoginRouters(props) {
    const {
        classes
    } = props


    // useEffect(() => {
    //     window.addEventListener("resize", resizeWidth)
    //     return () => {
    //         window.removeEventListener("resize", resizeWidth)
    //     }
    // }, [])


    // const resizeWidth = (e) => {
    //     localStorage.setItem('windowSize', (e.target.innerWidth - e.target.outerWidth) / 2)
    //     if (Timer) {
    //         clearTimeout(Timer)
    //     }
    //     Timer = setTimeout(() => {
    //         window.location.reload()
    //     }, 300)
    // }

    return (
        <Routes>
            <Route path="*" element={<Login />} />
            <Route path="NewPassword" element={<NewPassWord />} />
            <Route path="SendCode" element={<SendCode />} />
            <Route path="SendCode/ChangePassword" element={<ChangePassword />} />
            <Route path="ChangeOK" element={<ChangeOK />} />
        </Routes>
    )
}


LoginRouters.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(LoginRouters);