import { VW, VH } from "../../componets/function/sizeAdapter"
function AddIcon_small() {

    return (
        <svg width={VW(16)} height={VH(16)} viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="#2F83FF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00454 4.50903C7.61894 4.50903 7.30636 4.82162 7.30636 5.20722V7.30176H5.21185C4.82626 7.30176 4.51367 7.61435 4.51367 7.99995C4.51367 8.38554 4.82626 8.69813 5.21185 8.69813H7.30636V10.7927C7.30636 11.1783 7.61894 11.4909 8.00454 11.4909C8.39013 11.4909 8.70272 11.1783 8.70272 10.7927V8.69813H10.7973C11.1829 8.69813 11.4955 8.38554 11.4955 7.99995C11.4955 7.61435 11.1829 7.30176 10.7973 7.30176H8.70272V5.20721C8.70272 4.82162 8.39013 4.50903 8.00454 4.50903Z" fill="white" />
        </svg>

    )
}

export default AddIcon_small;