import React, { useEffect, useState, useRef } from "react";
import {
    TablePagination
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { Input, Button } from 'antd'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ClearRounded } from "@mui/icons-material"
import log from "../../componets/function/log";
import { useNavigate } from 'react-router-dom'
import Common_checkBoxTable from "../../componets/common/Common_checkBoxTable";
import Common_errorAlert from '../../componets/common/Common_errorAlert'
import Common_successAlert from "../../componets/common/Common_successAlert";
import Common_pagenation from '../../componets/common/Common_pagination';
import { VW, VH } from "../../componets/function/sizeAdapter"
import AddIcon from '@mui/icons-material/Add';
import Tag_add from "../../componets/dialog/Tag_add";
import Tag_delete from "../../componets/dialog/Tag_delete";
import { ApiSagyohyoList } from '../../network/api'
import MenuHeader_inspectionList from "../../componets/icon/MenuHeader_inspectionList";
import Inspection_delete from "../../componets/dialog/Inspection_delete";
import Inspection_add from "../../componets/dialog/Inspection_add";

const styles = (theme) => ({
    ground: {
        width: '100%',
        height: '100%',
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8)
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    retrievalBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        marginTop: VH(12),
        paddingLeft: VW(16),
        paddingRight: VW(16),
        height: VH(72),
        justifyContent: 'space-between',
        '& > Input': {
            width: VW(832),
            height: VH(40),
            fontSize: VW(16)
        },
        '& > Button': {
            width: VW(112),
            height: VH(40),
            fontSize: VW(16),
            marginLeft: VW(16),
            backgroundColor: theme.palette.primary.main,
        }
    },
    AddDeleteBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: VH(24),
        '& > Button': {
            marginLeft: VW(8),
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
        },
        '& > Button:nth-of-type(1)': {
            color: '#E60024',
        },
        '& > Button:nth-of-type(2)': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
        }
    },
    iconContainer: {
        color: '#999',
    },
    tableBlock: {
        marginRight: VW(24),
        paddingTop: '1vh'
    },
    toolbar: {
        "& > p:nth-of-type(2)": {
            fontSize: VW(12),
            color: "#333333",
            fontWeight: 400
        },

    },
    selectLabel: {
        "& .MuiTablePagination-selectLabel": {
            fontSize: VW(12),
            color: "#333333",
            fontWeight: 400
        }
    }
});

function InspectionList(props) {
    const {
        classes,
        setSelectFlag,
    } = props



    useEffect(() => {
        setSelectFlag(routerNames.InspectionList)
    }, [setSelectFlag])





    const navigate = useNavigate()


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // list api
    const getList = (successFlag) => {
        if (localStorage.getItem('inspection_deleteFlag')) {
            setSuccessContent('削除が完了しました。')
            setSuccessOpen(true)
            localStorage.removeItem('inspection_deleteFlag')
        }
        let params = {
            query_content: encodeURIComponent(keywordSearch),
            pageNum: page + 1,
            pageLimit: rowsPerPage,
            sortKey: pagingFlag.current,
            sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc'
        }
        ApiSagyohyoList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                document.getElementById('father_Block').scrollTop = 0;
                localStorage.setItem('inspection_keywordSearch', keywordSearch)
                log(resData)
                setRrrorOpen(false)
                setCount(resData.count)
                setBodyList([...resData.data])
                if (successFlag === 'add') {
                    setSuccessContent('登録が完了しました。')
                    setSuccessOpen(true)
                }
                if (successFlag === 'delete') {
                    setSuccessContent('削除が完了しました。')
                    setSuccessOpen(true)
                }
                setDeleteData([])
                setIndeterminate(false)
                setCheckAll(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // 検索
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  キーワード input
    const [keywordSearch, setKeywordSearch] = useState(localStorage.getItem('inspection_keywordSearch') == null || localStorage.getItem('inspection_keywordSearch') == undefined ? '' : localStorage.getItem('inspection_keywordSearch'))
    const [searchParam, setSearchParam] = useState(localStorage.getItem('inspection_searchParam') == null || localStorage.getItem('inspection_searchParam') == undefined ? '' : localStorage.getItem('inspection_searchParam'))
    const search = () => {
        setSearchParam(keywordSearch)
        setPage(0)
        setCurrentPage(0)
    }

    // Common_checkBoxTable
    const [titleList, setTitleList] = useState([
        { titleIcon: '↓', name: '点検表', field: 'name', width: '40%', canClick: true },
        { titleIcon: '↓', name: '作成日', field: 'ins_date', width: '20%' },
        { titleIcon: '↓', name: '最終更新日', field: 'upd_date', width: '40%' }
    ])
    const [bodyList, setBodyList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  update
    const getItemData = (item) => {
        log(item)
        navigate(routerNames.InspectionListDetail, { state: item })
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete
    const [deleteData, setDeleteData] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  sort
    const [count, setCount] = useState(0)
    const pagingFlag = useRef('ins_date')
    const pagingDirection = useRef('↑')
    const toSort = (item) => {
        log(item)
        pagingFlag.current = item.field
        pagingDirection.current = item.titleIcon
        titleList.map(titleItem => {
            if (titleItem.name === item.name) {
                titleItem.titleIcon = item.titleIcon === '↓' ? '↑' : '↓'
                titleItem.checked = true
            } else {
                titleItem.checked = false
            }
        })
        setTitleList([...titleList])
        getList()
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [page, setPage] = useState(localStorage.getItem('inspection_page') ? Number(localStorage.getItem('inspection_page')) : 0);
    const [currentPage, setCurrentPage] = useState(localStorage.getItem('inspection_page') ? Number(localStorage.getItem('inspection_page')) : 0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('inspection_rowsPerPage') ? Number(localStorage.getItem('inspection_rowsPerPage')) : 50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setCurrentPage(newPage)
        localStorage.setItem('inspection_page', String(newPage))
    };
    const handleChangePageForPagination = (event, newPage) => {
        const page = newPage - 1
        setPage(page)
        setCurrentPage(page)
        localStorage.setItem('inspection_page', String(page))
    };
    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('inspection_rowsPerPage', String(event.target.value))
        setRowsPerPage(event.target.value);
        setPage(0);
        localStorage.setItem('inspection_page', '0')
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);


    // dialog
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }


    // get list
    useEffect(() => {
        getList()
    }, [page, rowsPerPage, searchParam])

    useEffect(() => {
        window.addEventListener('keydown', enter);
        return () => {
            window.removeEventListener('keydown', enter);
        };
    }, [keywordSearch])
    const enter = (e) => {
        switch (e.keyCode) {
            case 13:
                search()
                break;
        }
    }

    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}>点検表一覧</div>
            <div className={classes.tableTitle} style={{ marginBottom: VH(12) }}>
                <MenuHeader_inspectionList />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>点検表マスタ一覧</div>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
            />
            <div className={classes.retrievalBlock}>
                <Input
                    placeholder='キーワードを入力してください'
                    value={keywordSearch}
                    style={{
                        // width: VW(874),
                        height: VH(40),
                        fontSize: VW(16)
                    }}
                    onChange={(event) => { setKeywordSearch(event.target.value) }}
                    suffix={
                        (
                            <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                setKeywordSearch('')
                            }}></ClearRounded>
                        )
                    }
                />
                <Button type="primary" icon={<SearchOutlined />} style={{ fontWeight: 'bold' }} onClick={search}>検索</Button>
            </div>
            <div className={classes.AddDeleteBlock}>
                <Button type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} disabled={deleteData.length === 0} onClick={openDeleteDialog}>削除</Button>
                <Button type="primary" icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openAddDialog}>登録</Button>
            </div>
            <TablePagination
                style={{ fontSize: VW(12) }}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
                labelRowsPerPage='表示件数:'
                className={classes.selectLabel}
                classes={{
                    toolbar: classes.toolbar
                }}
            />
            <div className={classes.tableBlock}>
                <Common_checkBoxTable
                    titleList={titleList}
                    bodyList={bodyList}
                    setBodyList={setBodyList}
                    getItemData={getItemData}
                    setCheckList={setDeleteData}
                    toSort={toSort}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                />
            </div>
            <Inspection_add
                open={addOpen}
                onClose={addOnClose}
                getList={getList}
            />
            <Inspection_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={deleteData}
                setDeleteData={setDeleteData}
                getList={getList}
                setIndeterminate={setIndeterminate}
                setCheckAll={setCheckAll}
            />
            <Common_pagenation
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChange={handleChangePageForPagination}
            />
        </div>
    )
}

InspectionList.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(InspectionList); 