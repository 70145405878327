import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_file_file(props) {
    const {
        leftMargin
    } = props
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ float: 'left', marginRight: VW(8), marginLeft: leftMargin ? leftMargin : 0 }}>
            <path d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z" fill="#0052CC" />
        </svg>
    )
}
export default PropertyDetail_file_file;


