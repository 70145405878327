import { VW, VH } from "../../componets/function/sizeAdapter"
function Inspection_clean() {
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" style={{ display: 'block' }}>
            <path d="M10.0003 1.66699C5.39199 1.66699 1.66699 5.39199 1.66699 10.0003C1.66699 14.6087 5.39199 18.3337 10.0003 18.3337C14.6087 18.3337 18.3337 14.6087 18.3337 10.0003C18.3337 5.39199 14.6087 1.66699 10.0003 1.66699ZM14.167 12.992L12.992 14.167L10.0003 11.1753L7.00866 14.167L5.83366 12.992L8.82533 10.0003L5.83366 7.00866L7.00866 5.83366L10.0003 8.82533L12.992 5.83366L14.167 7.00866L11.1753 10.0003L14.167 12.992Z" fill="#E0ECFF" />
        </svg>
    )
}

export default Inspection_clean;


