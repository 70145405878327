import React, { useEffect, useState, Fragment } from "react";
import {
    Button,
    TextField,
    IconButton,
    Alert
} from "@mui/material";
import log from "../componets/function/log";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { routerNames } from '../wordsFile/wordsFile'
import { Visibility, VisibilityOff } from "@mui/icons-material"
import Common_errorAlert from "../componets/common/Common_errorAlert";
import { formatCheck, errorMessage } from "../wordsFile/wordsFile";
import { ApiToken, ApiUserUserauthority } from '../network/api'
import qs from "qs"
import { VW, VH } from "../componets/function/sizeAdapter"
import trademark from '../componets/picture/trademark.png'



const styles = (theme) => ({
    groundBlock: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        // borderBottom: '1px solid #EBE2E2',
        // borderRight: '1px solid #EBE2E2',
    },
    LoginView: {
        width: VW(680),
        backgroundColor: '#FFF',
        boxShadow: '0px 0.26vw 1.05vh 0.26vw #EAEFF3',
        textAlign: 'center',
        paddingTop: VH(40),
        position: 'absolute',
    },
    tittleIcon: {
        width: '100%',
        textAlign: 'center',
        fontSize: VW(24),
        fontWeight: 'bold'
    },
    label: {
        width: '100%',
        display: "flex",
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24)
    },
    text: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontSize: VW(16)
    },
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        marginTop: VH(40),
        fontSize: VW(16),
        fontWeight: 'bold'
    },
    input: {
        height: VH(48),
        fontSize: VW(16),
    },
    inputContent: {
        padding: VH(14),
        fontSize: VW(16),
    },
    linkFont: {
        color: '#0052CC',
        marginTop: VH(24),
        marginBottom: VH(54),
        fontSize: VW(16),
        '& > span': {
            cursor: 'pointer'
        }
    },
    errorAlert: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24)
    }
});

function Login(props) {
    const {
        classes,
    } = props


    useEffect(() => {
        let realEstateRowsPerPage = localStorage.getItem('realEstate_rowsPerPage')
        let ownersListRowsPerPage = localStorage.getItem('ownersList_rowsPerPage')
        let propertyRowsPerPage = localStorage.getItem('property_rowsPerPage')
        let notificationRowsPerPage = localStorage.getItem('notification_rowsPerPage')
        let tagRowsPerPage = localStorage.getItem('tag_rowsPerPage')
        let businessRowsPerPage = localStorage.getItem('business_rowsPerPage')
        let claimRowsPerPage = localStorage.getItem('claim_rowsPerPage')
        let repairRowsPerPage = localStorage.getItem('repair_rowsPerPage')
        let memberRowsPerPage = localStorage.getItem('member_rowsPerPage')
        let equipmentRecordsRowsPerPage = localStorage.getItem('equipment_records_rowsPerPage')
        let fileRowsPerPage = localStorage.getItem('file_rowsPerPage')
        let equipmentListRowsPerPage = localStorage.getItem('equipmentList_rowsPerPage')
        let inspectionListRowsPerPage = localStorage.getItem('inspectionList_rowsPerPage')


        // let windowSize = localStorage.getItem('windowSize')
        localStorage.clear()
        // if (windowSize) {
        //     localStorage.setItem('windowSize', windowSize)
        // }
        if (realEstateRowsPerPage) {
            localStorage.setItem('realEstate_rowsPerPage', realEstateRowsPerPage)
        }
        if (ownersListRowsPerPage) {
            localStorage.setItem('ownersList_rowsPerPage', ownersListRowsPerPage)
        }
        if (propertyRowsPerPage) {
            localStorage.setItem('property_rowsPerPage', propertyRowsPerPage)
        }
        if (notificationRowsPerPage) {
            localStorage.setItem('notification_rowsPerPage', notificationRowsPerPage)
        }
        if (tagRowsPerPage) {
            localStorage.setItem('tag_rowsPerPage', tagRowsPerPage)
        }
        if (businessRowsPerPage) {
            localStorage.setItem('business_rowsPerPage', businessRowsPerPage)
        }
        if (claimRowsPerPage) {
            localStorage.setItem('claim_rowsPerPage', claimRowsPerPage)
        }
        if (repairRowsPerPage) {
            localStorage.setItem('repair_rowsPerPage', repairRowsPerPage)
        }
        if (memberRowsPerPage) {
            localStorage.setItem('member_rowsPerPage', memberRowsPerPage)
        }
        if (equipmentRecordsRowsPerPage) {
            localStorage.setItem('equipment_records_rowsPerPage', equipmentRecordsRowsPerPage)
        }
        if (fileRowsPerPage) {
            localStorage.setItem('file_rowsPerPage', fileRowsPerPage)
        }
        if (equipmentListRowsPerPage) {
            localStorage.setItem('equipmentList_rowsPerPage', equipmentListRowsPerPage)
        }
        if (inspectionListRowsPerPage) {
            localStorage.setItem('inspectionList_rowsPerPage', inspectionListRowsPerPage)
        }


        // window.history.pushState(null, null, window.location.href);
        // window.onpopstate = function (event) {
        //     window.history.go(1);
        // }
        // return () => {
        //     window.onpopstate = function (event) {
        //     }
        // };

        let groundBox = document.getElementById('loginGround')
        localStorage.setItem('innerWidth', groundBox.getBoundingClientRect().width)
        localStorage.setItem('innerHeight', groundBox.getBoundingClientRect().height)
    }, [])



    // password display
    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    // login api
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()
    const login = () => {
        if (username === '') {
            setErrorContent(errorMessage.nameEmptyError)
            setRrrorOpen(true)
        } else if (!formatCheck.Email.test(username)) {
            setErrorContent(errorMessage.nameFormatError)
            setRrrorOpen(true)
        } else if (password === '') {
            setErrorContent(errorMessage.passwordNull)
            setRrrorOpen(true)
        } else {
            setRrrorOpen(false)
            let params = {
                username: username,
                password: password
            }
            ApiToken(params).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    log(resData)
                    if (resData.ChallengeName) {
                        navigate(routerNames.NewPassword, { state: { session: resData.Session, email: username } })
                    } else {
                        localStorage.setItem('refresh_token', resData.refresh_token)
                        localStorage.setItem('access_token', resData.access_token)
                        ApiUserUserauthority().then(userRes => {
                            if (userRes.status === 200) {
                                log(userRes.data)
                                localStorage.setItem('tenant_id', userRes.data.tenant_id)
                                localStorage.setItem('authority_id', userRes.data.authority_id)
                                localStorage.setItem('userObject', qs.stringify(userRes.data))
                                if (userRes.data.authority_id == '5') {
                                    navigate(routerNames.NotificationManagement)
                                } else {
                                    navigate(routerNames.Notification)
                                }

                            } else {
                                log(userRes)
                                setErrorContent(userRes.data.detail)
                                setRrrorOpen(true)
                            }

                        }).catch(err => {

                        })
                    }
                } else {
                    setErrorContent(resData.detail)
                    setRrrorOpen(true)
                }
            }).catch(err => {

            })
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', enter);
        return () => {
            window.removeEventListener('keydown', enter);
        };
    }, [username, password])
    const enter = (e) => {
        switch (e.keyCode) {
            case 13:
                login()
                break;
        }
    }


    // to sendCode
    const toSendCode = () => {
        navigate(routerNames.SendCode)
    }


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    return (
        <div className={classes.groundBlock} id='loginGround'>
            <div className={classes.LoginView}>
                <img
                    style={{ height: VH(70), objectFit: "contain", marginBottom: VH(10) }}
                    src={trademark}
                />
                <div className={classes.tittleIcon}>ログイン</div>
                {errorOpen && <div className={classes.errorAlert}>
                    <div style={{ width: VW(410) }}>
                        <Common_errorAlert
                            open={errorOpen}
                            setRrrorOpen={setRrrorOpen}
                            errorContent={errorContent}
                        />
                    </div>
                </div>}
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', fontSize: VW(14), marginBottom: VH(8) }}>メールアドレス <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        name="username"
                        autoComplete="username"
                        sx={{ width: VW(400) }}
                        placeholder="メールアドレスを入力してください"
                        onChange={(event) => { setUsername(event.target.value) }}
                        value={username}
                        InputProps={{
                            className: classes.input,
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', fontSize: VW(14), marginBottom: VH(8) }}>パスワード <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        name="password"
                        autoComplete="password"
                        sx={{ width: VW(400) }}
                        type={showPassword ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setPassword(event.target.value) }}
                        value={password}
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            className: classes.input,
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>

                <Button
                    className={classes.loginButton}
                    variant="contained"
                    style={{ width: VW(400), height: VH(48) }}
                    onClick={login}
                >ログイン
                </Button>

                <div className={classes.linkFont}>
                    <span onClick={toSendCode}>パスワードをお忘れの場合</span>
                </div>
            </div>
        </div>
    )
}

Login.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Login);