import React, { useEffect, useState, useRef } from "react";
import {
    TextField
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { useNavigate } from 'react-router-dom'
import { VW, VH } from "../../componets/function/sizeAdapter"
import { Button, Input } from 'antd'
import CreateIcon from '@mui/icons-material/Create';
import PersonalData_icon from "../../componets/icon/PersonalData_icon";
import PersonalData_icon_large from "../../componets/icon/PersonalData_icon_large ";
import { useLocation } from "react-router-dom";
import log from "../../componets/function/log";
import { UploadS3 } from "../../componets/function/UploadS3";
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { ApiUserHeadsculpture, ApiUserUpdate } from '../../network/api'
import Common_errorAlert from '../../componets/common/Common_errorAlert'
import { formatCheck, errorMessage } from "../../wordsFile/wordsFile";
import qs from 'qs'


const styles = (theme) => ({
    ground: {
        width: '100%',
        height: '100%',
        position: 'relative',
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8)
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    headSculpture: {
        height: VH(314),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        paddingBottom: VH(32),
        '& > div': {
            width: '200px',
            height: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button': {
            width: VW(360),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: '#333',
            border: '1px solid #AAA',
            marginTop: VH(8),
            borderRadius: '4px'
        },
    },
    contentBody: {
        display: 'flex',
        borderTop: '1px solid #DDD',
        '& > div': {
            height: VH(74),
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: VW(752),
        },
        '& > div > Input': {
            height: VH(50)
        }
    },
    passwordBlock: {
        display: 'flex',
        borderTop: '1px solid #DDD',
        '& > div': {
            height: VH(370),
            fontSize: VW(16),
            paddingLeft: VW(16)
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            alignItems: 'center',
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: VW(752),
        },
        '& > div > Input': {
            height: VH(50)
        }
    },
    bottomButton: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
});

function PersonalDataDetail(props) {
    const {
        classes,
    } = props

    useEffect(() => {
        log(state)
    }, [])


    // 显示loading
    function showLoading() {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.createRoot(dom).render(
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
                <div style={{ fontWeight: "bold", color: '#DDD' }}>処理中です</div>
                <div style={{ fontWeight: "bold", color: '#DDD' }}>しばらくお待ちください</div>
            </div>
        )
    }
    // 隐藏loading
    function hideLoading() {
        document.body.removeChild(document.getElementById('loading'))
    }



    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;

    const [userData, setUserData] = useState(state)

    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // date
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>> head sculpture url
    const [headerUrl, setHeaderUrl] = useState(userData.head_sculpture)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>> 名
    const [name, setName] = useState(userData.name)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>> 姓
    const [surname, setSurname] = useState(userData.surname)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>> 所属
    const [affiliation, setAffiliation] = useState(userData.affiliation)
    // old password
    const [oldPassword, setOldPassword] = useState('')
    // new password
    const [newPassword, setNewPassword] = useState('')
    // new password again
    const [newPasswordAgain, setNewPasswordAgain] = useState('')



    const newData = useRef('')
    const fileName = useRef('')
    const uploadImg = (item) => {
        showLoading()
        log(item)
        newData.current = dayjs().format("YYYYMMDDHHmmss")
        fileName.current = item.target.files[0]['name']
        UploadS3(state.user_id, item.target.files[0], item.target.files[0]['type'], callBack, newData.current)
    }
    const callBack = (response) => {
        log(response)
        if (response.error) {
            hideLoading()
            setErrorContent('アップロードに失敗しました')
            setRrrorOpen(true)
        }
        if (response.success) {
            hideLoading()
            log(fileName.current)
            log(newData.current)
            let params = {
                str_time: newData.current,
                file_name: fileName.current
            }
            ApiUserHeadsculpture(params).then(res => {
                let resData = res.data
                if (res.status == 200) {
                    log(resData)
                    setHeaderUrl(resData.data)
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    // update api
    const toUpdate = () => {
        document.getElementById('father_Block').scrollTop = 0;
        if (name == '' || surname == '') {
            setErrorContent('氏名を入力してください')
            setRrrorOpen(true)
        } else if ((newPassword != '' || newPasswordAgain != '') && oldPassword == '') {
            setErrorContent('現在のパスワードを入力してください')
            setRrrorOpen(true)
        } else if (oldPassword != '' && (newPassword == '' || newPasswordAgain == '')) {
            setErrorContent('新しいパスワードを入力してください')
            setRrrorOpen(true)
        } else if (oldPassword != '' && (newPassword != newPasswordAgain)) {
            setErrorContent('新しいパスワード（確認用）が新しいパスワードと一致しません。')
            setRrrorOpen(true)
        } else if (newPassword != '' && newPasswordAgain != '' && oldPassword != '' && (!formatCheck.Password.test(newPassword) || !formatCheck.Password.test(newPasswordAgain) || !formatCheck.Password.test(oldPassword))) {
            setErrorContent(errorMessage.passwordFormatError)
            setRrrorOpen(true)
        } else {
            let params = {
                // user_id: state.user_id,
                surname: surname,
                name: name,
                affiliation: affiliation,
                head_sculpture: fileName.current,
                str_time: newData.current,
                old_password: oldPassword,
                password: newPassword,
                second_password: newPasswordAgain
            }
            ApiUserUpdate(params).then(res => {
                let resData = res.data
                if (res.status == 200) {
                    log(resData)
                    window.history.back()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}>プロフィール</div>
            <div className={classes.tableTitle}>
                <PersonalData_icon />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>プロフィールの編集</div>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            <div className={classes.headSculpture}>
                {headerUrl ?
                    <div style={{ width: '200px', height: '200px', borderRadius: '50%', marginBottom: VH(24), overflow: 'hidden' }}>
                        <img
                            src={headerUrl}
                            width="100%"
                            height="100%"
                        />
                    </div>
                    :
                    <PersonalData_icon_large />
                }
                <Button style={{ position: 'relative', zIndex: '999' }}>写真をアップロード<input
                    style={{ width: '100%', height: '100%', position: 'absolute', opacity: 0 }} type="file" accept=".png,.jpg,.gif,.jpeg,.heic,.heif" onChange={uploadImg} /></Button>
            </div>
            <div className={classes.contentBody}>
                <div>氏名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                <div>
                    <Input
                        style={{ width: VW(168), fontSize: VW(16), borderRadius: '4px' }}
                        value={surname}
                        placeholder="姓"
                        onChange={(event) => { setSurname(event.target.value) }}
                        maxLength={30}

                    />
                    <Input
                        style={{ width: VW(168), fontSize: VW(16), borderRadius: '4px', marginLeft: VW(16) }}
                        value={name}
                        placeholder="名"
                        onChange={(event) => { setName(event.target.value) }}
                        maxLength={30}
                    />
                </div>
            </div>
            <div className={classes.contentBody}>
                <div>メールアドレス</div>
                <div>{userData.email}</div>
            </div>
            <div className={classes.contentBody}>
                <div>所属</div>
                <div>
                    <Input
                        style={{ width: VW(720), fontSize: VW(16), borderRadius: '4px' }}
                        value={affiliation}
                        placeholder="所属を入力してください"
                        onChange={(event) => { setAffiliation(event.target.value) }}
                        maxLength={60}
                    />
                </div>
            </div>
            <div className={classes.contentBody}>
                <div>権限</div>
                <div>{userData.authority_name}</div>
            </div>
            <div className={classes.passwordBlock} style={{ borderBottom: '1px solid #DDD' }}>
                <div>パスワード</div>
                <div>
                    <div style={{ marginTop: VH(12) }}>※パスワードは8文字以上、英大文字、英小文字、数字をそれぞれ最低1つずつ含める必要があります。</div>
                    <div style={{ marginTop: VH(16), fontSize: VW(14) }}>現在のパスワード</div>
                    <Input
                        type="text"
                        name="username"
                        autoComplete="username"
                        value={qs.parse(localStorage.getItem('userObject')).email}
                        style={{ position: "absolute", width: 0, height: 0, zIndex: -9999, marginTop: VH(10) }}
                    />
                    <Input.Password
                        style={{ width: VW(720), height: VH(50), fontSize: VW(16), borderRadius: '4px', marginTop: VH(8) }}
                        placeholder="現在のパスワードを入力してください"
                        autoComplete="new-password"
                        value={oldPassword}
                        onChange={(event) => { setOldPassword(event.target.value) }}
                    />
                    <div style={{ marginTop: VH(16), fontSize: VW(14) }}>新しいパスワード</div>
                    <Input.Password
                        style={{ width: VW(720), height: VH(50), fontSize: VW(16), borderRadius: '4px', marginTop: VH(8) }}
                        placeholder="新しいパスワードを入力してください"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={(event) => { setNewPassword(event.target.value) }}
                    />
                    <div style={{ marginTop: VH(16), fontSize: VW(14) }}>新しいパスワード（確認用）</div>
                    <Input.Password
                        style={{ width: VW(720), height: VH(50), fontSize: VW(16), borderRadius: '4px', marginTop: VH(8) }}
                        placeholder="新しいパスワードを入力してください"
                        autoComplete="new-password"
                        value={newPasswordAgain}
                        onChange={(event) => { setNewPasswordAgain(event.target.value) }}
                    />
                </div>
            </div>
            <div className={classes.bottomButton}>
                <Button onClick={() => { window.history.back() }}>キャンセル</Button>
                <Button onClick={toUpdate}>更新</Button>
            </div>
            <div style={{ height: VH(40) }}></div>
        </div>
    )
}

PersonalDataDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PersonalDataDetail);