import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import PropertyDetail_calendar_first from "../../componets/icon/PropertyDetail_calendar_first";
import PropertyDetail_calendar_second from "../../componets/icon/PropertyDetail_calendar_second";
import PropertyDetail_calendar_third from "../../componets/icon/PropertyDetail_calendar_third";

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    headerBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        marginTop: VH(16),
        backgroundColor: '#F8F8F8',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: '100%',
            fontWeight: 'bold',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw'
        },
        '& > div:nth-child(1)': {
            width: '11%'
        },
        '& > div:nth-child(2)': {
            width: '12%'
        },
        '& > div:nth-child(3)': {
            width: '14%'
        },
        '& > div:nth-child(4)': {
            width: '14%'
        },
        '& > div:nth-child(5)': {
            width: '39%'
        },
        '& > div:nth-child(6)': {
            width: '11%'
        }
    },
    scrollBlock: {
        width: '100%',
        maxHeight: VH(224),
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
    bodyBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDD',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw',
            overflow: 'hidden'
        },
        '& > div:nth-child(1)': {
            width: '11%'
        },
        '& > div:nth-child(2)': {
            width: '12%'
        },
        '& > div:nth-child(3)': {
            width: '14%'
        },
        '& > div:nth-child(4)': {
            width: '14%'
        },
        '& > div:nth-child(5)': {
            width: '39%'
        },
        '& > div:nth-child(6)': {
            width: '11%'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_calendar_add_confirm(props) {
    const {
        classes,
        open,
        onClose,
        titleName,
        setCopyFlag
    } = props






    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                {titleName == '清掃' && <div><PropertyDetail_calendar_first /></div>}
                {titleName == '点検' && <div><PropertyDetail_calendar_second /></div>}
                {titleName == '各種支払い' && <div><PropertyDetail_calendar_third /></div>}
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{titleName}のスケジュール</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <div style={{ fontSize: VW(16) }}>前年分のスケジュールをコピーします。<br />すでに入力された予定は上書きされますがコピーしますか？</div>
            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={() => { setCopyFlag(true) }}>コピーする</Button>
            </div>
        </Modal>
    )
}
PropertyDetail_calendar_add_confirm.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_calendar_add_confirm);