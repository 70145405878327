import { VW, VH } from "../../componets/function/sizeAdapter"
function SelectColor_Tag() {
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" fill="none" style={{ display: 'block' }}>
            <path d="M17.8417 9.65002L10.3417 2.15002C10.0417 1.85002 9.62508 1.66669 9.16675 1.66669H3.33341C2.41675 1.66669 1.66675 2.41669 1.66675 3.33335V9.16669C1.66675 9.62502 1.85008 10.0417 2.15841 10.35L9.65842 17.85C9.95842 18.15 10.3751 18.3334 10.8334 18.3334C11.2917 18.3334 11.7084 18.15 12.0084 17.8417L17.8417 12.0084C18.1501 11.7084 18.3334 11.2917 18.3334 10.8334C18.3334 10.375 18.1417 9.95002 17.8417 9.65002ZM4.58341 5.83335C3.89175 5.83335 3.33341 5.27502 3.33341 4.58335C3.33341 3.89169 3.89175 3.33335 4.58341 3.33335C5.27508 3.33335 5.83342 3.89169 5.83342 4.58335C5.83342 5.27502 5.27508 5.83335 4.58341 5.83335Z" fill="#2F83FF" />
        </svg>

    )
}

export default SelectColor_Tag;