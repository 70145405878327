import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"
import { Modal, Button, Input, DatePicker, Select, Radio } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import locale from "antd/es/date-picker/locale/ja_JP";


const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    updateInput: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
        width: '98%',
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
            minHeight: VH(74),
        },
        '& > div > div > Input': {
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: '24.2%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: '75.8%',
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        }
    },
    customerBlock: {
        '& > div': {
            height: VH(124),
        },
        '& > div:nth-child(1)': {
            width: VW(146)
        },
        '& > div:nth-child(2)': {
            width: VW(593),
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                '& > Input': {
                    height: VH(50),
                    fontSize: VW(16),
                    borderRadius: "4px"
                },
            }
        }
    },
});

function PropertyDetail_equipment_records_input(props) {
    const {
        classes,
        detailedData,
        changeDetailedData,
        setsubi_idOption,
        state
    } = props




    return (
        <div className={classes.updateInput} style={{ marginTop: VH(16) }}>
            <div>
                <div>実施日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(272), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.implementation_date ? dayjs(detailedData.implementation_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'implementation_date')}
                    />
                </div>
            </div>
            <div>
                <div>スペース</div>
                <div>
                    <Select
                        style={{ width: VW(360), }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.setsubi_id == undefined ? undefined : detailedData.setsubi_id}
                        onChange={(event) => changeDetailedData(event, 'setsubi_id')}
                        allowClear
                        onClear={() => changeDetailedData(undefined, 'setsubi_id')}
                    >
                        {setsubi_idOption.length > 0 && setsubi_idOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.setsubi_id}>{item.room_num}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div>
                <div>対象設備<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                <div>
                    <Input
                        style={{ width: '100%' }}
                        placeholder="対象設備を記入してください"
                        maxLength={60}
                        value={detailedData.setsubi}
                        onChange={(event) => changeDetailedData(event.target.value, 'setsubi')}
                    />
                </div>
            </div>
            <div style={{ height: VH(304) }}>
                <div>実施内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(280) }}
                        placeholder="実施内容を記入してください"
                        maxLength={500}
                        value={detailedData.implementation_content}
                        onChange={(event) => changeDetailedData(event.target.value, 'implementation_content')}
                    />
                </div>
            </div>
            <div>
                <div>次回実施日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(272), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        format="YYYY/MM/DD"
                        locale={locale}
                        value={detailedData.next_implementation_date ? dayjs(detailedData.next_implementation_date) : ''}
                        onChange={(event) => changeDetailedData(event, 'next_implementation_date')}
                    />
                </div>
            </div>
        </div>
    )
}

PropertyDetail_equipment_records_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_records_input);