import { VW, VH } from "../../componets/function/sizeAdapter"
function System_warning(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(64)} height={VH(64)} viewBox="0 0 64 64" fill="none">
            <path d="M34.6667 26.6668H29.3334V40.0002H34.6667V26.6668Z" fill="#0052CC" />
            <path d="M34.6667 42.6668H29.3334V48.0002H34.6667V42.6668Z" fill="#0052CC" />
            <path d="M32.0001 15.9735L52.0801 50.6668H11.9201L32.0001 15.9735ZM32.0001 5.3335L2.66675 56.0002H61.3334L32.0001 5.3335Z" fill="#0052CC" />
        </svg>

    )
}

export default System_warning;