import React, { useState, useEffect, useRef } from "react";
import {
} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, Select, Radio } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import Common_errorAlert from "../common/Common_errorAlert";
import { ApiSagyohyoAdd, ApiSagyohyoReadyData } from '../../network/api'
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Inspection_picture_first from "./Inspection_picture_first";
import Inspection_picture_last from "./Inspection_picture_last";
import Question_mark from "../icon/Question_mark";
import Inspection_cross from "../icon/Inspection_cross";
import Inspection_clean from "../icon/Inspection_clean";
import Inspection_move from "../icon/Inspection_move";
import CrossIcon from "../icon/CrossIcon";
import Forward from "../icon/Forward";
import Backward from "../icon/Backward";

dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
        '& .ant-select-selection-item': {
            height: VH(30),
            marginRight: VW(24)
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    updateInput: {
        width: '100%',
    },
    titleBlock: {
        height: 'auto',
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            minHeight: VH(74),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(200),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: VW(792),
            '& > Input': {
                height: VH(50),
                fontSize: VW(16)
            }
        },
    },
    commonItemTitle: {
        marginTop: VH(40),
        width: '100%',
        height: VH(24),
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        fontSize: VW(16),
        fontWeight: 'bold',
        paddingLeft: VW(12),
        display: 'flex',
        alignItems: 'center'
    },
    commonItemDataT: {
        width: '100%',
        height: 'auto',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderBottom: '1px solid #DDD',
        marginTop: VH(16),
        '& > div': {
            width: '100%',
            display: 'flex',
        },
        '& > div > div': {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& > div:nth-child(1)': {
            height: VH(48),
            backgroundColor: "#F8F8F8",
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        }
    },
    commonItemDataB: {
        width: '100%',
        height: 'auto',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        '& > div': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& > div:nth-child(1)': {
            height: VH(48),
            backgroundColor: "#F8F8F8",
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
    },
    scrollBlock: {
        width: '100%',
        position: 'relative',
        '& > div:nth-child(2)': {
            position: 'absolute',
            cursor: 'pointer',
        },
        '& > div:nth-child(3)': {
            position: 'absolute',
            cursor: 'pointer',
        }
    },
    tabTitle: {
        width: '100%',
        display: 'flex',
        marginTop: VH(16),
        overflowX: 'auto',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        '& > div:not(:last-child)': {
            width: VW(160),
            height: VH(64),
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: '#E0ECFF',
            border: `1px solid ${theme.palette.primary.main}`,
            borderBottom: 0,
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: VW(16),
            fontWeight: 'bold',
            cursor: 'pointer',
            flexShrink: 0
        },
        '& > div > Input': {
            width: VW(120),
            height: VH(48),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            textAlign: 'center'
        },
        '& > div:last-child': {
            width: VW(119),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: VW(16),
            cursor: 'pointer',
            flexShrink: 0,
            color: theme.palette.primary.main,
            '& > div:nth-child(1)': {
                marginRight: VW(8),
                marginTop: VH(2)
            }
        }
    },
    tabHeader: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#F8F8F8',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        '& > div': {
            height: VH(48),
            display: 'flex',
            alignItems: 'center',
            padding: VW(16),
            fontSize: VW(16),
            fontWeight: 'bold',
        },
        '& > div:nth-child(1)': {
            width: '2.3%',
            borderRight: `1px solid #FFF`,
        },
        '& > div:nth-child(2)': {
            width: '25.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '25.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(4)': {
            width: '42%',
            borderRight: `1px solid #FFF`,
        },
        '& > div:nth-child(5)': {
            width: '5.1%',
        },
    },
    tabBody: {
        display: 'flex',
        width: '100%',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        minHeight: VH(48),
        userSelect: 'none',
        '& Input': {
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div': {
            minHeight: VH(48),
            paddingTop: VH(12),
            padding: VW(16),
            fontSize: VW(16),
        },
        '& > div:nth-child(1)': {
            width: '2.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EEE',
            cursor: 'pointer'
        },
        '& > div:nth-child(2)': {
            width: '25.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '25.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(4)': {
            width: '42%',
            paddingBottom: VH(4),
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
            '& > div': {
                wdith: '100%',
                height: VH(50),
                marginBottom: VH(8),
                display: 'flex',
                alignItems: 'center',
                fontSize: VW(16),
            }
        },
        '& > div:nth-child(5)': {
            width: '5.1%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > div': {
                cursor: 'pointer'
            }
        },
    },
    tabHeaderItem: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#F8F8F8',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        '& > div': {
            height: VH(48),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16),
            fontWeight: 'bold',
        },
        '& > div:nth-child(1)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(2)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '48.4%'
        },
    },
    tabBodyItem: {
        display: 'flex',
        width: '100%',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        minHeight: VH(48),
        '& > div': {
            minHeight: VH(48),
            paddingTop: VH(12),
            paddingLeft: VW(16),
            fontSize: VW(16),
        },
        '& > div:nth-child(1)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(2)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '48.4%',
            paddingBottom: VH(4),
            '& > div': {
                marginBottom: VH(8)
            }
        },
    },
    questionMark: {
        marginLeft: VW(8),
        cursor: 'pointer'
    },
    radioBlock: {
        width: '100%',
        marginTop: VH(12)
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function Inspection_add(props) {
    const {
        classes,
        open,
        onClose,
        getList
    } = props

    useEffect(() => {
        if (!open) {
            setRrrorOpen(false)
            setDetailedData({
                category: 0,
                name: '',
                common_flag: 0,
                settings_flag: 0
            })
        } else {
            getAddList()
        }
    }, [open])

    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')




    // all Data
    const [allData, setAllData] = useState({})

    // detailedData
    const [detailedData, setDetailedData] = useState({
        category: 0,
        name: '',
        common_flag: 0,
        settings_flag: 0
    })


    //  change detailedData
    const changeDetailedData = (value, type) => {
        if (type == 'category' && value == 0) {
            if (detailedData.common_flag == 0) {
                setM_keka_data(allData.m_keka_data)
            }
            if (detailedData.settings_flag == 0) {
                setM_keka_hosoku_data(allData.m_keka_hosoku_data)
            }
            allData.sagyo_shubetsu_data[0].checked = true
            allData.sagyo_shubetsu_data.map((item, index) => {
                item.id = index + 1
                item.sagyo_komoku.map((TItem, TIdex) => {
                    TItem.id = TIdex + 1
                })
            })
            setSagyo_shubetsu_data(allData.sagyo_shubetsu_data)
            setDisplayData(allData.sagyo_shubetsu_data[0].sagyo_komoku)
        } else if (type == 'category' && value == 1) {
            if (detailedData.common_flag == 0) {
                setM_keka_data(allData.c_m_keka_data)
            }
            if (detailedData.common_flag == 1 && m_keka_data.length == 4) {
                m_keka_data.splice(3, 1)
                setM_keka_data([...m_keka_data])
            }
            if (detailedData.settings_flag == 0) {
                setM_keka_hosoku_data(allData.c_m_keka_hosoku_data)
            }
            let newary = []
            newary.push({
                id: 1,
                checked: true,
                sagyo_komoku: [],
                sagyo_shubetsu_name: ''
            })
            setSagyo_shubetsu_data(newary)
            setDisplayData([])
        }


        if (type == 'common_flag' && value == 0) {
            if (detailedData.category == 0) {
                setM_keka_data(allData.m_keka_data)
            } else {
                setM_keka_data(allData.c_m_keka_data)
            }
        } else if (type == 'common_flag' && value == 1) {
            setM_keka_data([{ name: '', sname: '' }])
        }


        if (type == 'settings_flag' && value == 0) {
            if (detailedData.category == 0) {
                setM_keka_hosoku_data(allData.m_keka_hosoku_data)
            } else {
                setM_keka_hosoku_data(allData.c_m_keka_hosoku_data)
            }
        } else if (type == 'settings_flag' && value == 1) {
            setM_keka_hosoku_data([{ name: '' }])
        }
        detailedData[type] = value
        setDetailedData({ ...detailedData })
    }

    // 結果項目設定 list 
    const [m_keka_data, setM_keka_data] = useState([])
    // 問題程度 list
    const [m_keka_hosoku_data, setM_keka_hosoku_data] = useState([])

    // change 結果項目設定 list
    const changeM_keka_data = (value, type, index) => {
        m_keka_data[index][type] = value
        setM_keka_data([...m_keka_data])
    }
    // change 問題程度 list
    const changeM_keka_hosoku_data = (value, type, index) => {
        m_keka_hosoku_data[index][type] = value
        setM_keka_hosoku_data([...m_keka_hosoku_data])
    }
    // add  結果項目設定 item
    const addM_keka_dataItem = () => {
        m_keka_data.push({ 'name': '', 'sname': '' })
        setM_keka_data([...m_keka_data])
    }
    // add  問題程度 item
    const addM_keka_hosoku_dataItem = () => {
        m_keka_hosoku_data.push({ 'name': '' })
        setM_keka_hosoku_data([...m_keka_hosoku_data])
    }
    // delete 結果項目設定 item
    const deleteM_keka_dataItem = (index) => {
        m_keka_data.splice(index, 1)
        setM_keka_data([...m_keka_data])
    }
    // delete 問題程度 item
    const deleteM_keka_hosoku_dataItem = (index) => {
        m_keka_hosoku_data.splice(index, 1)
        setM_keka_hosoku_data([...m_keka_hosoku_data])
    }

    // 点検項目設定 list
    const [sagyo_shubetsu_data, setSagyo_shubetsu_data] = useState([{ checked: true }])
    // display data
    const [displayData, setDisplayData] = useState([])

    // title move
    const [draggedItem, setDraggedItem] = useState(null);
    const handleDragStart = (event, index) => {
        setDraggedItem(sagyo_shubetsu_data[index]);
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('text/plain', '');
    };
    const handleDragEnter = (index) => {
        if (!draggedItem) return;
        if (draggedItem.id === sagyo_shubetsu_data[index].id) return;
        sagyo_shubetsu_data.splice(index, 0, sagyo_shubetsu_data.splice(sagyo_shubetsu_data.indexOf(draggedItem), 1)[0]);
        setSagyo_shubetsu_data([...sagyo_shubetsu_data]);
    };
    const handleDragEnd = () => {
        setDraggedItem(null);
    };
    const selectTab = (item, index) => {
        sagyo_shubetsu_data.map(listitem => {
            listitem.checked = false
        })
        sagyo_shubetsu_data[index].checked = true
        setDisplayData([...sagyo_shubetsu_data[index].sagyo_komoku])
        setSagyo_shubetsu_data([...sagyo_shubetsu_data])
    }
    // body move
    const [draggedItemBody, setDraggedItemBody] = useState(null);
    const handleDragStartBody = (event, index) => {
        setDraggedItemBody(displayData[index]);
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('text/plain', '');
    };
    const handleDragEnterBody = (index) => {
        if (!draggedItemBody) return;
        if (draggedItemBody.id === displayData[index].id) return;
        displayData.splice(index, 0, displayData.splice(displayData.indexOf(draggedItemBody), 1)[0]);
        setDisplayData([...displayData]);
    };
    const handleDragEndBody = () => {
        setDraggedItemBody(null);
    };
    // change sagyo_shubetsu_data
    const changeSagyo_shubetsu_dataItem = (value, index, type, TIndex) => {
        if (TIndex != null && TIndex != undefined && type) {
            displayData[index]['hokoku_select'][TIndex][type] = value
            setDisplayData([...displayData])
        } else if (type != null && type != undefined && !TIndex) {
            displayData[index][type] = value
            setDisplayData([...displayData])
        } else if ((type == null || type == undefined) && !TIndex) {
            sagyo_shubetsu_data[index]['sagyo_shubetsu_name'] = value
            setSagyo_shubetsu_data([...sagyo_shubetsu_data])
        }
    }
    // add sagyo_shubetsu_data
    const addListItem = () => {
        sagyo_shubetsu_data.map(item => {
            item.checked = false
        })
        if (detailedData.category == 0) {
            sagyo_shubetsu_data.splice(sagyo_shubetsu_data.length - 1, 0, {
                checked: true,
                sagyo_shubetsu_name: '',
                sagyo_shubetsu_id: null,
                sagyo_komoku: [{
                    'sagyo_naiyo': '',
                    'sagyo_naiyo_hosoku': '',
                    'hokoku_select': [],
                }]
            })
        } else {
            sagyo_shubetsu_data.push({
                checked: true,
                sagyo_shubetsu_name: '',
                sagyo_shubetsu_id: null,
                sagyo_komoku: [{
                    'sagyo_naiyo': '',
                    'sagyo_naiyo_hosoku': '',
                    'hokoku_select': [],
                }]
            })
        }

        sagyo_shubetsu_data.map((item, index) => {
            item.id = index + 1
        })
        setSagyo_shubetsu_data([...sagyo_shubetsu_data])
        if (detailedData.category == 0) {
            setDisplayData([...sagyo_shubetsu_data[sagyo_shubetsu_data.length - 2].sagyo_komoku])
        } else {
            setDisplayData([...sagyo_shubetsu_data[sagyo_shubetsu_data.length - 1].sagyo_komoku])
        }
    }
    // delete sagyo_shubetsu_data
    const deleteSagyo_shubetsu_dataItem = (e, index) => {
        e.stopPropagation()
        sagyo_shubetsu_data.splice(index, 1)
        sagyo_shubetsu_data.map((item, Tindex) => {
            item.id = Tindex + 1
            item.checked = false
        })
        if (sagyo_shubetsu_data[index] == undefined) {
            sagyo_shubetsu_data[index - 1].checked = true
            setSagyo_shubetsu_data([...sagyo_shubetsu_data])
            setDisplayData([...sagyo_shubetsu_data[index - 1].sagyo_komoku])
        } else {
            sagyo_shubetsu_data[index].checked = true
            setSagyo_shubetsu_data([...sagyo_shubetsu_data])
            setDisplayData([...sagyo_shubetsu_data[index].sagyo_komoku])
        }
    }

    // add displayItem
    const addDisplayItem = () => {
        displayData.push({
            'sagyo_naiyo': '',
            'sagyo_naiyo_hosoku': '',
            'hokoku_select': [],
        })
        displayData.map((item, index) => {
            item.id = index + 1
        })
        setDisplayData([...displayData])
    }
    // delete hokokoItem
    const deleteHokokoItem = (index, TIndex) => {
        displayData[index]['hokoku_select'].splice(TIndex, 1)
        setDisplayData([...displayData])
    }
    // add hokokoItem
    const addHokokoItem = (index) => {
        displayData[index]['hokoku_select'].push({ 'hokoku_select': '' })
        setDisplayData([...displayData])
    }
    // delete displayItem
    const deleteDisplayItem = (index) => {
        displayData.splice(index, 1)
        displayData.map((item, idx) => {
            item.id = idx + 1
        })
        setDisplayData([...displayData])
    }

    useEffect(() => {
        sagyo_shubetsu_data.map(item => {
            if (item.checked) {
                item.sagyo_komoku = displayData
            }
            setSagyo_shubetsu_data([...sagyo_shubetsu_data])
        })
    }, [displayData])
    // list api
    const getAddList = () => {
        ApiSagyohyoReadyData().then(res => {
            let resData = res.data
            if (res.status === 200) {
                log(resData)
                setAllData(resData)
                setM_keka_data(resData.m_keka_data)
                setM_keka_hosoku_data(resData.m_keka_hosoku_data)
                resData.sagyo_shubetsu_data[0].checked = true
                resData.sagyo_shubetsu_data.map((item, index) => {
                    item.id = index + 1
                    item.sagyo_komoku.map((TItem, TIdex) => {
                        TItem.id = TIdex + 1
                    })
                })
                setDisplayData([...resData.sagyo_shubetsu_data[0].sagyo_komoku])
                setSagyo_shubetsu_data([...resData.sagyo_shubetsu_data])
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    // add api
    const toAdd = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        let params = detailedData
        params.m_keka_data = m_keka_data
        params.m_keka_hosoku_data = m_keka_hosoku_data
        params.sagyo_shubetsu_data = sagyo_shubetsu_data
        params.sagyo_shubetsu_data.map(item => {
            delete (item.id)
            delete (item.checked)
            item.sagyo_komoku.map(itemChild => {
                delete (itemChild.id)
            })
        })
        ApiSagyohyoAdd(params).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                onClose()
                getList('add')
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    // 結果項目設定  dialog
    const [firstOpen, setFirstOpen] = useState(false)
    const firstClose = () => {
        setFirstOpen(false)
    }
    const openFirstPicture = () => {
        setFirstOpen(true)
    }
    // 点検項目設定  dialog
    const [lastOpen, setLastOpen] = useState(false)
    const lastClose = () => {
        setLastOpen(false)
    }
    const openLastPicture = () => {
        setLastOpen(true)
    }


    // scroll button flag
    const [leftFlag, setLeftFlag] = useState(false)
    const [rightFlag, setRightFlag] = useState(false)
    // left move
    const leftMove = () => {
        let parent = document.getElementById('inspectionAddParent')
        parent.scrollLeft = parent.scrollLeft - 480
    }
    // right move
    const rightMove = () => {
        let parent = document.getElementById('inspectionAddParent')
        parent.scrollLeft = parent.scrollLeft + 480
    }


    useEffect(() => {
        if (open) {
            let parent = document.getElementById('inspectionAddParent')
            if (parent.scrollLeft + parent.clientWidth != parent.scrollWidth) {
                setRightFlag(true)
            }
            parent.addEventListener('scroll', function () {
                if (parent.scrollLeft > 0) {
                    setLeftFlag(true)
                } else {
                    setLeftFlag(false)
                }
                if (parent.scrollLeft + parent.clientWidth == parent.scrollWidth) {
                    setRightFlag(false)
                } else {
                    setRightFlag(true)
                }
            });
        }
    }, [open])

    useEffect(() => {
        if (open) {
            let parent = document.getElementById('inspectionAddParent')
            if (parent.scrollLeft + parent.clientWidth != parent.scrollWidth) {
                setRightFlag(true)
            } else {
                setRightFlag(false)
            }
        }
    }, [sagyo_shubetsu_data])


    const backFc = () => {
        return
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>点検表の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.updateInput} style={{ marginTop: VH(13) }}>
                    <div className={classes.titleBlock}>
                        <div>点検表種類</div>
                        <div>
                            <Radio.Group onChange={(e) => { changeDetailedData(e.target.value, 'category') }} value={detailedData.category}>
                                <Radio value={0} style={{ fontSize: VW(16) }} defaultChecked>定期巡回</Radio>
                                <Radio value={1} style={{ marginLeft: VW(40), fontSize: VW(16) }}>マンスリー</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className={classes.titleBlock} style={{ borderBottom: '1px solid #DDD' }}>
                        <div>点検表名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                        <div>
                            <Input
                                value={detailedData.name}
                                placeholder="点検表名"
                                onChange={(e) => { changeDetailedData(e.target.value, 'name') }}
                                maxLength={60}
                            />
                        </div>
                    </div>
                    <div className={classes.commonItemTitle}>
                        結果項目設定
                        <div className={classes.questionMark} onClick={openFirstPicture}>
                            <Question_mark />
                        </div>
                    </div>
                    <div className={classes.radioBlock}>
                        <Radio.Group onChange={(e) => { changeDetailedData(e.target.value, 'common_flag') }} value={detailedData.common_flag}>
                            <Radio value={0} style={{ fontSize: VW(16) }} defaultChecked>デフォルト</Radio>
                            <Radio value={1} style={{ marginLeft: VW(40), fontSize: VW(16) }}>カスタム</Radio>
                        </Radio.Group>
                    </div>
                    {detailedData.common_flag == 0
                        ?
                        <div className={classes.commonItemDataT}>
                            <div>
                                <div>点検結果</div>
                                <div> 結果（表示）</div>
                            </div>
                            {m_keka_data.length > 0 && m_keka_data.map((item, index) => {
                                return (
                                    <div key={index} style={{ height: VH(38) }}>
                                        <div>
                                            <p>{(index + 1) + '. ' + item.name}</p>
                                        </div>
                                        <div>
                                            <p>{item.sname}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className={classes.commonItemDataT}>
                            <div>
                                <div>点検結果<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                                <div> 結果（表示）<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                            </div>
                            {m_keka_data.length > 0 && m_keka_data.map((item, index) => {
                                return (
                                    <div key={index} style={{ minHeight: VH(38) }}>
                                        <div>
                                            <p style={{ width: VW(16) }}>{index + 1 + '.'}</p>
                                            <Input
                                                style={{ width: VW(434), height: VH(50), fontSize: VW(16), marginLeft: VW(16) }}
                                                value={item.name}
                                                placeholder={'点検結果 ' + (index + 1)}
                                                onChange={(e) => { changeM_keka_data(e.target.value, 'name', index) }}
                                                maxLength={10}
                                            />
                                        </div>
                                        <div>
                                            <Input
                                                style={{ width: VW(432), height: VH(50), fontSize: VW(16), marginRight: VW(16) }}
                                                value={item.sname}
                                                placeholder={'結果表示 ' + (index + 1)}
                                                onChange={(e) => { changeM_keka_data(e.target.value, 'sname', index) }}
                                                maxLength={10}
                                            />
                                            {m_keka_data.length != 1 &&
                                                <div style={{ cursor: 'pointer' }} onClick={() => { deleteM_keka_dataItem(index) }}><Inspection_cross /></div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            {((m_keka_data.length < 4 && detailedData.category != 1) || (m_keka_data.length < 3 && detailedData.category == 1)) &&
                                <div style={{ color: '#0052CC' }}>
                                    <div></div>
                                    <div style={{ justifyContent: 'flex-end', paddingRight: VW(56), height: VH(48) }}>
                                        <div style={{ cursor: 'pointer', marginTop: VH(2), marginRight: VW(8) }} onClick={addM_keka_dataItem}>
                                            <AddIcon />
                                        </div>
                                        <div style={{ cursor: 'pointer' }} onClick={addM_keka_dataItem}>選択肢を追加する</div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <div className={classes.radioBlock} style={{ marginBottom: VH(12) }}>
                        <Radio.Group onChange={(e) => { changeDetailedData(e.target.value, 'settings_flag') }} value={detailedData.settings_flag}>
                            <Radio value={0} style={{ fontSize: VW(16) }} defaultChecked>デフォルト</Radio>
                            <Radio value={1} style={{ marginLeft: VW(40), fontSize: VW(16) }}>カスタム</Radio>
                        </Radio.Group>
                    </div>
                    {detailedData.settings_flag == 0
                        ?
                        <div className={classes.commonItemDataB}>
                            <div>問題程度</div>
                            {m_keka_hosoku_data.length > 0 && m_keka_hosoku_data.map((item, index) => {
                                return (
                                    <div key={index} style={{ height: VH(38) }}>
                                        <p>{(index + 1) + '. ' + item.name}</p>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className={classes.commonItemDataB}>
                            <div>問題程度<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                            {m_keka_hosoku_data.length > 0 && m_keka_hosoku_data.map((item, index) => {
                                return (
                                    <div key={index} style={{ minHeight: VH(38) }}>
                                        <p style={{ width: VW(16) }}>{index + 1 + '.'}</p>
                                        <Input
                                            style={{ width: VW(432), height: VH(50), fontSize: VW(16), marginLeft: VW(16) }}
                                            value={item.name}
                                            placeholder={'問題程度 ' + (index + 1)}
                                            onChange={(e) => { changeM_keka_hosoku_data(e.target.value, 'name', index) }}
                                            maxLength={200}
                                        />
                                        {m_keka_hosoku_data.length != 1 &&
                                            <div style={{ cursor: 'pointer', marginLeft: VW(16) }} onClick={() => { deleteM_keka_hosoku_dataItem(index) }}><Inspection_cross /></div>
                                        }
                                    </div>
                                )
                            })}
                            <div style={{ justifyContent: 'flex-end', paddingRight: VW(56), height: VH(48), color: '#0052CC' }}>
                                <div style={{ cursor: 'pointer', marginTop: VH(2), marginRight: VW(8) }} onClick={addM_keka_hosoku_dataItem}>
                                    <AddIcon />
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={addM_keka_hosoku_dataItem}>選択肢を追加する</div>
                            </div>
                        </div>
                    }
                    <div className={classes.commonItemTitle}>
                        点検項目設定
                        <div className={classes.questionMark} onClick={openLastPicture}>
                            <Question_mark />
                        </div>
                    </div>
                    <div className={classes.scrollBlock}>
                        <div className={classes.tabTitle} id='inspectionAddParent'>
                            {sagyo_shubetsu_data.map((item, index) => {
                                if (detailedData.category == 0 && (index == 0 || index == sagyo_shubetsu_data.length - 1)) {
                                    return (
                                        <div
                                            key={index}
                                            style={{ backgroundColor: item.checked ? '#0052CC' : '', color: item.checked ? '#FFF' : '' }}
                                            onClick={() => { selectTab(item, index) }}
                                        >
                                            {item.sagyo_shubetsu_name}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                backgroundColor: item.checked ? '#0052CC' : '',
                                                color: item.checked ? '#FFF' : '',
                                                position: 'relative'
                                            }}
                                            draggable
                                            onDragStart={(event) => handleDragStart(event, index)}
                                            onDragEnter={() => handleDragEnter(index)}
                                            onDragEnd={handleDragEnd}
                                            onClick={() => { selectTab(item, index) }}
                                        >
                                            {item.checked &&
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontWeight: 'bold',
                                                        position: 'absolute',
                                                        zIndex: '9999',
                                                        marginLeft: VW(75),
                                                        marginBottom: VW(5),
                                                    }}>*</span>
                                            }
                                            {item.checked ?
                                                <Input
                                                    value={item.sagyo_shubetsu_name}
                                                    placeholder="点検種別名"
                                                    onChange={(e) => { changeSagyo_shubetsu_dataItem(e.target.value, index) }}
                                                    maxLength={60}
                                                />
                                                :
                                                item.sagyo_shubetsu_name
                                            }
                                            {item.checked && sagyo_shubetsu_data.length != 1 && <div style={{ marginLeft: VW(5), marginBottom: VH(35) }} onClick={(e) => { deleteSagyo_shubetsu_dataItem(e, index) }}>
                                                <Inspection_clean />
                                            </div>}
                                        </div>
                                    )
                                }
                            })}
                            <div onClick={addListItem}>
                                <div><AddIcon /></div>
                                <div>追加</div>
                            </div>
                        </div>
                        <div onClick={leftFlag ? leftMove : backFc} style={{ top: leftFlag ? VH(16) : VH(18), left: leftFlag ? VW(0) : VW(2) }}>
                            <Forward flag={leftFlag} />
                        </div>
                        <div onClick={rightFlag ? rightMove : backFc} style={{ top: rightFlag ? VH(16) : VH(18), right: rightFlag ? VW(0) : VW(2) }}>
                            <Backward flag={rightFlag} />
                        </div>
                    </div>
                    {(detailedData.category == 1 || (detailedData.category == 0 && !sagyo_shubetsu_data[0]['checked'] && !sagyo_shubetsu_data[sagyo_shubetsu_data.length - 1]['checked'])) ?
                        <div className={classes.tabHeader}>
                            <div></div>
                            <div>点検項目名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                            <div>点検方法</div>
                            <div>報告事項</div>
                            <div></div>
                        </div>
                        :
                        <div className={classes.tabHeaderItem}>
                            <div>点検項目名</div>
                            <div>点検方法</div>
                            <div>報告事項</div>
                        </div>
                    }

                    {(detailedData.category == 1 || (detailedData.category == 0 && !sagyo_shubetsu_data[0]['checked'] && !sagyo_shubetsu_data[sagyo_shubetsu_data.length - 1]['checked'])) && displayData.length > 0 && displayData.map((item, index) => {
                        return (
                            <div className={classes.tabBody} key={index}>
                                <div
                                    draggable
                                    onDragStart={(event) => handleDragStartBody(event, index)}
                                    onDragEnter={() => handleDragEnterBody(index)}
                                    onDragEnd={handleDragEndBody}
                                >
                                    <div><Inspection_move /></div>
                                </div>
                                <div>
                                    <Input
                                        value={item.sagyo_naiyo}
                                        placeholder="点検項目名"
                                        onChange={(e) => { changeSagyo_shubetsu_dataItem(e.target.value, index, 'sagyo_naiyo') }}
                                        maxLength={200}
                                    />
                                </div>
                                <div>
                                    <Input
                                        value={item.sagyo_naiyo_hosoku}
                                        placeholder="点検方法"
                                        onChange={(e) => { changeSagyo_shubetsu_dataItem(e.target.value, index, 'sagyo_naiyo_hosoku') }}
                                        maxLength={200}
                                    />
                                </div>
                                <div>
                                    {item.hokoku_select && item.hokoku_select.length > 0 && item.hokoku_select.map((TItem, TIndex) => {
                                        return (
                                            <div key={TIndex}>{TIndex + 1 + '. '}
                                                <Input
                                                    value={TItem.hokoku_select}
                                                    style={{ marginLeft: VW(16) }}
                                                    placeholder={'選択肢' + (TIndex + 1)}
                                                    onChange={(e) => { changeSagyo_shubetsu_dataItem(e.target.value, index, 'hokoku_select', TIndex) }}
                                                    maxLength={200}
                                                />
                                                <div style={{ marginLeft: VW(16), cursor: 'pointer' }} onClick={() => { deleteHokokoItem(index, TIndex) }}>
                                                    <Inspection_cross />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {item.hokoku_select.length < 7 &&
                                        <div style={{ color: '#0052CC', justifyContent: 'flex-end', paddingRight: VW(41) }}>
                                            <div style={{ cursor: 'pointer', marginTop: VH(2), marginRight: VW(8) }} onClick={() => { addHokokoItem(index) }}>
                                                <AddIcon />
                                            </div>
                                            <div style={{ cursor: 'pointer' }} onClick={() => { addHokokoItem(index) }}>選択肢を追加する</div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <div onClick={() => { deleteDisplayItem(index) }}><CrossIcon /></div>
                                </div>
                            </div>
                        )
                    })}
                    {(detailedData.category == 0 && (sagyo_shubetsu_data[0].checked || sagyo_shubetsu_data[sagyo_shubetsu_data.length - 1].checked)) && displayData.length > 0 && displayData.map((item, index) => {
                        return (
                            <div className={classes.tabBodyItem} key={index} >
                                <div>{item.sagyo_naiyo}</div>
                                <div>{item.sagyo_naiyo_hosoku}</div>
                                <div>
                                    {item.hokoku_select && item.hokoku_select.length > 0 && item.hokoku_select.map((TItem, TIndex) => {
                                        return (
                                            <div key={TIndex}>{TIndex + 1 + '. ' + TItem.hokoku_select}</div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                    {(detailedData.category == 1 || (detailedData.category == 0 && !sagyo_shubetsu_data[0].checked && !sagyo_shubetsu_data[sagyo_shubetsu_data.length - 1].checked)) &&
                        <div style={{ width: '100%', color: '#0052CC', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: VH(48) }}>
                            <div style={{ cursor: 'pointer', marginTop: VH(2), marginRight: VW(8) }} onClick={addDisplayItem}>
                                <AddIcon />
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={addDisplayItem}>項目を追加する</div>
                        </div >
                    }
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={toAdd}>登録</Button>
                </div>
                <Inspection_picture_first
                    open={firstOpen}
                    onClose={firstClose}
                />
                <Inspection_picture_last
                    open={lastOpen}
                    onClose={lastClose}
                />
            </div>
        </Modal >
    )
}

Inspection_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Inspection_add);