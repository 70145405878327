import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter";


const styles = (theme) => ({
    ground: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            whiteSpace: 'pre-line',
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '30%'
        },
        '& > div:nth-child(3)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(4)': {
            width: '30%'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }
});

function Item_four(props) {
    const {
        classes,
        titleName,
        content,
        height
    } = props





    return (
        <div className={classes.ground} style={{ minHeight: VH(height) }}>
            <div style={{ minHeight: VH(height) }}>{titleName[0]}</div>
            <div style={{ minHeight: VH(height) }}><p>{content[0]}</p></div>
            <div style={{ minHeight: VH(height) }}>{titleName[1]}</div>
            <div style={{ minHeight: VH(height) }}><p>{content[1]}</p></div>
        </div>
    )
}

Item_four.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Item_four);