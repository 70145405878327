import moment from 'moment'
const dic = {
    // utc time change to location time
    UTCDateToLocalDate: function (dateStr) {
        if (dateStr.indexOf('.') != -1) {
            dateStr = dateStr.substring(0, dateStr.lastIndexOf('.'))
        }
        /// fix safari
        dateStr = dateStr.replace(/-/g, '/')
        if (dateStr.indexOf("T") != -1) {
            dateStr = dateStr.split("T").join(" ")
        }
        var utc_date = new Date(dateStr + ' UTC')
        var local_date = new Date(utc_date.toString())
        var local_date_str = moment(local_date).format('YYYY/MM/DD')
        return local_date_str;
    },
    // utc time change to location time
    UTCDateToLocalDateNotice: function (dateStr) {
        if (dateStr.indexOf('.') != -1) {
            dateStr = dateStr.substring(0, dateStr.lastIndexOf('.'))
        }
        /// fix safari
        dateStr = dateStr.replace(/-/g, '/')
        if (dateStr.indexOf("T") != -1) {
            dateStr = dateStr.split("T").join(" ")
        }
        var utc_date = new Date(dateStr + ' UTC')
        var local_date = new Date(utc_date.toString())
        var local_date_str = moment(local_date).format('YYYY.MM.DD')
        return local_date_str;
    }
}

export default dic