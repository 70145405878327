import { CaretDownOutlined } from '@ant-design/icons';
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, DatePicker, Input, Modal, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { VH, VW } from "../../componets/function/sizeAdapter";
import { ApiBusinessrecordAdd } from '../../network/api';
import Common_errorAlert from "../common/Common_errorAlert";
import log from "../function/log";
import AddIcon from "../icon/AddIcon";


dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        height: VH(74),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(56)
        },
        '& > div:nth-child(odd)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(even)': {
            width: '26%'
        },
        '& > div > .ant-select-single .ant-select-selector': {
            width: '100%'
        }
    },
    groundTwo: {
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        width: '100%',
        height: VH(74),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(56),
        },
        '& > div:nth-child(1)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '76%'
        },
        '& > div > Input': {
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_business_add(props) {
    const {
        classes,
        open,
        onClose,
        state,
        getList,
        priorityValueOption
    } = props


    useEffect(() => {
        if (!open) {
            setPerson('')
            setSuggestedDate('')
            setSuggestedValue('')
            setStatusValue(undefined)
            setSubject('')
            setProposal('')
            setSuggestions('')
            setRemarks('')
            setPriorityValue(undefined)
            setCompatibleDate('')
            setCompatibleValue('')
        }
    }, [open])



    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 担当者
    const [person, setPerson] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 提案日
    const [suggestedDate, setSuggestedDate] = useState('')
    const [suggestedValue, setSuggestedValue] = useState('');
    const changeSuggestedDate = (value, dateString) => {
        setSuggestedDate(value)
        setSuggestedValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 対応期日
    const [compatibleDate, setCompatibleDate] = useState('')
    const [compatibleValue, setCompatibleValue] = useState('');
    const changeCompatibleDate = (value, dateString) => {
        setCompatibleDate(value)
        setCompatibleValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ステータス
    const [statusValueOption, setStatusValueOption] = useState([
        { value: '0', label: '提案中' },
        { value: '1', label: '保留' },
        { value: '2', label: '失注' },
        { value: '3', label: '受注' }
    ])
    const [statusValue, setStatusValue] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  優先度 select
    const [priorityValue, setPriorityValue] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 件名
    const [subject, setSubject] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 提案内容
    const [proposal, setProposal] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 提案結果
    const [suggestions, setSuggestions] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 備考
    const [remarks, setRemarks] = useState('')



    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    // add api 
    const addItem = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        if (subject == '') {
            setErrorContent('件名を記入してください')
            setRrrorOpen(true)
        } else {
            let params = {
                buken_id: state.buken_id,
                manager: person,
                proposal_date: suggestedValue,
                status: (statusValue == undefined || statusValue == null) ? -1 : Number(statusValue),
                subject: subject,
                proposal_content: proposal,
                proposal_result: suggestions,
                remarks: remarks,
                priority: (priorityValue == undefined || priorityValue == null) ? -1 : Number(priorityValue),
                compatible_date: compatibleValue,
            }
            log(params)
            ApiBusinessrecordAdd(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    getList('add')
                    onClose()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }
    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>営業記録の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.groundFour}>
                    <div>提案日</div>
                    <div>
                        <DatePicker
                            style={{ width: '15.4vw', height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={suggestedDate}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeSuggestedDate}
                        />
                    </div>
                    <div>優先度</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={priorityValue}
                            onChange={(event) => setPriorityValue(event)}
                            allowClear
                            onClear={() => { setPriorityValue(undefined) }}
                        >
                            {priorityValueOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>
                                        <span style={{ color: item.color }} >●</span>&nbsp;{item.label}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className={classes.groundFour}>
                    <div>対応期日</div>
                    <div>
                        <DatePicker
                            style={{ width: '15.4vw', height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={compatibleDate}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeCompatibleDate}
                        />
                    </div>
                    <div>ステータス</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={statusValue}
                            onChange={(event) => setStatusValue(event)}
                            allowClear
                            onClear={() => { setStatusValue(undefined) }}
                        >
                            {statusValueOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className={classes.groundTwo} >
                    <div>担当者</div>
                    <div>
                        <Input
                            style={{ width: VW(360) }}
                            placeholder="担当者"
                            value={person}
                            onChange={(event) => setPerson(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo} >
                    <div>オーナー</div>
                    <div>{state.owner_name}</div>
                </div>
                <div className={classes.groundTwo} >
                    <div>件名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                    <div>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="件名を記入してください"
                            value={subject}
                            onChange={(event) => setSubject(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div style={{ height: VH(304) }} className={classes.groundTwo} >
                    <div>提案内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                    <div>
                        <TextArea
                            style={{ height: VH(280) }}
                            placeholder="提案内容を記入してください"
                            value={proposal}
                            onChange={(event) => setProposal(event.target.value)}
                            maxLength={500}
                        />
                    </div>
                </div>
                <div style={{ height: VH(304) }} className={classes.groundTwo} >
                    <div>提案結果<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                    <div>
                        <TextArea
                            style={{ height: VH(280) }}
                            placeholder="提案結果を記入してください"
                            value={suggestions}
                            onChange={(event) => setSuggestions(event.target.value)}
                            maxLength={500}
                        />
                    </div>
                </div>
                <div style={{ height: VH(304), borderBottom: '1px solid #DDD' }} className={classes.groundTwo} >
                    <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                    <div>
                        <TextArea
                            style={{ height: VH(280) }}
                            placeholder="備考を記入してください"
                            value={remarks}
                            onChange={(event) => setRemarks(event.target.value)}
                            maxLength={500}
                        />
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={addItem}>登録</Button>
                </div>
            </div>
        </Modal>
    )
}

PropertyDetail_business_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_business_add);