import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_file_folder_open() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ float: 'left', marginRight: VW(8) }}>
            <mask id="mask0_10674_3451" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width={VW(24)} height={VH(24)}>
                <rect width={VW(24)} height={VH(24)} fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_10674_3451)">
                <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8H4V18L6.4 10H23.5L20.925 18.575C20.7917 19.0083 20.5458 19.3542 20.1875 19.6125C19.8292 19.8708 19.4333 20 19 20H4Z" fill="#0052CC" />
            </g>
        </svg>
    )
}
export default PropertyDetail_file_folder_open;


