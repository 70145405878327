// 1280 * 720
function VW(width) {
    // const vw = (width / 1280) * 100;
    // const vw = (width / (1280 + Number(localStorage.getItem('windowSize')))) * 100;
    return `${width}px`;
}

function VH(height) {
    // const vh = (height / 720) * 100;
    return `${height}px`;
}

export { VW, VH };
