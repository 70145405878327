import { VW, VH } from "../../componets/function/sizeAdapter"
function Forward(props) {
    const {
        flag
    } = props
    if (flag) {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" style={{ display: 'block' }}>
                <circle cx="16" cy="16" r="13" fill="#0052CC" />
                <mask id="mask0_11996_8175" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                    <rect x="32" y="32" width="32" height="32" transform="rotate(-180 32 32)" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_11996_8175)">
                    <path d="M15.0997 15.9997L19.1997 11.8997L17.333 9.99967L11.333 15.9997L17.333 21.9997L19.1997 20.0997L15.0997 15.9997ZM15.9997 2.66634C17.8441 2.66634 19.5775 3.01634 21.1997 3.71634C22.8219 4.41634 24.233 5.36634 25.433 6.56634C26.633 7.76634 27.583 9.17745 28.283 10.7997C28.983 12.4219 29.333 14.1552 29.333 15.9997C29.333 17.8441 28.983 19.5775 28.283 21.1997C27.583 22.8219 26.633 24.233 25.433 25.433C24.233 26.633 22.8219 27.583 21.1997 28.283C19.5775 28.983 17.8441 29.333 15.9997 29.333C14.1552 29.333 12.4219 28.983 10.7997 28.283C9.17745 27.583 7.76634 26.633 6.56634 25.433C5.36634 24.233 4.41634 22.8219 3.71634 21.1997C3.01634 19.5775 2.66634 17.8441 2.66634 15.9997C2.66635 14.1552 3.01635 12.4219 3.71634 10.7997C4.41635 9.17745 5.36634 7.76634 6.56634 6.56634C7.76634 5.36634 9.17745 4.41634 10.7997 3.71634C12.4219 3.01634 14.1552 2.66634 15.9997 2.66634Z" fill="white" />
                </g>
            </svg>
        )
    } else {
        return (
            <svg width={VW(28)} height={VH(28)} viewBox="0 0 28 28" style={{ display: 'block' }}>
                <path
                    d="M13.0997 13.9997L17.1997 9.89967L15.333 7.99967L9.33301 13.9997L15.333 19.9997L17.1997 18.0997L13.0997 13.9997ZM13.9997 0.666343C15.8441 0.666343 17.5775 1.01634 19.1997 1.71634C20.8219 2.41634 22.233 3.36634 23.433 4.56634C24.633 5.76634 25.583 7.17745 26.283 8.79967C26.983 10.4219 27.333 12.1552 27.333 13.9997C27.333 15.8441 26.983 17.5775 26.283 19.1997C25.583 20.8219 24.633 22.233 23.433 23.433C22.233 24.633 20.8219 25.583 19.1997 26.283C17.5775 26.983 15.8441 27.333 13.9997 27.333C12.1552 27.333 10.4219 26.983 8.79968 26.283C7.17745 25.583 5.76634 24.633 4.56634 23.433C3.36634 22.233 2.41634 20.8219 1.71634 19.1997C1.01634 17.5775 0.666345 15.8441 0.666345 13.9997C0.666345 12.1552 1.01635 10.4219 1.71634 8.79967C2.41635 7.17745 3.36634 5.76634 4.56634 4.56634C5.76634 3.36634 7.17745 2.41634 8.79968 1.71634C10.4219 1.01634 12.1552 0.666342 13.9997 0.666343Z"
                    fill='#D9D9D9'
                />
            </svg>
        )
    }

}
export default Forward;