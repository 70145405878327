import { VW, VH } from "../../componets/function/sizeAdapter"
function WrongIcon() {
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" fill="none">
            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#13A221" />
        </svg>
    )
}

export default WrongIcon;


