import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import Property_detail_router_claim from "../icon/Property_detail_router_claim";
import CorrectIcon from "../icon/CorrectIcon";
import WrongIcon from "../icon/WrongIcon";
import log from "../function/log";
import { ApiFilemanageDelete } from '../../network/api'
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Property_detail_router_repair from "../icon/Property_detail_router_repair";
import PropertyDetail_file_icon from "../icon/PropertyDetail_file_icon";
import PropertyDetail_file_folder_open_arrow from "../icon/PropertyDetail_file_folder_open_arrow";
import PropertyDetail_file_folder_close_arrow from "../icon/PropertyDetail_file_folder_close_arrow";
import PropertyDetail_file_folder_open from "../icon/PropertyDetail_file_folder_open";
import PropertyDetail_file_folder_close from "../icon/PropertyDetail_file_folder_close";
import PropertyDetail_file_file from "../icon/PropertyDetail_file_file";

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    headerBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        marginTop: VH(16),
        backgroundColor: '#F8F8F8',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: '100%',
            fontWeight: 'bold',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw'
        },
        '& > div:nth-child(1)': {
            width: '44%'
        },
        '& > div:nth-child(2)': {
            width: '17%'
        },
        '& > div:nth-child(3)': {
            width: '19%'
        },
        '& > div:nth-child(4)': {
            width: '10%'
        },
        '& > div:nth-child(5)': {
            width: '8%'
        },
    },
    scrollBlock: {
        width: '100%',
        maxHeight: VH(224),
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
    bodyBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDD',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw',
            overflow: 'hidden'
        },
        '& > div:nth-child(1)': {
            width: '44%',
            color: '#0052CC',
            cursor: 'pointer'
        },
        '& > div:nth-child(2)': {
            width: '17%'
        },
        '& > div:nth-child(3)': {
            width: '19%'
        },
        '& > div:nth-child(4)': {
            width: '10%'
        },
        '& > div:nth-child(5)': {
            width: '8%'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: '#E60024',
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_file_delete(props) {
    const {
        classes,
        open,
        onClose,
        deleteData,
        getList,
        setDeleteData,
        setIndeterminate,
        setCheckAll,
        historyFlag
    } = props


    const [newDeleteData, setNewDeleteData] = useState([])

    useEffect(() => {
        if (open) {
            log(deleteData)
            deleteData.map(item => {
                if (item.file_category == 1 || item.file_category == 2) {
                    item.open_in_dialog = false
                }
            })
            setNewDeleteData([...deleteData])
        } else {
            setTitleFlag(false)
            setRrrorOpen(false)
            setNewDeleteData([])
            log(deleteData)
        }
    }, [open])


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // delete api
    const toDelete = () => {
        if (!titleFlag) {
            setTitleFlag(true)
        } else {
            let deleteList = []
            deleteData.map(item => {
                deleteList.push({ file_manage_id: item.file_manage_id })
            })
            ApiFilemanageDelete(deleteList).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    getList('delete')
                    onClose()
                    setDeleteData([])
                    setIndeterminate(false)
                    setCheckAll(false)
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    // title text flag
    const [titleFlag, setTitleFlag] = useState(false)

    // change open flag
    const changeOpenFlag = (item, index) => {
        log(item)
        if (item.file_category == 1) {
            newDeleteData[index].open_in_dialog = !item.open_in_dialog
            newDeleteData.map(ii => {
                if (ii.parent_file_manage_id == item.file_manage_id) {
                    ii.open_in_dialog = item.open_in_dialog
                }
            })
            setNewDeleteData([...newDeleteData])
        }
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <PropertyDetail_file_icon />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>ファイルの削除</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {titleFlag ? <div style={{ color: '#E60024', fontSize: VW(16) }}>以下のフォルダ / ファイルを削除します。<br />削除されたデータは復元できませんが、本当に削除しますか？</div> : <div style={{ fontSize: VW(16) }}>以下のフォルダ / ファイルを削除します。</div>}
            <div className={classes.headerBlock}>
                <div>フォルダ名 / ファイル名</div>
                <div>アップロード</div>
                <div>物件 / 部屋</div>
                <div>形式</div>
                <div>サイズ</div>
            </div>
            <div className={classes.scrollBlock}>
                {newDeleteData && newDeleteData.map((item, index) => {
                    return (
                        <div className={classes.bodyBlock} key={index} style={{ height: item.file_category == 2 && item.open_in_dialog == false ? 0 : '', borderBottom: item.file_category == 2 && item.open_in_dialog == false ? 0 : '' }}>
                            <div onClick={() => { changeOpenFlag(item, index) }}>
                                {item.file_type_name != 'file_folder'
                                    ?
                                    <p><PropertyDetail_file_file leftMargin={item.file_type_name == 'file_in_folder' ? '70px' : ''} />{item.file_name}</p>
                                    :
                                    item.open_in_dialog ?
                                        <p><PropertyDetail_file_folder_open_arrow /><PropertyDetail_file_folder_open />{item.file_name}</p>
                                        :
                                        <p><PropertyDetail_file_folder_close_arrow /><PropertyDetail_file_folder_close />{item.file_name}</p>
                                }
                            </div>
                            <div>
                                <p>{item.upload_date}</p>
                            </div>
                            <div>
                                <p>{item.goodsAndroom}</p>
                            </div>
                            <div>
                                <p>{item.file_type}</p>
                            </div>
                            <div>
                                <p>{item.file_size}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>キャンセル</Button>
                <Button danger onClick={toDelete} disabled={deleteData.length == 0}>削除</Button>
            </div>
        </Modal>
    )
}

PropertyDetail_file_delete.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_file_delete);