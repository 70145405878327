import React, { useState, useEffect } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, Select, DatePicker } from 'antd';
import { VW, VH } from "../../componets/function/sizeAdapter";
import System_warning from '../icon/System_warning'


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
    },
    groundBlock: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: VH(40),
        flexDirection: 'column'
    },
    contentBlock: {
        textAlign: 'center',
        marginTop: VH(40),
        fontSize: VW(24),
        fontWeight: 'bold'
    },
    okButton: {
        width: VW(400),
        height: VH(48),
        marginTop: VH(40),
        marginBottom: VH(40),
        borderRadius: '4px',
        fontSize: VW(16),
        fontWeight: 'bold',
        color: "#FFF",
        backgroundColor: "#0052CC"
    }
});

function System_error_431(props) {
    const {
        classes,
        goLogin
    } = props




    return (
        <Modal
            open={true}
            className={classes.noneButton}
            centered
            width={VW(800)}
            closable={false}
        >
            <div className={classes.groundBlock}>
                <System_warning />
                <div className={classes.contentBlock}>
                    アカウントが停止されております。<br />管理者にお問い合わせください。
                </div>
                <Button className={classes.okButton} onClick={goLogin}>OK</Button>
            </div>
        </Modal >
    )
}

System_error_431.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(System_error_431);