

const routerNames = {
    Login: 'login',
    NewPassword: 'newPassword',
    SendCode: 'sendCode',
    ChangePassword: '/sendCode/changePassword',
    ChangeOK: '/changeOk',
    Home: 'home',
    PersonalData: '/home/personalData',
    PersonalDataDetail: '/home/personalData/personalDataDetail',
    DashBoard: '/home/dashBoard',
    InspectionDate: '/home/dashBoard/InspectionDate',
    Property: '/home/property',
    PropertyDetail: '/home/property/propertyDetail',
    PropertyDetail_equipment_list: '/home/property/propertyDetail_equipment_list',
    PropertyDetail_equipment: '/home/property/propertyDetail_equipment',
    PropertyDetail_calendar: '/home/property/propertyDetail_calendar',
    PropertyDetail_business: '/home/property/propertyDetail_business',
    PropertyDetail_business_detail: '/home/property/propertyDetail_business/propertyDetail_business_detail',
    PropertyDetail_claim: '/home/property/propertyDetail_claim',
    PropertyDetail_claim_detail: '/home/property/propertyDetail_claim/propertyDetail_claim_detail',
    PropertyDetail_repair: '/home/property/propertyDetail_repair',
    PropertyDetail_repair_detail: '/home/property/propertyDetail_repair/propertyDetail_repair_detail',
    PropertyDetail_equipment_records: '/home/property/propertyDetail_equipment_records',
    PropertyDetail_equipment_records_detail: '/home/property/propertyDetail_equipment_records/propertyDetail_equipment_records_detail',
    PropertyDetail_file: '/home/property/propertyDetail_file',
    RealEstate: '/home/realEstate',
    RealEstateDetail: '/home/realEstate/realEstateDetail',
    OwnersList: '/home/ownersList',
    OwnersDetail: '/home/ownersList/ownersListDetail',
    Notification: '/home/notification',
    NotificationManagement: '/home/notificationManagement',
    NotificationManagementDetail: '/home/notificationManagement/notificationManagementDetail',
    Tag: '/home/tag',
    TagDetail: '/home/tag/tagDetail',
    Member: '/home/member',
    MemberDetail: '/home/member/memberDetail',
    Reminder: '/home/reminder',
    ReminderManagement: '/home/reminderManagement',
    ReminderManagementDetail: '/home/reminderManagement/reminderManagementDetail',
    InspectionList: '/home/inspectionList',
    InspectionListDetail: '/home/inspectionList/inspectionListDetail'
}


const successMessage = {
    codeSendSuccess: '認証コードの送信に成功しました'
}


const errorMessage = {
    namePasswordFormatError: 'メールアドレスもしくはパスワードが異なります',
    nameFormatError: 'メールアドレスを正しく入力してください。',
    nameEmptyError: 'メールアドレスを入力してください。',
    passwordFormatError: 'パスワードは8文字以上、英大文字、英小文字、数字をそれぞれ最低1つずつ含める必要があります。',
    passwordNull: 'パスワードを入力してください。',
    namePasswordDif: '新しいパスワード（確認用）が新しいパスワードと一致しません。',
    tenantNameNull: '会社名を入力してください。',
    startEndDayNull: '終了日は開始日よりも先の日付に設定してください。',
    administratorNull: '管理者を追加する場合には、管理者名とメールアドレスを漏れなく入力してください。',
    ownerNameNull: ' オーナー名を入力してください。',
    emailFormatError: 'メールアドレスを正しく入力してください。',
    zipCodeFormatError: '郵便番号は、000-0000の形式で入力してください。',
    phoneFormatError: '電話番号は、0から9の数字と、+, -,  ()のみを利用して入力してください。',
    E0037: 'パパネッツ巡回契約中の物件のため削除できません。',
    E0007: 'パスワードには半角英数字、半角記号のみ含めることができます。',
    E0004: '新しいパスワードを入力してください。',
    E0010: '認証コードを入力してください。',
    E0042: 'タイトルは90文字以内で入力してください。',
    E0043: 'お知らせ本文は500文字以内で入力してください。',
    E0044: 'お知らせが削除済みのため参照できません。',
    E0045: 'お知らせが削除済みのため編集できません。',
    noticeDateNull: 'お知らせ配信期間を入力してください。',
    E0046: 'リマインダー本文は500文字以内で入力してください。',
    E0047: 'リマインダーが削除済みのため参照できません。',
    E0048: 'リマインダーが削除済みのため編集できません。',
    reminderDateNull: 'リマインダー配信期間を入力してください。'
}



const formatCheck = {
    Email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    Code: /^\d{6}$/,
    Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/,
    Phone: /^[0-9＋+() ー（） ^\uFF10-\uFF19 -]{1,15}$/,
    ZipCode: /^[0-9]{3}-[0-9]{4}$/
}



export {
    routerNames,
    errorMessage,
    formatCheck,
    successMessage
}