import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { Button, Input } from 'antd'
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useLocation } from "react-router-dom";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import MenuHeader_character from "../../componets/icon/MenuHeader_character";
import { ApiOwnerUpdate, ApiOwnerDetail } from '../../network/api'
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos } from "@mui/icons-material";
import NotificationManagementDetail_item from "../../componets/HtmlBody/NotificationManagementDetail_item";
import Notification_delete from "../../componets/dialog/Notification_delete";
import MenuHeader_notificationManagement from "../../componets/icon/MenuHeader_notificationManagement";
import NotificationManagementDetai_input from "../../componets/HtmlBody/NotificationManagementDetai_input";
import { ApiNoticeExit, ApiNoticeUpdate, ApiNoticeDetail } from "../../network/api";
import dic from "../../componets/function/utc";

dayjs.extend(utc)

const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingRight: VW(24),
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
            paddingRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        },
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        marginTop: VH(16),
        justifyContent: 'flex-end',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button:nth-child(2)': {
            color: '#E60024',
        }
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function NotificationManagementDetail(props) {
    const {
        classes,
        setSelectFlag
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.NotificationManagement)
        log(state)
    }, [setSelectFlag])


    const location = useLocation();
    const { state } = location;


    const [updateFlag, setUpdateFlag] = useState(false)


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // detailed list data
    const [detailedData, setDetailedData] = useState(state)



    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 登録日
    const [createDate, setCreateDate] = useState(detailedData.create_date)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> タイトル
    const [title, setTitle] = useState(detailedData.title)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 本文
    const [text, setText] = useState(detailedData.text)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  開始日
    const [startDate, setStartDate] = useState(dayjs(detailedData.usage_start_date))
    const [startValue, setStartValue] = useState(detailedData.usage_start_date);
    const changeStartDate = (value, dateString) => {
        setStartDate(value)
        setStartValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  終了日
    const [endDate, setEndDate] = useState(dayjs(detailedData.usage_end_date))
    const [endValue, setEndValue] = useState(detailedData.usage_end_date);
    const changeEndDate = (value, dateString) => {
        setEndDate(value)
        setEndValue(dateString);
        log(dateString)
    };


    // add api
    const toAdd = () => {
        document.getElementById('father_Block').scrollTop = 0;
        let param = {
            notice_id: detailedData.notice_id
        }
        ApiNoticeExit(param).then(res => {
            if (res.status === 200) {
                let params = {
                    notice_id: detailedData.notice_id,
                    title: title,
                    text: text,
                    usage_start_date: startValue,
                    usage_end_date: endValue
                }
                log(params)
                ApiNoticeUpdate(params).then(res => {
                    if (res.status === 200) {
                        let detailParam = {
                            notice_id: detailedData.notice_id
                        }
                        ApiNoticeDetail(detailParam).then(resDetail => {
                            let detail = resDetail.data
                            if (resDetail.status == 200) {
                                log(detail)
                                detail.create_date = dic.UTCDateToLocalDate(detail.create_date)
                                detail.usage_start_date = dayjs(detail.usage_start_date).format('YYYY/MM/DD')
                                detail.usage_end_date = dayjs(detail.usage_end_date).format('YYYY/MM/DD')
                                setDetailedData(detail)
                                setRrrorOpen(false)
                                setSuccessOpen(true)
                                setSuccessContent('更新が完了しました。')
                                setUpdateFlag(false)
                            } else if (resDetail.status >= 300 && resDetail.status < 500 && resDetail.status != 401) {
                                log(detail)
                                setSuccessOpen(false)
                                setRrrorOpen(true)
                                setErrorContent(resDetail.data.detail.toString())
                            }
                        }).catch(errDetail => {

                        })
                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                        log(res)
                        setSuccessOpen(false)
                        setRrrorOpen(true)
                        setErrorContent(res.data.detail.toString())
                    }
                }).catch(err => {

                })
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { window.history.back() }}>お知らせ管理</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>お知らせ情報</div>
            <div className={classes.tableTitle}>
                <MenuHeader_notificationManagement />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{updateFlag ? 'お知らせ情報の編集' : 'お知らせ情報'}</div>
                <div className={classes.updateDeleteBlock}>
                    {!updateFlag &&
                        <>
                            <Button style={{ padding: 0, marginRight: VW(24) }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={() => { setUpdateFlag(true) }}>&nbsp;編集</Button>
                            <Button style={{ padding: 0 }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openDeleteDialog}>&nbsp;削除</Button>
                        </>
                    }
                </div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            {!updateFlag ?
                <NotificationManagementDetail_item
                    detailedData={detailedData}
                />
                :
                <NotificationManagementDetai_input
                    createDate={createDate}
                    title={title}
                    setTitle={setTitle}
                    text={text}
                    setText={setText}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    startValue={startValue}
                    setStartValue={setStartValue}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    endValue={endValue}
                    setEndValue={setEndValue}
                    changeStartDate={changeStartDate}
                    changeEndDate={changeEndDate}
                />
            }
            <div className={classes.bottomButton}>
                {!updateFlag ?
                    <Button onClick={() => { window.history.back() }}>お知らせ管理へ戻る</Button>
                    :
                    <>
                        <Button onClick={() => { setUpdateFlag(false) }}>キャンセル</Button>
                        <Button onClick={toAdd}>更新</Button>
                    </>
                }
            </div>
            <Notification_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[detailedData]}
                historyFlag={true}
            />
        </div>
    )
}

NotificationManagementDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(NotificationManagementDetail);