import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_lampBulb() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width={VW(24)} height={VH(24)} rx="2" fill="#2F83FF" />
            <path d="M9.75 18.75C9.75 19.125 10.05 19.5 10.5 19.5H13.5C13.95 19.5 14.25 19.125 14.25 18.75V18H9.75V18.75ZM12 4.5C9.075 4.5 6.75 6.825 6.75 9.75C6.75 11.55 7.65 13.125 9 14.025V15.75C9 16.125 9.3 16.5 9.75 16.5H14.25C14.7 16.5 15 16.125 15 15.75V14.025C16.35 13.05 17.25 11.475 17.25 9.75C17.25 6.825 14.925 4.5 12 4.5Z" fill="white" />
        </svg>
    )
}

export default Property_detail_lampBulb;
