import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"
import log from "../function/log";
import { Button, Select } from 'antd'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropertyDetail_equipment_First from "../../componets/icon/PropertyDetail_equipment_First";
import PropertyDetail_equipment_second from "../../componets/icon/PropertyDetail_equipment_second";
import PropertyDetail_equipment_third from "../../componets/icon/PropertyDetail_equipment_third";

const { Option } = Select;

const styles = (theme) => ({
    iconTitleBlock: {
        width: '100%',
        height: VH(56),
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& > div:nth-child(1)': {
                marginRight: VW(12)
            },
            '& > div:nth-child(2)': {
                fontSize: VW(16),
                fontWeight: 'bold',
                marginRight: VW(12)
            },
            '& > div:nth-child(3)': {
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }
        }
    },
    contentBlock: {
        width: VW(476),
        '& > div': {
            width: '100%',
            fontSize: VW(16),
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            alignItems: 'center',
            height: VH(40),
            borderBottom: '1px solid #DDD',
            '& > div': {
                height: VH(22),
                borderLeft: `4px solid ${theme.palette.primary.main}`,
                paddingLeft: VW(12),
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
            }
        }
    },
    detailBlock: {
        '& > div': {
            height: VH(56),
            borderBottom: '1px solid #DDD',
            display: 'flex',
            '& > div': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: VW(16)
            },
            '& > div:nth-child(1)': {
                width: VW(160),
                backgroundColor: '#F8F8F8',
                fontWeight: 'bold'
            },
            '& > div:nth-child(2)': {
                width: VW(316),
                whiteSpace: 'pre-line',
                wordBreak: 'break-all'
            }
        }
    }
});

function PropertyDetail_equipment_item(props) {
    const {
        classes,
        bodylist,
        changeDisplayFlag
    } = props



    return (
        <div>
            {bodylist.length > 0 && bodylist.map((item, index) => {
                return (
                    <div key={index}>
                        <div className={classes.iconTitleBlock}>
                            <div onClick={() => { changeDisplayFlag(index) }}>
                                {item.category_name === '付帯設備' && <div><PropertyDetail_equipment_First /></div>}
                                {item.category_name === '家電' && <div><PropertyDetail_equipment_second /></div>}
                                {item.category_name === '家具' && <div><PropertyDetail_equipment_third /></div>}
                                <div>{item.category_name}</div>
                                <div>{item.displayFlag ? <ArrowDropUpIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} /> : <ArrowDropDownIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} />}</div>
                            </div>
                        </div>
                        {item.displayFlag &&
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                {item.data.length > 0 && item.data.map((sItem, sindex) => {
                                    return (
                                        <div className={classes.contentBlock} key={sindex}>
                                            <div>
                                                <div>{sItem.category_name}</div>
                                            </div>
                                            {sItem.result_data.length > 0 && sItem.result_data.map((tItem, tIndex) => {
                                                return (
                                                    <div className={classes.detailBlock} key={tIndex}>
                                                        <div>
                                                            <div>メーカー</div>
                                                            <div>
                                                                <div style={{ width: '100%', maxHeight: VH(56), overflowY: "scroll" }}>{tItem.maker}</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>型番（規格）</div>
                                                            <div>
                                                                <div style={{ width: '100%', maxHeight: VH(56), overflowY: "scroll" }}>{tItem.standard}</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>設置日</div>
                                                            <div>{tItem.installation_date}</div>
                                                        </div>
                                                        <div>
                                                            <div>備考</div>
                                                            <div>
                                                                <div style={{ width: '100%', maxHeight: VH(56), overflowY: "scroll" }}>{tItem.remarks}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    )
}

PropertyDetail_equipment_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_item);