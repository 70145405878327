import React, { useState, useEffect, useRef } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import AddIcon from "../icon/AddIcon";
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ApiFilemanageCreateFolder } from '../../network/api'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
});

function PropertyDetail_file_preview_pdf(props) {
    const {
        classes,
        open,
        onClose,
        url
    } = props

    useEffect(() => {

    }, [open]);


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}></div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "100%", maxHeight: "100%" }}>
                    {
                        url &&
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">

                            <Viewer fileUrl={url} />

                        </Worker>
                    }
                </div>
            </div>
        </Modal >
    )
}

PropertyDetail_file_preview_pdf.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_file_preview_pdf);