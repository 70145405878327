import React, { useState, useEffect, useRef } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, DatePicker, Select, Radio } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import locale from "antd/es/date-picker/locale/ja_JP";
import { errorMessage } from "../../wordsFile/wordsFile";
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CaretDownOutlined } from '@ant-design/icons'
import { ApiSetubirecordAdd, ApiMendingBusinessrecord, ApiMendingClaimrecord, ApiMendingSetsubi } from '../../network/api'

dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    updateInput: {
        width: '100%',
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
            minHeight: VH(74),
        },
        '& > div > div > Input': {
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: VW(768),
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        }
    },
    customerBlock: {
        '& > div': {
            height: VH(124),
        },
        '& > div:nth-child(1)': {
            width: VW(146)
        },
        '& > div:nth-child(2)': {
            width: VW(622),
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                '& > Input': {
                    height: VH(50),
                    fontSize: VW(16),
                    borderRadius: "4px"
                },
            }
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_equipment_records_add(props) {
    const {
        classes,
        open,
        onClose,
        state,
        getList
    } = props


    useEffect(() => {
        if (!open) {
            setImplementation_date('')
            setSetsubi_id(undefined)
            setSetsubi('')
            setImplementation_content('')
            setNext_implementation_date('')
        } else {
            getOptionList()
        }
    }, [open])


    // get option list
    const getOptionList = () => {
        let param = {
            buken_id: state.buken_id
        }
        ApiMendingSetsubi(param).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                log(res.data)
                setSetsubi_idOption([...res.data])
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }




    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 実施日
    const [implementation_date, setImplementation_date] = useState('')
    const changeImplementation_date = (value, dateString) => {
        setImplementation_date(value)
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> スペース
    const [setsubi_idOption, setSetsubi_idOption] = useState([])
    const [setsubi_id, setSetsubi_id] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 対象設備
    const [setsubi, setSetsubi] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 実施内容
    const [implementation_content, setImplementation_content] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 次回実施日
    const [next_implementation_date, setNext_implementation_date] = useState('')
    const changeNext_implementation_date = (value, dateString) => {
        setNext_implementation_date(value)
        log(dateString)
    };


    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    // add api
    const addItem = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        if (setsubi == '') {
            setErrorContent('対象設備を入力してください。')
            setRrrorOpen(true)
        } else {
            let params = {
                buken_id: state.buken_id,
                implementation_date: implementation_date ? dayjs(implementation_date).format('YYYY/MM/DD') : '',
                setsubi_id: (setsubi_id == undefined || setsubi_id == null) ? -1 : Number(setsubi_id),
                setsubi: setsubi,
                implementation_content: implementation_content,
                next_implementation_date: next_implementation_date ? dayjs(next_implementation_date).format('YYYY/MM/DD') : ''
            }
            log(params)
            ApiSetubirecordAdd(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    getList('add')
                    onClose()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>設備記録の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.updateInput} style={{ marginTop: VH(13) }}>
                    <div>
                        <div>実施日</div>
                        <div>
                            <DatePicker
                                style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                                placeholder="YYYY/MM/DD"
                                value={implementation_date}
                                format="YYYY/MM/DD"
                                locale={locale}
                                onChange={changeImplementation_date}
                            />
                        </div>
                    </div>
                    <div>
                        <div>スペース</div>
                        <div>
                            <Select
                                style={{ width: '47.9%', }}
                                placeholder='選択してください'
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                                value={setsubi_id}
                                onChange={(event) => setSetsubi_id(event)}
                                allowClear
                                onClear={() => { setSetsubi_id(undefined) }}
                            >
                                {setsubi_idOption.length > 0 && setsubi_idOption.map((item, index) => {
                                    return (
                                        <Option style={{ fontSize: VW(16) }} key={index} value={item.setsubi_id}>{item.room_num}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <div>対象設備<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                        <div>
                            <Input
                                style={{ width: '100%' }}
                                placeholder="対象設備を記入してください"
                                value={setsubi}
                                onChange={(event) => setSetsubi(event.target.value)}
                                maxLength={60}
                            />
                        </div>
                    </div>
                    <div style={{ height: VH(304) }}>
                        <div>実施内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                        <div>
                            <TextArea
                                style={{ height: VH(280) }}
                                placeholder="実施内容を記入してください"
                                value={implementation_content}
                                onChange={(event) => setImplementation_content(event.target.value)}
                                maxLength={500}
                            />
                        </div>
                    </div>
                    <div>
                        <div>次回実施日</div>
                        <div>
                            <DatePicker
                                style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                                placeholder="YYYY/MM/DD"
                                value={next_implementation_date}
                                format="YYYY/MM/DD"
                                locale={locale}
                                onChange={changeNext_implementation_date}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={addItem}>登録</Button>
                </div>
            </div>
        </Modal>
    )
}

PropertyDetail_equipment_records_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_records_add);