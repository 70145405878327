import React, { useState, useEffect } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, Select, DatePicker } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { CaretDownOutlined } from '@ant-design/icons'
import Common_errorAlert from "../common/Common_errorAlert";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";
import { ApiOwnerAdd } from '../../network/api'
import { VW, VH } from "../../componets/function/sizeAdapter"
import { padding } from "@mui/system";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import locale from "antd/es/date-picker/locale/ja_JP";
import { ApiNoticeAdd } from "../../network/api";
dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    updateInput: {
        width: '100%',
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
        },
        '& > div > div > Input': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: VW(760),
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function Notification_add(props) {
    const {
        classes,
        open,
        onClose,
        getList
    } = props

    useEffect(() => {
        if (!open) {
            setTitle('')
            setText('')
            setStartDate('')
            setStartValue('')
            setEndDate('')
            setEndValue('')
            setRrrorOpen(false)
        }
    }, [open])

    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> タイトル
    const [title, setTitle] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 本文
    const [text, setText] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  開始日
    const [startDate, setStartDate] = useState('')
    const [startValue, setStartValue] = useState('');
    const changeStartDate = (value, dateString) => {
        setStartDate(value)
        setStartValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  終了日
    const [endDate, setEndDate] = useState('')
    const [endValue, setEndValue] = useState('');
    const changeEndDate = (value, dateString) => {
        setEndDate(value)
        setEndValue(dateString);
        log(dateString)
    };


    // add api
    const toAdd = () => {
        let params = {
            title: title,
            text: text,
            usage_start_date: startValue,
            usage_end_date: endValue
        }
        log(params)
        ApiNoticeAdd(params).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                log(res.data)
                onClose()
                getList('add')
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
        // }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>お知らせの登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.updateInput} style={{ marginTop: VH(13) }}>
                    <div style={{ height: VH(120) }}>
                        <div>タイトル<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span><p style={{ fontSize: VW(14), fontWeight: '400' }}>（90文字まで）</p></div>
                        <div>
                            <TextArea
                                style={{ height: VH(96), width: "100%" }}
                                placeholder="お知らせのタイトルを記入してください。"
                                value={title}
                                onChange={(event) => setTitle(event.target.value)}
                                maxLength={90}
                            />
                        </div>
                    </div>

                    <div style={{ height: VH(304) }}>
                        <div>本文<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span><p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                        <div >
                            <TextArea
                                style={{ height: VH(280), width: "100%" }}
                                placeholder="お知らせの内容を記入してください。"
                                maxLength={500}
                                value={text}
                                onChange={(event) => setText(event.target.value)}
                            />
                        </div>
                    </div>
                    <div style={{ height: VH(74) }}>
                        <div>配信期間<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                        <div style={{ fontSize: VW(16) }}>{'開始：'}  <div>
                            <DatePicker
                                style={{ width: '15vw', height: VH(50), fontSize: VW(16), borderRadius: '4px', marginLeft: VW(16), marginRight: VW(16) }}
                                placeholder="YYYY/MM/DD"
                                value={startDate}
                                format="YYYY/MM/DD"
                                locale={locale}
                                onChange={changeStartDate}
                            />
                        </div>
                            <div style={{ marginRight: VW(16) }}>{'〜'}</div>
                            <div style={{ marginRight: VW(16) }}>{'終了：'}</div>
                            <div>
                                <DatePicker
                                    style={{ width: '15vw', height: VH(50), fontSize: VW(16), borderRadius: '4px' }}
                                    placeholder="YYYY/MM/DD"
                                    value={endDate}
                                    format="YYYY/MM/DD"
                                    locale={locale}
                                    onChange={changeEndDate}
                                />
                            </div></div>

                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={toAdd}>登録</Button>
                </div>
            </div>
        </Modal >
    )
}

Notification_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Notification_add);