import React, { useState, useEffect, useRef } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, Select } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { CaretDownOutlined } from '@ant-design/icons'
import Common_errorAlert from "../common/Common_errorAlert";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";
import { ApiOwnerAdd } from '../../network/api'
import { VW, VH } from "../../componets/function/sizeAdapter"
import { padding } from "@mui/system";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    updateInput: {
        width: '100%',
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
            minHeight: VH(74),
        },
        '& > div > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: VW(760),
        },
        '& > div:nth-last-child(2) > div > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        '& > div:nth-last-child(3) > div > div:nth-child(1)': {
            fontSize: VW(16),
            // marginRight: VW(10)
            width: "3%",
        },
        '& > div:nth-last-child(3) > div > div:nth-child(2)': {
            width: "22%",
            minHeight: VH(50)
        },
        '& > div:nth-last-child(3) > div > div:nth-child(3)': {
            fontSize: VW(16),
            color: '#AAA',
            marginLeft: VW(16)
        },
        '& > div:nth-last-child(3) > div > div > Input': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px",
            minHeight: VH(50)
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
            minHeight: VH(96)

        },
        '& > div:last-child > div:nth-child(2)': {
            height: VH(120)

        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function OwnersList_add(props) {
    const {
        classes,
        open,
        onClose,
        getList
    } = props

    useEffect(() => {
        if (!open) {
            setNumber('')
            setCharacterFlag(undefined)
            setOwnerName('')
            setPhone('')
            setEmail('')
            setZipCode('')
            setAddress('')
            setRemarks('')
            setRrrorOpen(false)
        }
    }, [open])

    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 管理番号など
    const [number, setNumber] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 個人 / 法人
    const [characterFlag, setCharacterFlag] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> オーナー名
    const [ownerName, setOwnerName] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 電話番号
    const [phone, setPhone] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> メールアドレス
    const [email, setEmail] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 郵便番号
    const [zipCode, setZipCode] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 住所
    const [address, setAddress] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 備考
    const [remarks, setRemarks] = useState('')

    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    // add api
    const toAdd = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        if (ownerName === '') {
            setErrorContent(errorMessage.ownerNameNull)
            setRrrorOpen(true)
        } else if (email != '' && !formatCheck.Email.test(email)) {
            setErrorContent(errorMessage.emailFormatError)
            setRrrorOpen(true)
        } else if (phone != '' && !formatCheck.Phone.test(phone)) {
            setErrorContent(errorMessage.phoneFormatError)
            setRrrorOpen(true)
        } else if (zipCode != '' && !formatCheck.ZipCode.test(zipCode)) {
            setErrorContent(errorMessage.zipCodeFormatError)
            setRrrorOpen(true)
        } else {
            let params = {
                control_num: number,
                ind_corp_flag: characterFlag ? Number(characterFlag) : null,
                owner_name: ownerName,
                tel: phone,
                email: email,
                postal: zipCode,
                address: address,
                remarks: remarks
            }
            log(params)
            ApiOwnerAdd(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    onClose()
                    getList('add')
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>オーナーの登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.updateInput} style={{ marginTop: VH(13) }}>
                    <div>
                        <div>管理番号など</div>
                        <div>
                            <Input
                                placeholder="管理番号などを入力してください"
                                value={number}
                                onChange={(event) => setNumber(event.target.value)}
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div>
                        <div>個人 / 法人</div>
                        <div>
                            <Select
                                style={{ width: '100%', }}
                                placeholder='選択してください'
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                                value={characterFlag}
                                onChange={(event) => setCharacterFlag(event)}
                                allowClear
                                onClear={() => { setCharacterFlag(undefined) }}
                            >
                                <Option style={{ fontSize: VW(16) }} value='1'>個人</Option>
                                <Option style={{ fontSize: VW(16) }} value='2'>法人</Option>
                            </Select>
                        </div>
                    </div>
                    <div>
                        <div>オーナー名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                        <div>
                            <Input
                                placeholder="オーナー名を入力してください"
                                value={ownerName}
                                onChange={(event) => setOwnerName(event.target.value)}
                                maxLength={20}
                            />
                        </div>
                    </div>
                    <div>
                        <div>電話番号</div>
                        <div>
                            <Input
                                placeholder="電話番号を入力してください"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                                maxLength={15}
                            />
                        </div>
                    </div>
                    <div>
                        <div>メールアドレス</div>
                        <div>
                            <Input
                                placeholder="メールアドレスを入力してください"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                maxLength={200}
                            />
                        </div>
                    </div>
                    <div>
                        <div>郵便番号</div>
                        <div>
                            <div>〒</div>
                            <div>
                                <Input
                                    placeholder="郵便番号"
                                    value={zipCode}
                                    onChange={(event) => setZipCode(event.target.value)}
                                    maxLength={8}
                                />
                            </div>
                            <div>※半角７桁ハイフンあり</div>
                        </div>
                    </div>
                    <div>
                        <div>所在地</div>
                        <div>
                            <Input
                                placeholder="所在地を入力してください"
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div>
                        <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（200文字まで）</p></div>
                        <div >
                            <TextArea
                                style={{ height: VH(96), width: "100%" }}
                                placeholder="備考を記入してください。"
                                maxLength={200}
                                value={remarks}
                                onChange={(event) => setRemarks(event.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={toAdd}>登録</Button>
                </div>
            </div>
        </Modal >
    )
}

OwnersList_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(OwnersList_add);