import { VW, VH } from "../../componets/function/sizeAdapter"
function Checkbox_Table_Block(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(15)} height={VH(16)} viewBox="0 0 15 16">
            <path d="M0 8.83333H6.66667V0.5H0V8.83333ZM0 15.5H6.66667V10.5H0V15.5ZM8.33333 15.5H15V7.16667H8.33333V15.5ZM8.33333 0.5V5.5H15V0.5H8.33333Z" fill={color ? color : '#2F83FF'} />
        </svg>
    )
}

export default Checkbox_Table_Block;