import React from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../../componets/function/sizeAdapter"

const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
        '& > div': {
            height: 'auto',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
        },
        '& > div > div': {
            minHeight: VH(74),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16),
            wordBreak: 'break-all'
        },
        '& > div > div:nth-child(1)': {
            width: '15.8%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div > div:nth-child(2)': {
            width: '84.2%',
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
            whiteSpace: 'pre-line',
        },
    }
});

function ReminderManagementDetail_item(props) {
    const {
        classes,
        detailedData,
    } = props

    const linkifyText = () => {
        const urlRegex = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@&#63;^=%&amp;amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/
        return detailedData.text.replace(urlRegex, '<a href="$&" target="_blank">$&</a>');
    };

    return (
        <div className={classes.contentBlock}>
            <div>
                <div>登録日</div>
                <div>{detailedData.create_date}</div>
            </div>
            <div>
                <div>作成者</div>
                <div>{detailedData.all_name}</div>
            </div>
            <div>
                <div>配信先</div>
                <div>{detailedData.show_usage_direction}</div>
            </div>
            <div>
                <div>タイトル</div>
                <div>{detailedData.title}</div>
            </div>
            <div style={{ height: VH(224) }}>
                <div>本文</div>
                <div style={{ height: VH(224) }}>
                    <div style={{ width: '100%', maxHeight: VH(224), overflowY: "scroll", whiteSpace: 'pre-line', }} dangerouslySetInnerHTML={{ __html: linkifyText() }}></div>
                </div>
            </div>
            <div >
                <div>配信期間</div>
                <div>{'開始 : '} {detailedData.usage_start_date}　〜　終了 : {detailedData.usage_end_date}</div>
            </div>
        </div>
    )
}

ReminderManagementDetail_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(ReminderManagementDetail_item);