import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../../componets/function/sizeAdapter"
import Question_mark from "../icon/Question_mark";
import Inspection_picture_first from "../dialog/Inspection_picture_first";
import Inspection_picture_last from "../dialog/Inspection_picture_last";
import { Input } from 'antd';
import Inspection_clean from "../icon/Inspection_clean";
import AddIcon from "../icon/AddIcon";
import CrossIcon from "../icon/CrossIcon";
import Inspection_cross from "../icon/Inspection_cross";
import Inspection_move from "../icon/Inspection_move";
import Forward from "../icon/Forward";
import Backward from "../icon/Backward";



const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
    },
    titleBlock: {
        height: 'auto',
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            minHeight: VH(74),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(200),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: VW(792),
        },
    },
    commonItemTitle: {
        marginTop: VH(40),
        width: '100%',
        height: VH(24),
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        fontSize: VW(16),
        fontWeight: 'bold',
        paddingLeft: VW(12),
        display: 'flex',
        alignItems: 'center'
    },
    commonItemDataT: {
        width: '100%',
        height: 'auto',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderBottom: 0,
        marginTop: VH(16),
        '& > div': {
            width: '100%',
            display: 'flex',
        },
        '& > div > div': {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& > div:nth-child(1)': {
            height: VH(48),
            backgroundColor: "#F8F8F8",
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:not(:nth-child(1))': {
            height: VH(38),
        }
    },
    commonItemDataB: {
        width: '100%',
        height: 'auto',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        '& > div': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& > div:nth-child(1)': {
            height: VH(48),
            backgroundColor: "#F8F8F8",
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:not(:nth-child(1))': {
            height: VH(38),
        },
    },
    scrollBlock: {
        width: '100%',
        position: 'relative',
        '& > div:nth-child(2)': {
            position: 'absolute',
            cursor: 'pointer',
        },
        '& > div:nth-child(3)': {
            position: 'absolute',
            cursor: 'pointer',
        }
    },
    tabTitle: {
        width: '100%',
        display: 'flex',
        marginTop: VH(16),
        overflowX: 'auto',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        '& > div:not(:last-child)': {
            minWidth: VW(160),
            height: VH(64),
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: '#E0ECFF',
            border: `1px solid ${theme.palette.primary.main}`,
            borderBottom: 0,
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: VW(16),
            fontWeight: 'bold',
            cursor: 'pointer',
            flexShrink: 0,
        },
        '& > div > Input': {
            width: VW(120),
            height: VH(48),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            textAlign: 'center'
        },
        '& > div:last-child': {
            width: VW(119),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: VW(16),
            cursor: 'pointer',
            flexShrink: 0,
            color: theme.palette.primary.main,
            '& > div:nth-child(1)': {
                marginRight: VW(8),
                marginTop: VH(2)
            }
        },
    },
    tabHeader: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#F8F8F8',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        '& > div': {
            height: VH(48),
            display: 'flex',
            alignItems: 'center',
            padding: VW(16),
            fontSize: VW(16),
            fontWeight: 'bold',
        },
        '& > div:nth-child(1)': {
            width: '2.3%',
            borderRight: `1px solid #FFF`,
        },
        '& > div:nth-child(2)': {
            width: '25.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '25.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(4)': {
            width: '42%',
            borderRight: `1px solid #FFF`,
        },
        '& > div:nth-child(5)': {
            width: '5.1%',
        },
    },
    tabBody: {
        display: 'flex',
        width: '100%',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        minHeight: VH(48),
        userSelect: 'none',
        '& Input': {
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div': {
            minHeight: VH(48),
            paddingTop: VH(12),
            padding: VW(16),
            fontSize: VW(16),
        },
        '& > div:nth-child(1)': {
            width: '2.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EEE',
            cursor: 'pointer'
        },
        '& > div:nth-child(2)': {
            width: '25.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '25.3%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(4)': {
            width: '42%',
            paddingBottom: VH(4),
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
            '& > div': {
                wdith: '100%',
                height: VH(50),
                marginBottom: VH(8),
                display: 'flex',
                alignItems: 'center',
                fontSize: VW(16),
            }
        },
        '& > div:nth-child(5)': {
            width: '5.1%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > div': {
                cursor: 'pointer'
            }
        },
    },
    tabHeaderItem: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#F8F8F8',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        '& > div': {
            height: VH(48),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontSize: VW(16),
            fontWeight: 'bold',
        },
        '& > div:nth-child(1)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(2)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '48.4%'
        },
    },
    tabBodyItem: {
        display: 'flex',
        width: '100%',
        border: `1px solid ${theme.palette.themeBorder.main}`,
        borderTop: 0,
        minHeight: VH(48),
        '& > div': {
            minHeight: VH(48),
            paddingTop: VH(12),
            paddingLeft: VW(16),
            fontSize: VW(16),
        },
        '& > div:nth-child(1)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(2)': {
            width: '25.8%',
            borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        },
        '& > div:nth-child(3)': {
            width: '48.4%',
            paddingBottom: VH(4),
            '& > div': {
                marginBottom: VH(8)
            }
        },
    },
    questionMark: {
        marginLeft: VW(8),
        cursor: 'pointer'
    },
});
function InspectionListDetail_input(props) {
    const {
        classes,
        detailedData,
        setDetailedData,
        displayData,
        setDisplayData,
        listItems,
        setListItems
    } = props



    // title move

    const [draggedItem, setDraggedItem] = useState(null);
    const handleDragStart = (event, index) => {
        setDraggedItem(listItems[index]);
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('text/plain', '');
    };
    const handleDragEnter = (index) => {
        if (!draggedItem) return;
        if (draggedItem.id === listItems[index].id) return;
        listItems.splice(index, 0, listItems.splice(listItems.indexOf(draggedItem), 1)[0]);
        setListItems([...listItems]);
    };
    const handleDragEnd = () => {
        setDraggedItem(null);
    };
    const selectTab = (item, index) => {
        listItems.map(listitem => {
            listitem.checked = false
        })
        listItems[index].checked = true
        setDisplayData([...listItems[index].sagyo_komoku])
        setListItems([...listItems])
    }



    const [draggedItemBody, setDraggedItemBody] = useState(null);
    const handleDragStartBody = (event, index) => {
        setDraggedItemBody(displayData[index]);
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('text/plain', '');
    };
    const handleDragEnterBody = (index) => {
        if (!draggedItemBody) return;
        if (draggedItemBody.id === displayData[index].id) return;
        displayData.splice(index, 0, displayData.splice(displayData.indexOf(draggedItemBody), 1)[0]);
        setDisplayData([...displayData]);
    };
    const handleDragEndBody = () => {
        setDraggedItemBody(null);
    };


    // 結果項目設定  dialog
    const [firstOpen, setFirstOpen] = useState(false)
    const firstClose = () => {
        setFirstOpen(false)
    }
    const openFirstPicture = () => {
        setFirstOpen(true)
    }


    // 点検項目設定  dialog
    const [lastOpen, setLastOpen] = useState(false)
    const lastClose = () => {
        setLastOpen(false)
    }
    const openLastPicture = () => {
        setLastOpen(true)
    }

    // scroll button flag
    const [leftFlag, setLeftFlag] = useState(false)
    const [rightFlag, setRightFlag] = useState(false)
    // left move
    const leftMove = () => {
        let parent = document.getElementById('inspectionDetailParent')
        parent.scrollLeft = parent.scrollLeft - 480
    }
    // right move
    const rightMove = () => {
        let parent = document.getElementById('inspectionDetailParent')
        parent.scrollLeft = parent.scrollLeft + 480
    }


    useEffect(() => {
        let parent = document.getElementById('inspectionDetailParent')
        if (parent.scrollLeft + parent.clientWidth != parent.scrollWidth) {
            setRightFlag(true)
        }
        parent.addEventListener('scroll', function () {
            if (parent.scrollLeft > 0) {
                setLeftFlag(true)
            } else {
                setLeftFlag(false)
            }
            if (parent.scrollLeft + parent.clientWidth == parent.scrollWidth) {
                setRightFlag(false)
            } else {
                setRightFlag(true)
            }
        });
    }, [])

    useEffect(() => {
        let parent = document.getElementById('inspectionDetailParent')
        if (parent.scrollLeft + parent.clientWidth != parent.scrollWidth) {
            setRightFlag(true)
        } else {
            setRightFlag(false)
        }
    }, [listItems])

    useEffect(() => {
        listItems.map(item => {
            if (item.checked) {
                item.sagyo_komoku = displayData
            }
            setListItems([...listItems])
        })
    }, [displayData])
    useEffect(() => {
        detailedData.sagyo_shubetsu_data = listItems
        setDetailedData({ ...detailedData })
    }, [listItems])

    // change ListItem
    const changeListItem = (value, index, type, TIndex) => {
        if (TIndex != null && TIndex != undefined && type) {
            displayData[index]['hokoku_select'][TIndex][type] = value
            setDisplayData([...displayData])
        } else if (type != null && type != undefined && !TIndex) {
            displayData[index][type] = value
            setDisplayData([...displayData])
        } else if ((type == null || type == undefined) && !TIndex) {
            listItems[index]['sagyo_shubetsu_name'] = value
            setListItems([...listItems])
        }
    }
    // add ListItem
    const addListItem = () => {
        listItems.map(item => {
            item.checked = false
        })
        if (detailedData.category == 0) {
            listItems.splice(listItems.length - 1, 0, {
                checked: true,
                sagyo_shubetsu_name: '',
                sagyo_shubetsu_id: null,
                sagyo_komoku: [{
                    'sagyo_naiyo': '',
                    'sagyo_naiyo_hosoku': '',
                    'hokoku_select': [],
                }]
            })
        } else {
            listItems.push({
                checked: true,
                sagyo_shubetsu_name: '',
                sagyo_shubetsu_id: null,
                sagyo_komoku: [{
                    'sagyo_naiyo': '',
                    'sagyo_naiyo_hosoku': '',
                    'hokoku_select': [],
                }]
            })
        }

        listItems.map((item, index) => {
            item.id = index + 1
        })
        setListItems([...listItems])
        if (detailedData.category == 0) {
            setDisplayData([...listItems[listItems.length - 2].sagyo_komoku])
        } else {
            setDisplayData([...listItems[listItems.length - 1].sagyo_komoku])
        }
    }
    // delete ListItem
    const deleteListItem = (e, index) => {
        e.stopPropagation()
        listItems.splice(index, 1)
        listItems.map((item, Tindex) => {
            item.id = Tindex + 1
            item.checked = false
        })
        if (listItems[index] == undefined) {
            listItems[index - 1].checked = true
            setListItems([...listItems])
            setDisplayData([...listItems[index - 1].sagyo_komoku])
        } else {
            listItems[index].checked = true
            setListItems([...listItems])
            setDisplayData([...listItems[index].sagyo_komoku])
        }
    }
    // delete hokokoItem
    const deleteHokokoItem = (index, TIndex) => {
        displayData[index]['hokoku_select'].splice(TIndex, 1)
        setDisplayData([...displayData])
    }
    // add hokokoItem
    const addHokokoItem = (index) => {
        displayData[index]['hokoku_select'].push({ 'hokoku_select': '' })
        setDisplayData([...displayData])
    }
    // delete displayItem
    const deleteDisplayItem = (index) => {
        displayData.splice(index, 1)
        displayData.map((item, idx) => {
            item.id = idx + 1
        })
        setDisplayData([...displayData])
    }
    // add displayItem
    const addDisplayItem = () => {
        displayData.push({
            'sagyo_naiyo': '',
            'sagyo_naiyo_hosoku': '',
            'hokoku_select': [],
        })
        displayData.map((item, index) => {
            item.id = index + 1
        })
        setDisplayData([...displayData])
    }

    const backFc = () => {
        return
    }

    return (
        <div className={classes.contentBlock}>
            <div className={classes.titleBlock}>
                <div>点検表種類</div>
                <div>{detailedData.category == 0 ? '定期巡回' : 'マンスリー'}</div>
            </div>
            <div className={classes.titleBlock} style={{ borderBottom: '1px solid #DDD' }}>
                <div>点検表名</div>
                <div>{detailedData.name}</div>
            </div>
            <div className={classes.commonItemTitle}>
                結果項目設定
                <div className={classes.questionMark} onClick={openFirstPicture}>
                    <Question_mark />
                </div>
            </div>
            <div className={classes.commonItemDataT}>
                <div>
                    <div>点検結果</div>
                    <div> 結果（表示）</div>
                </div>
                {detailedData.m_keka_data && detailedData.m_keka_data.length > 0 && detailedData.m_keka_data.map((item, index) => {
                    return (
                        <div key={index}>
                            <div>
                                <p>{(index + 1) + '. ' + item.name}</p>
                            </div>
                            <div>
                                <p>{item.sname}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.commonItemDataB}>
                <div>問題程度</div>
                {detailedData.m_keka_hosoku_data && detailedData.m_keka_hosoku_data.length > 0 && detailedData.m_keka_hosoku_data.map((item, index) => {
                    return (
                        <div key={index}>
                            <p>{(index + 1) + '. ' + item.name}</p>
                        </div>
                    )
                })}
            </div>
            <div className={classes.commonItemTitle}>
                点検項目設定
                <div className={classes.questionMark} onClick={openLastPicture}>
                    <Question_mark />
                </div>
            </div>
            <div className={classes.scrollBlock}>
                <div className={classes.tabTitle} id='inspectionDetailParent'>
                    {listItems.map((item, index) => {
                        if (detailedData.category == 0 && (index == 0 || index == listItems.length - 1)) {
                            return (
                                <div
                                    key={index}
                                    style={{ backgroundColor: item.checked ? '#0052CC' : '', color: item.checked ? '#FFF' : '' }}
                                    onClick={() => { selectTab(item, index) }}
                                >
                                    {item.sagyo_shubetsu_name}
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        backgroundColor: item.checked ? '#0052CC' : '',
                                        color: item.checked ? '#FFF' : '',
                                        position: 'relative'
                                    }}
                                    draggable
                                    onDragStart={(event) => handleDragStart(event, index)}
                                    onDragEnter={() => handleDragEnter(index)}
                                    onDragEnd={handleDragEnd}
                                    onClick={() => { selectTab(item, index) }}
                                >
                                    {item.checked &&
                                        <span
                                            style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                                position: 'absolute',
                                                zIndex: '9999',
                                                marginLeft: VW(75),
                                                marginBottom: VW(5),
                                            }}>*</span>
                                    }
                                    {item.checked ?
                                        <Input
                                            value={item.sagyo_shubetsu_name}
                                            placeholder="点検種別名"
                                            onChange={(e) => { changeListItem(e.target.value, index) }}
                                            maxLength={60}
                                        />
                                        :
                                        item.sagyo_shubetsu_name
                                    }
                                    {item.checked && <div style={{ marginLeft: VW(5), marginBottom: VH(35) }} onClick={(e) => { deleteListItem(e, index) }}>
                                        <Inspection_clean />
                                    </div>}
                                </div>
                            )
                        }
                    })}
                    <div onClick={addListItem}>
                        <div><AddIcon /></div>
                        <div>追加</div>
                    </div>
                </div>
                <div onClick={leftFlag ? leftMove : backFc} style={{ top: leftFlag ? VH(16) : VH(18), left: leftFlag ? VW(0) : VW(2) }}>
                    <Forward flag={leftFlag} />
                </div>
                <div onClick={rightFlag ? rightMove : backFc} style={{ top: rightFlag ? VH(16) : VH(18), right: rightFlag ? VW(0) : VW(2) }}>
                    <Backward flag={rightFlag} />
                </div>
            </div>
            {(detailedData.category == 1 || (detailedData.category == 0 && !listItems[0].checked && !listItems[listItems.length - 1].checked)) ?
                <div className={classes.tabHeader}>
                    <div></div>
                    <div>点検項目名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                    <div>点検方法</div>
                    <div>報告事項</div>
                    <div></div>
                </div>
                :
                <div className={classes.tabHeaderItem}>
                    <div>点検項目名</div>
                    <div>点検方法</div>
                    <div>報告事項</div>
                </div>
            }

            {(detailedData.category == 1 || (detailedData.category == 0 && !listItems[0].checked && !listItems[listItems.length - 1].checked)) && displayData.length > 0 && displayData.map((item, index) => {
                return (
                    <div className={classes.tabBody} key={index}>
                        <div
                            draggable
                            onDragStart={(event) => handleDragStartBody(event, index)}
                            onDragEnter={() => handleDragEnterBody(index)}
                            onDragEnd={handleDragEndBody}
                        >
                            <div><Inspection_move /></div>
                        </div>
                        <div>
                            <Input
                                value={item.sagyo_naiyo}
                                placeholder="点検項目名"
                                onChange={(e) => { changeListItem(e.target.value, index, 'sagyo_naiyo') }}
                                maxLength={200}
                            />
                        </div>
                        <div>
                            <Input
                                value={item.sagyo_naiyo_hosoku}
                                placeholder="点検方法"
                                onChange={(e) => { changeListItem(e.target.value, index, 'sagyo_naiyo_hosoku') }}
                                maxLength={200}
                            />
                        </div>
                        <div>
                            {item.hokoku_select && item.hokoku_select.length > 0 && item.hokoku_select.map((TItem, TIndex) => {
                                return (
                                    <div key={TIndex}>{TIndex + 1 + '. '}
                                        <Input
                                            value={TItem.hokoku_select}
                                            style={{ marginLeft: VW(16) }}
                                            placeholder={'選択肢' + (TIndex + 1)}
                                            onChange={(e) => { changeListItem(e.target.value, index, 'hokoku_select', TIndex) }}
                                            maxLength={200}
                                        />
                                        <div style={{ marginLeft: VW(16), cursor: 'pointer' }} onClick={() => { deleteHokokoItem(index, TIndex) }}>
                                            <Inspection_cross />
                                        </div>
                                    </div>
                                )
                            })}
                            {item.hokoku_select.length < 7 &&
                                <div style={{ color: '#0052CC', justifyContent: 'flex-end', paddingRight: VW(41) }}>
                                    <div style={{ cursor: 'pointer', marginTop: VH(2), marginRight: VW(8) }} onClick={() => { addHokokoItem(index) }}>
                                        <AddIcon />
                                    </div>
                                    <div style={{ cursor: 'pointer' }} onClick={() => { addHokokoItem(index) }}>選択肢を追加する</div>
                                </div>
                            }
                        </div>
                        <div>
                            <div onClick={() => { deleteDisplayItem(index) }}><CrossIcon /></div>
                        </div>
                    </div>
                )
            })}
            {(detailedData.category == 0 && (listItems[0].checked || listItems[listItems.length - 1].checked)) && displayData.length > 0 && displayData.map((item, index) => {
                return (
                    <div className={classes.tabBodyItem} key={index} >
                        <div>{item.sagyo_naiyo}</div>
                        <div>{item.sagyo_naiyo_hosoku}</div>
                        <div>
                            {item.hokoku_select && item.hokoku_select.length > 0 && item.hokoku_select.map((TItem, TIndex) => {
                                return (
                                    <div key={TIndex}>{TIndex + 1 + '. ' + TItem.hokoku_select}</div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
            {(detailedData.category == 1 || (detailedData.category == 0 && !listItems[0].checked && !listItems[listItems.length - 1].checked)) &&
                <div style={{ width: '100%', color: '#0052CC', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: VH(48) }}>
                    <div style={{ cursor: 'pointer', marginTop: VH(2), marginRight: VW(8) }} onClick={addDisplayItem}>
                        <AddIcon />
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={addDisplayItem}>項目を追加する</div>
                </div >
            }
            <Inspection_picture_first
                open={firstOpen}
                onClose={firstClose}
            />
            <Inspection_picture_last
                open={lastOpen}
                onClose={lastClose}
            />
        </div >
    )
}

InspectionListDetail_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(InspectionListDetail_input);