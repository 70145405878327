import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Question_mark from "../icon/Question_mark";
import { VW, VH } from "../../componets/function/sizeAdapter"
import annotationA from '../picture/annotationA.png'

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    pictureBlock: {
        width: '100%',
        marginTop: VH(24)
    }
});

function Inspection_picture_first(props) {
    const {
        classes,
        open,
        onClose,
    } = props



    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <Question_mark />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>結果項目設定</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <div style={{ fontSize: VW(16), marginTop: VH(13) }}>ここでは以下点検表の赤枠部分を設定します</div>
            <div className={classes.pictureBlock}>
                <img
                    style={{ width: "100%", objectFit: "contain", marginBottom: VH(10) }}
                    src={annotationA}
                />
            </div>
        </Modal>
    )
}

Inspection_picture_first.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Inspection_picture_first);