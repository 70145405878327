import { CaretDownOutlined } from '@ant-design/icons';
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, DatePicker, Input, Modal, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { VH, VW } from "../../componets/function/sizeAdapter";
import { ApiMendingAdd, ApiMendingBusinessrecord, ApiMendingClaimrecord, ApiMendingSetsubi } from '../../network/api';
import Common_errorAlert from "../common/Common_errorAlert";
import log from "../function/log";
import AddIcon from "../icon/AddIcon";

dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(74),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(74)
        },
        '& > div:nth-child(odd)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(even)': {
            width: '26%'
        },
        '& > div > .ant-select-single .ant-select-selector': {
            width: '100%'
        }
    },
    groundTwo: {
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(74),
        width: '100%',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(74),
        },
        '& > div:nth-child(1)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '76%'
        },
        '& > div > Input': {
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
    },
    customerBlock: {
        '& > div': {
            height: VH(124),
        },
        '& > div:nth-child(1)': {
            width: VW(146)
        },
        '& > div:nth-child(2)': {
            width: VW(622),
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                '& > Input': {
                    height: VH(50),
                    fontSize: VW(16),
                    borderRadius: "4px"
                },
            }
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_repair_add(props) {
    const {
        classes,
        open,
        onClose,
        state,
        getList,
        priorityValueOption
    } = props


    useEffect(() => {
        if (!open) {
            setOrder_date('')
            setRequested_date('')
            setScheduled_date('')
            setCompletion_date('')
            setStatus(undefined)
            setSetsubi_id(undefined)
            setMending_location('')
            setMending_content('')
            setOrganizing_company('')
            setBusiness_record_id(undefined)
            setClaim_record_id(undefined)
            setPriority(undefined)
            setSubject('')
        } else {
            getOptionList()
            if (state.business_record_id != null) {
                setBusiness_record_id(state.business_record_id)
            }
            if (state.claim_record_id != null) {
                setClaim_record_id(state.claim_record_id)
            }
        }
    }, [open])


    // get option list
    const getOptionList = () => {
        let param = {
            buken_id: state.buken_id
        }
        ApiMendingSetsubi(param).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                log(res.data)
                setSetsubi_idOption([...res.data])
                ApiMendingBusinessrecord(param).then(resS => {
                    if (resS.status === 200) {
                        setRrrorOpen(false)
                        log(resS.data)
                        setBusiness_record_idOption([...resS.data])
                        ApiMendingClaimrecord(param).then(resT => {
                            if (resT.status === 200) {
                                setRrrorOpen(false)
                                log(resT.data)
                                setClaim_record_idOption([...resT.data])
                            } else if (resT.status >= 300 && resT.status < 500 && resT.status != 401) {
                                log(resT)
                                setRrrorOpen(true)
                                setErrorContent(resT.data.detail.toString())
                            }
                        }).catch(err => {

                        })
                    } else if (resS.status >= 300 && resS.status < 500 && resS.status != 401) {
                        log(resS)
                        setRrrorOpen(true)
                        setErrorContent(resS.data.detail.toString())
                    }
                }).catch(err => {

                })
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }




    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 受注日
    const [order_date, setOrder_date] = useState('')
    const changeOrder_date = (value, dateString) => {
        setOrder_date(value)
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 依頼日
    const [requested_date, setRequested_date] = useState('')
    const changeRequested_date = (value, dateString) => {
        setRequested_date(value)
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 予定日
    const [scheduled_date, setScheduled_date] = useState('')
    const changeScheduled_date = (value, dateString) => {
        setScheduled_date(value)
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 完了日
    const [completion_date, setCompletion_date] = useState('')
    const changeCompletion_date = (value, dateString) => {
        setCompletion_date(value)
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ステータス
    const [statusValueOption, setStatusValueOption] = useState([
        { value: '0', label: '受注' },
        { value: '1', label: '手配済' },
        { value: '2', label: '実施待ち' },
        { value: '3', label: '実施済' }
    ])
    const [status, setStatus] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 修繕スペース
    const [setsubi_idOption, setSetsubi_idOption] = useState([])
    const [setsubi_id, setSetsubi_id] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 修繕箇所
    const [mending_location, setMending_location] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 修繕内容
    const [mending_content, setMending_content] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 実施会社
    const [organizing_company, setOrganizing_company] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 営業番号
    const [business_record_idOption, setBusiness_record_idOption] = useState([])
    const [business_record_id, setBusiness_record_id] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> クレーム番号
    const [claim_record_idOption, setClaim_record_idOption] = useState([])
    const [claim_record_id, setClaim_record_id] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  優先度 select
    const [priority, setPriority] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 件名
    const [subject, setSubject] = useState('')




    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    // add api
    const addItem = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        if (subject == '') {
            setErrorContent('件名を記入してください')
            setRrrorOpen(true)
        } else {
            let params = {
                buken_id: state.buken_id,
                order_date: order_date ? dayjs(order_date).format('YYYY/MM/DD') : '',
                requested_date: requested_date ? dayjs(requested_date).format('YYYY/MM/DD') : '',
                scheduled_date: scheduled_date ? dayjs(scheduled_date).format('YYYY/MM/DD') : '',
                completion_date: completion_date ? dayjs(completion_date).format('YYYY/MM/DD') : '',
                status: (status == undefined || status == null) ? -1 : Number(status),
                setsubi_id: (setsubi_id == undefined || setsubi_id == null) ? -1 : Number(setsubi_id),
                mending_location: mending_location,
                mending_content: mending_content,
                organizing_company: organizing_company,
                business_record_id: (business_record_id == undefined || business_record_id == null) ? -1 : Number(business_record_id),
                claim_record_id: (claim_record_id == undefined || claim_record_id == null) ? -1 : Number(claim_record_id),
                priority: (priority == undefined || priority == null) ? -1 : Number(priority),
                subject: subject
            }
            log(params)
            ApiMendingAdd(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    if (state.business_record_id == null && claim_record_id == null) {
                        getList('add')
                        onClose()
                    }
                    else {
                        onClose('addRepair')
                    }
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>修繕記録の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div style={{ marginTop: VH(13) }} className={classes.groundFour}>
                    <div>優先度</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={priority}
                            onChange={(event) => setPriority(event)}
                            allowClear
                            onClear={() => { setPriority(undefined) }}
                        >
                            {priorityValueOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>
                                        <span style={{ color: item.color }} >●</span>&nbsp;{item.label}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div>ステータス</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={status}
                            onChange={(event) => setStatus(event)}
                            allowClear
                            onClear={() => { setStatus(undefined) }}
                        >
                            {statusValueOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>受注日</div>
                    <div>
                        <DatePicker
                            style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={order_date}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeOrder_date}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>依頼日</div>
                    <div>
                        <DatePicker
                            style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={requested_date}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeRequested_date}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>予定日</div>
                    <div>
                        <DatePicker
                            style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={scheduled_date}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeScheduled_date}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>完了日</div>
                    <div>
                        <DatePicker
                            style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={completion_date}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeCompletion_date}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>修繕スペース</div>
                    <div>
                        <Select
                            style={{ width: VW(360), }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={setsubi_id}
                            onChange={(event) => setSetsubi_id(event)}
                            allowClear
                            onClear={() => { setSetsubi_id(undefined) }}
                        >
                            {setsubi_idOption.length > 0 && setsubi_idOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.setsubi_id}>{item.room_num}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>件名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                    <div>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="件名を記入してください"
                            value={subject}
                            onChange={(event) => setSubject(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>修繕箇所</div>
                    <div>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="修繕箇所を記入してください"
                            value={mending_location}
                            onChange={(event) => setMending_location(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div style={{ height: VH(304) }} className={classes.groundTwo}>
                    <div>修繕内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                    <div>
                        <TextArea
                            style={{ height: VH(280) }}
                            placeholder="修繕内容を記入してください"
                            value={mending_content}
                            onChange={(event) => setMending_content(event.target.value)}
                            maxLength={500}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>実施会社</div>
                    <div>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="実施会社を記入してください"
                            value={organizing_company}
                            onChange={(event) => setOrganizing_company(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>営業番号</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={business_record_id}
                            onChange={(event) => setBusiness_record_id(event)}
                            allowClear
                            onClear={() => { setBusiness_record_id(undefined) }}
                        >
                            {business_record_idOption.length > 0 && business_record_idOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.business_record_id}>{item.business_record_no + '　' + item.subject}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div style={{ borderBottom: '1px solid #DDD' }} className={classes.groundTwo}>
                    <div>クレーム番号</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={claim_record_id}
                            onChange={(event) => setClaim_record_id(event)}
                            allowClear
                            onClear={() => { setClaim_record_id(undefined) }}
                        >
                            {claim_record_idOption.length > 0 && claim_record_idOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.claim_record_id}>{item.claim_record_no + '　' + item.subject}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={addItem}>登録</Button>
                </div>
            </div>
        </Modal>
    )
}

PropertyDetail_repair_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_repair_add);