import React, { useEffect, useState, useRef } from "react";
import {
    TablePagination,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput
} from "@mui/material";
import { Input, Button } from 'antd'
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import MenuHeader_property from "../../componets/icon/MenuHeader_property";
import { VW, VH } from '../../componets/function/sizeAdapter'
import AddIcon_small from "../../componets/icon/AddIcon_small";
import ReduceIcon_small from "../../componets/icon/ReduceIcon_small";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Common_checkBoxTable from "../../componets/common/Common_checkBoxTable";
import { useNavigate } from 'react-router-dom'
import log from "../../componets/function/log";
import { CaretDownOutlined } from '@ant-design/icons'
import { ApiTagListBuken, ApiBukenList, ApiBukenFileDownloadModel, ApiBukenFileChoosedownload, ApiBukenFileDownload, ApiBukenFileDownloadBase, ApiBukenFileDownloadBaseAll } from '../../network/api'
import { ClearRounded } from "@mui/icons-material"
import Common_errorAlert from '../../componets/common/Common_errorAlert'
import Common_successAlert from "../../componets/common/Common_successAlert";
import Common_pagenation from '../../componets/common/Common_pagination';
import qs from 'qs'
import Property_delete from "../../componets/dialog/Property_delete";
import Property_select_color from "../../componets/dialog/Property_select_color";
import Property_add from "../../componets/dialog/Property_add";
import { errorMessage } from "../../wordsFile/wordsFile";
import Property_csv_upload from "../../componets/dialog/Property_csv_upload";
import PropertyDetail_equipment_add from "../../componets/dialog/PropertyDetail_equipment_add";
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import Property_information_upload from "../../componets/dialog/Property_information_upload";


const styles = (theme) => ({
    ground: {
        width: '100%',
        height: '100%',
        // '& .ant-select-single .ant-select-selector': {
        //     borderRadius: '4px',
        //     height: VH(40),
        //     display: 'flex',
        //     alignItems: 'center',
        //     fontSize: '1.25vw'
        // },
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8)
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        '& > div:nth-child(2)': {
            marginLeft: VW(8)
        }
    },
    csvLink: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: VW(24),
        '& > div': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            fontSize: VW(14)
        }
    },
    retrievalBlock: {
        marginTop: VH(8),
        marginLeft: VW(24),
        marginRight: VW(24),
        minHeight: VH(128),
        backgroundColor: '#F5F5F5',
        paddingTop: VH(16),
        paddingLeft: VW(16),
        paddingRight: VW(16),
        paddingBottom: VH(16),
        '& > Input': {
            height: VH(40),
            fontSize: VW(16)
        },
        '& > div:nth-of-type(1)': {
            width: '100%',
            display: 'flex',
            marginTop: VH(16),
            '& > div:nth-child(1)': {
                fontSize: VW(14),
                width: VW(364)
            },
            '& > div:nth-child(2)': {
                fontSize: VW(14),
                marginLeft: VW(8)
            }
        },
        '& > div:nth-of-type(2)': {
            width: '100%',
            display: 'flex',
            marginTop: VH(8),
            '& > div:nth-child(1)': {
                width: VW(360)
            },
            '& > div:nth-child(2)': {
                marginLeft: VW(8),
                width: VW(592),
            },
            '& > div > Input': {
                height: VH(40),
                fontSize: VW(16)
            }
        },
        '& > div:nth-of-type(3)': {
            width: '100%',
            display: 'flex',
            marginTop: VH(16),
            '& > div:nth-child(1)': {
                fontSize: VW(14),
                width: VW(176),
            },
            '& > div:nth-child(2)': {
                fontSize: VW(14),
                width: VW(176),
                marginLeft: VW(8)
            },
            '& > div:nth-child(3)': {
                fontSize: VW(14),
                marginLeft: VW(8)
            }
        },
        '& > div:nth-of-type(4)': {
            width: '100%',
            display: 'flex',
            marginTop: VH(8),
            '& > div:nth-child(1)': {
                width: VW(176)
            },
            '& > div:nth-child(2)': {
                width: VW(176),
                marginLeft: VW(8)
            },
            '& > div:nth-child(3)': {
                marginLeft: VW(8),
                width: VW(592),
            },
            '& > div > Input': {
                height: VH(40),
                fontSize: VW(16)
            }
        },
        '& > div:nth-of-type(5)': {
            width: '100%',
            display: 'flex',
            marginTop: VH(16),
            '& > div:nth-child(1)': {
                fontSize: VW(14),
                width: VW(176)
            },
            '& > div:nth-child(2)': {
                fontSize: VW(14),
                width: VW(176),
                marginLeft: VW(8),
            },
            '& > div:nth-child(3)': {
                fontSize: VW(14),
                marginLeft: VW(8),
                width: VW(176)
            },
            '& > div:nth-child(4)': {
                fontSize: VW(14),
                marginLeft: VW(8),
                width: VW(408)
            }
        },
        '& > div:nth-of-type(6)': {
            width: '100%',
            display: 'flex',
            marginTop: VH(8),
            '& > div:nth-child(1)': {
                width: VW(176)
            },
            '& > div:nth-child(2)': {
                marginLeft: VW(8),
                width: VW(176),
            },
            '& > div:nth-child(3)': {
                marginLeft: VW(8),
                width: VW(176),
            },
            '& > div:nth-child(4)': {
                marginLeft: VW(8),
                width: VW(408)
            },
            '& > div > Input': {
                height: VH(40),
                fontSize: VW(16)
            },
            '& > div > Select': {
                height: VH(40),
                fontSize: VW(16)
            }
        },
        '& > div:nth-of-type(7)': {
            width: '100%',
            display: 'flex',
            marginTop: VH(16),
            '& > div:nth-child(1)': {
                fontSize: VW(14),
                width: VW(192)
            },
            '& > div:nth-child(2)': {
                fontSize: VW(14),
                width: VW(192),
                marginLeft: VW(8),
            },
            '& > div:nth-child(3)': {
                fontSize: VW(14),
                marginLeft: VW(8),
                width: VW(192)
            }
        },
        '& > div:nth-of-type(8)': {
            width: '100%',
            display: 'flex',
            marginTop: VH(8),
            '& > div:nth-child(1)': {
                width: VW(192)
            },
            '& > div:nth-child(2)': {
                marginLeft: VW(8),
                width: VW(192),
            },
            '& > div:nth-child(3)': {
                marginLeft: VW(8),
                width: VW(192),
            },
            '& > div > Select': {
                height: VH(40),
                fontSize: VW(16)
            }
        },
        '& > div:last-child': {
            width: '100%',
            display: 'flex',
            marginTop: VH(16),
            justifyContent: 'space-between',
            alignItems: 'center',
            '& > div:nth-child(1)': {
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
            },
            '& > div:nth-child(1) > div:nth-child(1)': {
                fontSize: VW(16),
                fontWeight: 'bold',
                marginRight: VW(8)
            },
            '& > div:nth-child(1) > div:nth-child(2)': {
                paddingTop: VH(4)
            },
            '& > Button': {
                width: VW(112),
                height: VH(40),
                backgroundColor: theme.palette.primary.main,
                fontSize: VW(16),
                fontWeight: 'bold',
            }
        }
    },
    buttonGroup: {
        width: '100%',
        marginTop: VH(24),
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: VW(24),
        position: 'relative',
        '& > Button': {
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
            borderRadius: '4px'
        },
        '& > div > div': {
            width: '100%',
            height: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: VW(14),
            color: theme.palette.primary.main,
            border: '1px solid #0052CC',
            borderTop: 0,
            cursor: 'pointer'
        },
    },
    selectLast: {
        position: 'absolute',
        width: VW(224),
        height: VH(80),
        top: VH(40),
        backgroundColor: "#FFF",
        zIndex: '888'
    },
    selectFront: {
        position: 'absolute',
        right: VW(272),
        width: VW(184),
        height: VH(80),
        top: VH(40),
        backgroundColor: "#FFF",
        zIndex: '888'
    },
    AB: {
        color: '#E60024',
    },
    BB: {
        fontWeight: 'bold',
        marginLeft: VW(24),
        backgroundColor: theme.palette.primary.main,
    },
    CB: {
        fontWeight: 'bold',
        marginLeft: VW(24),
        backgroundColor: theme.palette.primary.main,
    },
    DB: {
        fontWeight: 'bold',
        marginLeft: VW(24),
        border: '1px solid #0052CC',
        color: theme.palette.primary.main,
    },
    tableBlock: {
        width: '100%',
        paddingLeft: VW(24),
        paddingRight: VW(24)
    },

    toolbar: {
        "& > p:nth-of-type(2)": {
            fontSize: VW(12),
            color: "#333333",
            fontWeight: 400
        },

    },
    selectLabel: {
        "& .MuiTablePagination-selectLabel": {
            fontSize: VW(12),
            color: "#333333",
            fontWeight: 400
        }
    },
    iconContainer: {
        color: '#999',

    }
});

function Property(props) {
    const {
        classes,
        setSelectFlag,
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.Property)
    }, [setSelectFlag])



    const [tagListApiFlag, setTagListApiFlag] = useState(true)


    const navigate = useNavigate()
    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    useEffect(() => {
        let newPersonName = []
        if (localStorage.getItem('property_personName') != null && localStorage.getItem('property_personName') != undefined) {
            newPersonName = Object.values(qs.parse(localStorage.getItem('property_personName')))
        }
        setPersonName(newPersonName)
        log(newPersonName)
        getTagList(newPersonName)
    }, [])


    // タグ list api
    const getTagList = (newPersonName) => {
        // let param = {
        //     tenant_id: localStorage.getItem('tenant_id')
        // }
        ApiTagListBuken().then(res => {
            let resData = res.data
            if (res.status == 200) {
                setTagListApiFlag(false)
                log(resData)
                resData.data.map(item => {
                    if (item.color_type === 1) {
                        item.color = '#FFB872'
                    } else if (item.color_type === 2) {
                        item.color = '#BB99FE'
                    } else if (item.color_type === 3) {
                        item.color = '#80BFFF'
                    } else if (item.color_type === 4) {
                        item.color = '#BCFA38'
                    } else if (item.color_type === 5) {
                        item.color = '#F9CFF9'
                    } else if (item.color_type === 6) {
                        item.color = '#ADEAFF'
                    } else if (item.color_type === 7) {
                        item.color = '#85F8B3'
                    } else if (item.color_type === 8) {
                        item.color = '#FF8CCF'
                    }
                })
                setNameOption(resData.data)
                getList(resData.data, newPersonName)
            }
        })
    }

    const getNewTag = (successFlag, newPersonName) => {
        log(nameOption)
        let newTag = ''
        if (Array.isArray(successFlag)) {
            successFlag.map(item => {
                newPersonName.map(person => {
                    if (item.tag_name === person) {
                        if (newTag === '') {
                            newTag = item.tag_id
                        } else {
                            newTag = newTag + ',' + item.tag_id
                        }
                    }
                })
            })
        } else {
            nameOption.map(item => {
                personName.map(person => {
                    if (item.tag_name === person) {
                        if (newTag === '') {
                            newTag = item.tag_id
                        } else {
                            newTag = newTag + ',' + item.tag_id
                        }
                    }
                })
            })
        }
        log(newTag)
        return newTag
    }

    // list api
    const getList = (successFlag, newPersonName) => {
        if (localStorage.getItem('property_deleteFlag')) {
            setSuccessContent('削除が完了しました。')
            setSuccessOpen(true)
            localStorage.removeItem('property_deleteFlag')
        }
        getNewTag()
        let params = {
            pageNum: page + 1,
            pageLimit: rowsPerPage,
            sortKey: pagingFlag.current,
            sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc',
            area: searchArea,
            address: searchLocation,
            papanets_buken_code: searchPapaNetsCD,
            customer_buken_code: searchCustomerGoodsCD,
            genba_name: searchSiteName,
            tenant_name: searchCompany,
            owner_name: searchOwner,
            keiyaku_status: searchRoundContract === 'すべて' ? '' : searchRoundContract,
            tag: searchTag,
            query_content: encodeURIComponent(searchParam),
            select_seko_gaisya_flag: searchcCompany === 'すべて' ? -1 : Number(searchcCompany),
            select_seti_jyokyo_flag: searchESituation === 'すべて' ? -1 : Number(searchESituation),
            select_buzai_flag: searchISituation === 'すべて' ? -1 : Number(searchISituation),
        }
        log(params)
        ApiBukenList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                document.getElementById('father_Block').scrollTop = 0;
                localStorage.setItem('property_retrievalFlag', retrievalFlag)
                localStorage.setItem('property_keywordSearch', keywordSearch)
                localStorage.setItem('property_searchParam', searchParam)
                localStorage.setItem('property_area', area)
                localStorage.setItem('property_location', location)
                localStorage.setItem('property_papaNetsCD', papaNetsCD)
                localStorage.setItem('property_customerGoodsCD', customerGoodsCD)
                localStorage.setItem('property_siteName', siteName)
                localStorage.setItem('property_company', company)
                localStorage.setItem('property_owner', owner)
                localStorage.setItem('property_roundContract', roundContract)
                localStorage.setItem('property_cCompany', cCompany)
                localStorage.setItem('property_eSituation', eSituation)
                localStorage.setItem('property_iSituation', iSituation)
                localStorage.setItem('property_personName', qs.stringify(personName))
                log(resData)
                setRrrorOpen(false)
                setCount(resData.count)
                resData.data.map(item => {
                    item.keiyaku_status = item.keiyaku_status === 'あし' ? '' : 'correct'
                    item.tag = item.tag == '' ? [] : item.tag.indexOf(',') != '-1' ? item.tag.split(',') : [item.tag]
                    item.tag_color = item.tag_color == '' ? [] : item.tag_color.indexOf(',') != '-1' ? item.tag_color.split(',') : [item.tag_color]
                    item.seko_gaisya_flag = item.seko_gaisya_flag == 0 ? '---' : '✓'
                    item.seti_jyokyo_flag = item.seti_jyokyo_flag == 0 ? '---' : '✓'
                    item.buzai_flag = item.buken_shubetsu == 0 ? '' : item.buzai_flag == 0 ? '---' : '✓'
                })
                setBodyList([...resData.data])
                if (successFlag === 'add') {
                    setSuccessContent('登録が完了しました。')
                    setSuccessOpen(true)
                }
                if (successFlag === 'delete') {
                    setSuccessContent('削除が完了しました。')
                    setSuccessOpen(true)
                }
                if (successFlag == 'eqAdd') {
                    openAddDialogS()
                }
                setDeleteData([])
                setIndeterminate(false)
                setCheckAll(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }
    const authority_id = localStorage.getItem("authority_id");
    // const authority_id = "4";

    // Common_checkBoxTable
    const [titleList, setTitleList] = useState(propertyTitleItem)
    const [bodyList, setBodyList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  update
    const getItemData = (item) => {
        log(item)
        navigate(routerNames.PropertyDetail, { state: item })
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete
    const [deleteData, setDeleteData] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  sort
    const [count, setCount] = useState(0)
    const pagingFlag = useRef('buken_id')
    const pagingDirection = useRef('↑')
    const toSort = (item) => {
        log(item)
        pagingFlag.current = item.field
        pagingDirection.current = item.titleIcon
        titleList.map(titleItem => {
            if (titleItem.name === item.name) {
                titleItem.titleIcon = item.titleIcon === '↓' ? '↑' : '↓'
                titleItem.checked = true
            } else {
                titleItem.checked = false
                if (titleItem.field === 'tag_color') {
                    titleItem.titleIcon = ''
                } else {
                    titleItem.titleIcon = '↓'
                }
            }
        })
        setTitleList([...titleList])
        getList()
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [page, setPage] = useState(localStorage.getItem('property_page') ? Number(localStorage.getItem('property_page')) : 0);
    const [currentPage, setCurrentPage] = useState(localStorage.getItem('property_page') ? Number(localStorage.getItem('property_page')) : 0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('property_rowsPerPage') ? Number(localStorage.getItem('property_rowsPerPage')) : 50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setCurrentPage(newPage)
        localStorage.setItem('property_page', String(newPage))
    };
    const handleChangePageForPagination = (event, newPage) => {
        const page = newPage - 1
        setPage(page)
        setCurrentPage(page)
        localStorage.setItem('property_page', String(page))
    };
    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('property_rowsPerPage', String(event.target.value))
        setRowsPerPage(event.target.value);
        setPage(0);
        localStorage.setItem('property_page', '0')
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);






    // 検索
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  検索 display flag
    const [retrievalFlag, setRetrievalFlag] = useState(false)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  キーワード
    const [keywordSearch, setKeywordSearch] = useState(localStorage.getItem('property_keywordSearch') == null || localStorage.getItem('property_keywordSearch') == undefined ? '' : localStorage.getItem('property_keywordSearch'))
    const [searchParam, setSearchParam] = useState(localStorage.getItem('property_searchParam') == null || localStorage.getItem('property_searchParam') == undefined ? '' : localStorage.getItem('property_searchParam'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  エリア
    const [area, setArea] = useState(localStorage.getItem('property_area') == null || localStorage.getItem('property_area') == undefined ? '' : localStorage.getItem('property_area'))
    const [searchArea, setSearchArea] = useState(localStorage.getItem('property_searchArea') == null || localStorage.getItem('property_searchArea') == undefined ? '' : localStorage.getItem('property_searchArea'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  所在地
    const [location, setLocation] = useState(localStorage.getItem('property_location') == null || localStorage.getItem('property_location') == undefined ? '' : localStorage.getItem('property_location'))
    const [searchLocation, setSearchLocation] = useState(localStorage.getItem('property_location') == null || localStorage.getItem('property_location') == undefined ? '' : localStorage.getItem('property_location'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  パパネッツ物件CD
    const [papaNetsCD, setPapaNetsCD] = useState(localStorage.getItem('property_papaNetsCD') == null || localStorage.getItem('property_papaNetsCD') == undefined ? '' : localStorage.getItem('property_papaNetsCD'))
    const [searchPapaNetsCD, setSearchPapaNetsCD] = useState(localStorage.getItem('property_searchPapaNetsCD') == null || localStorage.getItem('property_searchPapaNetsCD') == undefined ? '' : localStorage.getItem('property_searchPapaNetsCD'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  物件CD
    const [customerGoodsCD, setCustomerGoodsCD] = useState(localStorage.getItem('property_customerGoodsCD') == null || localStorage.getItem('property_customerGoodsCD') == undefined ? '' : localStorage.getItem('property_customerGoodsCD'))
    const [searchCustomerGoodsCD, setSeachCustomerGoodsCD] = useState(localStorage.getItem('property_searchCustomerGoodsCD') == null || localStorage.getItem('property_searchCustomerGoodsCD') == undefined ? '' : localStorage.getItem('property_searchCustomerGoodsCD'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  物件名
    const [siteName, setSiteName] = useState(localStorage.getItem('property_siteName') == null || localStorage.getItem('property_siteName') == undefined ? '' : localStorage.getItem('property_siteName'))
    const [searchSiteName, setSearchSiteName] = useState(localStorage.getItem('property_searchSiteName') == null || localStorage.getItem('property_searchSiteName') == undefined ? '' : localStorage.getItem('property_searchSiteName'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  管理会社
    const [company, setCompany] = useState(localStorage.getItem('property_company') == null || localStorage.getItem('property_company') == undefined ? '' : localStorage.getItem('property_company'))
    const [searchCompany, setSearchCompany] = useState(localStorage.getItem('property_searchCompany') == null || localStorage.getItem('property_searchCompany') == undefined ? '' : localStorage.getItem('property_searchCompany'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  オーナー
    const [owner, setOwner] = useState(localStorage.getItem('property_owner') == null || localStorage.getItem('property_owner') == undefined ? '' : localStorage.getItem('property_owner'))
    const [searchOwner, setSearchOwner] = useState(localStorage.getItem('property_searchOwner') == null || localStorage.getItem('property_searchOwner') == undefined ? '' : localStorage.getItem('property_searchOwner'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  巡回契約
    const [roundContract, setRoundContract] = useState(localStorage.getItem('property_roundContract') == null || localStorage.getItem('property_roundContract') == undefined ? 'すべて' : localStorage.getItem('property_roundContract'))
    const [searchRoundContract, setSearchRoundContract] = useState(localStorage.getItem('property_searchRoundContract') == null || localStorage.getItem('property_searchRoundContract') == undefined ? 'すべて' : localStorage.getItem('property_searchRoundContract'))
    const selectRoundContract = (e) => {
        setRoundContract(e.target.value);
    }
    const [roundContractOption, setRoundContractOption] = useState([
        { value: 'すべて', label: 'すべて' },
        { value: '0', label: 'あり' },
        { value: '1', label: 'なし' },
    ])
    const [loginOption, setLoginOption] = useState([
        { value: 'すべて', label: 'すべて' },
        { value: '1', label: '登録済' },
        { value: '0', label: '未登録' },
    ])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> タグ
    const [searchTag, setSearchTag] = useState(localStorage.getItem('property_searchTag') == null || localStorage.getItem('property_searchTag') == undefined ? '' : localStorage.getItem('property_searchTag'))
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 施工会社の登録
    const [cCompany, setCCompany] = useState(localStorage.getItem('property_cCompany') == null || localStorage.getItem('property_cCompany') == undefined ? 'すべて' : localStorage.getItem('property_cCompany'))
    const [searchcCompany, setSearchcCompany] = useState(localStorage.getItem('property_searchcCompany') == null || localStorage.getItem('property_searchcCompany') == undefined ? 'すべて' : localStorage.getItem('property_searchcCompany'))
    const selectCCompany = (e) => {
        setCCompany(e.target.value);
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 設備状況の登録
    const [eSituation, setESituation] = useState(localStorage.getItem('property_eSituation') == null || localStorage.getItem('property_eSituation') == undefined ? 'すべて' : localStorage.getItem('property_eSituation'))
    const [searchESituation, setSearchESituation] = useState(localStorage.getItem('property_SearchESituation') == null || localStorage.getItem('property_SearchESituation') == undefined ? 'すべて' : localStorage.getItem('property_SearchESituation'))
    const selectESituation = (e) => {
        setESituation(e.target.value);
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 設置状況の登録
    const [iSituation, setISituation] = useState(localStorage.getItem('property_iSituation') == null || localStorage.getItem('property_iSituation') == undefined ? 'すべて' : localStorage.getItem('property_iSituation'))
    const [searchISituation, setSearchISituation] = useState(localStorage.getItem('property_SearchISituation') == null || localStorage.getItem('property_SearchISituation') == undefined ? 'すべて' : localStorage.getItem('property_SearchISituation'))
    const selectISituation = (e) => {
        setISituation(e.target.value);
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox select タグ
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [nameOption, setNameOption] = useState([])
    const [personName, setPersonName] = useState([])
    const handleChange = (event) => {
        log(event.target.value)
        setPersonName(
            typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
        );
    };

    const search = () => {
        setSearchParam(keywordSearch)
        setSearchArea(area)
        setSearchLocation(location)
        setSearchPapaNetsCD(papaNetsCD)
        setSeachCustomerGoodsCD(customerGoodsCD)
        setSearchSiteName(siteName)
        setSearchCompany(company)
        setSearchOwner(owner)
        setSearchRoundContract(roundContract)
        setSearchTag(getNewTag())
        setSearchcCompany(cCompany)
        setSearchESituation(eSituation)
        setSearchISituation(iSituation)
        setPage(0)
        setCurrentPage(0)
    }

    // dialog
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        let count = ''
        deleteData.map(item => {
            if (item.keiyaku_status == 'correct') {
                count = count + item.genba_name + ','
            }
        })
        if (count == '') {
            log(deleteData)
            setDeleteData(deleteData)
            if (deleteData.length > 0) {
                setDeleteOpen(true)
            }
        } else {
            setErrorContent(`${count.slice(0, -1)}は、パパネッツ巡回契約中の物件のため削除できません。`)
            setRrrorOpen(true)

        }

    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  select dialog
    const [colorModelOpen, setColorModelOpen] = useState(false)
    const [colorTagList, setColorTagList] = useState()
    const colorModelOnClose = () => {
        setColorModelOpen(false)
    }
    const openColorModel = (item) => {
        setColorTagList(item)
        setColorModelOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  upload dialog csv
    const [uploadOpen, setUploadOpen] = useState(false)
    const uploadOnClose = () => {
        setUploadOpen(false)
    }
    const openUploadDialog = () => {
        setUploadOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  upload dialog information
    const [uploadOpenInfor, setUploadOpenInfor] = useState(false)
    const uploadOnCloseInfor = () => {
        setUploadOpenInfor(false)
    }
    const openInforUploadDialog = () => {
        setUploadOpenInfor(true)
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog equipmnet
    const [addOpenS, setAddOpenS] = useState(false)
    const addOnCloseS = () => {
        setAddOpenS(false)
    }
    const openAddDialogS = () => {
        setAddOpenS(true)
    }

    const [buken_id, setBuken_id] = useState('')



    //  物件ダウンロード button  select display flag
    const [downloadSelectA, setDownloadSelectA] = useState(false)
    const [downloadSelectB, setDownloadSelectB] = useState(false)
    const [downloadSelectC, setDownloadSelectC] = useState(false)

    const getUrlname = (url) => {
        url = url.split('?')[0];
        var urlSlashCount = url.split('/').length;
        return url.split('/')[urlSlashCount - 1];
    };

    // CSVテンプレートはこちら download api
    const downloadTemplate = () => {
        ApiBukenFileDownloadModel().then(res => {
            let resData = res.data
            if (res.status == 200) {
                log(resData)
                setRrrorOpen(false)
                var downloadName = getUrlname(decodeURIComponent(resData.data))
                log(downloadName)
                fetch(resData.data, {
                    method: 'get',
                    responseType: 'blob'
                }).then(res => {
                    // 获取blob文件流
                    return res.blob();
                }).then(blob => {
                    let a = document.createElement('a');
                    // 通过 blob 对象获取对应的 url
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = downloadName;
                    a.click();
                    a.remove();
                })
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // 選択ダウンロード csv download api
    const downSelectCsv = (flag) => {
        if (deleteData.length == 0) {
            return
        } else {
            let bukenIdString = ''
            deleteData.map(item => {
                bukenIdString = bukenIdString + ',' + item.buken_id
            })
            bukenIdString = bukenIdString.slice(1)
            log(bukenIdString)
            if (flag == 'template') {
                let param = {
                    buken_id_list: bukenIdString
                }
                ApiBukenFileDownloadBase(param).then(res => {
                    let resData = res.data
                    if (res.status == 200) {
                        log(resData)
                        setRrrorOpen(false)
                        var downloadName = getUrlname(decodeURIComponent(resData.data))
                        fetch(resData.data, {
                            method: 'get',
                            responseType: 'blob'
                        }).then(res => {
                            // 获取blob文件流
                            return res.blob();
                        }).then(blob => {
                            let a = document.createElement('a');
                            // 通过 blob 对象获取对应的 url
                            let url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.download = downloadName;
                            a.click();
                            a.remove();
                        })
                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                        log(res)
                        setRrrorOpen(true)
                        setErrorContent(res.data.detail.toString())
                    }
                }).catch(err => {

                })
            } else {
                let param = {
                    buken_id_lt: bukenIdString
                }
                ApiBukenFileChoosedownload(param).then(res => {
                    let resData = res.data
                    if (res.status == 200) {
                        log(resData)
                        setRrrorOpen(false)
                        var downloadName = getUrlname(decodeURIComponent(resData.data))
                        fetch(resData.data, {
                            method: 'get',
                            responseType: 'blob'
                        }).then(res => {
                            // 获取blob文件流
                            return res.blob();
                        }).then(blob => {
                            let a = document.createElement('a');
                            // 通过 blob 对象获取对应的 url
                            let url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.download = downloadName;
                            a.click();
                            a.remove();
                        })
                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                        log(res)
                        setRrrorOpen(true)
                        setErrorContent(res.data.detail.toString())
                    }
                }).catch(err => {

                })
            }
        }
    }



    // 显示loading
    function showLoading() {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.createRoot(dom).render(
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
                <div style={{ fontWeight: "bold", color: '#DDD' }}>ダウンロード準備中</div>
            </div>
        )
    }
    // 隐藏loading
    function hideLoading() {
        document.body.removeChild(document.getElementById('loading'))
    }


    // 全件ダウンロード
    const downAllCsv = (flag) => {
        let newTag = ''
        nameOption.map(item => {
            Object.values(qs.parse(localStorage.getItem('property_personName'))).map(person => {
                if (item.tag_name === person) {
                    if (newTag === '') {
                        newTag = item.tag_id
                    } else {
                        newTag = newTag + ',' + item.tag_id
                    }
                }
            })
        })
        let params = {
            sortKey: pagingFlag.current,
            sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc',
            area: localStorage.getItem('property_area'),
            address: localStorage.getItem('property_location'),
            papanets_buken_code: localStorage.getItem('property_papaNetsCD'),
            customer_buken_code: localStorage.getItem('property_customerGoodsCD'),
            genba_name: localStorage.getItem('property_siteName'),
            tenant_name: localStorage.getItem('property_company'),
            owner_name: localStorage.getItem('property_owner'),
            keiyaku_status: localStorage.getItem('property_roundContract') === 'すべて' ? '' : localStorage.getItem('property_roundContract'),
            select_seko_gaisya_flag: localStorage.getItem('property_cCompany') === 'すべて' ? -1 : Number(localStorage.getItem('property_cCompany')),
            select_seti_jyokyo_flag: localStorage.getItem('property_eSituation') === 'すべて' ? -1 : Number(localStorage.getItem('property_eSituation')),
            select_buzai_flag: localStorage.getItem('property_iSituation') === 'すべて' ? -1 : Number(localStorage.getItem('property_iSituation')),
            tag: newTag,
            query_content: encodeURIComponent(localStorage.getItem('property_keywordSearch'))
        }
        if (flag == 'template') {
            ApiBukenFileDownloadBaseAll(params).then(res => {
                let resData = res.data
                if (res.status == 200) {
                    log(resData)
                    setRrrorOpen(false)
                    var downloadName = getUrlname(decodeURIComponent(resData.data))
                    log(downloadName)
                    showLoading()
                    fetch(resData.data, {
                        method: 'get',
                        responseType: 'blob'
                    }).then(res => {
                        // 获取blob文件流
                        return res.blob();
                    }).then(blob => {
                        hideLoading()
                        let a = document.createElement('a');
                        // 通过 blob 对象获取对应的 url
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = downloadName;
                        a.click();
                        a.remove();
                    })
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        } else {
            ApiBukenFileDownload(params).then(res => {
                let resData = res.data
                if (res.status == 200) {
                    log(resData)
                    setRrrorOpen(false)
                    var downloadName = getUrlname(decodeURIComponent(resData.data))
                    log(downloadName)
                    showLoading()
                    fetch(resData.data, {
                        method: 'get',
                        responseType: 'blob'
                    }).then(res => {
                        // 获取blob文件流
                        return res.blob();
                    }).then(blob => {
                        hideLoading()
                        let a = document.createElement('a');
                        // 通过 blob 对象获取对应的 url
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = downloadName;
                        a.click();
                        a.remove();
                    })
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }




    // get list
    useEffect(() => {
        if (!tagListApiFlag) {
            getList()
        }
    }, [
        page,
        rowsPerPage,
        searchParam,
        searchArea,
        searchLocation,
        searchPapaNetsCD,
        searchCustomerGoodsCD,
        searchSiteName,
        searchCompany,
        searchOwner,
        searchRoundContract,
        searchcCompany,
        searchTag,
        searchESituation,
        searchISituation
    ]
    )



    useEffect(() => {
        window.addEventListener('keydown', enter);
        return () => {
            window.removeEventListener('keydown', enter);
        };
    }, [keywordSearch])
    const enter = (e) => {
        switch (e.keyCode) {
            case 13:
                search()
                break;
        }
    }


    function propertyTitleItem() {
        var item = []
        if (authority_id != "3" && authority_id != "4") {
            item = [
                { titleIcon: '↓', name: 'パパネッツ\n\u00A0\u00A0\物件CD', field: 'papanets_buken_code', width: '5.8%' },
                { titleIcon: '↓', name: '物件CD', field: 'customer_buken_code', width: '5.3%' },
                { titleIcon: '↓', name: '管理会社', field: 'tenant_name', width: '20.4%' },
                { titleIcon: '↓', name: 'オーナー', field: 'owner_name', width: '6.8%' },
                { titleIcon: '↓', name: 'propertyTitleIcon', field: 'buken_shubetsu', width: '3.9%', paddingLeft: '0.9vw' },
                { titleIcon: '↓', name: '物件名', field: 'genba_name', width: '16.5%', canClick: true },
                { titleIcon: '', name: 'タグ', field: 'tag_color', width: '4.9%', center: 'center', },
                { titleIcon: '↓', name: '巡回\n契約', field: 'keiyaku_status', width: '3.9%', paddingLeft: '0.4vw' },
                { titleIcon: '↓', name: '所在地', field: 'address', width: '20.4%' },
                { titleIcon: '↓', name: '施工\n業者', field: 'seko_gaisya_flag', width: '3.9%', center: 'center' },
                { titleIcon: '↓', name: '設備\n状況', field: 'seti_jyokyo_flag', width: '3.9%', center: 'center' },
                { titleIcon: '↓', name: '設置\n状況', field: 'buzai_flag', width: '3.9%', center: 'center' }
            ]
        } else {
            item = [
                { titleIcon: '↓', name: '物件CD', field: 'customer_buken_code', width: '9.65%' },
                { titleIcon: '↓', name: 'オーナー', field: 'owner_name', width: '10.95%' },
                { titleIcon: '↓', name: 'propertyTitleIcon', field: 'buken_shubetsu', width: '6.35%', paddingLeft: '0.9vw' },
                { titleIcon: '↓', name: '物件名', field: 'genba_name', width: '20.65%', canClick: true },
                { titleIcon: '', name: 'タグ', field: 'tag_color', width: '6.05%', center: 'center' },
                { titleIcon: '↓', name: '巡回\n契約', field: 'keiyaku_status', width: '4.55%', paddingLeft: '0.4vw' },
                { titleIcon: '↓', name: '所在地', field: 'address', width: '29.05%' },
                { titleIcon: '↓', name: '施工\n業者', field: 'seko_gaisya_flag', width: '3.9%', center: 'center' },
                { titleIcon: '↓', name: '設備\n状況', field: 'seti_jyokyo_flag', width: '3.9%', center: 'center' },
                { titleIcon: '↓', name: '設置\n状況', field: 'buzai_flag', width: '3.9%', center: 'center' }
            ]
        }
        return item
    }

    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}>物件一覧</div>
            <div className={classes.tableTitle}>
                <MenuHeader_property />
                <div>物件一覧</div>
            </div>
            {errorOpen && <div style={{ marginLeft: VW(24), marginRight: VW(24), marginBottom: VH(12), marginTop: VH(12) }}>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
            </div>}
            {successOpen && <div style={{ marginLeft: VW(24), marginRight: VW(24), marginBottom: VH(12), marginTop: VH(12) }}>
                <Common_successAlert
                    open={successOpen}
                    setSuccessOpen={setSuccessOpen}
                    successContent={successContent}
                />
            </div>}
            <div className={classes.csvLink}>
                {(authority_id == '3' || authority_id == '4') && <div onClick={downloadTemplate}>CSVテンプレートはこちら&nbsp;&nbsp;&gt;</div>}
            </div>
            <div className={classes.retrievalBlock}>
                <Input
                    style={{
                        // width: VW(874),
                        height: VH(40),
                        fontSize: VW(16)
                    }}
                    placeholder='キーワードを入力してください'
                    maxLength={345}
                    value={keywordSearch}
                    onChange={(event) => { setKeywordSearch(event.target.value) }}
                    suffix={
                        (
                            <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                setKeywordSearch('')
                            }}></ClearRounded>
                        )
                    }
                />
                <div style={{ display: retrievalFlag ? '' : 'none' }}>
                    <div>エリア</div>
                    <div>所在地</div>
                </div>
                <div style={{ display: retrievalFlag ? '' : 'none' }}>
                    <div>
                        <Input
                            style={{
                                height: VH(40),
                                fontSize: VW(16)
                            }}
                            placeholder='エリア'
                            maxLength={60}
                            value={area}
                            onChange={(event) => { setArea(event.target.value) }}
                            suffix={
                                (
                                    <ClearRounded className={classes.iconContainer} style={{ display: area ? '' : 'none' }} onClick={(e) => {
                                        setArea('')
                                    }}></ClearRounded>
                                )
                            }
                        />
                    </div>
                    <div>
                        <Input
                            style={{
                                height: VH(40),
                                fontSize: VW(16)
                            }}
                            placeholder='所在地'
                            maxLength={100}
                            value={location}
                            onChange={(event) => { setLocation(event.target.value) }}
                            suffix={
                                (
                                    <ClearRounded className={classes.iconContainer} style={{ display: location ? '' : 'none' }} onClick={(e) => {
                                        setLocation('')
                                    }}></ClearRounded>
                                )
                            }
                        />
                    </div>
                </div>
                <div style={{ display: retrievalFlag ? '' : 'none' }}>
                    {(authority_id != "3" && authority_id != "4") && <div>パパネッツ物件CD</div>}
                    <div style={{ width: VW(176) }}>物件CD</div>
                    <div style={{ width: VW(592) }}>物件名</div>
                </div>
                <div style={{ display: retrievalFlag ? '' : 'none' }}>
                    {(authority_id != "3" && authority_id != "4") && <div>
                        <Input
                            style={{
                                height: VH(40),
                                fontSize: VW(16)
                            }}
                            placeholder='パパネッツ物件CD'
                            maxLength={20}
                            value={papaNetsCD}
                            onChange={(event) => { setPapaNetsCD(event.target.value) }}
                            suffix={
                                (
                                    <ClearRounded className={classes.iconContainer} style={{ display: papaNetsCD ? '' : 'none' }} onClick={(e) => {
                                        setPapaNetsCD('')
                                    }}></ClearRounded>
                                )
                            }
                        />
                    </div>}
                    <div style={{ width: VW(176) }}>
                        <Input
                            style={{
                                height: VH(40),
                                fontSize: VW(16)
                            }}
                            placeholder='物件CD'
                            maxLength={50}
                            value={customerGoodsCD}
                            onChange={(event) => { setCustomerGoodsCD(event.target.value) }}
                            suffix={
                                (
                                    <ClearRounded className={classes.iconContainer} style={{ display: customerGoodsCD ? '' : 'none' }} onClick={(e) => {
                                        setCustomerGoodsCD('')
                                    }}></ClearRounded>
                                )
                            }
                        />
                    </div>
                    <div style={{ width: VW(592) }}>
                        <Input
                            style={{
                                height: VH(40),
                                fontSize: VW(16)
                            }}
                            placeholder='物件名'
                            maxLength={100}
                            value={siteName}
                            onChange={(event) => { setSiteName(event.target.value) }}
                            suffix={
                                (
                                    <ClearRounded className={classes.iconContainer} style={{ display: siteName ? '' : 'none' }} onClick={(e) => {
                                        setSiteName('')
                                    }}></ClearRounded>
                                )
                            }
                        />
                    </div>
                </div>
                <div style={{ display: retrievalFlag ? '' : 'none' }}>
                    {(authority_id != "3" && authority_id != "4") && <div>管理会社</div>}
                    <div style={{ width: VW(176) }}>オーナー</div>
                    <div style={{ width: VW(176) }}>巡回契約</div>
                    <div style={{ width: VW(408) }}>タグ</div>
                </div>
                <div style={{ display: retrievalFlag ? '' : 'none' }}>
                    {(authority_id != "3" && authority_id != "4") && <div>
                        <Input
                            style={{
                                height: VH(40),
                                fontSize: VW(16)
                            }}
                            placeholder='管理会社'
                            maxLength={60}
                            value={company}
                            onChange={(event) => { setCompany(event.target.value) }}
                            suffix={
                                (
                                    <ClearRounded className={classes.iconContainer} style={{ display: company ? '' : 'none' }} onClick={(e) => {
                                        setCompany('')
                                    }}></ClearRounded>
                                )
                            }
                        />
                    </div>}
                    <div style={{ width: VW(176) }}>
                        <Input
                            style={{
                                height: VH(40),
                                fontSize: VW(16)
                            }}
                            placeholder='オーナー'
                            maxLength={100}
                            value={owner}
                            onChange={(event) => { setOwner(event.target.value) }}
                            suffix={
                                (
                                    <ClearRounded className={classes.iconContainer} style={{ display: owner ? '' : 'none' }} onClick={(e) => {
                                        setOwner('')
                                    }}></ClearRounded>
                                )
                            }
                        />
                    </div>
                    <div style={{ width: VW(176) }}>
                        <Select
                            style={{ width: VW(176), height: VH(40), fontSize: VW(16), backgroundColor: '#FFF' }}
                            value={roundContract}
                            onChange={selectRoundContract}
                        >
                            {roundContractOption.map((item, index) => {
                                return (
                                    <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div style={{ width: VW(408) }}>
                        <Select
                            style={{ width: '100%', height: VH(40), fontSize: VW(16), backgroundColor: '#FFF' }}
                            multiple
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput label="" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {nameOption && nameOption.map((item, index) => (
                                <MenuItem key={index} value={item.tag_name} style={{ height: VH(38), fontSize: VW(16) }} >
                                    <Checkbox color="success" checked={personName.indexOf(item.tag_name) > -1} />
                                    <ListItemText style={{ height: VH(30), display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: item.color }} primary={item.tag_name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div style={{ display: retrievalFlag ? '' : 'none' }}>
                    <div>施工会社の登録</div>
                    <div>設備状況の登録</div>
                    <div>設置状況の登録</div>
                </div>
                <div style={{ display: retrievalFlag ? '' : 'none' }}>
                    <div>
                        <Select
                            style={{ width: '100%', height: VH(40), fontSize: VW(16), backgroundColor: '#FFF' }}
                            value={cCompany}
                            onChange={selectCCompany}
                        >
                            {loginOption.map((item, index) => {
                                return (
                                    <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div>
                        <Select
                            style={{ width: '100%', height: VH(40), fontSize: VW(16), backgroundColor: '#FFF' }}
                            value={eSituation}
                            onChange={selectESituation}
                        >
                            {loginOption.map((item, index) => {
                                return (
                                    <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div>
                        <Select
                            style={{ width: '100%', height: VH(40), fontSize: VW(16), backgroundColor: '#FFF' }}
                            value={iSituation}
                            onChange={selectISituation}
                        >
                            {loginOption.map((item, index) => {
                                return (
                                    <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div>
                    <div onClick={() => { setRetrievalFlag(!retrievalFlag) }}>
                        <div>{retrievalFlag ? '条件非表示' : '条件表示'}</div>
                        <div>{retrievalFlag ? <ReduceIcon_small /> : <AddIcon_small />}</div>
                    </div>
                    <Button type="primary" icon={<SearchIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={search}>検索</Button>
                </div>
            </div>
            <div className={classes.buttonGroup}>
                {(authority_id == '3' || authority_id == '4') &&
                    <Button className={classes.CB}
                        type="primary"
                        style={{ borderRadius: downloadSelectA ? '4px 4px 0 0' : '', width: VW(184) }}
                        icon={<DownloadIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={() => { setDownloadSelectA(!downloadSelectA) }} >物件出力<CaretDownOutlined style={{ color: '#FFF' }} /></Button>
                }
                {downloadSelectA &&
                    <div className={classes.selectFront}>
                        <div onClick={downSelectCsv} style={{ backgroundColor: deleteData.length == 0 ? '#DDD' : '' }}>選択ダウンロード（{deleteData.length}件）</div>
                        <div onClick={downAllCsv}>全件ダウンロード（{count}件）</div>
                    </div>
                }
                {(authority_id == '3' || authority_id == '4') &&
                    <Button
                        className={classes.DB}
                        style={{ borderRadius: downloadSelectB ? '4px 4px 0 0' : '', width: VW(224) }}
                        icon={<DownloadIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={() => { setDownloadSelectB(!downloadSelectB) }}>物件付帯情報出力<CaretDownOutlined style={{ color: '#0052CC' }} /></Button>
                }
                {downloadSelectB &&
                    <div className={classes.selectLast}>
                        <div onClick={() => { downSelectCsv('template') }} style={{ backgroundColor: deleteData.length == 0 ? '#DDD' : '' }}>選択ダウンロード（{deleteData.length}件）</div>
                        <div onClick={() => { downAllCsv('template') }}>全件ダウンロード（{count}件）</div>
                    </div>
                }
            </div>
            <div className={classes.buttonGroup}>
                {authority_id == '3' &&
                    <Button
                        className={classes.AB}
                        type="text"
                        danger
                        icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        disabled={deleteData.length === 0}
                        onClick={openDeleteDialog}>削除</Button>
                }
                {(authority_id == '3' || authority_id == '4') &&
                    <Button
                        className={classes.BB}
                        type="primary"
                        style={{ width: VW(120) }}
                        icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={openAddDialog}>物件登録</Button>
                }
                {(authority_id == '3' || authority_id == '4') &&
                    <Button className={classes.CB}
                        type="primary"
                        style={{ width: VW(184) }}
                        icon={<UploadIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={openUploadDialog}>一括物件登録</Button>
                }
                {(authority_id == '3' || authority_id == '4') &&
                    <Button className={classes.DB}
                        style={{ width: VW(224) }}
                        icon={<UploadIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={openInforUploadDialog}>物件付帯情報登録</Button>
                }
                {(authority_id != '3' && authority_id != '4') &&
                    <Button className={classes.CB}
                        type="primary"
                        style={{ borderRadius: downloadSelectC ? '4px 4px 0 0' : '', width: VW(184) }}
                        icon={<DownloadIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        onClick={() => { setDownloadSelectC(!downloadSelectC) }} >物件出力<CaretDownOutlined style={{ color: '#FFF' }} /></Button>
                }
                {downloadSelectC &&
                    <div className={classes.selectLast} style={{ width: VW(184) }}>
                        <div onClick={downSelectCsv} style={{ backgroundColor: deleteData.length == 0 ? '#DDD' : '' }}>選択ダウンロード（{deleteData.length}件）</div>
                        <div onClick={downAllCsv}>全件ダウンロード（{count}件）</div>
                    </div>
                }
            </div>
            <TablePagination
                component="div"
                style={{ paddingRight: VW(24), fontSize: VW(12) }}
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
                labelRowsPerPage='表示件数:'
                className={classes.selectLabel}
                classes={{
                    toolbar: classes.toolbar
                }}
            />
            <div className={classes.tableBlock}>
                <Common_checkBoxTable
                    titleList={titleList}
                    bodyList={bodyList}
                    setBodyList={setBodyList}
                    getItemData={getItemData}
                    setCheckList={setDeleteData}
                    toSort={toSort}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                    selectColor={openColorModel}
                    // allWidth={(authority_id != "3" && authority_id != "4") ? '1440px' : '1280px'}
                    allWidth={(authority_id != "3" && authority_id != "4") ? '2100px' : '1540px'}
                />
            </div>
            <Property_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={deleteData}
                setDeleteData={setDeleteData}
                getList={getList}
                setIndeterminate={setIndeterminate}
                setCheckAll={setCheckAll}
            />
            <Property_select_color
                open={colorModelOpen}
                onClose={colorModelOnClose}
                tagList={colorTagList}
                getList={getList}
            />
            <Property_add
                open={addOpen}
                onClose={addOnClose}
                getList={getList}
                setBuken_id={setBuken_id}
            />
            <Property_csv_upload
                open={uploadOpen}
                onClose={uploadOnClose}
                getList={getList}
            />
            <Property_information_upload
                open={uploadOpenInfor}
                onClose={uploadOnCloseInfor}
                getList={getList}
            />
            <PropertyDetail_equipment_add
                open={addOpenS}
                onClose={addOnCloseS}
                getList={getList}
                buken_id={buken_id}
            />
            <Common_pagenation
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChange={handleChangePageForPagination}
            />
        </div >
    )
}

Property.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Property);