import {
    Checkbox
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, Popover } from 'antd';
import React from "react";
import { VH, VW } from "../../componets/function/sizeAdapter";
import AddIcon from "../icon/AddIcon";
import Checkbox_Table_Block from "../icon/Checkbox_Table_Block";
import CorrectIcon from "../icon/CorrectIcon";
import MenuHeader_property from "../icon/MenuHeader_property";
import PropertyDetail_file_download from "../icon/PropertyDetail_file_download";
import PropertyDetail_file_file from "../icon/PropertyDetail_file_file";
import PropertyDetail_file_folder_close from "../icon/PropertyDetail_file_folder_close";
import PropertyDetail_file_folder_close_arrow from "../icon/PropertyDetail_file_folder_close_arrow";
import PropertyDetail_file_folder_open from "../icon/PropertyDetail_file_folder_open";
import PropertyDetail_file_folder_open_arrow from "../icon/PropertyDetail_file_folder_open_arrow";
import PropertyDetail_file_upload from "../icon/PropertyDetail_file_upload";
import WrongIcon from "../icon/WrongIcon";
import Member_tab_true from "../icon/Member_tab_true";
import Member_tab_false from "../icon/Member_tab_false";
import EditIcon from '@mui/icons-material/Edit';

const styles = (theme) => ({
    checkBoxTable: {
        width: '100%',
        height: '100%',
        fontSize: VW(16),
    },
    titleBlock: {
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDDDDD',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold'
        },
        '& > div:nth-child(1)': {
            paddingRight: VW(11.5),
            paddingLeft: VW(19.5)
        },
        '& > div > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            userSelect: 'none'
        },
        // '& > div > div > span':{
        //     height:'100%',
        //     display:'flex',
        //     alignItems:'center',
        //     marginLeft:,
        //     fontSize:,
        //     color:'#AAA'
        // },
    },
    bodyBlock: {
        width: '100%',
        height: VH(48),
        borderBottom: '1px solid #DDDDDD',
        display: 'flex',
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& > div:nth-child(1)': {
            paddingRight: VW(11.5),
            paddingLeft: VW(19.5)
        }
    },
    checkBox: {
        '&:hover': {
            backgroundColor: 'transparent !important'
        },
        padding: VW(0)
    },
    sendMessangeButton: {
        width: VW(63),
        height: VH(32),
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        fontSize: VW(14),
        fontWeight: 'bold',
    },
});

function Common_checkBoxTable(props) {
    const {
        classes,
        titleList,
        bodyList,
        setBodyList,
        getItemData,
        setCheckList,
        toSort,
        indeterminate,
        setIndeterminate,
        checkAll,
        setCheckAll,
        selectColor,
        sendMessageAgain,
        downlaodFile,
        allWidth,
        pageFlag,
        uploadToFolder,
        getItemDataAgain,
        changeFolderName,
        getPriorityColor,
        changeUseState
    } = props


    const authority_id = localStorage.getItem("authority_id")



    const changeBodyCheckd = (list) => {
        if (pageFlag != 'property_detail_file') {
            let checkTotal = 0
            let checkList = []
            bodyList.map(item => {
                if (item === list) {
                    if (item.checked) {
                        item.checked = false
                    } else {
                        item.checked = true
                        checkTotal = checkTotal + 1
                        checkList.push(item)
                    }
                } else {
                    if (item.checked) {
                        checkTotal = checkTotal + 1
                        checkList.push(item)
                    }
                }
            })
            setCheckList([...checkList])
            setBodyList([...bodyList])
            if (checkTotal === bodyList.length) {
                setCheckAll(true)
                setIndeterminate(false)
            } else if (checkTotal < bodyList.length && checkTotal != 0) {
                setCheckAll(false)
                setIndeterminate(true)
            } else {
                setCheckAll(false)
                setIndeterminate(false)
            }
        } else {
            let checkTotal = 0
            let checkList = []
            bodyList.map(item => {
                if (item === list) {
                    if (item.checked) {
                        item.checked = false
                    } else {
                        item.checked = true
                        checkTotal = checkTotal + 1
                        checkList.push(item)
                    }
                } else {
                    if (item.parent_file_manage_id == list.file_manage_id) {
                        item.checked = list.checked
                    }
                    if (item.file_manage_id == list.parent_file_manage_id) {
                        item.checked = true
                    }
                    if (item.checked) {
                        checkTotal = checkTotal + 1
                        checkList.push(item)
                    }
                }
            })
            setCheckList([...checkList])
            setBodyList([...bodyList])
            if (checkTotal === bodyList.length) {
                setCheckAll(true)
                setIndeterminate(false)
            } else if (checkTotal < bodyList.length && checkTotal != 0) {
                setCheckAll(false)
                setIndeterminate(true)
            } else {
                setCheckAll(false)
                setIndeterminate(false)
            }
        }
    };
    const onCheckAllChange = (e) => {
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        bodyList.map(item => {
            if (e.target.checked) {
                item.checked = true
                setCheckList([...bodyList])
            } else {
                item.checked = false
                setCheckList([])
            }
        })
    };

    const returnFc = () => {
        return
    }



    return (
        <div className={classes.checkBoxTable} style={{ overflowX: allWidth ? 'scroll' : '' }}>
            <div style={{ width: allWidth ? allWidth : '100%' }}>
                <div className={classes.titleBlock}>
                    <div>
                        <Checkbox style={{ backgroundColor: '#FFF' }} key={checkAll} indeterminate={indeterminate} checked={checkAll} onChange={onCheckAllChange} className={classes.checkBox}
                            sx={{
                                color: "#DDDDDD",
                                "& .MuiSvgIcon-root": {
                                    fontSize: VW(21)
                                },
                                '&:hover': {
                                    backgroundColor: 'transparent !important'
                                },
                            }}
                        />
                    </div>
                    {titleList.map((item, index) => {
                        return (
                            <div key={index} style={{ width: item.width, whiteSpace: 'pre-line', display: 'flex', justifyContent: item.center }}>
                                <div onClick={() => { item.titleIcon === '' ? returnFc() : toSort(item) }}>
                                    {item.name === 'propertyTitleIcon' ? <MenuHeader_property color={'#333'} /> : ''}
                                    {item.name === 'propertyTitleIcon' && <div style={{ marginLeft: VW(2), marginRight: VW(2), fontSize: VW(13), fontWeight: 'bold' }}>{item.name === 'propertyTitleIcon' ? '/' : ''}</div>}
                                    {item.name === 'propertyTitleIcon' ? <Checkbox_Table_Block color={'#333'} /> : ''}
                                    {(item.name === 'propertyTitleIcon' || item.name === 'downloadIcon') ? '' : item.name}
                                    {item.name === 'downloadIcon' ? <PropertyDetail_file_download /> : ''}
                                    {/* <span>
                                    {item.titleIcon === '↓' ? <Checkbox_Table_Down color={item.checked ? '#0052CC' : ''}/> : item.titleIcon === '↑' ? <Checkbox_Table_Up color={item.checked ? '#0052CC' : ''}/> : ''}
                                </span> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {bodyList.map((item, index) => {
                    return (
                        <div className={classes.bodyBlock} key={index} style={{ height: item.file_display_flg == 'none' ? 0 : '', borderBottom: item.file_display_flg == 'none' ? 0 : '' }}>
                            <div>
                                <Checkbox
                                    className={classes.checkBox}
                                    sx={{
                                        color: "#DDDDDD",
                                        "& .MuiSvgIcon-root": {
                                            fontSize: VW(21)
                                        },
                                        '&:hover': {
                                            backgroundColor: 'transparent !important'
                                        },
                                    }}
                                    key={item.checked}
                                    checked={item.checked}
                                    onChange={() => { changeBodyCheckd(item) }}

                                />
                            </div>
                            {titleList.map((titleItem, titleIndex) => {
                                return (
                                    <div
                                        key={titleIndex}
                                        style={{
                                            width: titleItem.width,
                                            color: (titleItem.field == 'status_name' && item[titleItem.field] == '完了') ? '#AAA' :
                                                ((titleItem.field == 'seko_gaisya_flag' && item[titleItem.field] == '✓') || (titleItem.field == 'seti_jyokyo_flag' && item[titleItem.field] == '✓') || (titleItem.field == 'buzai_flag' && item[titleItem.field] == '✓')) ? 'blue' :
                                                    titleItem.canClick ? '#0052CC' : '',
                                            cursor: titleItem.canClick ? 'pointer' : '',
                                            paddingLeft: titleItem.paddingLeft ? titleItem.paddingLeft : '',
                                            paddingRight: titleItem.center == 'center' ? 0 : VW(16),
                                            justifyContent: titleItem.center,
                                        }}
                                        onClick={() => { { titleItem.canClick ? getItemData(item) : returnFc() } }}
                                    >
                                        {(titleItem.field !== 'tag_color' && titleItem.field !== 'color_type' && titleItem.field !== 'priority_name')
                                            ?
                                            titleItem.field == 'is_active' && item[titleItem.field] == 0 ? <div style={{ display: (authority_id == 1 || authority_id == 3) && item.authority_id == '5' ? 'none' : 'flex', cursor: 'pointer' }} onClick={() => { changeUseState(item) }}> <Member_tab_true />利用</div> :
                                                titleItem.field == 'is_active' && item[titleItem.field] == 1 ? <div style={{ display: (authority_id == 1 || authority_id == 3) && item.authority_id == '5' ? 'none' : 'flex', cursor: 'pointer' }} onClick={() => { changeUseState(item) }}> <Member_tab_false />停止</div> :
                                                    (titleItem.field === 'buken_shubetsu' && item[titleItem.field] === 0) ? <MenuHeader_property /> :
                                                        (titleItem.field === 'buken_shubetsu' && item[titleItem.field] === 1) ? <Checkbox_Table_Block /> :
                                                            item[titleItem.field] === 'correct' ? <CorrectIcon /> :
                                                                item[titleItem.field] === 'wrong' ? <WrongIcon /> :
                                                                    item['file_type_name'] == 'file_folder' && titleItem.field == 'file_name' && item['openFlag'] == 'open' ?
                                                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', cursor: 'default' }}>
                                                                            <div style={{ cursor: 'pointer' }} onClick={() => { { titleItem.canClick ? getItemDataAgain(item) : returnFc() } }}>
                                                                                <PropertyDetail_file_folder_open_arrow />
                                                                            </div>
                                                                            <div style={{ cursor: 'pointer' }} onClick={() => { { titleItem.canClick ? getItemDataAgain(item) : returnFc() } }}>
                                                                                <PropertyDetail_file_folder_open />
                                                                            </div>
                                                                            <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', userSelect: 'none' }}>{item[titleItem.field]}</p>
                                                                            {item.tenant_id == localStorage.getItem('tenant_id') &&
                                                                                <div onClick={() => { changeFolderName(item) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: VW(5) }}>
                                                                                    <EditIcon />
                                                                                </div>
                                                                            }
                                                                        </div> :
                                                                        item['file_type_name'] == 'file_folder' && titleItem.field == 'file_name' && item['openFlag'] == 'close' ?
                                                                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', cursor: 'default' }}>
                                                                                <div style={{ cursor: 'pointer' }} onClick={() => { { titleItem.canClick ? getItemDataAgain(item) : returnFc() } }}>
                                                                                    <PropertyDetail_file_folder_close_arrow />
                                                                                </div>
                                                                                <div style={{ cursor: 'pointer' }} onClick={() => { { titleItem.canClick ? getItemDataAgain(item) : returnFc() } }}>
                                                                                    <PropertyDetail_file_folder_close />
                                                                                </div>
                                                                                <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', userSelect: 'none' }}>{item[titleItem.field]}</p>
                                                                                {item.tenant_id == localStorage.getItem('tenant_id') &&
                                                                                    <div onClick={() => { changeFolderName(item) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: VW(5) }}>
                                                                                        <EditIcon />
                                                                                    </div>
                                                                                }
                                                                            </div> :
                                                                            (item['file_type_name'] == 'file_file' || item['file_type_name'] == 'file_in_folder') && titleItem.field == 'file_name' ? <p onClick={() => { downlaodFile(item, item.file_type) }} ><PropertyDetail_file_file leftMargin={item['file_type_name'] == 'file_in_folder' ? '70px' : ''} />{item[titleItem.field]}</p> :
                                                                                item[titleItem.field] === 'downloadIcon' ? <div onClick={() => { downlaodFile(item) }} style={{ cursor: 'pointer' }}><PropertyDetail_file_download /></div> :
                                                                                    item[titleItem.field] === 'uploadIcon' ? <div style={{ cursor: 'pointer' }} onClick={() => { uploadToFolder(item) }}><PropertyDetail_file_upload /></div> :
                                                                                        titleItem.field == 'sendMessage_button' ? <Button onClick={() => { sendMessageAgain(item.email) }} className={classes.sendMessangeButton} style={{ display: item[titleItem.field] ? '' : 'none' }}>再送信</Button> :
                                                                                            <p style={{ userSelect: 'none' }}>{item[titleItem.field]}</p>
                                            :
                                            titleItem.field == 'tag_color' ?
                                                item[titleItem.field].length > 0
                                                    ?
                                                    <Popover content={<div style={{ fontSize: VW(16) }}>{item.tag_name}</div>} title="">
                                                        <div style={{ width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', paddingLeft: VW(9.6), paddingTop: VH(7), cursor: 'pointer' }} onClick={() => { selectColor(item) }} >
                                                            {item[titleItem.field].map((tagItem, tagIndex) => {
                                                                return (
                                                                    <div
                                                                        key={tagIndex}
                                                                        style={{
                                                                            width: VW(28),
                                                                            height: VH(6),
                                                                            backgroundColor:
                                                                                tagItem === '1' ? '#FFB872' :
                                                                                    tagItem === '2' ? '#BB99FE' :
                                                                                        tagItem === '3' ? '#80BFFF' :
                                                                                            tagItem === '4' ? '#BCFA38' :
                                                                                                tagItem === '5' ? '#F9CFF9' :
                                                                                                    tagItem === '6' ? '#ADEAFF' :
                                                                                                        tagItem === '7' ? '#85F8B3' :
                                                                                                            '#FF8CCF',
                                                                            borderRadius: '4px',
                                                                            marginRight: (tagIndex % 2 == 0) ? VW(8) : 0,
                                                                            marginTop: VH(2)
                                                                        }}>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </Popover>
                                                    :
                                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { selectColor(item) }}>
                                                        <div style={{ cursor: 'pointer' }}>
                                                            <AddIcon color='#AAA' />
                                                        </div>
                                                    </div>
                                                :
                                                titleItem.field == 'priority_name' ?
                                                    <div>
                                                        <span style={{
                                                            display: (item[titleItem.field] != null) ? '' : 'none',
                                                            color: getPriorityColor(item[titleItem.field])
                                                        }}>●</span>
                                                        &nbsp;{item[titleItem.field]}
                                                    </div>
                                                    :
                                                    <div
                                                        style={{
                                                            width: VW(160),
                                                            height: VH(30),
                                                            borderRadius: '4px',
                                                            backgroundColor:
                                                                item[titleItem.field] === 1 ? '#FFB872' :
                                                                    item[titleItem.field] === 2 ? '#BB99FE' :
                                                                        item[titleItem.field] === 3 ? '#80BFFF' :
                                                                            item[titleItem.field] === 4 ? '#BCFA38' :
                                                                                item[titleItem.field] === 5 ? '#F9CFF9' :
                                                                                    item[titleItem.field] === 6 ? '#ADEAFF' :
                                                                                        item[titleItem.field] === 7 ? '#85F8B3' :
                                                                                            '#FF8CCF',
                                                        }}></div>
                                        }

                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

Common_checkBoxTable.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Common_checkBoxTable);