import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_file_folder_close_arrow() {

    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ float: 'left', marginRight: VW(8) }}>
            <path d="M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z" fill="#5B5B5B" />
        </svg>
    )
}
export default PropertyDetail_file_folder_close_arrow;


