import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_reminder(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(18)} height={VH(18)} viewBox="0 0 14 17" fill="none" >
            <path
                d="M2.16667 17.3333C1.70833 17.3333 1.31597 17.1701 0.989583 16.8438C0.663194 16.5174 0.5 16.125 0.5 15.6667V4C0.5 3.54167 0.663194 3.14931 0.989583 2.82292C1.31597 2.49653 1.70833 2.33333 2.16667 2.33333H3V0.666668H4.66667V2.33333H11.3333V0.666668H13V2.33333H13.8333C14.2917 2.33333 14.684 2.49653 15.0104 2.82292C15.3368 3.14931 15.5 3.54167 15.5 4V15.6667C15.5 16.125 15.3368 16.5174 15.0104 16.8438C14.684 17.1701 14.2917 17.3333 13.8333 17.3333H2.16667ZM2.16667 15.6667H13.8333V7.33333H2.16667V15.6667Z"
                fill={color ? color : '#2F83FF'}
            />
        </svg>
    )
}

export default MenuHeader_reminder;