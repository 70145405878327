import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import React from "react";
import { VH, VW } from "../../componets/function/sizeAdapter";
import { ApiTenantReinvite } from '../../network/api';
import log from "../function/log";
const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
        '& > div': {
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',

        },
        '& > div > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(1) > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
        '& > div:nth-child(2) > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`
        },
        '& > div:last-child > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
    },
    administratorBlock: {
        '& > div:nth-child(2)': {
            width: VW(168),
        },
        '& > div:nth-child(3)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            overflow: 'hidden'
        },
        '& > div:nth-child(4)': {
            width: VW(336),
            overflow: 'hidden'
        },
        '& > div:nth-child(5)': {
            right: 0,
            fontSize: VW(16)
        },
        '& > div:nth-child(6)': {
            justifyContent: 'center',
            marginLeft: VW(16),
            padding: '0',
            '& > Button': {
                display: 'flex',
                right: 0,
                alignItems: 'center',
                justifyContent: 'center',
                width: VW(64),
                height: VH(32),
                backgroundColor: theme.palette.primary.main,
                fontSize: VW(14),
                fontWeight: 'bold'
            }
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
});

function RealEstateDetail_item(props) {
    const {
        classes,
        falseData,
        setRrrorOpen,
        setErrorContent,
        setSuccessOpen,
        setSuccessContent
    } = props


    // send email api
    const sengEmailAgain = (item) => {
        log(item)
        let param = {
            email: item
        }
        ApiTenantReinvite(param).then(res => {
            if (res.status === 200) {
                log(res.data)
                setRrrorOpen(false)
                setSuccessOpen(true)
                setSuccessContent(res.data.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    return (
        <div className={classes.contentBlock}>
            <div>
                <div>管理会社CD</div>
                <div>{falseData.tenant_id}</div>
            </div>
            <div>
                <div>会社名</div>
                <div>{falseData.tenant_name}</div>
            </div>
            {falseData.customer_master && falseData.customer_master.map((item, index) => {
                return (
                    <div key={index}>
                        <div>得意先CD{index + 1}</div>
                        <div>{item.code} ({item.name})</div>
                    </div>
                )
            })}
            {/* <div>
                <div>カルテ契約開始日</div>
                <div>{falseData.karte_contract_date_start}</div>
            </div> */}
            <div>
                <div>カルテ契約終了日</div>
                <div>{falseData.karte_contract_date_end}</div>
            </div>
            {falseData.user_master && falseData.user_master.map((item, index) => {
                return (
                    <div key={index} className={classes.administratorBlock}>
                        <div>管理者</div>
                        <div>
                            <p>{item.surname + item.name}</p>
                        </div>
                        <div>メールアドレス</div>
                        <div>
                            <p>{item.email}</p>
                        </div>
                        <div style={{ color: item.status_id === '1' ? '#0052CC' : '#AAAAAA' }}>
                            {item.status_id === '1' ? '招待中' : '完了'}
                        </div>
                        {item.status_id === '1' &&
                            <div>
                                <Button type="primary" onClick={() => { sengEmailAgain(item.email) }}>再送信</Button>
                            </div>
                        }
                    </div>
                )
            })}
            <div>
                <div>登録日</div>
                <div>{falseData.create_date}</div>
            </div>
        </div>
    )
}

RealEstateDetail_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(RealEstateDetail_item);