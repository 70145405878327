import { CaretDownOutlined } from '@ant-design/icons';
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, DatePicker, Input, Modal, Radio, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { VH, VW } from "../../componets/function/sizeAdapter";
import { ApiClaimrecordAdd } from '../../network/api';
import Common_errorAlert from "../common/Common_errorAlert";
import log from "../function/log";
import AddIcon from "../icon/AddIcon";


dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        height: VH(74),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(56)
        },
        '& > div:nth-child(odd)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(even)': {
            width: '26%'
        },
        '& > div > .ant-select-single .ant-select-selector': {
            width: '100%'
        }
    },
    groundTwo: {
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        width: '100%',
        height: VH(74),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(56),
        },
        '& > div:nth-child(1)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '76%'
        },
        '& > div > Input': {
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
    },
    customerBlock: {
        '& > div': {
            height: VH(124),
        },
        '& > div:nth-child(1)': {
            width: VW(146)
        },
        '& > div:nth-child(2)': {
            width: VW(622),
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                '& > Input': {
                    height: VH(50),
                    fontSize: VW(16),
                    borderRadius: "4px"
                },
            }
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_claim_add(props) {
    const {
        classes,
        open,
        onClose,
        state,
        getList,
        priorityValueOption
    } = props


    useEffect(() => {
        if (!open) {
            setAccrual_date('')
            setManager('')
            setPic('')
            setCustomer_type(0)
            setCustomer_name('')
            setSubject('')
            setClaim_content('')
            setCorrespondence_content('')
            setCompletion_date('')
            setRemarks('')
            setStatus(undefined)
            setPriority(undefined)
            setCompatible_date('')
        }
    }, [open])



    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 発生日
    const [accrual_date, setAccrual_date] = useState('')
    const changeAccrual_date = (value, dateString) => {
        setAccrual_date(value)
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 受付者
    const [pic, setPic] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 対応者
    const [manager, setManager] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> お客様 type
    const [customer_type, setCustomer_type] = useState(0)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> お客様 name
    const [customer_name, setCustomer_name] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 件名
    const [subject, setSubject] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> クレーム内容
    const [claim_content, setClaim_content] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 対応内容
    const [correspondence_content, setCorrespondence_content] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 対応完了日
    const [completion_date, setCompletion_date] = useState('')
    const changeCompletion_date = (value, dateString) => {
        setCompletion_date(value)
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 備考
    const [remarks, setRemarks] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ステータス
    const [statusValueOption, setStatusValueOption] = useState([
        { value: '0', label: '未対応' },
        { value: '1', label: '対応中' },
        { value: '2', label: '対応済' }
    ])
    const [status, setStatus] = useState(undefined)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 対応期日
    const [compatible_date, setCompatible_date] = useState('')
    const changeCompatible_date = (value, dateString) => {
        setCompatible_date(value)
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  優先度 select
    const [priority, setPriority] = useState(undefined)


    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    // add api
    const addItem = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        if (subject == '') {
            setErrorContent('件名を記入してください')
            setRrrorOpen(true)
        } else {
            let params = {
                buken_id: state.buken_id,
                accrual_date: accrual_date ? dayjs(accrual_date).format('YYYY/MM/DD') : '',
                pic: pic,
                manager: manager,
                customer_type: customer_type,
                customer_name: customer_type == 1 ? customer_name : state.owner_name,
                subject: subject,
                claim_content: claim_content,
                correspondence_content: correspondence_content,
                completion_date: completion_date ? dayjs(completion_date).format('YYYY/MM/DD') : '',
                remarks: remarks,
                status: (status == undefined || status == null) ? -1 : Number(status),
                priority: (priority == undefined || priority == null) ? -1 : Number(priority),
                compatible_date: compatible_date ? dayjs(compatible_date).format('YYYY/MM/DD') : ''
            }
            log(params)
            ApiClaimrecordAdd(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    getList('add')
                    onClose()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>クレーム記録の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.groundFour} style={{ marginTop: VH(13) }}>
                    <div>発生日</div>
                    <div>
                        <DatePicker
                            style={{ width: '19.4vw', height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={accrual_date}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeAccrual_date}
                        />
                    </div>
                    <div>優先度</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={priority}
                            onChange={(event) => setPriority(event)}
                            allowClear
                            onClear={() => { setPriority(undefined) }}
                        >
                            {priorityValueOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>
                                        <span style={{ color: item.color }} >●</span>&nbsp;{item.label}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className={classes.groundFour}>
                    <div>対応期日</div>
                    <div>
                        <DatePicker
                            style={{ width: '19.4vw', height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={compatible_date}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeCompatible_date}
                        />
                    </div>
                    <div>ステータス</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='選択してください'
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                            value={status}
                            onChange={(event) => setStatus(event)}
                            allowClear
                            onClear={() => { setStatus(undefined) }}
                        >
                            {statusValueOption.map((item, index) => {
                                return (
                                    <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className={classes.groundTwo} >
                    <div>受付者</div>
                    <div>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="受付者"
                            value={pic}
                            onChange={(event) => setPic(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo} >
                    <div>対応者</div>
                    <div>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="対応者"
                            value={manager}
                            onChange={(event) => setManager(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div style={{ height: VH(124) }} className={classes.groundTwo} >
                    <div>お客様</div>
                    <div className={classes.customerBlock}>
                        <div>
                            <Radio.Group onChange={(e) => { setCustomer_type(e.target.value) }} value={customer_type}>
                                <Radio value={0} style={{ fontSize: VW(16), marginTop: VH(25) }} defaultChecked>オーナー</Radio>
                                <Radio value={1} style={{ fontSize: VW(16), marginTop: VH(26) }}>オーナー以外</Radio>
                            </Radio.Group>
                        </div>
                        <div>
                            <div style={{ fontSize: VW(16), marginTop: VH(25) }}>{state.owner_name}</div>
                            <div style={{ fontSize: VW(16), marginTop: VH(13) }}>
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="お客様名を記入してください"
                                    value={customer_name}
                                    onChange={(event) => setCustomer_name(event.target.value)}
                                    maxLength={60}
                                    disabled={customer_type == 0}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.groundTwo} >
                    <div>件名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                    <div>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="件名を記入してください"
                            value={subject}
                            onChange={(event) => setSubject(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div style={{ height: VH(304) }} className={classes.groundTwo} >
                    <div>クレーム内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                    <div>
                        <TextArea
                            style={{ height: VH(280) }}
                            placeholder="クレーム内容を記入してください"
                            value={claim_content}
                            onChange={(event) => setClaim_content(event.target.value)}
                            maxLength={500}
                        />
                    </div>
                </div>
                <div style={{ height: VH(304) }} className={classes.groundTwo} >
                    <div>対応内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                    <div>
                        <TextArea
                            style={{ height: VH(280) }}
                            placeholder="対応内容を記入してください"
                            value={correspondence_content}
                            onChange={(event) => setCorrespondence_content(event.target.value)}
                            maxLength={500}
                        />
                    </div>
                </div>
                <div className={classes.groundTwo} >
                    <div>対応完了日</div>
                    <div>
                        <DatePicker
                            style={{ width: VW(272), height: VH(50), fontSize: VW(16) }}
                            placeholder="YYYY/MM/DD"
                            value={completion_date}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeCompletion_date}
                        />
                    </div>
                </div>
                <div style={{ height: VH(304), borderBottom: '1px solid #DDD' }} className={classes.groundTwo} >
                    <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                    <div>
                        <TextArea
                            style={{ height: VH(280) }}
                            placeholder="備考を記入してください"
                            value={remarks}
                            onChange={(event) => setRemarks(event.target.value)}
                            maxLength={500}
                        />
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={addItem}>登録</Button>
                </div>
            </div>
        </Modal>
    )
}

PropertyDetail_claim_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_claim_add);