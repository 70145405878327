import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_tag(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(20)} height={VH(20)} viewBox="0 0 20 20" fill="none" >
            <path d="M17.8417 9.65L10.3417 2.15C10.0417 1.85 9.625 1.66666 9.16667 1.66666H3.33333C2.41667 1.66666 1.66667 2.41666 1.66667 3.33333V9.16666C1.66667 9.625 1.85 10.0417 2.15833 10.35L9.65834 17.85C9.95833 18.15 10.375 18.3333 10.8333 18.3333C11.2917 18.3333 11.7083 18.15 12.0083 17.8417L17.8417 12.0083C18.15 11.7083 18.3333 11.2917 18.3333 10.8333C18.3333 10.375 18.1417 9.95 17.8417 9.65ZM4.58333 5.83333C3.89167 5.83333 3.33333 5.275 3.33333 4.58333C3.33333 3.89166 3.89167 3.33333 4.58333 3.33333C5.275 3.33333 5.83333 3.89166 5.83333 4.58333C5.83333 5.275 5.275 5.83333 4.58333 5.83333Z" fill={color ? color : '#2F83FF'} />
        </svg>
    )
}

export default MenuHeader_tag;