import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import MenuHeader_property from "../../componets/icon/MenuHeader_property";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos, Tune } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import { useNavigate } from 'react-router-dom'
import CreateIcon from '@mui/icons-material/Create';
import { Button, Select, Input, DatePicker } from 'antd';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropertyDetail_calendar_copy from "../../componets/icon/PropertyDetail_calendar_copy";
import PropertyDetail_calendar_add from "../../componets/icon/PropertyDetail_calendar_add";
import PropertyDetail_calendar_first from "../../componets/icon/PropertyDetail_calendar_first";
import PropertyDetail_calendar_second from "../../componets/icon/PropertyDetail_calendar_second";
import PropertyDetail_calendar_third from "../../componets/icon/PropertyDetail_calendar_third";
import PropertyDetail_calendar_front from "../../componets/icon/PropertyDetail_calendar_front";
import PropertyDetail_calendar_after from "../../componets/icon/PropertyDetail_calendar_after";
import { ApiMaintenanceList, ApiMaintenanceCopy, ApiMaintenanceUpdate } from '../../network/api'
import PropertyDetail_calendar_add_confirm from "../../componets/dialog/PropertyDetail_calendar_add_confirm";
import PropertyDetail_calendar_delete from "../../componets/icon/PropertyDetail_calendar_delete";
import PropertyDetail_calendar_date from "../../componets/icon/PropertyDetail_calendar_date";
import locale from "antd/es/date-picker/locale/ja_JP";
import moment from 'moment'
import Month_Days_dialog from "../../componets/common/Month_Days_dialog";

dayjs.extend(utc)
const { Option } = Select;


const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingBottom: VW(24),
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
        '& .anticon-calendar': {
            display: 'none'
        },
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    objectNameBlock: {
        marginLeft: VW(24),
        marginTop: VH(12),
        minHeight: VH(24),
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
        wordBreak: 'break-all',
        '& > div:nth-child(1)': {
            fontSize: VW(14),
        },
        '& > div:nth-child(2)': {
            fontSize: VW(24),
            fontWeight: 'bold',
            marginLeft: VW(16)
        }
    },
    titleMenu: {
        width: 'auto',
        display: 'flex',
        marginTop: VH(16),
        height: VH(40),
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    menuContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: VW(16)
    },
    fatherBlock: {
        '& > div': {
            width: '100%',
        }
    },
    titleBlock: {
        height: VH(56),
        borderTop: '1px solid #DDD',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '& > div:nth-child(1)': {
            '& > div:nth-child(1)': {
                marginRight: VW(12)
            },
            '& > div:nth-child(2)': {
                fontSize: VW(18),
                fontWeight: 'bold',
                marginRight: VW(12)
            },
            '& > div:nth-child(3)': {
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }
        },
        '& > div:nth-child(2)': {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    },
    yearTitle: {
        height: VH(40),
        borderTop: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        display: 'flex',
        backgroundColor: '#E0ECFF',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: VW(8)
        },
        '& > div:nth-child(1)': {
            flex: 1,
            fontSize: VW(16),
            fontWeight: 'bold',
            '& > div:nth-child(2)': {
                marginLeft: VW(16),
                marginRight: VW(16)
            }
        },
        '& > div:not(:nth-child(1)):not(:last-child)': {
            width: VW(65),
            fontSize: VW(14),
            fontWeight: 'bold'
        },
        '& > div:last-child': {
            width: VW(65),
            fontSize: VW(14),
        }
    },
    contentBlock: {
        display: "flex",
        minHeight: VH(48),
        '& > div:nth-child(1)': {
            flex: 1,
            paddingTop: VH(12),
            minHeight: VH(48),
            borderRight: '1px solid #DDD',
            '& > div': {
                width: "100%",
                height: '100%',
                backgroundColor: '#F8F8F8',
                fontSize: VW(12),
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: VW(8),
                whiteSpace: 'pre-line',
                alignItems: 'center'
            }
        },
        '& > div:nth-child(3)': {
            width: VW(65),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: VW(14),
            backgroundColor: '#EEE',
            marginTop: VH(12),
            marginBottom: VH(4),
            marginLeft: VW(1)
        },
    },
    moonBlock: {
        borderRight: 'bold',
        borderRight: '1px solid #DDD',
        display: 'flex',
        minHeight: VH(48),
        '& > div': {
            width: VW(65),
            minHeight: VH(48),
            justifyContent: 'center',
            alignItems: 'flex-end',
            '& > div': {
                width: VW(48),
                height: '40px',
                marginTop: VH(12),
                marginBottom: VH(4),
                marginLeft: VW(8.5),
            }
        }
    },
    deleteIcon: {
        width: '14px',
        height: '14px',
        cursor: 'pointer',
        borderRadius: '50%',
        position: 'absolute',
        right: '-2px',
        top: '-4px',
        zIndex: '999'
    }
});

function PropertyDetail_calendar(props) {
    const {
        classes,
        setSelectFlag
    } = props

    const location = useLocation();
    const { state } = location;


    useEffect(() => {
        setSelectFlag(routerNames.Property)
        getList()
    }, [setSelectFlag])




    const navigate = useNavigate()


    const authority_id = localStorage.getItem("authority_id")

    // body list
    const [bodyList, setBodyList] = useState([])
    // change display flag
    const changeDisplayFlag = (index) => {
        bodyList[index].displayFlag = !bodyList[index].displayFlag
        setBodyList([...bodyList])
    }

    // result raary
    const [resultArray, setResultArray] = useState([])
    // get list api
    const getList = (index, flag) => {
        let newResult = []
        if (flag && flag != 'update') {
            bodyList.map((ll, llIndx) => {
                if (flag == 'front') {
                    newResult.push({ year: String(Number(ll.year) - 1), parent_category_id: ll.parent_category_id },)
                } else {
                    newResult.push({ year: String(Number(ll.year) + 1), parent_category_id: ll.parent_category_id },)
                }
            })
            setResultArray([...newResult])
        }
        let params = {
            buken_id: state.buken_id,
            result: !flag ? [] : flag == 'update' ? resultArray : newResult
        }
        log(params)
        ApiMaintenanceList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                log(resData)
                if (bodyList.length > 0) {
                    resData.data[0].displayFlag = bodyList[0].displayFlag
                    resData.data[1].displayFlag = bodyList[1].displayFlag
                    resData.data[2].displayFlag = bodyList[2].displayFlag
                    resData.data.map(item => {
                        item.updateFlag = false
                        item.yearButtonFlag = false
                        item.updateButtonFlag = false
                    })
                } else {
                    resData.data.map(item => {
                        item.displayFlag = true
                        item.updateFlag = false
                        item.yearButtonFlag = false
                        item.updateButtonFlag = false
                    })
                }
                setRrrorOpen(false)
                setBodyList([...resData.data])
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    // const authority_id = "5";
    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // title menu content
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list
    const [titleMenuList, setTitleMenuList] = useState(routerTitleList)
    function routerTitleList() {
        var items = []
        if (state.buken_shubetsu === 0) {
            items = [
                { name: '物件情報', width: 130, checked: false },
                { name: '部屋情報', width: 120, checked: false },
                { name: 'メンテナンス', width: 130, checked: true },
                { name: '設備記録', width: 120, checked: false },
                { name: '営業記録', width: 120, checked: false },
                { name: 'クレーム記録', width: 130, checked: false },
                { name: '修繕記録', width: 120, checked: false },
                { name: 'ファイル', width: 122, checked: false }
            ]
        } else {
            items = [
                { name: 'コンテナマスタ', width: 142, checked: false },
                { name: 'メンテナンス', width: 142, checked: true },
                { name: '設備記録', width: 142, checked: false },
                { name: '営業記録', width: 142, checked: false },
                { name: 'クレーム記録', width: 142, checked: false },
                { name: '修繕記録', width: 142, checked: false },
                { name: 'ファイル', width: 142, checked: false }
            ]
        }

        return items
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list click
    const selectTitleItem = (content) => {
        log(content)
        if (content.name == "物件情報" || content.name == "コンテナマスタ") {
            navigate(routerNames.PropertyDetail, { state: state })
        } else if (content.name == "部屋情報") {
            navigate(routerNames.PropertyDetail_equipment_list, { state: state })
        } else if (content.name == "営業記録") {
            navigate(routerNames.PropertyDetail_business, { state: state })
        } else if (content.name == "クレーム記録") {
            navigate(routerNames.PropertyDetail_claim, { state: state })
        } else if (content.name == "修繕記録") {
            navigate(routerNames.PropertyDetail_repair, { state: state })
        } else if (content.name == "設備記録") {
            navigate(routerNames.PropertyDetail_equipment_records, { state: state })
        } else if (content.name == "ファイル") {
            navigate(routerNames.PropertyDetail_file, { state: state })
        }
    }


    // add
    const changeColor = (index, indexS, indexT, flag) => {
        if (flag == 'add') {
            bodyList[index].result_data[indexS].result_data[indexT].num.push({ target_date: '' })
        } else {
            bodyList[index].result_data[indexS].result_data[indexT].num.splice(flag, 1)
        }
        let maxNum = 0
        bodyList[index].result_data[indexS].result_data.map(item => {
            if (item.num.length > maxNum) {
                maxNum = item.num.length
            }
        })
        log(maxNum)
        if (maxNum == 5) {
            bodyList[index].result_data[indexS].result_data.map(item => {
                if (item.num.length == 5) {
                    item.remainder = 0
                } else {
                    item.remainder = 4 - item.num.length
                }
            })
            log(bodyList)
            setBodyList([...bodyList])
        } else {
            bodyList[index].result_data[indexS].result_data.map(item => {
                item.remainder = maxNum - item.num.length
            })
            log(bodyList)
            setBodyList([...bodyList])
        }
    }


    // copy
    const copyYear = (index) => {
        let param = {}
        param.buken_id = bodyList[index].buken_id
        param.year = bodyList[index].year
        param.category_id = bodyList[index].parent_category_id
        ApiMaintenanceCopy(param).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                getList(1, 'update')
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    // 回数  update  flag
    const updateFrequency = (index) => {
        if (bodyList[index].updateFlag) {
            //  update => ok
            bodyList.map(ff => {
                ff.result_data.map(ss => {
                    if (ss.total_num == '') {
                        ss.total_num = 0
                    }
                })
            })
            setBodyList([...bodyList])
            ApiMaintenanceUpdate(bodyList[index]).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    getList(1, 'update')
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setSuccessOpen(false)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        } else {
            bodyList.map(item => {
                item.yearButtonFlag = true
                item.updateButtonFlag = true
            })
            bodyList[index].updateButtonFlag = false
            bodyList[index].updateFlag = !bodyList[index].updateFlag
            log(bodyList)
            bodyList.map(iA => {
                iA.result_data.map(iB => {
                    if (iB.large_num == 5) {
                        iB.result_data.map(iC => {
                            if (iC.num == 5) {
                                iC.remainder = 0
                            } else {
                                iC.remainder = 4 - iC.num
                            }
                        })
                    }
                })
            })
            setBodyList([...bodyList])
        }
    }

    // 回数  change
    const changeFrequency = (item, index, indexS) => {
        bodyList[index].result_data[indexS].total_num = item
        setBodyList([...bodyList])
    }



    //  confirm dialog
    const [copyFlag, setCopyFlag] = useState(false)
    const [copyIndex, setCopyIndex] = useState(0)
    const [titleName, setTitleName] = useState('')
    const [confirmOpen, setConfirmOpen] = useState(false)
    const confirmOpenClose = () => {
        setConfirmOpen(false)
    }
    const openConfirmDialog = (name, index) => {
        setCopyIndex(index)
        setTitleName(name)
        setConfirmOpen(true)
    }

    // momth days dialog
    const [daysOpen, setDaysOpen] = useState(false)
    const [daysArray, setDaysArray] = useState([])
    const [dayYear, setDayYear] = useState('')
    const [dayMonth, setDayMonth] = useState('')
    const daysOnClose = () => {
        setDaysArray([])
        setDaysOpen(false)
    }
    const [indexA, setIndexA] = useState(0)
    const [indexB, setIndexB] = useState(0)
    const [indexC, setIndexC] = useState(0)
    const [indexD, setIndexD] = useState(0)
    const openDaysDialog = (year, month, bindingDate, A, B, C, D) => {
        setIndexA(A)
        setIndexB(B)
        setIndexC(C)
        setIndexD(D)
        setDayYear(year)
        setDayMonth(month)
        Array.from({ length: getNum(year, month) }).map((item, index) => {
            daysArray.push({ name: index + 1, checked: false })
        })
        if (bindingDate) {
            daysArray[getCaption(bindingDate) - 1].checked = true
        }
        setDaysArray([...daysArray])
        setDaysOpen(true)
    }
    const changeDay = (index) => {
        daysArray.map(item => {
            item.checked = false
        })
        daysArray[index].checked = true
        setDaysArray([...daysArray])
        bodyList[indexA].result_data[indexB].result_data[indexC].num[indexD].target_date = String(dayMonth) + '/' + String(index + 1)
        setBodyList([...bodyList])
        daysOnClose()
    }


    // date and string dispose
    const getNum = (year, month) => {
        var temp;
        month = parseInt(month, 10);
        temp = new Date(year, month, 0);
        return temp.getDate();
    };
    const getCaption = (str) => {
        var index = str.lastIndexOf("/")
        str = str.substring(index + 1, str.length)
        return str
    }



    useEffect(() => {
        if (copyFlag) {
            confirmOpenClose()
            setCopyFlag(false)
            copyYear(copyIndex)
        }
    }, [copyFlag])


    const back = () => {
        return
    }





    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { navigate(routerNames.Property) }}>物件一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>物件詳細</div>
            <div className={classes.tableTitle}>
                <MenuHeader_property />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件詳細</div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            <div className={classes.objectNameBlock}>
                <div>物件名</div>
                <div>{state.genba_name}</div>
            </div>
            <div className={classes.titleMenu}>
                {titleMenuList.map((item, index) => {
                    return (
                        <div
                            className={classes.menuContent}
                            key={index}
                            style={{ width: VW(item.width), backgroundColor: item.checked ? '#E0ECFF' : '', fontWeight: item.checked ? 'bold' : '' }}
                            onClick={() => { selectTitleItem(item) }}
                        >{item.name}</div>
                    )
                })}
            </div>
            {bodyList.length > 0 && bodyList.map((item, index) => {
                return (
                    <div className={classes.fatherBlock} key={index} style={{ marginTop: index == 0 ? VH(16) : '', userSelect: 'none' }}>
                        <div className={classes.titleBlock}>
                            <div onClick={() => { changeDisplayFlag(index) }}>
                                {item.parent_category_name == '清掃' && <div><PropertyDetail_calendar_first /></div>}
                                {item.parent_category_name == '点検' && <div><PropertyDetail_calendar_second /></div>}
                                {item.parent_category_name == '各種支払い' && <div><PropertyDetail_calendar_third /></div>}
                                <div>{item.parent_category_name}</div>
                                <div>{item.displayFlag ? <ArrowDropUpIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} /> : <ArrowDropDownIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} />}</div>
                            </div>
                            <div>
                                {item.copy_flag == 1 && (authority_id == 3 || authority_id == 4) &&
                                    <Button
                                        style={{ padding: 0, fontSize: VW(16), marginRight: VW(24) }}
                                        type="text"
                                        icon={<PropertyDetail_calendar_copy />}
                                        disabled={item.yearButtonFlag}
                                        onClick={() => { openConfirmDialog(item.parent_category_name, index) }}
                                    ><div style={{ marginLeft: VW(8) }}>前年分をコピーする</div></Button>
                                }
                                {(authority_id == 3 || authority_id == 4) && item.updateFlag &&
                                    <Button
                                        style={{ padding: 0, fontSize: VW(16), color: '#0052CC', marginRight: VW(24) }}
                                        type="text"
                                        icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                                        disabled={item.updateButtonFlag}
                                        onClick={() => { getList(1, 'update') }}
                                    >編集キャンセル</Button>
                                }
                                {(authority_id == 3 || authority_id == 4) &&
                                    <Button
                                        style={{ padding: 0, fontSize: VW(16), color: item.updateFlag ? '#0052CC' : '' }}
                                        type="text"
                                        icon={item.updateFlag ? '' : <CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                                        disabled={item.updateButtonFlag}
                                        onClick={() => { updateFrequency(index) }}>{item.updateFlag ? '編集保存' : '編集'}</Button>
                                }
                            </div>
                        </div>
                        {item.displayFlag &&
                            <div className={classes.yearTitle}>
                                <div >
                                    <div style={{ cursor: 'pointer', display: item.before_flag == 1 ? '' : 'none' }} onClick={() => { item.yearButtonFlag ? back() : getList(index, 'front') }}><PropertyDetail_calendar_front color={item.yearButtonFlag ? '#DDD' : ''} /></div>
                                    <div>{item.year}年</div>
                                    <div style={{ cursor: 'pointer', display: item.after_flag == 1 ? '' : 'none' }} onClick={() => { item.yearButtonFlag ? back() : getList(index, 'last') }}><PropertyDetail_calendar_after color={item.yearButtonFlag ? '#DDD' : ''} /></div>
                                </div>
                                {Array.from({ length: 12 }).map((moItem, moIndex) => {
                                    return (
                                        <div key={moIndex}>{moIndex + 1}月</div>
                                    )
                                })}
                                <div>回数</div>
                            </div>
                        }
                        {item.result_data.length > 0 && item.result_data.map((itemS, indexS) => {
                            return (
                                <div className={classes.contentBlock} key={indexS} style={{ display: item.displayFlag ? '' : 'none' }}>
                                    <div>
                                        <div>{itemS.category_name}</div>
                                    </div>
                                    <div className={classes.moonBlock}>
                                        {itemS.result_data.length > 0 && itemS.result_data.map((itemT, indexT) => {
                                            return (
                                                <div key={indexT} style={{ borderRight: indexT == 11 ? '0' : '1px solid #DDD' }}>
                                                    {itemT.num.length != 0 && itemT.num.map((CC, CCIndex) => {
                                                        return (
                                                            <div key={CCIndex} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: index == 0 ? '#D4BFFF' : index == 1 ? '#B5FF80' : '#FFCF73', position: 'relative', fontSize: VW(16) }}>
                                                                {item.updateFlag &&
                                                                    <div className={classes.deleteIcon} onClick={() => { item.updateFlag ? changeColor(index, indexS, indexT, CCIndex) : back() }}>
                                                                        <PropertyDetail_calendar_delete />
                                                                    </div>

                                                                }
                                                                <div style={{ cursor: item.updateFlag ? 'pointer' : '' }} onClick={() => { item.updateFlag ? openDaysDialog(Number(item.year), indexT + 1, CC.target_date, index, indexS, indexT, CCIndex) : back() }}>{CC.target_date ? CC.target_date : item.updateFlag ? <PropertyDetail_calendar_date /> : ''}</div>
                                                            </div>
                                                        )
                                                    })}
                                                    {itemT.num.length < 5 && (authority_id == 3 || authority_id == 4) && item.updateFlag &&
                                                        <div style={{ backgroundColor: '#EEE', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => { item.updateFlag ? changeColor(index, indexS, indexT, 'add') : back() }}>
                                                            <PropertyDetail_calendar_add />
                                                        </div>}
                                                    {itemT.remainder != 0 && Array.from({ length: itemT.remainder }).map((NN, NNIndex) => {
                                                        return (
                                                            <div key={NNIndex} style={{ backgroundColor: '#EEE' }}></div>
                                                        )
                                                    })}
                                                    {itemS.large_num == 0 && !item.updateFlag && <div style={{ backgroundColor: '#EEE' }}></div>}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        {item.updateFlag &&
                                            <Input
                                                style={{ height: VH(30), fontSize: VW(16), borderRadius: '4px', marginLeft: VW(2), marginRight: VW(2) }}
                                                value={itemS.total_num}
                                                onChange={(e) => { changeFrequency(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), index, indexS) }}
                                                maxLength={3}
                                            />
                                        }
                                        {item.updateFlag &&
                                            <div> 回</div>
                                        }
                                        {!item.updateFlag &&
                                            <div>{itemS.total_num} 回</div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        <div style={{ width: '100%', height: VH(24), borderTop: '1px solid #DDD' }}></div>
                    </div>
                )
            })}
            <PropertyDetail_calendar_add_confirm
                open={confirmOpen}
                onClose={confirmOpenClose}
                titleName={titleName}
                setCopyFlag={setCopyFlag}
            />
            <Month_Days_dialog
                open={daysOpen}
                onClose={daysOnClose}
                daysArray={daysArray}
                dayYear={dayYear}
                dayMonth={dayMonth}
                changeDay={changeDay}
            />
        </div >
    )
}

PropertyDetail_calendar.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_calendar);