import React, { useState, useEffect, useRef } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, DatePicker, Select, Radio } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import locale from "antd/es/date-picker/locale/ja_JP";
import { errorMessage } from "../../wordsFile/wordsFile";
import Common_errorAlert from "../common/Common_errorAlert";
import Common_successAlert from "../common/Common_successAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ApiSetsubiDetail, ApiSetsubiTypeUpdate, } from '../../network/api'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropertyDetail_equipment_First from "../../componets/icon/PropertyDetail_equipment_First";
import PropertyDetail_equipment_second from "../../componets/icon/PropertyDetail_equipment_second";
import PropertyDetail_equipment_third from "../../componets/icon/PropertyDetail_equipment_third";
import CrossIcon from "../icon/CrossIcon";

dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    roomSelectBlock: {
        height: VH(74),
        marginTop: VH(13),
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        position: 'relative',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center'
        },
        '& > div:nth-of-type(1)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            paddingLeft: VW(16)
        }
    },
    iconTitleBlock: {
        width: '100%',
        height: VH(56),
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& > div:nth-child(1)': {
                marginRight: VW(12)
            },
            '& > div:nth-child(2)': {
                fontSize: VW(16),
                fontWeight: 'bold',
                marginRight: VW(12)
            },
            '& > div:nth-child(3)': {
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }
        }
    },
    contentBlock: {
        width: VW(476),
        '& > div': {
            width: '100%',
            fontSize: VW(16),
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            alignItems: 'center',
            height: VH(40),
            borderBottom: '1px solid #DDD',
            '& > div': {
                height: VH(22),
                borderLeft: `4px solid ${theme.palette.primary.main}`,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
            }
        }
    },
    detailBlock: {
        '& > div': {
            height: VH(56),
            borderBottom: '1px solid #DDD',
            display: 'flex',
            '& > div': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: VW(16)
            },
            '& > div:nth-child(1)': {
                backgroundColor: '#F8F8F8',
                fontWeight: 'bold'
            },
            '& > div:nth-child(2)': {
                width: VW(316),
                whiteSpace: 'pre-line',
                wordBreak: 'break-all'
            }
        }
    },
    titleAdd: {
        width: '100%',
        height: VH(50),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: VW(16),
        borderBottom: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
            fontSize: VW(16),
            cursor: 'pointer',
            '& > div:nth-child(1)': {
                marginTop: VH(4),
                marginRight: VW(8)
            }
        }
    },
    crossBlock: {
        width: VW(40),
        height: VH(224),
        backgroundColor: "#F8F8F8",
        borderRight: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            cursor: 'pointer'
        }
    },
    buttonGroupThree: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(280),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:not(:nth-child(1))': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
});

function PropertyDetail_roomType_update_item(props) {
    const {
        classes,
        open,
        onClose,
        buken_id,
        setsubi_id,
        getList
    } = props


    useEffect(() => {
        if (!open) {
            setRoom_type('')
        } else {
            getDetailData()
        }
    }, [open])



    // 部屋タイプ
    const [room_type, setRoom_type] = useState('')

    // body data
    const [bodyList, setBodyList] = useState([])


    // get list
    const getDetailData = () => {
        let param = {
            setsubi_id: setsubi_id
        }
        ApiSetsubiDetail(param).then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                log(res.data)
                res.data.data.map(item => {
                    item.displayFlag = false
                })
                setRoom_type(res.data.room_topic)
                setBodyList(res.data.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);
    // add api
    const toUpdate = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        let param = {}
        param.buken_id = buken_id
        param.setsubi_id = setsubi_id
        param.room_topic = room_type
        param.data = bodyList
        if (room_type == '') {
            setErrorContent('部屋タイプは必須です')
            setRrrorOpen(true)
        } else {
            ApiSetsubiTypeUpdate(param).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    onClose()
                    getList('update')
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // change display flag
    const changeDisplayFlag = (index) => {
        bodyList[index].displayFlag = !bodyList[index].displayFlag
        setBodyList([...bodyList])
    }

    // second add
    const addSecondaryData = (index) => {
        let param = {
            category_id: null,
            category_name: '',
            delete_flag: false,
            is_update: 1,
            sort_order: null,
            result_data: [{
                delete_flag: false,
                maker: '',
                standard: '',
                installation_date: '',
                remarks: '',
                purpose_id: 3,
                setsubi_detail_id: null
            }]
        }
        bodyList[index].data.push(param)
        setBodyList([...bodyList])
        if (index == 0) {
            let elementF = document.getElementById('roomType_first')
            elementF.scrollIntoView({ block: "center", behavior: "smooth" })
        } else if (index == 1) {
            let elementS = document.getElementById('roomType_second')
            elementS.scrollIntoView({ block: "center", behavior: "smooth" })
        } else if (index == 2) {
            let elementT = document.getElementById('roomType_third');
            elementT.scrollIntoView({ behavior: "smooth" })
        }
    }
    // second update
    const updateSecondaryData = (value, indexA, indexB, keyName) => {
        bodyList[indexA].data[indexB][keyName] = value
        setBodyList([...bodyList])
    }


    // third add
    const addThreeLevelData = (indexA, indexB) => {
        let param = {
            delete_flag: false,
            maker: '',
            standard: '',
            installation_date: '',
            remarks: '',
            purpose_id: 2,
            setsubi_detail_id: null
        }
        bodyList[indexA].data[indexB].result_data.push(param)
        setBodyList([...bodyList])
    }
    // third update
    const updateThreeLevelData = (value, indexA, indexB, indexC, keyName) => {
        log(value)
        if (keyName == 'installation_date') {
            if (value) {
                bodyList[indexA].data[indexB].result_data[indexC][keyName] = dayjs(value).format('YYYY/MM/DD')
            } else {
                bodyList[indexA].data[indexB].result_data[indexC][keyName] = ''
            }
        } else {
            bodyList[indexA].data[indexB].result_data[indexC][keyName] = value
        }
        setBodyList([...bodyList])
    }





    // third delete
    const deleteThreeLevelData = (indexA, indexB, indexC) => {
        bodyList[indexA].data[indexB].result_data[indexC].delete_flag = true
        bodyList[indexA].data[indexB].delete_flag = true
        bodyList[indexA].data[indexB].result_data.map(item => {
            if (item.delete_flag == false) {
                bodyList[indexA].data[indexB].delete_flag = false
            }
        })
        setBodyList([...bodyList])
    }




    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>部屋タイプの編集</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <Common_successAlert
                    open={successOpen}
                    setSuccessOpen={setSuccessOpen}
                    successContent={successContent}
                />
                <div className={classes.roomSelectBlock}>
                    <div>部屋タイプ <span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                    <Input
                        style={{ width: VW(296), marginLeft: VW(16), height: VH(50), fontSize: VW(16) }}
                        placeholder="部屋タイプ "
                        value={room_type}
                        onChange={(event) => setRoom_type(event.target.value)}
                        maxLength={30}
                    />
                </div>
                {bodyList.length > 0 && bodyList.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className={classes.iconTitleBlock} id={item.category_name === '家電' ? 'roomType_first' : item.category_name === '家具' ? 'roomType_second' : ''}>
                                <div onClick={() => { changeDisplayFlag(index) }}>
                                    {item.category_name === '付帯設備' && <div><PropertyDetail_equipment_First /></div>}
                                    {item.category_name === '家電' && <div><PropertyDetail_equipment_second /></div>}
                                    {item.category_name === '家具' && <div><PropertyDetail_equipment_third /></div>}
                                    <div>{item.category_name}</div>
                                    <div>{item.displayFlag ? <ArrowDropUpIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} /> : <ArrowDropDownIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} />}</div>
                                </div>
                            </div>
                            {item.displayFlag &&
                                <div className={classes.titleAdd}>
                                    <div onClick={() => { addSecondaryData(index) }}>
                                        <div><AddIcon /></div>
                                        <div>{item.category_name + 'を追加する'}</div>
                                    </div>
                                </div>}
                            {item.displayFlag &&
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                    {item.data.length > 0 && item.data.map((sItem, sindex) => {
                                        return (
                                            <div className={classes.contentBlock} key={sindex} style={{ float: sindex % 2 == 0 ? '' : '', display: sItem.delete_flag ? 'none' : '' }}>
                                                {sItem.is_update == 0 ?
                                                    <div>
                                                        <div style={{ paddingLeft: VW(12), }}>{sItem.category_name}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div style={{ paddingLeft: VW(8) }}>
                                                            <Input
                                                                placeholder='設備名'
                                                                style={{ width: VW(448), height: VH(30), borderRadius: '4px', fontSize: VW(16) }}
                                                                value={sItem.category_name}
                                                                onChange={(event) => { updateSecondaryData(event.target.value, index, sindex, 'category_name') }}
                                                                maxLength={60}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                {sItem.result_data.length > 0 && sItem.result_data.map((tItem, tIndex) => {
                                                    return (
                                                        <div style={{ display: tItem.delete_flag ? 'none' : 'flex' }} key={tIndex}>
                                                            {tItem.purpose_id != 1 &&
                                                                <div className={classes.crossBlock} >
                                                                    <div onClick={() => { deleteThreeLevelData(index, sindex, tIndex) }}><CrossIcon /></div>
                                                                </div>
                                                            }
                                                            <div className={classes.detailBlock}>
                                                                <div>
                                                                    <div style={{ width: tItem.purpose_id == 1 ? VW(160) : VW(120) }}>メーカー</div>
                                                                    <div>
                                                                        <Input
                                                                            style={{ width: VW(284), height: VH(40), borderRadius: '4px', fontSize: VW(16) }}
                                                                            placeholder="メーカー"
                                                                            value={tItem.maker}
                                                                            onChange={(event) => { updateThreeLevelData(event.target.value, index, sindex, tIndex, 'maker') }}
                                                                            maxLength={60}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{ width: tItem.purpose_id == 1 ? VW(160) : VW(120) }}>型番（規格）</div>
                                                                    <div>
                                                                        <Input
                                                                            style={{ width: VW(284), height: VH(40), borderRadius: '4px', fontSize: VW(16) }}
                                                                            placeholder="型番（規格）"
                                                                            value={tItem.standard}
                                                                            onChange={(event) => { updateThreeLevelData(event.target.value, index, sindex, tIndex, 'standard') }}
                                                                            maxLength={60}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{ width: tItem.purpose_id == 1 ? VW(160) : VW(120) }}>設置日</div>
                                                                    <div>
                                                                        <DatePicker
                                                                            style={{ width: VW(284), height: VH(40), borderRadius: '4px', fontSize: VW(16) }}
                                                                            placeholder="YYYY/MM/DD"
                                                                            value={tItem.installation_date ? dayjs(tItem.installation_date) : ''}
                                                                            format="YYYY/MM/DD"
                                                                            locale={locale}
                                                                            onChange={(event) => { updateThreeLevelData(event, index, sindex, tIndex, 'installation_date') }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{ width: tItem.purpose_id == 1 ? VW(160) : VW(120) }}>備考</div>
                                                                    <div>
                                                                        <Input
                                                                            style={{ width: VW(284), height: VH(40), borderRadius: '4px', fontSize: VW(16) }}
                                                                            placeholder="備考"
                                                                            value={tItem.remarks}
                                                                            onChange={(event) => { updateThreeLevelData(event.target.value, index, sindex, tIndex, 'remarks') }}
                                                                            maxLength={200}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className={classes.titleAdd}>
                                                    <div onClick={() => { addThreeLevelData(index, sindex) }}>
                                                        <div><AddIcon /></div>
                                                        <div>追加する</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    )
                })}
                <div className={classes.buttonGroupThree} id='roomType_third'>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={toUpdate}>登録</Button>
                </div>
            </div>
        </Modal>
    )
}

PropertyDetail_roomType_update_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_roomType_update_item);