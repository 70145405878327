import React, { useState, useEffect, useRef } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import AddIcon from "../icon/AddIcon";
import Common_errorAlert from "../common/Common_errorAlert";
import Common_successAlert from "../common/Common_successAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import MoveIcon from "../icon/MoveIcon";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Folder_Black from "../icon/Folder_Black";
import PropertyDetail_file_folder_add from "./PropertyDetail_file_folder_add";
import { ApiFilemanageFolderList, ApiFilemanageMove } from '../../network/api'


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    itemBlock: {
        width: '100%',
        height: VH(56),
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #DDD'
    },
    addBlock: {
        width: '100%',
        height: VH(50),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '1vw',
        '& > div': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.78vw',
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_file_move(props) {
    const {
        classes,
        open,
        onClose,
        getList,
        state,
        tabFlag,
        moveList
    } = props

    useEffect(() => {
        if (!open) {
            setRrrorOpen(false)
            setSelectedValue(0)
        } else {
            getFolderList()
            log(moveList)
        }
    }, [open]);


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")
    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')


    // folder List
    const [folderList, setFolderList] = useState([])
    const [selectedValue, setSelectedValue] = useState(0);
    const handleChange = (event) => {
        setSelectedValue(Number(event.target.value))
    };


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  folder add dialog
    const [addFolderOpen, setAddFolderOpen] = useState(false)
    const addFolderClose = () => {
        setAddFolderOpen(false)
    }
    const openAddFolderDialog = () => {
        setAddFolderOpen(true)
    }

    // get folder list api
    const getFolderList = (successFlag) => {
        let folderString = ''
        moveList.map(item => {
            if (item.file_type_name == "file_folder") {
                folderString = folderString + String(item.file_manage_id) + ','
            }
        })
        log(folderString)
        let param = {
            folder_list: folderString.slice(0, -1),
            buken_id: state.buken_id,
            is_belong: tabFlag ? 1 : 0,
        }
        log(param)
        ApiFilemanageFolderList(param).then(res => {
            let resData = res.data
            if (res.status === 200) {
                setFolderList(resData.data)
                if (successFlag === 'add') {
                    setSuccessContent('登録が完了しました。')
                    setSuccessOpen(true)
                }
                log(resData.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // 移動
    const toMove = () => {
        let newMoveList = []
        moveList.map(item => {
            newMoveList.push({
                file_manage_id: item.file_manage_id,
                file_url: item.file_url,
                file_name: item.file_name,
                file_category: item.file_category
            })
        })
        log(newMoveList)
        let fileUrl = ''
        folderList.map(item => {
            if (item.file_manage_id == selectedValue) {
                fileUrl = item.file_url
            }
        })
        let param = {
            buken_id: state.buken_id,
            file_manage_id: selectedValue == 0 ? null : selectedValue,
            file_url: selectedValue == 0 ? null : fileUrl,
            move_type: selectedValue == 0 ? 1 : 0,
            file_info_list: newMoveList
        }
        log(param)
        ApiFilemanageMove(param).then(res => {
            if (res.status === 200) {
                getList()
                onClose()
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
                setSelectedValue(0)
            }
        }).catch(err => {

        })
    }

    // キャンセル
    const toBack = () => {
        getList()
        onClose()
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <MoveIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>フォルダの移動</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_successAlert
                    open={successOpen}
                    setSuccessOpen={setSuccessOpen}
                    successContent={successContent}
                    marginTop={VH(12)}
                />
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.itemBlock} style={{ marginTop: VH(13), paddingLeft: VW(16) }}>
                    <FormControlLabel
                        checked={selectedValue === 0}
                        onChange={handleChange}
                        control={<Radio style={{ color: '#2F83FF' }} />}
                        value='0'
                        label='ファイル管理一覧'
                    />
                </div>
                {folderList.length > 0 && folderList.map((item, index) => {
                    return (
                        <div className={classes.itemBlock} key={index} style={{ paddingLeft: VW(56) }}>
                            <FormControlLabel
                                checked={selectedValue === item.file_manage_id}
                                onChange={handleChange}
                                control={<Radio style={{ color: '#2F83FF' }} />}
                                value={item.file_manage_id}
                                label={<div style={{ display: 'flex' }}><Folder_Black size={VW(16.67)} /><div style={{ marginLeft: VW(8) }}>{item.file_name}</div></div>}
                            />
                        </div>
                    )
                })}
                <div className={classes.addBlock}>
                    <div style={{ fontSize: VW(16) }} onClick={openAddFolderDialog}>
                        <AddIcon />&ensp;新規フォルダ
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={toBack}>キャンセル</Button>
                    <Button onClick={toMove}>移動</Button>
                </div>
                <PropertyDetail_file_folder_add
                    open={addFolderOpen}
                    onClose={addFolderClose}
                    getList={getFolderList}
                    state={state}
                    tabFlag={tabFlag}
                />
            </div>
        </Modal >
    )
}

PropertyDetail_file_move.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_file_move);