import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_equipment_First() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width="24" height="24" rx="2" fill="#2F83FF" />
            <path d="M12.8333 7.05V4.5H11.1667V7.05C7.41667 7.46667 4.5 10.6417 4.5 14.4917C4.5 15.4167 5.25 16.1667 6.175 16.1667H8.66667C8.66667 18.0083 10.1583 19.5 12 19.5C13.8417 19.5 15.3333 18.0083 15.3333 16.1667H17.825C18.75 16.1667 19.5 15.4167 19.5 14.4917C19.5 10.6417 16.5833 7.46667 12.8333 7.05ZM6.16667 14.5C6.16667 11.2833 8.78333 8.66667 12 8.66667C15.2167 8.66667 17.8333 11.2833 17.8333 14.5H6.16667Z" fill="white" />
        </svg>

    )
}
export default PropertyDetail_equipment_First;


