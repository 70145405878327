import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_calendar_delete(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(14)} height={VW(14)} viewBox="0 0 14 14" style={{ display: 'block' }}>
            <path d="M6.99967 0.333313C3.31967 0.333313 0.333008 3.31998 0.333008 6.99998C0.333008 10.68 3.31967 13.6666 6.99967 13.6666C10.6797 13.6666 13.6663 10.68 13.6663 6.99998C13.6663 3.31998 10.6797 0.333313 6.99967 0.333313ZM10.333 7.66665H3.66634V6.33331H10.333V7.66665Z" fill="#757575" />
        </svg>

    )
}

export default PropertyDetail_calendar_delete;