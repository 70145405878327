import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"
import log from "../function/log";
import { Button, Select, Input, DatePicker } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropertyDetail_equipment_First from "../../componets/icon/PropertyDetail_equipment_First";
import PropertyDetail_equipment_second from "../../componets/icon/PropertyDetail_equipment_second";
import PropertyDetail_equipment_third from "../../componets/icon/PropertyDetail_equipment_third";
import AddIcon from "../icon/AddIcon";
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import CrossIcon from "../icon/CrossIcon";


const { Option } = Select;

const styles = (theme) => ({
    roomSelectBlock: {
        height: VH(74),
        marginTop: VH(16),
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        position: 'relative',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center'
        },
        '& > div:nth-of-type(1)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            paddingLeft: VW(16)
        },
        '& > div:nth-of-type(3)': {
            marginLeft: VW(16)
        },
        '& > Button': {
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
            backgroundColor: '#E60024',
            color: '#FFF',
            fontWeight: 'bold',
            position: 'absolute',
            right: 0,
        }
    },
    iconTitleBlock: {
        width: '100%',
        height: VH(56),
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& > div:nth-child(1)': {
                marginRight: VW(12)
            },
            '& > div:nth-child(2)': {
                fontSize: VW(16),
                fontWeight: 'bold',
                marginRight: VW(12)
            },
            '& > div:nth-child(3)': {
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }
        }
    },
    contentBlock: {
        width: VW(476),
        '& > div': {
            width: '100%',
            fontSize: VW(16),
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            alignItems: 'center',
            height: VH(40),
            borderBottom: '1px solid #DDD',
            '& > div': {
                height: VH(22),
                borderLeft: `4px solid ${theme.palette.primary.main}`,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
            }
        }
    },
    detailBlock: {
        '& > div': {
            height: VH(56),
            borderBottom: '1px solid #DDD',
            display: 'flex',
            '& > div': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: VW(16)
            },
            '& > div:nth-child(1)': {
                backgroundColor: '#F8F8F8',
                fontWeight: 'bold'
            },
            '& > div:nth-child(2)': {
                width: VW(316),
                whiteSpace: 'pre-line',
                wordBreak: 'break-all'
            }
        }
    },
    titleAdd: {
        width: '100%',
        height: VH(50),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: VW(16),
        borderBottom: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
            fontSize: VW(16),
            cursor: 'pointer',
            '& > div:nth-child(1)': {
                marginTop: VH(4),
                marginRight: VW(8)
            }
        }
    },
    crossBlock: {
        width: VW(40),
        height: VH(224),
        backgroundColor: "#F8F8F8",
        borderRight: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            cursor: 'pointer'
        }
    }
});

function PropertyDetail_equipment_input(props) {
    const {
        classes,
        bodylist,
        setBodyList,
        changeDisplayFlag
    } = props

    useEffect(() => {

    }, [])


    // second add
    const addSecondaryData = (index) => {
        let param = {
            category_id: null,
            category_name: '',
            delete_flag: false,
            is_update: 1,
            sort_order: null,
            result_data: [{
                delete_flag: false,
                maker: '',
                standard: '',
                installation_date: '',
                remarks: '',
                purpose_id: 3,
                setsubi_detail_id: null
            }]
        }
        bodylist[index].data.push(param)
        setBodyList([...bodylist])
        if (index == 0) {
            let elementF = document.getElementById('FirstTitleId')
            elementF.scrollIntoView({ block: "center", behavior: "smooth" })
        } else if (index == 1) {
            let elementS = document.getElementById('secondTitleId')
            elementS.scrollIntoView({ block: "center", behavior: "smooth" })
        } else if (index == 2) {
            let elementT = document.getElementById('thirdTitleId');
            elementT.scrollIntoView({ behavior: "smooth" })
        }
    }
    // second update
    const updateSecondaryData = (value, indexA, indexB, keyName) => {
        bodylist[indexA].data[indexB][keyName] = value
        setBodyList([...bodylist])
    }



    // third add
    const addThreeLevelData = (indexA, indexB) => {
        let param = {
            delete_flag: false,
            maker: '',
            standard: '',
            installation_date: '',
            remarks: '',
            purpose_id: 2,
            setsubi_detail_id: null
        }
        bodylist[indexA].data[indexB].result_data.push(param)
        setBodyList([...bodylist])
    }
    // third update
    const updateThreeLevelData = (value, indexA, indexB, indexC, keyName) => {
        log(value)
        if (keyName == 'installation_date') {
            if (value) {
                bodylist[indexA].data[indexB].result_data[indexC][keyName] = dayjs(value).format('YYYY/MM/DD')
            } else {
                bodylist[indexA].data[indexB].result_data[indexC][keyName] = ''
            }
        } else {
            bodylist[indexA].data[indexB].result_data[indexC][keyName] = value
        }
        setBodyList([...bodylist])
    }





    // third delete
    const deleteThreeLevelData = (indexA, indexB, indexC) => {
        bodylist[indexA].data[indexB].result_data[indexC].delete_flag = true
        bodylist[indexA].data[indexB].delete_flag = true
        bodylist[indexA].data[indexB].result_data.map(item => {
            if (item.delete_flag == false) {
                bodylist[indexA].data[indexB].delete_flag = false
            }
        })
        setBodyList([...bodylist])
    }


    return (
        <div>
            {bodylist.length > 0 && bodylist.map((item, index) => {
                return (
                    <div key={index}>
                        <div className={classes.iconTitleBlock} id={item.category_name === '家電' ? 'FirstTitleId' : item.category_name === '家具' ? 'secondTitleId' : ''}>
                            <div onClick={() => { changeDisplayFlag(index) }}>
                                {item.category_name === '付帯設備' && <div><PropertyDetail_equipment_First /></div>}
                                {item.category_name === '家電' && <div><PropertyDetail_equipment_second /></div>}
                                {item.category_name === '家具' && <div><PropertyDetail_equipment_third /></div>}
                                <div>{item.category_name}</div>
                                <div>{item.displayFlag ? <ArrowDropUpIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} /> : <ArrowDropDownIcon style={{ width: VW(24), height: VH(24), color: '#0052CC' }} />}</div>
                            </div>
                        </div>
                        {item.displayFlag &&
                            <div className={classes.titleAdd}>
                                <div onClick={() => { addSecondaryData(index) }}>
                                    <div><AddIcon /></div>
                                    <div>{item.category_name + 'を追加する'}</div>
                                </div>
                            </div>}
                        {item.displayFlag &&
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                {item.data.length > 0 && item.data.map((sItem, sindex) => {
                                    return (
                                        <div className={classes.contentBlock} key={sindex} style={{ display: sItem.delete_flag ? 'none' : '' }}>
                                            {sItem.is_update == 0 ?
                                                <div>
                                                    <div style={{ paddingLeft: VW(12), }}>{sItem.category_name}</div>
                                                </div>
                                                :
                                                <div>
                                                    <div style={{ paddingLeft: VW(8) }}>
                                                        <Input
                                                            placeholder='設備名'
                                                            style={{ width: VW(448), height: VH(30), borderRadius: '4px', fontSize: VW(16) }}
                                                            value={sItem.category_name}
                                                            onChange={(event) => { updateSecondaryData(event.target.value, index, sindex, 'category_name') }}
                                                            maxLength={60}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {sItem.result_data.length > 0 && sItem.result_data.map((tItem, tIndex) => {
                                                return (
                                                    <div style={{ display: tItem.delete_flag ? 'none' : 'flex' }} key={tIndex}>
                                                        {tItem.purpose_id != 1 &&
                                                            <div className={classes.crossBlock} >
                                                                <div onClick={() => { deleteThreeLevelData(index, sindex, tIndex) }}><CrossIcon /></div>
                                                            </div>
                                                        }
                                                        <div className={classes.detailBlock}>
                                                            <div>
                                                                <div style={{ width: tItem.purpose_id == 1 ? VW(160) : VW(120) }}>メーカー</div>
                                                                <div>
                                                                    <Input
                                                                        style={{ width: VW(284), height: VH(40), borderRadius: '4px', fontSize: VW(16) }}
                                                                        placeholder="メーカー"
                                                                        value={tItem.maker}
                                                                        onChange={(event) => { updateThreeLevelData(event.target.value, index, sindex, tIndex, 'maker') }}
                                                                        maxLength={60}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{ width: tItem.purpose_id == 1 ? VW(160) : VW(120) }}>型番（規格）</div>
                                                                <div>
                                                                    <Input
                                                                        style={{ width: VW(284), height: VH(40), borderRadius: '4px', fontSize: VW(16) }}
                                                                        placeholder="型番（規格）"
                                                                        value={tItem.standard}
                                                                        onChange={(event) => { updateThreeLevelData(event.target.value, index, sindex, tIndex, 'standard') }}
                                                                        maxLength={60}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{ width: tItem.purpose_id == 1 ? VW(160) : VW(120) }}>設置日</div>
                                                                <div>
                                                                    <DatePicker
                                                                        style={{ width: VW(284), height: VH(40), borderRadius: '4px', fontSize: VW(16) }}
                                                                        placeholder="YYYY/MM/DD"
                                                                        value={tItem.installation_date ? dayjs(tItem.installation_date) : ''}
                                                                        format="YYYY/MM/DD"
                                                                        locale={locale}
                                                                        onChange={(event) => { updateThreeLevelData(event, index, sindex, tIndex, 'installation_date') }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{ width: tItem.purpose_id == 1 ? VW(160) : VW(120) }}>備考</div>
                                                                <div>
                                                                    <Input
                                                                        style={{ width: VW(284), height: VH(40), borderRadius: '4px', fontSize: VW(16) }}
                                                                        placeholder="備考"
                                                                        value={tItem.remarks}
                                                                        onChange={(event) => { updateThreeLevelData(event.target.value, index, sindex, tIndex, 'remarks') }}
                                                                        maxLength={200}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className={classes.titleAdd}>
                                                <div onClick={() => { addThreeLevelData(index, sindex) }}>
                                                    <div><AddIcon /></div>
                                                    <div>追加する</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                )
            })}
            <div id='thirdTitleId'></div>
        </div >
    )
}

PropertyDetail_equipment_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_input);