import { VW, VH } from "../../componets/function/sizeAdapter"
function Property_detail_parkingLot() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" fill="none" style={{ display: 'block' }} >
            <rect width={VW(24)} height={VH(24)} rx="2" fill="#2F83FF" />
            <path d="M12.75 5.25H7.5V18.75H10.5V14.25H12.75C15.2325 14.25 17.25 12.2325 17.25 9.75C17.25 7.2675 15.2325 5.25 12.75 5.25ZM12.9 11.25H10.5V8.25H12.9C13.725 8.25 14.4 8.925 14.4 9.75C14.4 10.575 13.725 11.25 12.9 11.25Z" fill="white" />
        </svg>
    )
}
export default Property_detail_parkingLot;



