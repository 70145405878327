import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import Item_icon_title from "../../componets/common/Item_icon_title";
import Item_secondary_title from "../../componets/common/Item_secondary_title";
import dayjs from 'dayjs';
import { VW, VH } from "../../componets/function/sizeAdapter"
import { Select, Input, DatePicker, Button } from 'antd'
import locale from "antd/es/date-picker/locale/ja_JP";
import SearchIcon from '@mui/icons-material/Search';
import { ApiOwnerOwnerNameByTenantid } from '../../network/api';
import log from "../../componets/function/log";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from "../icon/AddIcon";
import OwnersList_add from "../dialog/OwnersList_add";


const { TextArea } = Input;

const styles = (theme) => ({
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(56)
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '30%'
        },
        '& > div:nth-child(3)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(4)': {
            width: '30%'
        },
    },
    groundTwo: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(56),
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '80%'
        }
    },
    TextAreaBlock: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(120),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(120),
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '80%'
        }
    },
    nameBlock: {
        width: '100%',
        height: VH(50),
        display: 'flex',
        alignItems: 'center',
        paddingLeft: VW(16),
        fontSize: VW(16),
        border: '1px solid #DDD',
        borderBottom: 0,
        cursor: 'pointer'
    },
});

function PropertyDetail_input_First(props) {
    const {
        classes,
        titleData,
        setTitleData,
        state,
        bodyList,
        changeOpenFlag,
        changeTitleData,
        changeBodyData,
        syunkoDate,
        changeSyunkoDate,
        ownerList,
        getList,
        startDate,
        changeStartDate,
        endDate,
        changeEndDate
    } = props


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }




    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day')
    }

    const authority_id = localStorage.getItem("authority_id")



    return (
        <div style={{ marginTop: VH(16) }}>
            {authority_id == 3 || authority_id == 4 ?
                <div className={classes.groundTwo}>
                    <div>物件CD</div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.customer_buken_code}
                            onChange={(e) => { changeTitleData(e.target.value, 'customer_buken_code') }}
                            maxLength={50}
                        />
                    </div>
                </div>
                :
                <div className={classes.groundFour}>
                    <div>パパネッツ物件CD</div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.papanets_buken_code}
                            onChange={(e) => { changeTitleData(e.target.value, 'papanets_buken_code') }}
                            maxLength={20}
                            disabled
                        />
                    </div>
                    <div>物件CD</div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.customer_buken_code}
                            onChange={(e) => { changeTitleData(e.target.value, 'customer_buken_code') }}
                            maxLength={50}
                            disabled={authority_id != 3 && authority_id != 4}
                        />
                    </div>
                </div>
            }

            <div className={classes.groundFour}>
                <div>{'パパネッツ\n巡回契約開始日'}</div>
                <div>
                    <DatePicker
                        style={{ width: '100%', height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        placeholder=""
                        value={startDate}
                        format="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeStartDate}
                        disabled={authority_id == 3 || authority_id == 4}
                    />
                </div>
                <div>{'パパネッツ\n巡回契約終了日'}</div>
                <div>
                    <DatePicker
                        style={{ width: '100%', height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        placeholder=""
                        value={endDate}
                        format="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeEndDate}
                        disabled={authority_id == 3 || authority_id == 4}
                    />
                </div>
            </div>

            {authority_id == 3 || authority_id == 4 ?
                <div className={classes.groundTwo}>
                    <div>エリア</div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.area}
                            onChange={(e) => { changeTitleData(e.target.value, 'area') }}
                            maxLength={60}
                            disabled={authority_id != 3 && authority_id != 4}
                        />
                    </div>
                </div>
                :
                <div className={classes.groundFour}>
                    <div>エリア</div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.area}
                            onChange={(e) => { changeTitleData(e.target.value, 'area') }}
                            maxLength={60}
                            disabled={authority_id != 3 && authority_id != 4}
                        />
                    </div>
                    <div>管理会社</div>
                    <div>
                        <Input
                            disabled
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.tenant_name}
                            onChange={(e) => { changeTitleData(e.target.value, 'tenant_name') }}
                            maxLength={10}
                        />
                    </div>
                </div>
            }

            <div className={classes.groundTwo} >
                <div>物件名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                <div>
                    <Input
                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        value={titleData.genba_name}
                        onChange={(e) => { changeTitleData(e.target.value, 'genba_name') }}
                        maxLength={100}
                        disabled={authority_id != 3 && authority_id != 4}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>郵便番号<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                <div>
                    <div>〒</div>
                    <div>
                        <Input
                            style={{ width: VW(120), marginLeft: VW(10), marginRight: VW(16), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            placeholder="000-0000"
                            value={titleData.postal}
                            onChange={(e) => { changeTitleData(e.target.value, 'postal') }}
                            disabled={authority_id != 3 && authority_id != 4}
                            maxLength={8}
                        />
                    </div>
                    <div style={{ color: '#AAA' }}>※半角７桁ハイフンあり</div>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>所在地<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                <div>
                    <Input
                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        placeholder="所在地"
                        value={titleData.address}
                        onChange={(e) => { changeTitleData(e.target.value, 'address') }}
                        disabled={authority_id != 3 && authority_id != 4}
                        maxLength={100}
                    />
                </div>
            </div>
            <div className={classes.groundFour}>
                <div>オーナー<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                <div>
                    <Autocomplete
                        disabled={authority_id != 3 && authority_id != 4}
                        disablePortal
                        options={ownerList.map((option) => option.owner_name)}
                        value={titleData.owner_name ? titleData.owner_name : ''}
                        noOptionsText={"存在しないオーナーが入力されています。存在するオーナーを入力するか、オーナー選択欄にて、オーナーの新規登録を行ってから、物件情報の登録をしてください。"}
                        onChange={(e, newValue) => { changeTitleData(newValue, 'owner_name') }}
                        style={{ width: VW(264), fontSize: VW(16), borderRadius: '4px' }}
                        forcePopupIcon={false}
                        renderInput={(params) => <TextField placeholder='オーナー'{...params} label="" size="small" />}
                        PaperComponent={({ children }) => {
                            return (
                                <div
                                    style={{
                                        backgroundColor: '#FFF',
                                        border: '1px solid #DDD',
                                    }}
                                >
                                    {children}
                                    <div className={classes.nameBlock} style={{ border: 0, borderTop: '1px solid #DDD' }} onMouseDown={openAddDialog}>
                                        <div style={{ marginRight: VW(8) }}>新規登録</div>
                                        <div style={{ marginTop: VH(3) }}>
                                            <AddIcon color='#5B5B5B' />
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    ></Autocomplete>
                    <SearchIcon style={{ width: VW(24), height: VH(24), marginLeft: VW(8) }} />
                </div>
                <div>竣工日</div>
                <div>
                    <DatePicker
                        style={{ width: '100%', height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        placeholder=""
                        value={syunkoDate}
                        format="YYYY/MM/DD"
                        locale={locale}
                        // disabledDate={disabledDate}
                        onChange={changeSyunkoDate}
                        disabled={authority_id != 3 && authority_id != 4}
                    />
                </div>
            </div>
            {state.buken_shubetsu === 0 ?
                <div className={classes.groundTwo} >
                    <div>延床面積</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.nobeyuka_area}
                            onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), 'nobeyuka_area') }}
                            maxLength={10}
                            disabled={authority_id != 3 && authority_id != 4}
                        />
                        ㎡
                    </div>
                </div>
                :
                <div className={classes.groundFour}>
                    <div>延床面積</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.nobeyuka_area}
                            onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), 'nobeyuka_area') }}
                            maxLength={10}
                            disabled={authority_id != 3 && authority_id != 4}
                        />
                        ㎡
                    </div>
                    <div>基数</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.kisu}
                            onChange={(e) => { changeTitleData(e.target.value, 'kisu') }}
                            maxLength={10}
                            disabled={authority_id != 3 && authority_id != 4}
                        />
                        基
                    </div>
                </div>
            }
            <div className={classes.groundFour}>
                <div>階数</div>
                <div>
                    <Input
                        style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        value={titleData.floor}
                        onChange={(e) => { changeTitleData(e.target.value, 'floor') }}
                        maxLength={10}
                        disabled={authority_id != 3 && authority_id != 4}
                    />
                    階
                </div>
                <div>部屋数</div>
                <div>
                    <Input
                        style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        value={titleData.rooms}
                        onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), 'rooms') }}
                        maxLength={5}
                        disabled={authority_id != 3 && authority_id != 4}
                    />
                    部屋
                </div>
            </div>
            {state.buken_shubetsu === 1 &&
                <div className={classes.groundFour}>
                    <div>補強部材</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.hokyo_buzai}
                            onChange={(e) => { changeTitleData(e.target.value, 'hokyo_buzai') }}
                            maxLength={20}
                            disabled={authority_id != 3 && authority_id != 4}
                        // placeholder="補強部材"
                        />
                    </div>
                    <div>連結部材</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.renketu_buzai}
                            onChange={(e) => { changeTitleData(e.target.value, 'renketu_buzai') }}
                            maxLength={20}
                            disabled={authority_id != 3 && authority_id != 4}
                        // placeholder="連結部材"
                        />
                    </div>
                </div>
            }
            {state.buken_shubetsu === 1 &&
                <div className={classes.groundTwo}>
                    <div>その他部材</div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.other_buzai}
                            onChange={(e) => { changeTitleData(e.target.value, 'other_buzai') }}
                            disabled={authority_id != 3 && authority_id != 4}
                            maxLength={20}
                        // placeholder="その他部材"
                        />
                    </div>
                </div>
            }
            <div className={classes.groundFour}>
                <div>駐輪場</div>
                <div>
                    <Input
                        style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        value={titleData.tyurinjyo_num}
                        onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), 'tyurinjyo_num') }}
                        maxLength={5}
                        disabled={authority_id != 3 && authority_id != 4}
                    />
                    台
                </div>
                <div>駐車場</div>
                <div>
                    <Input
                        style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                        value={titleData.tyusyajyo_num}
                        onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), 'tyusyajyo_num') }}
                        maxLength={5}
                        disabled={authority_id != 3 && authority_id != 4}
                    />
                    台
                </div>
            </div>
            <div className={classes.TextAreaBlock}>
                <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（200文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(104), width: "100%", fontSize: VW(16) }}
                        placeholder="備考を記入してください。"
                        maxLength={200}
                        value={titleData.remarks}
                        onChange={(e) => { changeTitleData(e.target.value, 'remarks') }}
                        disabled={authority_id != 3 && authority_id != 4}
                    />
                </div>
            </div>

            {bodyList.length > 0 && bodyList.map((item, index) => {
                return (
                    <div key={index}>
                        <Item_icon_title height={56} titleName={item.parent_category_name} titleId={item.parent_category_id} flag={item.openFlag} changeFlag={() => { changeOpenFlag(item) }} />
                        {item.data.length > 0 && item.data.map((secondItem, secondIndex) => {
                            return (
                                <div key={secondIndex} style={{ display: item.openFlag ? '' : 'none' }}>
                                    {secondItem.category_name && <Item_secondary_title height={40} titleName={secondItem.category_name} />}
                                    {secondItem.result_data.key2_name &&
                                        <div className={classes.groundFour}>
                                            <div>{secondItem.result_data.key1_name}</div>
                                            <div>
                                                <Input
                                                    disabled={authority_id != 3 && authority_id != 4}
                                                    style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                    value={secondItem.result_data.key1_value}
                                                    onChange={(e) => { secondItem.result_data.key1_name == '設置台数' ? changeBodyData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ''), secondItem.result_data, 'key1_value') : changeBodyData(e.target.value, secondItem.result_data, 'key1_value') }}
                                                    maxLength={secondItem.result_data.key1_name == '設置台数' ? 5 : secondItem.result_data.key1_name == '施工業者' ? 60 : ''}

                                                />
                                            </div>
                                            <div>{secondItem.result_data.key2_name}</div>
                                            <div>
                                                <Input
                                                    disabled={authority_id != 3 && authority_id != 4}
                                                    style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                    value={secondItem.result_data.key2_value}
                                                    onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key2_value') }}
                                                    maxLength={(secondItem.result_data.key2_name == '設置種類' || secondItem.result_data.key2_name == '補強部材' || secondItem.result_data.key2_name == '連結部材' || secondItem.result_data.key2_name == '枠部材') ? 20 : secondItem.result_data.key2_name == '電話番号' ? 15 : ''}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {secondItem.result_data.key4_name &&
                                        <div className={classes.groundFour}>
                                            <div>{secondItem.result_data.key3_name}</div>
                                            <div>
                                                <Input
                                                    disabled={authority_id != 3 && authority_id != 4}
                                                    style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                    value={secondItem.result_data.key3_value}
                                                    onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key3_value') }}
                                                    maxLength={(secondItem.result_data.key3_name == 'メーカー' || secondItem.result_data.key3_name == '連結部材') ? 20 : ''}
                                                />
                                            </div>
                                            <div>{secondItem.result_data.key4_name}</div>
                                            <div>
                                                <Input
                                                    disabled={authority_id != 3 && authority_id != 4}
                                                    style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                    value={secondItem.result_data.key4_value}
                                                    onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key4_value') }}
                                                    maxLength={secondItem.result_data.key4_name == 'その他部材' ? 20 : secondItem.result_data.key4_name == '電話番号' ? 15 : ''}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {secondItem.result_data.key6_name &&
                                        <div className={classes.groundFour}>
                                            <div>{secondItem.result_data.key5_name}</div>
                                            <div>
                                                <Input
                                                    disabled={authority_id != 3 && authority_id != 4}
                                                    style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                    value={secondItem.result_data.key5_value}
                                                    onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key5_value') }}
                                                    maxLength={secondItem.result_data.key5_name == 'メーカー' ? 20 : ''}
                                                />
                                            </div>
                                            <div>{secondItem.result_data.key6_name}</div>
                                            <div>
                                                <Input
                                                    disabled={authority_id != 3 && authority_id != 4}
                                                    style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                    value={secondItem.result_data.key6_value}
                                                    onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key6_value') }}
                                                    maxLength={secondItem.result_data.key6_name == '電話番号' ? 15 : ''}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {secondItem.result_data.key8_name &&
                                        <div className={classes.groundFour}>
                                            <div>{secondItem.result_data.key7_name}</div>
                                            <div>
                                                <Input
                                                    disabled={authority_id != 3 && authority_id != 4}
                                                    style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                    value={secondItem.result_data.key7_value}
                                                    onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key7_value') }}
                                                />
                                            </div>
                                            <div>{secondItem.result_data.key7_name}</div>
                                            <div>
                                                <Input
                                                    disabled={authority_id != 3 && authority_id != 4}
                                                    style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                    value={secondItem.result_data.key8_value}
                                                    onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key8_value') }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className={classes.TextAreaBlock}>
                                        <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（200文字まで）</p></div>
                                        <div>
                                            <TextArea
                                                disabled={authority_id != 3 && authority_id != 4}
                                                style={{ height: VH(104), width: "100%", fontSize: VW(16) }}
                                                placeholder="備考を記入してください。"
                                                maxLength={200}
                                                value={secondItem.result_data.remark_value}
                                                onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'remark_value') }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            <div style={{ width: '100%', borderTop: '1px solid #DDD' }}></div>
            <OwnersList_add
                open={addOpen}
                onClose={addOnClose}
                getList={getList}
            />
        </div>
    )
}

PropertyDetail_input_First.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_input_First);