import React from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons'

const { Option } = Select;

const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
        '& .ant-select-selection-item': {
            height: VH(30),
            marginRight: VW(24)
        },
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
            minHeight: VH(74),
        },
        '& > div > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: VW(760),
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
    }
});

function TagDetail_input(props) {
    const {
        classes,
        colorName,
        setColorName,
        colorType,
        setColorType
    } = props





    return (
        <div className={classes.contentBlock}>
            <div>
                <div>タグ名<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                <div>
                    <Input
                        placeholder="タグ名を入力してください"
                        value={colorName}
                        onChange={(event) => setColorName(event.target.value)}
                        maxLength={30}
                    />
                </div>
            </div>
            <div>
                <div>カラー</div>
                <div>
                    <Select
                        style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16) }}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: '#5B5B5B' }} />}
                        value={colorType}
                        onChange={(e) => { setColorType(e) }}
                    >
                        <Option style={{ height: VH(40) }} value='1'>
                            <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#FFB872', color: '#FFB872' }}></div>
                        </Option>
                        <Option style={{ height: VH(40) }} value='2'>
                            <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#BB99FE', color: '#BB99FE' }}></div>
                        </Option>
                        <Option style={{ height: VH(40) }} value='3'>
                            <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#80BFFF', color: '#80BFFF' }}></div>
                        </Option>
                        <Option style={{ height: VH(40) }} value='4'>
                            <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#BCFA38', color: '#BCFA38' }}></div>
                        </Option>
                        <Option style={{ height: VH(40) }} value='5'>
                            <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#F9CFF9', color: '#F9CFF9' }}></div>
                        </Option>
                        <Option style={{ height: VH(40) }} value='6'>
                            <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#ADEAFF', color: '#ADEAFF' }}></div>
                        </Option>
                        <Option style={{ height: VH(40) }} value='7'>
                            <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#85F8B3', color: '#85F8B3' }}></div>
                        </Option>
                        <Option style={{ height: VH(40) }} value='8'>
                            <div style={{ width: VW(304), height: VH(50), display: 'flex', alignItems: 'center', fontSize: VW(16), backgroundColor: '#FF8CCF', color: '#FF8CCF' }}></div>
                        </Option>
                    </Select>
                </div>
            </div>
        </div>
    )
}

TagDetail_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TagDetail_input);