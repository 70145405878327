import { VW, VH } from "../../componets/function/sizeAdapter"
function MenuHeader_notification(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(14)} height={VH(17)} viewBox="0 0 14 17" fill="none" >
            <path d="M7.00001 16.3333C7.91668 16.3333 8.66668 15.5833 8.66668 14.6666H5.33334C5.33334 15.5833 6.07501 16.3333 7.00001 16.3333ZM12 11.3333V7.16658C12 4.60825 10.6333 2.46659 8.25001 1.89992V1.33325C8.25001 0.641585 7.69168 0.083252 7.00001 0.083252C6.30834 0.083252 5.75001 0.641585 5.75001 1.33325V1.89992C3.35834 2.46659 2.00001 4.59992 2.00001 7.16658V11.3333L0.333344 12.9999V13.8333H13.6667V12.9999L12 11.3333Z" fill={color ? color : '#2F83FF'} />
        </svg>

    )
}

export default MenuHeader_notification;