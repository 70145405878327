import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"
import log from "../function/log";


const styles = (theme) => ({
    contentBlock: {
        width: '98%',
        marginTop: VH(16),
        '& > div': {
            height: 'auto',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
        },
        '& > div > div': {
            minHeight: VH(74),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all'
        },
        '& > div > div:nth-child(1)': {
            width: '24.2%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div > div:nth-child(2)': {
            width: '75.8%',
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
    }
});

function PropertyDetail_equipment_records_item(props) {
    const {
        classes,
        detailedData,
        state
    } = props

    useEffect(() => {
        log(detailedData)
    }, [])



    return (
        <div className={classes.contentBlock}>
            <div>
                <div>実施日</div>
                <div>{detailedData.implementation_date}</div>
            </div>
            <div>
                <div>スペース</div>
                <div>{detailedData.room_num}</div>
            </div>
            <div>
                <div>対象設備</div>
                <div>{detailedData.setsubi}</div>
            </div>
            <div>
                <div style={{ height: VH(304) }}>実施内容</div>
                <div style={{ height: VH(304) }}>
                    <div style={{ width: '100%', maxHeight: VH(304), overflowY: "scroll" }}>{detailedData.implementation_content}</div>
                </div>
            </div>
            <div>
                <div>次回実施日</div>
                <div>{detailedData.next_implementation_date}</div>
            </div>
        </div>
    )
}

PropertyDetail_equipment_records_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_records_item);