import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import log from "../function/log";
import { VH, VW } from "../function/sizeAdapter";


const styles = (theme) => ({
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #DDD',
        minHeight: VH(74),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(74)
        },
        '& > div:nth-child(odd)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(even)': {
            width: '26%'
        }
    },
    groundTwo: {
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #DDD',
        minHeight: VH(56),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(74),
        },
        '& > div:nth-child(1)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '76%'
        }
    }
});

function PropertyDetail_repair_item(props) {
    const {
        classes,
        detailedData,
        toBusiness,
        toClaim,
        getPriorityColor
    } = props


    return (
        <div style={{ marginTop: VH(16) }}>
            <div style={{ borderTop: '1px solid #DDD' }} className={classes.groundTwo}>
                <div>修繕番号</div>
                <div>{detailedData.mending_no}</div>
            </div>
            <div className={classes.groundFour}>
                <div>優先度</div>
                <div>
                    <span style={{
                        display: (detailedData.priority_name != null) ? '' : 'none',
                        color: getPriorityColor(detailedData.priority_name)
                    }}>●</span>
                    &nbsp;{detailedData.priority_name}
                </div>
                <div>ステータス</div>
                <div>{detailedData.status_name}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>受注日</div>
                <div>{detailedData.order_date}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>依頼日</div>
                <div>{detailedData.requested_date}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>予定日</div>
                <div>{detailedData.scheduled_date}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>完了日</div>
                <div>{detailedData.completion_date}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>修繕スペース</div>
                <div>{detailedData.room_num}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>件名</div>
                <div>{detailedData.subject}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>修繕箇所</div>
                <div>{detailedData.mending_location}</div>
            </div>
            <div className={classes.groundTwo}>
                <div style={{ height: VH(304) }}>修繕内容</div>
                <div style={{ height: VH(304) }}>
                    <div style={{ width: '100%', maxHeight: VH(304), overflowY: "scroll" }}>{detailedData.mending_content}</div>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>実施会社</div>
                <div>{detailedData.organizing_company}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>営業番号</div>
                <div>
                    <span style={{ color: '#0052CC', cursor: 'pointer' }} onClick={toBusiness}>{detailedData.business_record_no + '　' + detailedData.business_subject}</span>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>クレーム番号</div>
                <div>
                    <span style={{ color: '#0052CC', cursor: 'pointer' }} onClick={toClaim}>{detailedData.claim_record_no + '　' + detailedData.claim_subject}</span>
                </div>
            </div>
        </div>
    )
}

PropertyDetail_repair_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_repair_item);