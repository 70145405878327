import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_calendar_first() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24" style={{ display: 'block' }} >
            <rect width="24" height="24" rx="2" fill="#2F83FF" />
            <path d="M15.3333 11.1666H14.5V4.49998C14.5 3.58331 13.75 2.83331 12.8333 2.83331H11.1667C10.25 2.83331 9.5 3.58331 9.5 4.49998V11.1666H8.66667C6.36667 11.1666 4.5 13.0333 4.5 15.3333V21.1666H19.5V15.3333C19.5 13.0333 17.6333 11.1666 15.3333 11.1666ZM17.8333 19.5H16.1667V17C16.1667 16.5416 15.7917 16.1666 15.3333 16.1666C14.875 16.1666 14.5 16.5416 14.5 17V19.5H12.8333V17C12.8333 16.5416 12.4583 16.1666 12 16.1666C11.5417 16.1666 11.1667 16.5416 11.1667 17V19.5H9.5V17C9.5 16.5416 9.125 16.1666 8.66667 16.1666C8.20833 16.1666 7.83333 16.5416 7.83333 17V19.5H6.16667V15.3333C6.16667 13.9583 7.29167 12.8333 8.66667 12.8333H15.3333C16.7083 12.8333 17.8333 13.9583 17.8333 15.3333V19.5Z" fill="white" />
        </svg>

    )
}
export default PropertyDetail_calendar_first;


