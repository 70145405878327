import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter";




const styles = (theme) => ({
    ground: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #DDD',
        '& > div': {
            height: VH(24),
            fontSize: VW(16),
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            borderLeft: '4px solid #0052CC'
        },
        '& > div > div': {
            height: VH(24),
            fontSize: VW(16),
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            marginLeft: VW(12)
        }
    }
});

function Item_secondary_title(props) {
    const {
        classes,
        titleName,
        height,
        borderBottom,
    } = props





    return (
        <div className={classes.ground} style={{ minHeight: VH(height), borderBottom: borderBottom ? `1px solid #DDD` : '' }}>
            <div>
                <div>{titleName}</div>
            </div>
        </div>
    )
}

Item_secondary_title.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Item_secondary_title);