import { VW, VH } from "../../componets/function/sizeAdapter"
function PropertyDetail_file_upload() {
    return (
        <svg width={VW(24)} height={VW(24)} viewBox="0 0 24 24" fill="none">
            <path d="M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V8H20V18ZM8 13.01L9.41 14.42L11 12.84V17H13V12.84L14.59 14.43L16 13.01L12.01 9L8 13.01Z" fill="#2F83FF" />
        </svg>
    )
}
export default PropertyDetail_file_upload;


