import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import log from "../function/log";
import { ApiSetsubiDelete } from '../../network/api'
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import MenuHeader_property from "../icon/MenuHeader_property";

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    headerBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        marginTop: VH(16),
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center'
        },
        '& > div:nth-child(1)': {
            fontWeight: 'bold',
            width: VW(160),
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: VW(920)
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: '#E60024',
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail_equipment_delete(props) {
    const {
        classes,
        open,
        onClose,
        setsubi_id,
        getList,
    } = props

    useEffect(() => {
        if (open) {
            log(setsubi_id)
        } else {
            setTitleFlag(false)
            setRrrorOpen(false)
        }
    }, [open])


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);

    // delete api
    const toDelete = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        if (!titleFlag) {
            setTitleFlag(true)
        } else {
            let deleteList = []
            setsubi_id.map(item => {
                deleteList.push({ setsubi_id: item.setsubi_id })
            })
            ApiSetsubiDelete(deleteList).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    getList('delete')
                    onClose()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    // title text flag
    const [titleFlag, setTitleFlag] = useState(false)


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <MenuHeader_property />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>部屋の削除</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                {titleFlag ? <div style={{ color: '#E60024', fontSize: VW(16) }}>以下の部屋を削除します。<br />削除されたデータは復元できませんが、本当に削除しますか？</div> : <div style={{ fontSize: VW(16) }}>以下の部屋を削除します。</div>}
                {setsubi_id.length > 0 && setsubi_id.map((item, index) => {
                    return (
                        <div className={classes.headerBlock} key={index} style={{ borderTop: index == 0 ? '' : '0', marginTop: index == 0 ? '' : 0 }}>
                            <div>部屋番号</div>
                            <div>{item.room_num}号室</div>
                        </div>
                    )
                })
                }
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button danger onClick={toDelete}>削除</Button>
                </div>
            </div>
        </Modal>
    )
}

PropertyDetail_equipment_delete.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_equipment_delete);