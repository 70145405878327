import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import log from "../function/log";
import { VH, VW } from "../function/sizeAdapter";


const styles = (theme) => ({
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #DDD',
        minHeight: VH(74),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(74)
        },
        '& > div:nth-child(odd)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(even)': {
            width: '26%'
        }
    },
    groundTwo: {
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #DDD',
        minHeight: VH(56),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(74),
        },
        '& > div:nth-child(1)': {
            width: '24%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '76%'
        }
    }
});

function PropertyDetail_business_item(props) {
    const {
        classes,
        detailedData,
        getPriorityColor
    } = props

    useEffect(() => {
        log(detailedData)
    }, [])



    return (
        <div style={{ marginTop: VH(16) }}>
            <div style={{ borderTop: '1px solid #DDD'}} className={classes.groundTwo}>
                <div>営業番号</div>
                <div>{detailedData.business_record_no}</div>
            </div>
            <div className={classes.groundFour}>
                <div>提案日</div>
                <div>{detailedData.proposal_date}</div>
                <div>優先度</div>
                <div>
                    <span style={{
                        display: (detailedData.priority_name != null) ? '' : 'none',
                        color: getPriorityColor(detailedData.priority_name)
                    }}>●</span>
                    &nbsp;{detailedData.priority_name}
                </div>
            </div>
            <div className={classes.groundFour}>
                <div>対応期日</div>
                <div>{detailedData.compatible_date}</div>
                <div>ステータス</div>
                <div>{detailedData.status_name}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>担当者</div>
                <div>{detailedData.manager}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>オーナー</div>
                <div>{detailedData.owner_name}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>件名</div>
                <div>{detailedData.subject}</div>
            </div>
            <div className={classes.groundTwo}>
                <div style={{ height: VH(304) }}>提案内容</div>
                <div style={{ height: VH(304) }}>
                    <div style={{ width: '100%', maxHeight: VH(304), overflowY: "scroll" }}>{detailedData.proposal_content}</div>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div style={{ height: VH(304) }}>提案結果</div>
                <div style={{ height: VH(304) }}>
                    <div style={{ width: '100%', maxHeight: VH(304), overflowY: "scroll" }}>{detailedData.proposal_result}</div>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div style={{ height: VH(304) }}>備考</div>
                <div style={{ height: VH(304) }}>
                    <div style={{ width: '100%', maxHeight: VH(304), overflowY: "scroll" }}>{detailedData.remarks}</div>
                </div>
            </div>
        </div>
    )
}

PropertyDetail_business_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_business_item);