import { CaretDownOutlined } from '@ant-design/icons';
import { withStyles } from "@mui/styles";
import { DatePicker, Input, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import React, { useState } from "react";
import { VH, VW } from "../function/sizeAdapter";


const { Option } = Select;
const { TextArea } = Input;

const styles = (theme) => ({
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(74),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(74)
        },
        '& > div:nth-child(odd)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(even)': {
            width: '30%'
        },
        '& > div > .ant-select-single .ant-select-selector': {
            width: '100%',
            height: VH(50),
            alignItems: 'center',
        }
    },
    groundTwo: {
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(74),
        width: '100%',
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(74),
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '80%'
        },
        '& > div > Input': {
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
    }
});

function PropertyDetail_business_input(props) {
    const {
        classes,
        detailedData,
        changeDetailedData,
        proposedDate,
        changeProposedDate,
        compatibleDate,
        changeCompatibleDate,
        priorityValueOption
    } = props

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ステータス
    const [statusValueOption, setStatusValueOption] = useState([
        { value: '0', label: '提案中' },
        { value: '1', label: '保留' },
        { value: '2', label: '失注' },
        { value: '3', label: '受注' }
    ])



    return (
        <div style={{ marginTop: VH(16) }}>
            <div className={classes.groundTwo}>
                <div>営業番号</div>
                <div>{detailedData.business_record_no}</div>
            </div>
            <div className={classes.groundFour}>
                <div>提案日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        value={proposedDate}
                        format="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeProposedDate}
                    />
                </div>
                <div>優先度</div>
                <div>
                    <Select
                        style={{ width: VW(360), }}
                        placeholder='選択してください'
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.priority == undefined ? undefined : String(detailedData.priority)}
                        onChange={(event) => changeDetailedData(event, 'priority')}
                        allowClear
                        onClear={() => changeDetailedData(undefined, 'priority')}
                    >
                        {priorityValueOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>
                                    <span style={{ color: item.color }}>●</span>&nbsp;{item.label}
                                </Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className={classes.groundFour}>
                <div>対応期日</div>
                <div>
                    <DatePicker
                        style={{ width: VW(200), height: VH(50), fontSize: VW(16) }}
                        placeholder="YYYY/MM/DD"
                        value={compatibleDate}
                        format="YYYY/MM/DD"
                        locale={locale}
                        onChange={changeCompatibleDate}
                    />
                </div>
                <div>ステータス</div>
                <div>
                    <Select
                        style={{ width: VW(360) }}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        placeholder='選択してください'
                        suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                        value={detailedData.status == undefined ? undefined : String(detailedData.status)}
                        onChange={(event) => changeDetailedData(event, 'status')}
                        allowClear
                        onClear={() => changeDetailedData(undefined, 'status')}
                    >
                        {statusValueOption.map((item, index) => {
                            return (
                                <Option style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>担当者</div>
                <div>
                    <Input
                        style={{ width: VW(360) }}
                        placeholder="担当者"
                        value={detailedData.manager}
                        onChange={(event) => changeDetailedData(event.target.value, 'manager')}
                        maxLength={60}
                    />
                </div>
            </div>
            <div className={classes.groundTwo}>
                <div>オーナー</div>
                <div>{detailedData.owner_name}</div>
            </div>
            <div className={classes.groundTwo}>
                <div>件名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                <div>
                    <Input
                        style={{ width: '100%' }}
                        placeholder="件名を記入してください"
                        value={detailedData.subject}
                        onChange={(event) => changeDetailedData(event.target.value, 'subject')}
                        maxLength={60}
                    />
                </div>
            </div>
            <div style={{ height: VH(304) }} className={classes.groundTwo}>
                <div>提案内容<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(280) }}
                        placeholder="提案内容を記入してください"
                        value={detailedData.proposal_content}
                        onChange={(event) => changeDetailedData(event.target.value, 'proposal_content')}
                        maxLength={500}
                    />
                </div>
            </div>
            <div style={{ height: VH(304) }} className={classes.groundTwo}>
                <div>提案結果<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(280) }}
                        placeholder="提案結果を記入してください"
                        value={detailedData.proposal_result}
                        onChange={(event) => changeDetailedData(event.target.value, 'proposal_result')}
                        maxLength={500}
                    />
                </div>
            </div>
            <div style={{ height: VH(304) }} className={classes.groundTwo}>
                <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（500文字まで）</p></div>
                <div>
                    <TextArea
                        style={{ height: VH(280) }}
                        placeholder="備考を記入してください"
                        value={detailedData.remarks}
                        onChange={(event) => changeDetailedData(event.target.value, 'remarks')}
                        maxLength={500}
                    />
                </div>
            </div>
        </div>
    )
}

PropertyDetail_business_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_business_input);